import clone from 'lodash/clone';
import * as types from './constants';

const initState = {
  agencies: [],
  countries: [],
  listAgencies: [],
  listCreators: [],
  filterListCreators: [],
  lists: [],
  list: {},
  imageList: [],
  genres: [],
  interests: [],
  musicgenres: [],
  credentials: [],
  channelTokensCount: 0,
  channelTokens: [],
  invalidChannelTokens: [],
  creatorChannels: [],
  users: [],
  roles: [],
  scheduledExports: [],
  permissions: [],
  permissionsListType: '',
  userPermissions: [],
  userGroupMembersAddable: [],
  menus: [],
  menuItem: {},
  logs: [],
  facebookLive: {},
  ownerList: null,
  owner: {},
  credentialList: null,
  trialList: [],
  usageList: null,
  usageDaily: null,
  creatorChannelNameList: null,
  twitterProMediaAccounts: null,
  circulates: null,
  jobs: null,
  invites: {},
  invitesList: [],
  addOns: [],
  agency: {},
  plans: [],
  accountList: [],
};

export default function reducers(state, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.SET_ACCOUNT_LIST: {
      return { ...state, accountList: action.payload };
    }
    case types.SET_AGENCY: {
      return { ...state, agency: action.payload };
    }
    case types.SET_MENU_ITEM: {
      return {
        ...state,
        userPermissions: action.payload.userPermissions,
        roles: action.payload.roles,
        menus: action.payload.menus,
        menuItem: action.payload.menuItem,
      };
    }
    case types.SET_MENUS: {
      return { ...state, menus: action.payload };
    }
    case types.SET_USER_GROUP_MEMBERS_ADDABLE: {
      return { ...state, userGroupMembersAddable: action.payload };
    }
    case types.SET_USER_PERMISSIONS: {
      return { ...state, userPermissions: action.payload };
    }
    case types.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
        permissionsListType: action.permissionsListType,
      };
    }
    case types.SET_SCHEDULED_EXPORTS: {
      return { ...state, scheduledExports: action.payload };
    }
    case types.SET_ROLES: {
      return { ...state, roles: action.payload };
    }
    case types.SET_USERS: {
      return { ...state, users: action.payload };
    }
    case types.SET_LIST: {
      return { ...state, list: action.payload };
    }
    case types.SET_LISTS: {
      return { ...state, lists: action.payload };
    }
    case types.SET_CREATOR_CHANNELS: {
      return { ...state, creatorChannels: action.payload };
    }
    case types.SET_CHANNEL_TOKENS: {
      return { ...state, channelTokensCount: action.count, channelTokens: action.rows };
    }
    case types.SET_INVALID_CHANNEL_TOKENS: {
      return { ...state, invalidChannelTokens: action.payload };
    }
    case types.SET_CREATOR_CHANNEL_NAME_LIST: {
      return { ...state, creatorChannelNameList: action.payload };
    }
    case types.SET_TWITTER_PRO_MEDIA_ACCOUNTS: {
      return { ...state, twitterProMediaAccounts: action.payload };
    }
    case types.DELETE_TWITTER_PRO_MEDIA_ACCOUNT: {
      return {
        ...state,
        twitterProMediaAccounts: state.twitterProMediaAccounts.filter(
          (obj: any) => obj.channel.handle !== action.payload.handle,
        ),
      };
    }
    case types.SET_CREDENTIALS: {
      return { ...state, credentials: action.payload };
    }
    case types.SET_GENRES: {
      return { ...state, genres: action.payload };
    }
    case types.REFRESH_GENRES: {
      let target;
      switch (action.crud) {
        case 'add':
          target = clone(state[action.typename]);
          target.push(action.payload);
          break;
        case 'delete':
          target = clone(state[action.typename]).filter(
            (item: any) => item._id !== action.payload._id,
          );
          break;
        case 'edit':
          target = clone(state[action.typename]).map((item: any) =>
            item._id === action.payload._id ? action.payload : item,
          );
          break;
        default:
          break;
      }
      return { ...state, [action.typename]: target };
    }
    case types.SET_INTERESTS: {
      return { ...state, interests: action.payload };
    }
    case types.SET_MUSICGENRES: {
      return { ...state, musicgenres: action.payload };
    }
    case types.SET_LIST_AGENCIES: {
      return { ...state, listAgencies: action.payload };
    }
    case types.SET_LIST_CREATORS: {
      return { ...state, listCreators: action.payload };
    }
    case types.SET_COUNTRIES: {
      return { ...state, countries: action.payload };
    }
    case types.SET_AGENCIES: {
      return { ...state, agencies: action.payload };
    }
    case types.SET_IMAGE_LIST: {
      return { ...state, imageList: action.payload };
    }
    case types.SET_STUDIO_LOGINS: {
      return { ...state, logs: action.payload };
    }
    case types.SET_USER_OKTA_ID: {
      const { UserId, result = {}, importResult = {} } = action.payload;
      const index = state.users.findIndex((user: any) => user._id === UserId);
      if (index !== -1) {
        const users = [
          ...state.users.slice(0, index),
          { ...state.users[index], OktaId: result.id || importResult.id },
          ...state.users.slice(index + 1),
        ];
        return { ...state, users };
      }
      return state;
    }
    case types.SET_FACEBOOKLIVE: {
      return { ...state, facebookLive: action.payload };
    }
    case types.SET_OWNER_LIST: {
      return { ...state, ownerList: action.payload };
    }
    case types.REFRESH_OWNER_LIST: {
      let target;
      switch (action.crud) {
        case 'add':
          target = clone(state[action.typename]);
          if (!target) {
            target = [];
          }
          target.push(action.payload);
          break;
        case 'delete':
          target = clone(state[action.typename]).filter(
            (item: any) => item.id !== action.payload.id,
          );
          break;
        case 'edit':
          target = clone(state[action.typename]).map((item: any) =>
            item.id === action.payload.id ? action.payload : item,
          );
          break;
        default:
          break;
      }
      return { ...state, [action.typename]: target };
    }
    case types.SET_CREDENTIAL_LIST: {
      return { ...state, credentialList: action.payload };
    }
    case types.SET_TRIAL_LIST: {
      return { ...state, trialList: action.payload };
    }
    case types.SET_USAGE_LIST: {
      return { ...state, usageList: action.payload };
    }
    case types.SET_USAGE_DAILY: {
      return { ...state, usageDaily: action.payload };
    }
    case types.SET_INVITES: {
      return { ...state, invites: action.payload };
    }
    case types.SET_INVITES_LIST: {
      return { ...state, invitesList: action.payload };
    }
    case types.SET_ADDONS: {
      return { ...state, addOns: action.payload };
    }
    case types.SET_CIRCULATES: {
      return { ...state, circulates: action.payload.items };
    }
    case types.SET_CIRCULATE_JOBS: {
      return { ...state, jobs: action.payload };
    }
    case types.SET_PLAN_LIST: {
      return { ...state, plans: action.payload };
    }
    case types.SET_CIRCULATE_JOBS_STATUS: {
      const oldJobs = clone(state.jobs);
      action.payload.forEach((newJob: any) => {
        const oldJob = oldJobs.find((oldJob2: any) => newJob._id === oldJob2._id);
        if (oldJob) {
          Object.assign(oldJob, newJob);
        }
      });
      return { ...state, jobs: oldJobs };
    }
    default:
      return state;
  }
}
