import { merge } from 'lodash';
import statuses from 'statuses';
import cookies from '@/utils/cookies';
import { QueryError } from './error';

export enum RequestBase {
  default = 'default',
  analytics = 'analytics',
  ezreal = 'ezreal',
}

const QUERY_TIMEOUT = process.env.REACT_APP_AXIOS_TIMEOUT_MILLISECONDS
  ? +process.env.REACT_APP_AXIOS_TIMEOUT_MILLISECONDS
  : NaN;
const BASE_2_API = {
  [RequestBase.default]: process.env.REACT_APP_API_HOST_NODE!,
  [RequestBase.analytics]: process.env.REACT_APP_API_HOST_PYTHON!,
  [RequestBase.ezreal]: process.env.REACT_APP_API_HOST_EZREAL,
};

const createCancelablePromise = <T>(cancel: () => void) => {
  let resolve!: (value: T | PromiseLike<T>) => void;
  let reject!: (reason?: any) => void;
  const promise = new Promise<T>((r, j) => {
    resolve = r;
    reject = j;
  });

  (promise as CancelablePromise<T>).cancel = cancel;

  return {
    resolve,
    reject,
    promise: promise as CancelablePromise<T>,
  };
};

interface CancelablePromise<T> extends Promise<T> {
  cancel: () => void;
}

export interface RequestInitWithExtendsion extends RequestInit {
  base?: RequestBase;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}

export const fetcher = <T>(input: string, init?: RequestInitWithExtendsion) => {
  // TODO: Remove legacy token support after self-service complete
  const legacyToken = cookies.get('token');

  const controller = new AbortController();
  const { base = RequestBase.default, ...args } = merge<
    RequestInitWithExtendsion,
    RequestInitWithExtendsion,
    typeof init
  >(
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: controller.signal,
    },
    legacyToken
      ? {
          headers: {
            Authorization: `Bearer ${legacyToken}`,
          },
        }
      : {},
    init,
  );

  const { promise, resolve, reject } = createCancelablePromise<T>(() => controller.abort());

  if (QUERY_TIMEOUT) {
    setTimeout(() => {
      reject(new QueryError(-1, 'Query timeout'));
      controller.abort();
    }, QUERY_TIMEOUT);
  }

  fetch(`${BASE_2_API[base]}/api${input}`, args)
    .then((response) => {
      const { ok, status } = response;

      if (status === 204) {
        resolve(undefined as unknown as T);
        return Promise.resolve();
      }

      // All requests return JSON by default
      return response
        .json()
        .then((responseBody) => {
          if (!ok) {
            reject(new QueryError(status, responseBody?.message, responseBody));
          }

          // TODO: Handle other response errors

          resolve(responseBody);
        })
        .catch((error) => {
          // Response content isn't JSON
          let message = 'Unknown Response Error';
          try {
            message = statuses(status).toString();
          } catch {
            // do nothing
          }
          reject(new QueryError(status, message, error));
        });
    })
    .catch((error) => {
      if (error && error.name === 'AbortError') {
        // Request cancelled
        reject(new QueryError(0, error?.message, error));
      }

      // Internal error, no network request
      reject(new QueryError(-2, error?.message, error));
    });

  return promise;
};
