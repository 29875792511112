import { optionNewAPI } from '@/utils/helper';
import { apiEnums } from '@/utils/constant';
import client from '../Client/index';

export const getCreators = () =>
  client.get('/creators', null, optionNewAPI(apiEnums.creators) as any);

export const getGenres = () => client.get('/genres');

export const getCountries = (params?: any) => client.get('/countries/getForm', params);

export const getProfile = (params: any) =>
  client.get(
    `creators/profile/${params.id}?leader=${params.leader}&timeZone=${params.timeZone}`,
    null,
    optionNewAPI(apiEnums.creators) as any,
  );

export const getAccountInfo = (params: any) =>
  client.get(`creators/${params}?includePostCounts=true`);

export const getGenders = () => client.get('/genders');

export const getTalentGroups = () => client.get('/talentGroups');

export const postAccount = (params: any) => {
  // edit or add
  // _id start from 0, so must judge it's type is undefined or not
  const url = typeof params._id !== 'undefined' ? `/creators/${params._id}` : '/creators';
  return client.post(url, params);
};

export const transferChannels = (params: any) => {
  const url = '/creators/transfer_channels';
  return client.post(url, params);
};

export const deleteCreatorChannel = (params: any) =>
  client.delete(`/creatorChannels/${params.CreatorId}/${params.code}/${params._id}`);

export const lookupName = (params: any) =>
  client.get(`creators/nameLookup/${encodeURIComponent(params)}`);
export const lookupHandle = (params: any) => {
  const query = params.creatorId ? { CreatorId: params.creatorId } : {};
  return client.get(
    `creators/handleLookup/${encodeURIComponent(params.handle)}/${params.code}`,
    query,
  );
};

export const getAllCreators = () => client.get('/creators/nameListLight', null);
