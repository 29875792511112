import { createSelector } from 'reselect';
import * as types from './constants';

const selector = (state: any) => state[types.SCOPENAME];

export const getOverviewSelector = createSelector(selector, (state) => state.overview);

export const getNameListSelector = createSelector(selector, (state) => state.nameList);

export const getMediaChannelSelector = createSelector(selector, (state) => state.mediaChannel);

export const getOlapBenchMarks = createSelector(selector, (state) => state.olapBenchMarks);

export const creatorInfoSelector = createSelector(selector, (state) => state.creatorInfo);

export const campaignsSelector = createSelector(selector, (state) => state.campaigns);

export const searchedTagsSelector = createSelector(selector, (state) => state.searchedTags);

export const agencyIdSelector = createSelector(selector, (state) => state.agencyId);

export const agenciesSelector = createSelector(selector, (state) => state.agencies);

export const adminTagsSelector = createSelector(selector, (state) => state.adminTags);

export const storyListSelector = createSelector(selector, (state) => state.storyList);

export const creatorChannelIdSelector = createSelector(selector, (state) => state.creatorChannelId);

export const snapStorySelector = createSelector(selector, (state) => state.snapStory);

export const deletingCampaignIdsSelector = createSelector(
  selector,
  (state) => state.deletingCampaignIds,
);

export const deletingTagIdsSelector = createSelector(selector, (state) => state.deletingTagIds);

export const getChannelMappingsSelector = createSelector(
  selector,
  (state) => state.channelMappings,
);

export const mediaDetailSelector = createSelector(selector, (state) => state.mediaDetail);
