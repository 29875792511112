declare global {
  interface QueryKeyResponse {
    '/users/me': {
      _id: number;
      interKey: string;
      name: string;
      email: string;
      role: UserRole;
      abilities: string[];
      agencies: { _id: number; name: string; timeZone: string; maxAccountNumber: number }[];
      uiSettings: {
        country: string;
      };
      OktaId: string;
    };
  }
}

export enum UserRole {
  PowerUser = 'Power User',
  admin = 'admin',
  agency = 'agency',
  analytics = 'analytics',
  brand = 'brand',
  creator = 'creator',
  system = 'system',
  trial = 'trial',
  user = 'user',
}
