import * as types from './constants';

export function getScheduledExport(params: any) {
  return {
    type: types.GET_SCHEDULEDEXPORT,
    params,
  };
}

export function setScheduledExport(payload: any) {
  return {
    type: types.SET_SCHEDULEDEXPORT,
    payload,
  };
}

export function updateScheduledExport(payload: any) {
  return {
    type: types.UPDATE_SCHEDULEDEXPORT,
    payload,
  };
}

export function getDownloadByUser(params: any) {
  return {
    type: types.GET_DOWNLOADS,
    params,
  };
}

export function setDownloadByUser(payload: any) {
  return {
    type: types.SET_DOWNLOADS,
    payload,
  };
}

export function updateProfile(params: any) {
  return {
    type: types.UPDATE_PROFILE,
    params,
  };
}

export function changePassword(params: any, callback: any) {
  return {
    type: types.CHANGE_PASSWORD,
    params,
    callback,
  };
}

export function regenerateFile(params: any, callback: any) {
  return {
    type: types.REGENERATE,
    params,
    callback,
  };
}

export function deleteDownload(params: any, callback: any) {
  return {
    type: types.DELETE_DONWLOAD,
    params,
    callback,
  };
}

export function updateDownload(payload: any) {
  return {
    type: types.UPDATE_DONWLOAD,
    payload,
  };
}

export function removeDownload(payload: any) {
  return {
    type: types.REMOVE_DOWNLOAD,
    payload,
  };
}

export function unlinkSocial(params: any) {
  return {
    type: types.UNLINK_SOCIAL,
    params,
  };
}

export function updateCredentials(params: any) {
  return {
    type: types.UPDATE_CREDENTIALS,
    params,
  };
}
