export const SCOPENAME = 'module:settings';

export const GET_SCHEDULEDEXPORT = `${SCOPENAME}/GET_SCHEDULEDEXPORT`;
export const SET_SCHEDULEDEXPORT = `${SCOPENAME}/SET_SCHEDULEDEXPORT`;
export const UPDATE_SCHEDULEDEXPORT = `${SCOPENAME}/UPDATE_SCHEDULEDEXPORT`;

export const GET_DOWNLOADS = `${SCOPENAME}/GET_DOWNLOADS`;
export const SET_DOWNLOADS = `${SCOPENAME}/SET_DOWNLOADS`;

export const UPDATE_PROFILE = `${SCOPENAME}/UPDATE_PROFILE`;

export const CHANGE_PASSWORD = `${SCOPENAME}/CHANGE_PASSWORD`;

export const REGENERATE = `${SCOPENAME}/REGENERATE`;

export const DELETE_DONWLOAD = `${SCOPENAME}/DELETE_DONWLOAD`;
export const UPDATE_DONWLOAD = `${SCOPENAME}/UPDATE_DONWLOAD`;
export const REMOVE_DOWNLOAD = `${SCOPENAME}/REMOVE_DOWNLOAD`;

export const UNLINK_SOCIAL = `${SCOPENAME}/UNLINK_SOCIAL`;

export const UPDATE_CREDENTIALS = `${SCOPENAME}/UPDATE_CREDENTIALS`;
