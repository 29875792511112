import { createSelector } from 'reselect';
import * as types from './constants';

const selector = (state: any) => state[types.SCOPENAME];

export const campaignsSelector = createSelector(selector, (state) => state.campaigns);

export const foldersSelector = createSelector(selector, (state) => state.folders);

export const folderIdSelector = createSelector(selector, (state) => state.folderId);

export const campaignDetailSelector = createSelector(selector, (state) => state.campaignDetail);

export const campaignInfoSelector = createSelector(selector, (state) => state.campaignInfo);

export const reportSelector = createSelector(selector, (state) => state.report);

export const usersSelector = createSelector(selector, (state) => state.users);

export const AgencyIdSelector = createSelector(selector, (state) => state.AgencyId);

export const agenciesSelector = createSelector(selector, (state) => state.agencies);

export const creatorsSelector = createSelector(selector, (state) => state.creators);

export const keywordSelector = createSelector(selector, (state) => state.keyword);

export const activeTypeSelector = createSelector(selector, (state) => state.activeType);

export const olapBenchMarksSelector = createSelector(selector, (state) => state.olapBenchMarks);

export const searchedTagsSelector = createSelector(selector, (state) => state.searchedTags);
