import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { stableSortBy } from '@/utils/helper';
import * as types from './constants';
import { averageAccount, getRowData } from '../helper';
import { tabsConfig, contentTypes } from '../helper/config';
import calculateCompanyMetrics from '../helper/companyMetircs';
import { getTQueryData } from '@/query';

export const initFilter = {
  talentGroupId: '',
  month: 'Last30',
  channel: 'ALL',
  name: '',
  searchKeyword: '',
  dateBegin: '',
  dateEnd: '',
  perDateBegin: '',
  perDateEnd: '',
  metricsFilter: [],
  postType: contentTypes.ALL.value,
};

export const initPostSort = {
  columnKey: 'engagement',
  order: 'descend',
};

export const initGeneralSort = {
  sortFunc: (item: any, channel: any) => getRowData(item, 'views.current', channel, true),
  columnKey: 'totalViews',
  order: 'descend',
};

export const initState = {
  tab: tabsConfig.defaultTab,
  creators: [],
  originalCreators: [],
  filterCreators: [],
  allCreators: [],
  filters: {
    ...initFilter,
  },
  sorter: initGeneralSort,
  postSorter: initPostSort,
  averageCreator: {},
  talentGroups: [],
  lastUpdated: '',
  total: 0,
  isLeaderBoardSaving: false,
  customTalentGroupsCounts: {},
};

const validator = {
  channel: (item: any, filter: any) => {
    if (filter === 'ALL') {
      return true;
    }
    return get(item, 'channels', []).some((channel: any) => channel.code === filter);
  },
};

type ValidatorType = keyof typeof validator;
const filterValidator = (item: any, filterState: any, validPaths = []) =>
  validPaths.every((path) => {
    const filterValue = filterState[path];
    return validator[path as ValidatorType](item, filterValue);
  });

const setRank = (list: any, order: any) => {
  let { length } = list;
  return list.map((c: any, index: any) => {
    const rank = order === 'descend' ? index + 1 : length--;
    return { ...c, rank };
  });
};

export const sortPosts = (posts: any, sorter: any) => {
  const { columnKey, order } = sorter;

  if (columnKey === 'media_type' || columnKey === 'channel_creator_name') {
    return posts;
  }

  return setRank(posts, order);
};

export const filterAndSortCreators = (
  creators: any,
  filters: any,
  sorter: any,
  validPaths: any = [],
) => {
  const filterCreators = validPaths.length
    ? creators.filter((item: any) => filterValidator(item, filters, validPaths))
    : creators;
  const sortedCreators = stableSortBy(
    filterCreators,
    (item: any) => sorter.sortFunc(item, filters.channel),
    sorter.order === 'descend' ? 'desc' : 'asc',
  );
  if (sorter.columnKey !== 'account') {
    return setRank(sortedCreators, sorter.order);
  }
  return sortedCreators;
};

export const updateSortColumn = (filters: any, sorter: any) => {
  const currentSorter = { ...sorter };
  if (
    (filters.channel === 'INST' || filters.channel === 'TWT') &&
    (currentSorter.columnKey === 'totalViews' || currentSorter.columnKey === 'viewsVideos')
  ) {
    currentSorter.columnKey = 'totalEngagements';
    currentSorter.sortFunc = (item: any, channel: any) =>
      getRowData(item, 'engagement.all.current', channel, true);
  }
  return currentSorter;
};

const getCacheKey = (tab?: string) => {
  const uid = getTQueryData('/users/me')?._id || '';
  return `SI-Leaderboard-Fitler-${uid}-${tab?.toString() || ''}`;
};
const storeFilterToCache = (tab: string, filter: Partial<typeof initFilter>) => {
  const key = getCacheKey(tab);
  if (filter) {
    localStorage.setItem(key, JSON.stringify(filter));
  }
};

const removeCacheFilter = (tab: string) => {
  const key = getCacheKey(tab);
  localStorage.removeItem(key);
};

const getCacheFilter = (tab: string): Partial<typeof initFilter> => {
  const key = getCacheKey(tab);
  const filter = localStorage.getItem(key);
  try {
    return JSON.parse(filter as string);
  } catch (e) {
    return {};
  }
};

export default function reducers(state: any, action: any) {
  state = state || cloneDeep(initState);
  switch (action.type) {
    case types.SET_CREATORS: {
      const {
        creators: originalCreators,
        lastUpdated,
        total,
        tab,
        postSorter,
        ...extraFilters
      } = action.payload;

      const filters = { ...state.filters, ...extraFilters };
      const currentSorter = updateSortColumn(filters, state.sorter);
      let creators = originalCreators;
      if (tab === 'post') {
        return {
          ...state,
          filters,
          creators: sortPosts(creators, postSorter),
          postSorter,
          total: total || 0,
        };
      }

      if (tab === 'company') {
        creators = calculateCompanyMetrics(originalCreators);
      }
      const rankedFilteredCreators = filterAndSortCreators(creators, filters, currentSorter, [
        'channel',
      ]);
      const averageCreator = averageAccount(creators, tab);
      return {
        ...state,
        filters,
        averageCreator,
        creators,
        originalCreators,
        filterCreators: rankedFilteredCreators,
        lastUpdated: lastUpdated || '',
        sorter: currentSorter,
        total: total || 0,
      };
    }
    case types.SET_ALL_CREATORS: {
      const { allCreators } = action.payload;
      return { ...state, allCreators };
    }
    case types.UPDATE_FILTER: {
      const preFilters = { ...state.filters };
      const filters = { ...state.filters, ...action.payload };

      // TODO: need to be removed in the next enhancement for filter saving feature
      storeFilterToCache(state.tab, filters);

      let newSorter = state.sorter;
      if (filters.channel !== preFilters.channel) {
        newSorter = state.tab === 'post' ? { ...initPostSort } : { ...initGeneralSort };
      }

      // not pure
      if (filters.channel !== preFilters.channel) {
        if (state.tab === 'post') {
          return {
            ...state,
            filters,
            postSorter: newSorter,
          };
        }
        const currentSorter = updateSortColumn(filters, newSorter);
        const rankedFilteredCreators = filterAndSortCreators(
          state.creators,
          filters,
          currentSorter,
          ['channel'],
        );
        return {
          ...state,
          filters,
          sorter: currentSorter,
          filterCreators: rankedFilteredCreators,
        };
      }
      return { ...state, filters };
    }
    case types.UPDATE_GENERAL_SORTER: {
      const { current, preColumnKey } = action.payload;
      let rankedFilteredCreators = [];
      // same order column just reverse
      if (current.columnKey === preColumnKey && current.columnKey !== 'account') {
        rankedFilteredCreators = state.filterCreators.slice().reverse();
      } else {
        rankedFilteredCreators = filterAndSortCreators(
          state.filterCreators,
          state.filters,
          current,
        );
      }
      return { ...state, sorter: current, filterCreators: rankedFilteredCreators };
    }
    case types.SET_TALENTGROUPS: {
      return { ...state, talentGroups: action.payload };
    }
    case types.SET_LEADERBOARD_COUNTS: {
      return { ...state, customTalentGroupsCounts: action.payload };
    }
    case types.RESET_LEADERBOARD: {
      return cloneDeep(initState);
    }
    case types.UPDATE_FILTER_NAME: {
      const filters = { ...state.filters, name: action.payload };
      // TODO: need to be removed in the next enhancement for filter saving feature
      storeFilterToCache(state.tab, filters);
      return { ...state, filters };
    }
    case types.POST_LIST: {
      return { ...state, isLeaderBoardSaving: true };
    }
    case types.POST_LIST_SAVED: {
      return { ...state, isLeaderBoardSaving: false };
    }
    case types.RESET_FILTER: {
      const filters = cloneDeep(initState.filters);
      // TODO: need to be removed in the next enhancement for filter saving feature
      removeCacheFilter(state.tab);
      return {
        ...state,
        filters,
      };
    }
    case types.SET_TAB: {
      const nextTab = action.payload;
      // TODO: need to be removed in the next enhancement for filter saving feature
      const cacheFilter = getCacheFilter(nextTab);
      const filters = { ...initFilter, ...cacheFilter };

      return {
        ...state,
        tab: nextTab,
        filters,
        sorter: { ...initGeneralSort },
        postSorter: { ...initPostSort },
      };
    }
    default: {
      const extraFilters = getCacheFilter(state.tab);
      if (extraFilters) {
        state.filters = {
          ...state.filters,
          ...extraFilters,
        };
      }
      return state;
    }
  }
}
