export class QueryError extends Error {
  readonly name = 'QueryError';

  status: number;

  canceled: boolean;

  timeout: boolean;

  internal: boolean;

  origin?: any;

  constructor(status: number, message = 'Unknown error', origin: any | undefined = undefined) {
    super(message);
    this.status = status;
    this.canceled = status === 0;
    this.timeout = status === -1;
    this.internal = status === -2;
    this.origin = origin;
  }
}
