import { call, put, takeLatest, select } from 'redux-saga/effects';
import { message } from 'antd';
import isEqual from 'lodash/isEqual';
import withLoading from '@/sagaMiddleware';
import * as api from '@/service/TopContent';
import { getCurrentTimeZone } from '@/utils/time';
import * as actions from './actions';
import * as types from './constants';
import { queryParamsSelector } from './selectors';

export function* fetchTopContent({ type, params }: any) {
  try {
    const queryParams = yield select(queryParamsSelector);
    if (!isEqual(queryParams, params)) {
      if (params.startDate && params.endDate) {
        const reqParams = {
          ...params,
          timeZone: getCurrentTimeZone(),
        };
        const content = yield call(withLoading, api.getTopContent, type, reqParams);
        yield put(actions.setTopContent({ params, content }));
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchTopContent() {
  yield takeLatest(types.GET_TOP_CONTENT, fetchTopContent);
}

export default [watchFetchTopContent];
