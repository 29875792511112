import axios from 'axios';
import get from 'lodash/get';
import cookies from '@/utils/cookies';

/**
 * axios instance with Request Config
 * https://github.com/axios/axios
 */

export const baseURL = `${process.env.REACT_APP_API_HOST_NODE}/api`;

const clientConfig = {
  baseURL,
  timeout: `${process.env.REACT_APP_AXIOS_TIMEOUT_MILLISECONDS}`,
  // timeoutErrorMessage: 'Looks like the network is unstable, please try again later',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    // 'Accept-Encoding': 'gzip, deflate',
    accept: 'application/json',
  },
};

const errorStatus = {
  403: 'Forbidden',
} as any;

function successHandler(result: any) {
  const response = result.data;
  // if (result.request.responseURL.includes(process.env.REACT_APP_API_HOST_GO)) {
  //   response = result.data;
  // } else {
  //   response = result;
  // }

  if (response.code !== undefined) {
    if (response.code >= 200 && response.code < 300) {
      return response.data;
    }
    const reject = {
      code: response.code || 400,
      message: response.message || 'unknown errors3',
    };
    return Promise.reject(reject);
  }

  return response;
}

function errorHandler(error: any) {
  const { response } = error;
  let reject;
  if (axios.isCancel(error)) {
    return Promise.reject(error.message);
  }
  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    reject = {
      code:
        response.data.code || response.data.error_code || response.data.status || response.status,
      message:
        (response.data.errors &&
          ((response.data.errors.length === 1 && response.data.errors[0].message) ||
            (response.data.name === 'SequelizeUniqueConstraintError' &&
              response.data.errors.length >= 1 &&
              response.data.errors[0].message))) ||
        get(response.data.message, '[0].message') ||
        response.data.msg ||
        response.data.message ||
        response.data.error ||
        response.data.error_message ||
        errorStatus[response.data.code || response.status],
    };
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    reject = {
      code: 400,
      message: error.msg || error.message || 'unknown errors',
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    reject = {
      message: 'unknown errors',
    };
  }

  return Promise.reject(reject);
}

class Client {
  constructor(config?: any) {
    if (!this.instance) {
      this.instance = axios.create({
        ...clientConfig,
        ...config,
      });
      this.instance.interceptors.response.use(successHandler, errorHandler);
      this.token = '';
    }
  }

  instance: any;

  token: any;

  get(url: any, params?: any, option = {}) {
    const defaulOption = {
      url,
      params,
      method: 'GET',
    };
    return this.request(defaulOption, option);
  }

  post(url: any, data?: any, option = {}) {
    const defaulOption = {
      url,
      data,
      method: 'POST',
    };
    return this.request(defaulOption, option);
  }

  put(url: any, data?: any, option = {}) {
    const defaulOption = {
      url,
      data,
      method: 'PUT',
    };
    return this.request(defaulOption, option);
  }

  patch(url: any, data?: any, option = {}) {
    const defaulOption = {
      url,
      data,
      method: 'PATCH',
    };
    return this.request(defaulOption, option);
  }

  delete(url: any, data?: any, option = {}) {
    const defaulOption = {
      url,
      method: 'DELETE',
    };
    return this.request(defaulOption, option);
  }

  request(defaulOption?: any, option?: any) {
    if (!this.instance.defaults.headers.Authorization) {
      const token = cookies.get('token');
      if (token) {
        this.instance.defaults.headers.Authorization = `Bearer ${token}`;
      }
    }

    return this.instance.request({ ...defaulOption, ...option });
  }

  reset() {
    this.instance.defaults.headers.Authorization = undefined;
  }
}

const client = new Client();

export default client;
