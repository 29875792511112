export async function batchAsync(tasks: Array<() => Promise<any>>, length: number) {
  const result: any[] = [];

  const taskQue = tasks.reduce((prev, cur, ix) => {
    const curIx = Math.floor(ix / length);

    if (!prev[curIx]) {
      prev[curIx] = [];
    }
    prev[curIx].push(cur);

    return prev;
  }, new Array<Array<() => Promise<any>>>(Math.ceil(tasks.length / length)));

  await taskQue.reduce(
    (prev, cur) =>
      prev
        .then(() => Promise.all(cur.map((it) => it())))
        .then((res) => {
          result.push(...res);
        }),
    Promise.resolve(),
  );

  return result;
}
