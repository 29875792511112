export const CHANNEL_NAME = {
  YTB: 'YouTube',
  TWT: 'Twitter',
  TWTV: 'Twitter Videos',
  FB: 'Facebook',
  FBAD: 'Facebook Ads',
  INST: 'Instagram',
  IGS: 'Instagram Stories',
  IGD: 'Instagram Dark Posts',
  IGAD: 'Instagram Ads',
  IGDAD: 'Instagram Ad Dark Posts',
  TIKT: 'TikTok',
  SNAP: 'Snapchat',
  SNAPD: 'Snapchat Discover',
  SNAPM: 'Snapchat Marketing',
  // else channel
  WEB: 'Web',
  VINE: 'Vine',
  YNW: 'YouNow',
  POD: 'Podcast',
  PRSC: 'Periscope',
  TCH: 'Twitch',
};

export const CHANNEL_ICON = {
  YTB: 'fa-youtube',
  TWT: 'fa-twitter',
  TWTV: 'fa-twitter-square',
  FB: 'fa-facebook',
  FBAD: 'fa-facebook-square',
  INST: 'fa-instagram',
  IGS: 'fa-instagram',
  IGD: 'fa-instagram',
  IGAD: 'fa-instagram',
  IGDAD: 'fa-instagram',
  TIKT: 'fa-tiktok',
  SNAP: 'fa-snapchat',
  SNAPD: 'fa-snapchat-square',
  SNAPM: 'fa-snapchat-ghost',
  TCH: 'fa-twitch',
};

export const channelCodes = {
  YTB: 'youtube',
  TWT: 'twitter',
  INST: 'instagram',
  SNAP: 'snapchat',
  WEB: 'web',
  VINE: 'vine',
  FB: 'facebook',
  TIKT: 'tiktok',
  YNW: 'younow',
  POD: 'podcast',
  PRSC: 'periscope',
  SNAPD: 'snapchat discover',
  IGS: 'igstories',
  IGD: 'Instagram Drak Posts',
  SNAPM: 'Snapchat Marketing',
  TCH: 'twitch',
};

export const originalPlatform = {
  facebook: {
    title: 'Facebook',
    color: '#3b5998',
  },
  igstories: {
    title: 'IG Stories',
    color: '#cf1264',
  },
  instagram: {
    title: 'Instagram',
    color: '#9b6954',
  },
  snapchat: {
    title: 'Snapchat',
    color: '#fffc00',
  },
  twitter: {
    title: 'Twitter',
    color: '#55acee',
  },
  vine: {
    title: 'Vine',
    color: '#00b488',
  },
  youtube: {
    title: 'YouTube',
    color: '#ff0000',
  },
  tiktok: {
    title: 'TikTok',
    color: '#6df5ee',
  },
  facebookads: {
    title: 'Facebook Ads',
    color: '#486CBA',
  },
  instagramads: {
    title: 'Instagram Ads',
    color: 'purple',
  },
  twitch: {
    title: 'Twitch',
    color: '#9146FF',
  },
};

export const platform = {
  Facebook: {
    title: 'Facebook',
    color: '#3b5998',
  },
  'Instagram Stories': {
    title: 'IG Stories',
    color: '#cf1264',
  },
  Instagram: {
    title: 'Instagram',
    color: '#9b6954',
  },
  Snapchat: {
    title: 'Snapchat',
    color: '#fffc00',
  },
  Twitter: {
    title: 'Twitter',
    color: '#55acee',
  },
  Vine: {
    title: 'Vine',
    color: '#00b488',
  },
  YouTube: {
    title: 'YouTube',
    color: '#ff0000',
  },
  TikTok: {
    title: 'TikTok',
    color: '#6df5ee',
  },
  Twitch: {
    title: 'Twitch',
    color: '#9146FF',
  },
};

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const pageSizeOptions = ['25', '100', '200'];

export const apiEnums = {
  creators: false,
  analytics: false,
  campaigns: false,
  leaders: true,
  facebookLive: false,
  tags: false,
  unTags: false,
  ruleReviews: false,
  ruleApplys: false,
  olap: true,
  dashboard: true,
};

export const trialExpires = 21;

/**
 * only while the prev command is completed(handled=spawned), the next command can be sent to SQS
 * FacebookFetchPagePostsCommand    -> FacebookFetchPageStatsCommand
 * InstagramFetchAccountMediaCommand
 * TwitterFetchUserTimelineCommand
 * TwitterGnipActivateCommand       -> TwitterGnipGenerateFetchTweetsBackfillCommand
 * TwitterProMediaActivateCommand   -> TwitterGenerateFetchProMediaListCommand       -> TwitterGenerateFetchProMediaVideoCommand
 * YouTubeFetchChannelVideosCommand -> YouTubeFetchChannelVideosCommand
 */
export const BatchJobs = [
  { name: 'FacebookFetchPagePostsCommand', params: { next: true } },
  { name: 'FacebookFetchPageStatsCommand', params: { prev: 'FacebookFetchPagePostsCommand' } },
  { name: 'InstagramFetchAccountMediaCommand', params: {} },
  { name: 'TwitterFetchUserTimelineCommand', params: {} },
  { name: 'TwitterGnipActivateCommand', params: { next: true } },
  {
    name: 'TwitterGnipGenerateFetchTweetsBackfillCommand',
    params: { prev: 'TwitterGnipActivateCommand' },
  },
  { name: 'TwitterProMediaActivateCommand', params: { next: true } },
  {
    name: 'TwitterGenerateFetchProMediaListCommand',
    params: { next: true, prev: 'TwitterProMediaActivateCommand' },
  },
  {
    name: 'TwitterGenerateFetchProMediaVideoCommand',
    params: { prev: 'TwitterGenerateFetchProMediaListCommand' },
  },
  { name: 'YouTubeFetchChannelVideosCommand', params: { next: true } },
  { name: 'YouTubeFetchChannelStatsCommand', params: { prev: 'YouTubeFetchChannelVideosCommand' } },
];
