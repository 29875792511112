import client from '../Client';

export const getChannels = () =>
  client
    .get('/channels')
    .then((channels: any) =>
      channels.map((item: any) =>
        item.code === 'TIKT' ? { ...item, urlFormat: 'https://www.tiktok.com/@' } : item,
      ),
    );

export const addPostByUrl = (params: any) => client.post('channels/addPostByUrl', params);

export const bulkAddPostsByCSV = ({ campaignId, data }: any) =>
  client.post(`channels/processCSV/${campaignId}`, data);

export const findSocialId = (params: { handle: string; code: string }) =>
  client.post('channels/findSocialId', params);
