export const SCOPENAME = 'module:accounts';

export const GET_ACCOUNTS = `${SCOPENAME}/GET_ACCOUNTS`;

export const SET_CREATORS = `${SCOPENAME}/SET_CREATORS`;

export const GET_ALL_CREATORS = `${SCOPENAME}/GET_ALL_CREATORS`;

export const SET_ALL_CREATORS = `${SCOPENAME}/SET_ALL_CREATORS`;

export const GET_GENRES = `${SCOPENAME}/GET_GENRES`;
export const SET_GENRES = `${SCOPENAME}/SET_GENRES`;

export const SET_COUNTRIES = `${SCOPENAME}/SET_COUNTRIES`;

export const GET_COUNTRIES = `${SCOPENAME}/GET_COUNTRIES`;

export const GET_PROFILE = `${SCOPENAME}/GET_PROFILE`;
export const SET_PROFILE = `${SCOPENAME}/SET_PROFILE`;

export const GET_ACCOUNT_INFO = `${SCOPENAME}/GET_ACCOUNT_INFO`;
export const SET_ACCOUNT_INFO = `${SCOPENAME}/SET_ACCOUNT_INFO`;

export const GET_GENDERS = `${SCOPENAME}/GET_GENDERS`;
export const SET_GENDERS = `${SCOPENAME}/SET_GENDERS`;

export const SET_TALENTGROUPS = `${SCOPENAME}/SET_TALENTGROUPS`;
export const GET_TALENTGROUPS = `${SCOPENAME}/GET_TALENTGROUPS`;

export const POST_ACCOUNT = `${SCOPENAME}/POST_ACCOUNT`;
export const POST_TRANSFER_CHANNELS = `${SCOPENAME}/POST_TRANSFER_CHANNELS`;

export const DELETE_CREATOR_CHANNEL = `${SCOPENAME}/DELETE_CREATOR_CHANNEL`;
