import * as Sentry from '@sentry/react';
import { resetTimeZone } from '@/utils/time';
import { isProductionEnv } from '@/utils/helper';

export const init = (data: QueryKeyResponse['/users/me']) => {
  const { email, name, role, agencies } = data;
  const [agency] = agencies;
  const timezone = agency.timeZone.trim();

  resetTimeZone(timezone);

  if (process.env.NODE_ENV !== 'production' || !isProductionEnv()) {
    return;
  }

  // Update sentry user info
  Sentry.setUser({ email });

  // Pendo initialize
  const { pendo } = window;

  if (pendo) {
    pendo.initialize({
      apiKey: '7df8727f-b771-4e46-6ae1-f3c3bb45f266',
      visitor: {
        id: email,
        name,
        email,
        role,
        timezone,
      },
      account: {
        // As suggested by Chris current user and aggency are 1:1 mapping so we can use agency info as account info
        // if such relationship changes in the future then we need to change this pendo integration as well
        id: agency.name,
      },
    });
  }
};
