import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import withLoading from '@/sagaMiddleware';
import * as api from '@/service/Settings';
import * as actions from './actions';
import * as types from './constants';

export function* fetchDownloads({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.getDownload, type, params);
    if (!res || res.err) {
      yield call(
        message.error,
        'Invalid download key. Please check your URL. The file may have been permanently deleted.',
      );
    } else {
      yield put(actions.setDownload(res));
    }
  } catch (err) {
    yield call(
      message.error,
      'Invalid download key. Please check your URL. The file may have been permanently deleted.',
    );
  }
}
function* watchFetchDownloads() {
  yield takeLatest(types.GET_DOWNLOAD, fetchDownloads);
}

export default [watchFetchDownloads];
