import { call, takeLatest, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import * as api from '@/service/Statics';
import * as types from './constants';
import * as actions from './actions';
import * as selectors from './selectors';

export function* fetchS3Urls() {
  try {
    const s3Urls = yield select(selectors.s3UrlsSelector);
    if (isEmpty(s3Urls)) {
      const res = yield call(api.getS3Urls);
      yield put(actions.setS3Urls(res));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchS3Urls() {
  yield takeLatest(types.GET_S3URLS, fetchS3Urls);
}

export default [watchFetchS3Urls];
