import * as types from './constants';

const initState = {
  downloads: [],
};

export default function reducers(state, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.SET_DOWNLOAD: {
      return { ...state, downloads: [action.payload] };
    }
    default:
      return state;
  }
}
