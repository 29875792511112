import * as types from './constants';

export function getAccounts() {
  return {
    type: types.GET_ACCOUNTS,
  };
}

export function setCreators(payload: any) {
  return {
    type: types.SET_CREATORS,
    payload,
  };
}

export function getAllCreators() {
  return {
    type: types.GET_ALL_CREATORS,
  };
}

export function setAllCreators(payload: any) {
  return {
    type: types.SET_CREATORS,
    payload,
  };
}

export function getGenres() {
  return {
    type: types.GET_GENRES,
  };
}

export function setGenres(payload: any) {
  return {
    type: types.SET_GENRES,
    payload,
  };
}

export function setCountries(payload: any) {
  return {
    type: types.SET_COUNTRIES,
    payload,
  };
}

export function getCountries(params: any) {
  return {
    type: types.GET_COUNTRIES,
    params,
  };
}

export function getProfile(params: any) {
  return {
    type: types.GET_PROFILE,
    params,
  };
}

export function setProfile(payload: any) {
  return {
    type: types.SET_PROFILE,
    payload,
  };
}

export function getAccountInfo(params: any) {
  return {
    type: types.GET_ACCOUNT_INFO,
    params,
  };
}

export function setAccountInfo(payload: any) {
  return {
    type: types.SET_ACCOUNT_INFO,
    payload,
  };
}

export function getGenders() {
  return {
    type: types.GET_GENDERS,
  };
}

export function setGenders(payload: any) {
  return {
    type: types.SET_GENDERS,
    payload,
  };
}

export function getTalentGroups() {
  return {
    type: types.GET_TALENTGROUPS,
  };
}

export function setTalentGroups(payload: any) {
  return {
    type: types.SET_TALENTGROUPS,
    payload,
  };
}

export function postAccount(params: any, autoAdd: any, rootPath: string, callback: any) {
  return {
    type: types.POST_ACCOUNT,
    params,
    autoAdd,
    rootPath,
    callback,
  };
}

export function postTransferChannels(params: any, callback: any) {
  return {
    type: types.POST_TRANSFER_CHANNELS,
    params,
    callback,
  };
}

export function deleteCreatorChannel(payload: any, callback: any) {
  return {
    type: types.DELETE_CREATOR_CHANNEL,
    payload,
    callback,
  };
}
