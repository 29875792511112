import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';
import set from 'lodash/set';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { optionNewAPI, capitalize } from '@/utils/helper';
import { apiEnums } from '@/utils/constant';
import client from '../Client/index';

const FACEBOOK_BRANDED_VALIDATOR = 'frontend interceptor';

const formatRuleFilter = (opts: any) => {
  const params = cloneDeep(opts);
  const criteria = get(params, 'ruleSet.criteria', []);

  const newCriteria = criteria.map((item: any) => {
    if (get(item, 'type') !== 'fbSponsorTag') return item;

    const data = get(item, 'data', [])
      .map((dt: any) => (isEmpty(get(dt, 'data')) ? null : dt))
      .filter(Boolean);

    return { ...item, data };
  });
  set(params, 'ruleSet.criteria', newCriteria);
  return params;
};

const formatRuleFilterForDetail = (params: any) => {
  const rules = get(params, 'rules', []).map((rule: any) => formatRuleFilter(rule));
  return rules.some((item: any) => item === false) ? false : { ...params, rules };
};

export const getAgenciesNameList = () => client.get('/agencies/nameList');

export const getTags = (params: any) => {
  const tgtUrl =
    'id' in params /* for admin */ ? `contentTags/agency/${params.id}` : 'contentTags/agency';

  return client.get(tgtUrl);
};

export const getTagRules = async (params: any) => {
  const res = await client.get(`/contentTags/rules/${params.id}`);

  return res?.map((it) => {
    const { contentType } = it.ruleSet.filters;

    const WTFContentType = {
      text: true,
      photo: true,
      video: true,
      minTime: '00:00:00',
      maxTime: '00:00:00',
    };

    if (contentType) {
      Object.assign(WTFContentType, contentType);
    }

    if (!WTFContentType.photo && !WTFContentType.text && !WTFContentType.video) {
      WTFContentType.photo = true;
      WTFContentType.text = true;
      WTFContentType.video = true;
    }

    it.ruleSet.filters.contentType = WTFContentType;

    return it;
  });
};

export const getTagPostCount = (params: any) => client.get(`/contentTags/count/${params.id}`);

export const getCreators = (params: any) =>
  client.get('creators/nameList', {
    simple: true,
    ...params,
  });

export const byPostAndChannel = (params: any) =>
  client.get(`/contentTags/byPost/${params.postId}/${params.channel}`, {
    AgencyId: params.agencyId,
  });

export const searchTags = (params: any) => {
  // assign target agencyId for admin role
  const agencyId = ['AgencyId', 'agencyId'].reduce((prev, key) => {
    if (params && key in params) {
      prev = params[key];
    }
    return prev;
  }, undefined);

  return client.get('/contentTags/search', {
    AgencyId: agencyId,
    search: params.search,
  });
};

export const tagPost = (params: any) => {
  if (apiEnums.tags) {
    return client.post(
      `contentTags/tagPost/${params.contentTagId || params.ContentTagId}`,
      params,
      optionNewAPI(apiEnums.tags) as any,
    );
  }

  const formatter = (p: any) =>
    mapKeys(p, (value, key) =>
      ['agencyId', 'channelCode', 'contentTagId', 'postId'].includes(key) ? capitalize(key) : key,
    );
  return client.post(
    `contentTags/tagPost/${params.postId || params.PostId}`,
    pick(formatter(params), ['ChannelCode', 'ContentTagId', 'PostId', 'AgencyId']),
    optionNewAPI(apiEnums.tags) as any,
  );
};

export const unTagAdminPostTag = (params: any) =>
  client.post(
    `contentTags/untagPost/${params.contentTagId}/${params.postId}`,
    {},
    optionNewAPI(apiEnums.unTags) as any,
  );

export const untagPost = (params: any) => {
  /*
  return client.post(`contentTags/untagPost/${params.ContentTagId}/${params.PostId}`, params);
   */
  if (apiEnums.unTags) {
    return client.post(
      `contentTags/untagPost/${params.contentTagId || params.ContentTagId}`,
      params,
      optionNewAPI(apiEnums.unTags) as any,
    );
  }

  return client.post(
    `contentTags/untagPost/${params.contentTagId}/${params.postId}`,
    {
      ChannelId: params.channelId,
    },
    optionNewAPI(apiEnums.unTags) as any,
  );
};

export const getFolders = (params: any) =>
  client.post('contentTags/folders', { AgencyId: params.id });

export const modifyFolders = (params: any) => {
  let res;
  switch (params.crud) {
    case 'edit':
      res = client.patch(`/contentTags/folder/${params.payload._id}`, params.payload);
      break;
    case 'add':
      res = client.post('/contentTags/folder', params.payload);
      break;
    case 'delete':
      res = client.delete(`/contentTags/folder/${params.payload._id}/false`);
      break;
    default:
      break;
  }
  return res;
};

export const modifyTagDetail = (params: any) => {
  let res;
  let param;
  switch (params.crud) {
    case 'edit':
    case 'move':
      param = formatRuleFilterForDetail(params.payload);
      res = param
        ? client.post(`/contentTags/full/${params.payload._id}`, param)
        : Promise.resolve(FACEBOOK_BRANDED_VALIDATOR);
      break;
    case 'add':
      param = formatRuleFilterForDetail(params.payload);
      res = param
        ? client.post('/contentTags/full', param)
        : Promise.resolve(FACEBOOK_BRANDED_VALIDATOR);
      break;
    case 'delete':
      res = client.delete(`/contentTags/${params.payload}`);
      break;
    default:
      break;
  }
  return res;
};

export const applyRules = (params: any) => {
  if (apiEnums.ruleApplys) {
    return client.post('contentTags/rule/apply', params.payload, optionNewAPI(true) as any);
  }
  return client.post(
    `contentTags/rule/${params.payload._id}/apply`,
    params.payload,
    optionNewAPI(apiEnums.ruleApplys) as any,
  );
};

export const saveRules = (params: any) =>
  client.post(
    `contentTags/rules/${params.payload._id}`,
    params.payload,
    optionNewAPI(false) as any,
    /* optionNewAPI('CONTENT_TAGS_RULE_APPLY') */
  );

export const deleteRulePreview = (params: any) =>
  client.get(`contentTags/ruleDeletePreview/${params._id}`);

export const deleteRule = (params: any) => client.delete(`contentTags/rule/${params._id}`);

export const createContentTags = (params: any) => {
  if (!apiEnums.tags) {
    params.AgencyId = params.agencyId;
  }

  return client.post('contentTags', params);
};

export const previewMatches = (params: any) => {
  const param = formatRuleFilter(params);
  if (!param) {
    return Promise.resolve(FACEBOOK_BRANDED_VALIDATOR);
  }
  return client.post('/contentTags/rule/preview', param, optionNewAPI(apiEnums.ruleReviews) as any);
};

export const getCriteriaTagList = (params: any) => client.get(`/facebook/searchTag/${params}`);

export const getLogoList = (params: string) =>
  client.get(`/contentTags/searchLogo/${encodeURIComponent(params)}`);
