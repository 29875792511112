import * as types from './constants';

export function getAccountList(searchTerm: any, agencyId: any) {
  return {
    type: types.GET_ACCOUNT_LIST,
    searchTerm,
    agencyId,
  };
}

export function setAccountList(payload: any) {
  return {
    type: types.SET_ACCOUNT_LIST,
    payload,
  };
}

export function getCountries() {
  return {
    type: types.GET_COUNTRIES,
  };
}

export function setCountries(payload: any) {
  return {
    type: types.SET_COUNTRIES,
    payload,
  };
}

export function setCountryActive(payload: any) {
  payload.active = !payload.active;
  return {
    type: types.SET_COUNTRY_ACTIVE,
    payload,
  };
}

export function getStudioLogins(payload: any) {
  return {
    type: types.GET_STUDIO_LOGINS,
    payload,
  };
}

export function setStudioLogins(payload: any) {
  return {
    type: types.SET_STUDIO_LOGINS,
    payload,
  };
}

export function getList(payload: any, callback: any) {
  return {
    type: types.GET_LIST,
    payload,
    callback,
  };
}

export function setList(payload: any) {
  return {
    type: types.SET_LIST,
    payload,
  };
}

export function getLists(payload: any, callback: any) {
  return {
    type: types.GET_LISTS,
    payload,
    callback,
  };
}

export function setLists(payload: any) {
  return {
    type: types.SET_LISTS,
    payload,
  };
}

export function postList(payload: any, callback: any) {
  return {
    type: types.POST_LIST,
    payload,
    callback,
  };
}

export function getListCreators(payload: any, callback: any) {
  return {
    type: types.GET_LIST_CREATORS,
    payload,
    callback,
  };
}

export function setListCreators(payload: any) {
  return {
    type: types.SET_LIST_CREATORS,
    payload,
  };
}

export function getListAgencies(payload: any, callback: any) {
  return {
    type: types.GET_LIST_AGENCIES,
    payload,
    callback,
  };
}

export function setListAgencies(payload: any) {
  return {
    type: types.SET_LIST_AGENCIES,
    payload,
  };
}

export function getAgencies(payload?: any, callback?: any) {
  return {
    type: types.GET_AGENCIES,
    payload,
    callback,
  };
}

export function setAgencies(payload: any) {
  return {
    type: types.SET_AGENCIES,
    payload,
  };
}

export function postAgency(payload: any, callback: any) {
  return {
    type: types.POST_AGENCY,
    payload,
    callback,
  };
}

export function getImageList(payload: any) {
  return {
    type: types.GET_IMAGE_LIST,
    payload,
  };
}

export function setImageList(payload: any) {
  return {
    type: types.SET_IMAGE_LIST,
    payload,
  };
}

export function getGenres() {
  return {
    type: types.GET_GENRES,
  };
}
export function setGenres(payload: any) {
  return {
    type: types.SET_GENRES,
    payload,
  };
}

export function getInterests() {
  return {
    type: types.GET_INTERESTS,
  };
}
export function setInterests(payload: any) {
  return {
    type: types.SET_INTERESTS,
    payload,
  };
}

export function getMusicGenres() {
  return {
    type: types.GET_MUSICGENRES,
  };
}

export function setMusicGenres(payload: any) {
  return {
    type: types.SET_MUSICGENRES,
    payload,
  };
}

export function modifyGenres(typename: any, crud: any, payload: any, callback: any) {
  return {
    type: types.MODIFY_GENRES,
    typename,
    crud,
    payload,
    callback,
  };
}

export function refreshGenres(typename: any, crud: any, payload: any) {
  return {
    type: types.REFRESH_GENRES,
    typename,
    crud,
    payload,
  };
}

export function getCredentials() {
  return {
    type: types.GET_CREDENTIALS,
  };
}
export function setCredentials(payload: any) {
  return {
    type: types.SET_CREDENTIALS,
    payload,
  };
}

export function getChannelTokens(payload: any) {
  return {
    type: types.GET_CHANNEL_TOKENS,
    payload,
  };
}

export function getInvalidChannelTokens(payload: any, callback: any) {
  return {
    type: types.GET_INVALID_CHANNEL_TOKENS,
    payload,
    callback,
  };
}

export function setInvalidChannelTokens(payload: any) {
  return {
    type: types.SET_INVALID_CHANNEL_TOKENS,
    payload,
  };
}

export function notifyInvalidToken(payload: any) {
  return {
    type: types.NOTIFY_INVALID_TOKEN,
    payload,
  };
}

export function setChannelTokens(count: any, rows: any) {
  return {
    type: types.SET_CHANNEL_TOKENS,
    count,
    rows,
  };
}

export function getCreatorChannels() {
  return {
    type: types.GET_CREATOR_CHANNELS,
  };
}

export function setCreatorChannels(payload: any) {
  return {
    type: types.SET_CREATOR_CHANNELS,
    payload,
  };
}

export function getUsers(payload: any) {
  return {
    type: types.GET_USERS,
    payload,
  };
}

export function setUsers(payload: any) {
  return {
    type: types.SET_USERS,
    payload,
  };
}

export function getRoles() {
  return {
    type: types.GET_ROLES,
  };
}

export function setRoles(payload: any) {
  return {
    type: types.SET_ROLES,
    payload,
  };
}

export function postUser(payload: any, callback: any) {
  return {
    type: types.POST_USER,
    payload,
    callback,
  };
}

export function nullifyUserPassword(payload: any) {
  return {
    type: types.NULLIFY_USER_PASSWORD,
    payload,
  };
}

export function expireUserPassword(payload: any) {
  return {
    type: types.EXPIRE_USER_PASSWORD,
    payload,
  };
}

export function getScheduledExports(payload: any) {
  return {
    type: types.GET_SCHEDULED_EXPORTS,
    payload,
  };
}

export function setScheduledExports(payload: any) {
  return {
    type: types.SET_SCHEDULED_EXPORTS,
    payload,
  };
}

export function cancelScheduledExports(payload: any, callback: any) {
  return {
    type: types.CANCEL_SCHEDULED_EXPORTS,
    payload,
    callback,
  };
}

export function excuteScheduledExports(payload: any, force: boolean, callback: any) {
  return {
    type: types.EXCUTE_SCHEDULED_EXPORTS,
    payload,
    force,
    callback,
  };
}

export function editScheduledExport(payload: any, callback: any) {
  return {
    type: types.EDIT_SCHEDULED_EXPORT,
    payload,
    callback,
  };
}

export function resetLastScheduledExport(payload: any, callback: any) {
  return {
    type: types.RESET_LAST_SCHEDULED_EXPORT,
    payload,
    callback,
  };
}

export function getPermissions(payload: any) {
  return {
    type: types.GET_PERMISSIONS,
    payload,
  };
}

export function setPermissions(payload: any, permissionsListType: any) {
  return {
    type: types.SET_PERMISSIONS,
    payload,
    permissionsListType,
  };
}

export function modifyPermission(typename: any, crud: any, payload: any, callback: any) {
  return {
    type: types.MODIFY_PERMISSION,
    typename,
    crud,
    payload,
    callback,
  };
}

export function getUserGroupMembers(payload: any) {
  return {
    type: types.GET_USER_GROUP_MEMBERS,
    payload,
  };
}

export function getUserGroupPermissions(payload: any) {
  return {
    type: types.GET_USER_GROUP_PERMISSIONS,
    payload,
  };
}

export function getUserRolePermissions(payload: any) {
  return {
    type: types.GET_USER_ROLE_PERMISSIONS,
    payload,
  };
}

export function deleteUserGroupMember(groupId: any, memberId: any, callback: any) {
  return {
    type: types.DELETE_USER_GROUP_MEMBER,
    groupId,
    memberId,
    callback,
  };
}

export function deleteUserGroupPermission(groupId: any, permissionId: any, callback: any) {
  return {
    type: types.DELETE_USER_GROUP_PERMISSION,
    groupId,
    permissionId,
    callback,
  };
}

export function deleteUserRolePermission(roleId: any, permissionId: any, callback: any) {
  return {
    type: types.DELETE_USER_ROLE_PERMISSION,
    roleId,
    permissionId,
    callback,
  };
}

export function getUserPermissions(callback: any) {
  return {
    type: types.GET_USER_PERMISSIONS,
    callback,
  };
}

export function setUserPermissions(payload: any) {
  return {
    type: types.SET_USER_PERMISSIONS,
    payload,
  };
}

export function getUserGroupMembersAddable(payload: any, callback: any) {
  return {
    type: types.GET_USER_GROUP_MEMBERS_ADDABLE,
    payload,
    callback,
  };
}

export function setUserGroupMembersAddable(payload: any) {
  return {
    type: types.SET_USER_GROUP_MEMBERS_ADDABLE,
    payload,
  };
}

export function getMenus(payload: any) {
  return {
    type: types.GET_MENUS,
    payload,
  };
}

export function setMenus(payload: any) {
  return {
    type: types.SET_MENUS,
    payload,
  };
}

export function getMenuItem(payload: any) {
  return {
    type: types.GET_MENU_ITEM,
    payload,
  };
}

export function setMenuItem(payload: any) {
  return {
    type: types.SET_MENU_ITEM,
    payload,
  };
}

export function modifyMenuItem(crud: any, payload: any, callback: any) {
  return {
    type: types.MODIFY_MENU_ITEM,
    crud,
    payload,
    callback,
  };
}

export function getChannelTokensByUserId(payload: any, callback: any) {
  return {
    type: types.GET_CHANNEL_TOKENS_BY_USER_ID,
    payload,
    callback,
  };
}

export function updateCredential(payload: any, callback: any) {
  return {
    type: types.UPDATE_CREDENTIAL,
    payload,
    callback,
  };
}

export function importUserIntoOkta(params: any, callback: any) {
  return {
    type: types.IMPORT_USER_IN_OKTA,
    params,
    callback,
  };
}

export function setUserOktaId(payload: any) {
  return {
    type: types.SET_USER_OKTA_ID,
    payload,
  };
}

export function getOwnerList(payload: any) {
  return {
    type: types.GET_OWNER_LIST,
    payload,
  };
}

export function setOwnerList(payload: any) {
  return {
    type: types.SET_OWNER_LIST,
    payload,
  };
}

export function getPlanList() {
  return {
    type: types.GET_PLAN_LIST,
  };
}

export function setPlanList(payload) {
  return {
    type: types.SET_PLAN_LIST,
    payload,
  };
}

export function getUsageList(payload: any) {
  return {
    type: types.GET_USAGE_LIST,
    payload,
  };
}

export function setUsageList(payload: any) {
  return {
    type: types.SET_USAGE_LIST,
    payload,
  };
}

export function getUsageDaily(payload: any) {
  return {
    type: types.GET_USAGE_DAILY,
    payload,
  };
}

export function setUsageDaily(payload: any) {
  return {
    type: types.SET_USAGE_DAILY,
    payload,
  };
}

export function getCreatorChannelNameList(payload: any) {
  return {
    type: types.GET_CREATOR_CHANNEL_NAME_LIST,
    payload,
  };
}

export function setCreatorChannelNameList(payload: any) {
  return {
    type: types.SET_CREATOR_CHANNEL_NAME_LIST,
    payload,
  };
}

export function getTwitterProMediaAccounts(payload: any) {
  return {
    type: types.GET_TWITTER_PRO_MEDIA_ACCOUNTS,
    payload,
  };
}

export function setTwitterProMediaAccounts(payload: any) {
  return {
    type: types.SET_TWITTER_PRO_MEDIA_ACCOUNTS,
    payload,
  };
}

export function deleteTwitterProMediaAccount(payload: any) {
  return {
    type: types.DELETE_TWITTER_PRO_MEDIA_ACCOUNT,
    payload,
  };
}

export function activeTwitterProMediaAccount(payload: any, callback: any) {
  return {
    type: types.ACTIVE_TWITTER_PRO_MEDIA_ACCOUNT,
    payload,
    callback,
  };
}

export function modifyOwner(crud: any, payload: any, callback: any) {
  return {
    type: types.MODIFY_OWNER,
    crud,
    payload,
    callback,
  };
}

export function deleteOwner(payload: any, callback: any) {
  return {
    type: types.DELETE_OWNER,
    payload,
    callback,
  };
}
export function refreshOwnerList(crud: any, typename: any, payload: any) {
  return {
    type: types.REFRESH_OWNER_LIST,
    crud,
    typename,
    payload,
  };
}

export function getCredentialList(payload: any) {
  return {
    type: types.GET_CREDENTIAL_LIST,
    payload,
  };
}

export function setCredentialList(payload: any) {
  return {
    type: types.SET_CREDENTIAL_LIST,
    payload,
  };
}

export function modifyAPICredential(crud: any, payload: any, callback: any) {
  return {
    type: types.MODIFY_API_CREDENTIAL,
    crud,
    payload,
    callback,
  };
}

export function checkFacebookLive(payload: any) {
  return {
    type: types.CHECK_FACEBOOKLIVE,
    payload,
  };
}

export function setFacebookLive(payload: any) {
  return {
    type: types.SET_FACEBOOKLIVE,
    payload,
  };
}

export function launchFacebookLive(payload: any, callback: any) {
  return {
    type: types.LAUNCH_FACEBOOKLIVE,
    payload,
    callback,
  };
}

export function createTrialUser(payload: any, callback: any) {
  return {
    type: types.CREATE_TRIAL_USER,
    payload,
    callback,
  };
}

export function getTrialUserList(params?: any) {
  return {
    type: types.GET_TRIAL_LIST,
    params,
  };
}

export function setTrialUserList(payload: any) {
  return {
    type: types.SET_TRIAL_LIST,
    payload,
  };
}

export function resendTrialConfirmRequest(payload: any, callback: any) {
  return {
    type: types.RESEND_TRIAL_REQUEST,
    payload,
    callback,
  };
}

export function enableProdAccounts(payload: any, callback: any) {
  return {
    type: types.ENABLE_PROD_ACCOUNT,
    payload,
    callback,
  };
}

export function getRemainingInvites() {
  return {
    type: types.GET_REMAINING_INVITES,
  };
}

export function setInvites(payload: any) {
  return {
    type: types.SET_INVITES,
    payload,
  };
}

export function inviteUsers(params: any, isTrial: boolean, callback: any) {
  return {
    type: types.INVITE_USERS,
    params,
    callback,
    isTrial,
  };
}

export function getInvitesList(params: any) {
  return {
    type: types.GET_INVITES_LIST,
    params,
  };
}

export function setInvitesList(payload: any) {
  return {
    type: types.SET_INVITES_LIST,
    payload,
  };
}

export function getAddOns() {
  return {
    type: types.GET_ADDONS,
  };
}

export function setAddOns(payload: any) {
  return {
    type: types.SET_ADDONS,
    payload,
  };
}

export function createOrUpdateAddOn(params: any, callback: any) {
  return {
    type: types.CREATE_OR_UPDATE_ADDON,
    params,
    callback,
  };
}

export function getAgencyDetail(params: { id: number }, callback?: any) {
  return {
    type: types.GET_AGENCY,
    params,
    callback,
  };
}

export function setAgencyDetail(payload: any) {
  return {
    type: types.SET_AGENCY,
    payload,
  };
}

export function getCirculates(payload: any) {
  return {
    type: types.GET_CIRCULATES,
    payload,
  };
}

export function setCirculates(payload: any) {
  return {
    type: types.SET_CIRCULATES,
    payload,
  };
}

export function getCirculateJobs(payload: any, callback: any) {
  return {
    type: types.GET_CIRCULATE_JOBS,
    payload,
    callback,
  };
}

export function setCirculateJobs(payload: any) {
  return {
    type: types.SET_CIRCULATE_JOBS,
    payload,
  };
}

// do not show loading mask, fetching in silent
export function getCirculateJobsStatus(payload: any, callback?: any) {
  return {
    type: types.GET_CIRCULATE_JOBS_STATUS,
    payload,
    callback,
  };
}

export function setCirculateJobsStatus(payload: any) {
  return {
    type: types.SET_CIRCULATE_JOBS_STATUS,
    payload,
  };
}

export function queueCirculateJob(payload: any, creatorId: any, creatorName: any, callback?: any) {
  return {
    type: types.QUEUE_CIRCULATE_JOB,
    payload,
    creatorId,
    creatorName,
    callback,
  };
}

export function batchQueueCirculateJob(
  payload: any,
  creatorId: any,
  creatorName: any,
  callback?: any,
) {
  return {
    type: types.BATCH_QUEUE_CIRCULATE_JOB,
    payload,
    creatorId,
    creatorName,
    callback,
  };
}
