import * as types from './constants';

const initState = {
  s3Urls: {},
};

export default function reducers(state, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.SET_S3URLS: {
      return { ...state, s3Urls: action.payload };
    }
    default:
      return state;
  }
}
