import TextIcon from '@/assets/svgs/text.svg';
import ImageIcon from '@/assets/svgs/image.svg';
import VideoIcon from '@/assets/svgs/video.svg';

export const categories = {
  total: {
    label: 'Totals',
    infos: 'Totals across all accounts in one category',
  },
  average: {
    label: 'Averages',
    infos: 'Averages across all accounts in one category',
  },
};

export const tabsConfig = {
  defaultTab: 'account',
  tabs: [
    {
      name: 'Accounts',
      key: 'account',
      export: true,
      auth: true,
      customDate: 'leaderboard.customDate',
      category: ['average', 'total'],
    },
    {
      name: 'Companies',
      key: 'company',
      export: true,
      auth: 'leaderboard.holdingCompany',
      customDate: false,
      category: ['average', 'total'],
    },
    {
      name: 'Posts',
      key: 'post',
      export: true,
      auth: 'leaderboard.postSearch',
    },
  ],
};

export const contentTypes = {
  ALL: {
    value: 'ALL',
    text: 'All Content Types',
    icon: null,
  },
  IMAGE: {
    value: 'Image',
    text: 'Image',
    icon: ImageIcon,
  },
  VIDEO: {
    value: 'Video',
    text: 'Video',
    icon: VideoIcon,
  },
  TEXT: {
    value: 'Text',
    text: 'Text',
    icon: TextIcon,
  },
};

export const platform = {
  ALL: 'ALL',
  FACEBOOK: 'FB',
  INSTAGRAM: 'INST',
  TWITTER: 'TWT',
  YOUTUBE: 'YTB',
};
