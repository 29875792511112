import omit from 'lodash/omit';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { optionNewAPI, getOlapBenchmarksApiForCampaign } from '@/utils/helper';
import { apiEnums } from '@/utils/constant';
import { mediaReportsAvailable } from '~/Analytics/containers/modalConstants';
import client from '../Client/index';

export const getCampaigns = (params: any) => client.get('campaigns', params);

export const getCampaignDetailById = (params: any) =>
  client.get(`campaigns/${params.id}`, {
    hashtags: true,
    tags: true,
  });

/*
Common URL params:
channel=['ALL', 'Facebook', 'Instagram', 'Instagram Stories', 'Snapchat', 'Snapchat Discover', 'Twitter', 'YouTube']
startDate(compareDateBegin)=2019-10-22T05:00:00Z
endDate(compareDateEnd)=2019-11-04T04:59:59Z
byAllDates="True" | "False" -----> changed
API endpoint for Analytics
http://host/v1/api/creators/analytics/{id}/benchmarks?startDate=2019-10-22T05:00:00Z&endDate=2019-11-04T04:59:59Z&channel=Instagram Stories
API endpoint for Campaigns
http://host/v1/api/campaigns/{id}/benchmarks?startDate=2019-01-31T05:00:00Z&endDate=2019-10-28T03:59:59Z&channel=Instagram Stories
*/
let lastOlapBenchmarksReq: any;
export const getOlapBenchmarks = (params: any, force: boolean) => {
  /* eslint-disable */
  if (!params) return null;

  if (params.media === 'twitterVideo') {
    return Promise.resolve({});
  }

  const { media, id, dateBegin, dateEnd, compareDateBegin, compareDateEnd, paidFilter, timeZone } =
    params;
  const isAvailable = ['dateBegin', 'dateEnd', 'compareDateBegin', 'compareDateEnd'].every(
    (key) => !!params[key],
  );
  if (!isAvailable) return undefined;

  const { url, search } = getOlapBenchmarksApiForCampaign(
    {
      id,
      channel: media,
      dateBegin,
      dateEnd,
      compareDateBegin,
      compareDateEnd,
      paidFilter: paidFilter || 'all',
      timeZone,
    },
    optionNewAPI(apiEnums.olap) as any,
  );
  const apVer = optionNewAPI(apiEnums.olap);

  const currentUrl = window.location.href.trim();
  const shouldReq = !lastOlapBenchmarksReq || currentUrl !== lastOlapBenchmarksReq;

  if (shouldReq) {
    lastOlapBenchmarksReq = currentUrl;
    return client.get(url, search, apVer as any);
  } else {
    lastOlapBenchmarksReq = null;
  }

  if (force /* request from export page */) {
    return client.get(url, search, apVer as any);
  }

  return null;
};

export const getCampaignsByCreator = ({ id, ...params }: any) => {
  return client.get(`campaigns/creator/${id}`, params);
};

export const getAdPosts = (params: any) => {
  return client.get(
    `${params.media}/adsByCampaignCreator/${params.ids || 'all'}/${params.campaignId}`,
    pick(params, ['dateBegin', 'dateEnd', 'timeZone']),
  );
};

export const linkPosts = (params: any) => {
  if (apiEnums.campaigns) {
    return client.post(
      `campaigns/refresh/${params.id}/${params.additionalParam}`,
      omit(params, ['id', 'additionalParam']),
      optionNewAPI(apiEnums.campaigns) as any,
    );
  }
  return client.get(
    `campaigns/link/${params.id}/${params.additionalParam}`,
    omit(params, ['id', 'additionalParam']),
  );
};

export const restoreCampaigns = (params: any) => client.post('campaigns/restore', params);

export const getCampaignInfo = (params: any) => {
  return client.get(`campaigns/${params.id}`);
};

export const getCampaignReport = (params: any) => {
  if (apiEnums.campaigns) {
    const channel = get(mediaReportsAvailable, `${params.media}.channel`, '');
    return client.get(
      `campaigns/${params.id}`,
      {
        startDate: params.dateBegin,
        endDate: params.dateEnd,
        timeZone: params.timezone,
        channel,
      },
      optionNewAPI(apiEnums.campaigns) as any,
    );
  }

  let controller = params.media;
  let subPath = '';
  if (params.media === 'overview') {
    controller = 'detail';
  }
  if (params.media === 'fblive') {
    controller = 'facebook';
    subPath = '/live';
  }
  if (params.media === 'instagramstories') {
    controller = 'instagram/stories';
  }
  if (params.media === 'twitterVideo') {
    controller = 'twitter';
    subPath = '/videos';
  }

  if (params.media === 'facebookad') {
    controller = 'facebook';
    subPath = 'ads/';
    return client.get(
      `campaigns/${controller}/${subPath}${params.id}`,
      omit(params, ['id', 'media']),
    );
  }

  if (params.media === 'instagramad') {
    controller = 'instagram';
    subPath = 'ads/';
    return client.get(
      `campaigns/${controller}/${subPath}${params.id}`,
      omit(params, ['id', 'media']),
    );
  }

  if (params.media === 'tiktok') {
    /* ${params.creatorId} */
    return client.get(
      `tiktok/byCampaignCreator/all/${params.id}`,
      omit(params, ['id', 'media', 'creatorId']),
    );
  }
  return client.get(
    `campaigns/${controller}/${params.id}${subPath}`,
    omit(params, ['id', 'media']),
  );
};

export const getCampaignPostByCreators = (params: any) => {
  let media = params.media;
  let suffix = '';
  if (params.media === 'twitterVideo') {
    media = 'tweets';
    suffix = '/videos';
  }
  return client.get(
    `${media}/byCampaignCreator/${params.ids}/${params.campaignId}${suffix}`,
    pick(params, ['dateBegin', 'dateEnd', 'timeZone']),
  );
};

export const getCampaignPostsFromFlaskApi = (params: any) => {
  const channel = get(mediaReportsAvailable, `${params.media}.channel`, '');
  const ids = params.ids.includes(',') ? '' : `/${params.ids}`;
  return client.get(
    `campaigns/posts/${params.campaignId}${ids}`,
    {
      startDate: params.dateBegin,
      endDate: params.dateEnd,
      timeZone: params.timeZone,
      channel,
    },
    optionNewAPI(apiEnums.campaigns) as any,
  );
};

export const getSnapchatCampaignPostByCreators = (params: any) => {
  return client.get(`campaigns/snapchat/stories/${params.campaignId}/${params.ids}`);
};

export const getIgStoriesCampaignPostByCreators = (params: any) => {
  return client.get(
    `campaigns/instagram/storiesByCreator/${params.campaignId}/${params.id}`,
    pick(params, ['dateBegin', 'dateEnd', 'timeZone']),
  );
};

export const deleteCampaignPosts = (params: any) => {
  return client.post(`campaignPosts/delete/${params._id}`, params);
};

export const getCreatorByChannel = (params: any) => {
  return client.post('creators/byChannel', params);
};

export const updateCampaignUser = (params: any) => {
  return client.put(`campaigns/${params._id}`, params);
};

export const deleteCampaigin = (params: any) => {
  return client.delete(`campaigns/${params._id}`);
};

export const createHashTag = (params: any) => {
  return client.post('hashtags', params);
};

export const editCampaigns = (params: any) => {
  let after = '';
  if (params._id) {
    after = `/${params._id}`;
  }
  return client.post(`campaigns${after}`, params);
};

export const getAgencies = () => client.get('/agencies/nameList');

export const getCreators = (params: any) => client.get('/creators/nameList', params);

export const getAgencyIdByUser = () => client.get('agencies/byUser');

export const getFolders = (payload: any) => {
  return client.get('/campaigns/folders', { AgencyId: payload });
};

export const modifyFolders = (params: any) => {
  let res;
  switch (params.crud) {
    case 'add':
      res = client.post('/campaigns/folders', params.payload);
      break;
    case 'edit':
      res = client.patch(`/campaigns/folder/${params.payload._id}`, params.payload);
      break;
    case 'delete':
      res = client.delete(`/campaigns/folder/${params.payload._id}`);
      break;
    default:
      break;
  }
  return res;
};

export const moveFolders = (params: any) => {
  return client.put(`campaigns/folders`, params);
};

export const getSearchedTags = (params: any) => {
  // assign target agencyId for admin role
  const agencyId = ['AgencyId', 'agencyId'].reduce((prev, key) => {
    if (params && key in params) {
      prev = params[key];
    }
    return prev;
  }, undefined);

  return client.get(`/contentTags/search`, {
    AgencyId: agencyId,
    search: params.search,
  });
};
