import get from 'lodash/get';
import set from 'lodash/set';
import isPlainObject from 'lodash/isPlainObject';

const NOT_FOUND = '@@NOT_FOUND';
const isArrayOrPlainObject = (str: any) =>
  typeof str === 'string' && (/^[[].*[\]]$/.test(str) || /^[{].*[}]$/.test(str));

/**
 * localstorage helper
 * 1. Serialize and deserialize
 * 2. namespace JSON in localstorage
 * 3. path accessor for deep object is available
 *
 * localStorage = {
 *   a: "1",
 *   b: "[1,2]",
 *   c: '{"a":1}'
 * }
 */
export default class LocalStorage {
  namespace?: any;

  store?: any;

  seperator?: any;

  constructor(namespace: any) {
    this.namespace = namespace;
    this.store = window.localStorage;
    this.seperator = '.';
  }

  // inner funciton
  static notFound(path: any) {
    const err = new Error(`Not Found [${path}]`) as any;
    err.notFound = true;
    err.path = path;
    return err;
  }

  get(path: any) {
    const key = this.getFullPath(path);
    const infoInStore = this.getFromStorage(key);

    if (infoInStore === NOT_FOUND) return [LocalStorage.notFound(key)];

    try {
      let data = get(infoInStore, 0);
      if (isArrayOrPlainObject(data)) {
        data = JSON.parse(data);
      }
      return [null, data];
    } catch (err) {
      return [err];
    }
  }

  put(path: any, value: any) {
    const key = this.getFullPath(path);
    const infoInStore = this.getFromStorage(key, true);

    if (infoInStore === NOT_FOUND) return [LocalStorage.notFound(key)];

    const [, rootKey, fullValue, innerPath] = infoInStore;

    try {
      if (path === key) {
        this.store.setItem(key, value);
        return [null];
      }

      const newVal = set(fullValue, innerPath, value);

      this.store.setItem(rootKey, JSON.stringify(newVal));
      return [null, newVal];
    } catch (err) {
      return [err];
    }
  }

  has(path: any) {
    const key = this.getFullPath(path);
    const infoInStore = this.getFromStorage(key);

    if (infoInStore === NOT_FOUND) return [LocalStorage.notFound(key)];

    const [value] = infoInStore;
    const has = value !== undefined && value !== 'undefined';
    return [null, has];
  }

  delete(path: any) {
    try {
      if (path) {
        const key = this.getFullPath(path);
        const infoInStore = this.getFromStorage(key);

        if (infoInStore === NOT_FOUND) return [LocalStorage.notFound(key)];

        const [, rootKey, fullValue, innerPath] = infoInStore;
        if (path === key) {
          // update store
          this.store.removeItem(rootKey);
          return [null];
        }

        const leafIndex = innerPath.lastIndexOf(this.seperator);
        let newVal;
        if (leafIndex === -1) {
          if (isPlainObject(fullValue)) {
            newVal = { ...fullValue };
            delete newVal[innerPath];
          } else {
            newVal = [...fullValue];
            newVal.splice(Number(innerPath), 1);
          }
        } else {
          const pathToLeaf = innerPath.substring(0, leafIndex);
          const leaf = innerPath.substring(leafIndex + 1);

          newVal = get(fullValue, pathToLeaf);
          if (isPlainObject(newVal)) {
            delete newVal[leaf];
          } else {
            newVal.splice(Number(leaf), 1);
          }
        }
        // update store
        this.store.setItem(key, JSON.stringify(newVal));
        return [null, newVal];
      }
    } catch (err) {
      return [err];
    }

    return [null];
  }

  clear(onlyForNamespace: any = true) {
    try {
      if (onlyForNamespace) {
        // is the root node of the window.localStorage: localstorage = { a: 1 } => a
        if (this.namespace in this.store) {
          this.store.removeItem(this.namespace);
        } else {
          // localstorage = { a: { b: 1 } } => a.b
          const index = this.namespace.lastIndexOf(this.seperator);
          const pathToLeaf = this.namespace.substring(0, index);
          const leaf = this.namespace.substring(index + 1);
          const infoInStore = this.getFromStorage(this.namespace);
          const value = get(infoInStore, 0);
          const newVal = get(value, pathToLeaf);

          if (isPlainObject(newVal)) {
            delete newVal[leaf];
          } else if (Array.isArray(newVal)) {
            newVal.splice(index, Number(leaf));
          }
          return [null, newVal];
        }
      } else {
        this.store.clear();
      }
      return [null];
    } catch (err) {
      return [err];
    }
  }

  /**
   * get value from storage by path and deserialize for object or array
   * @return [value, key] || [value, key, fullValue]
   */
  getFromStorage(path: any, autoInit: any = false) {
    if (!path || typeof path !== 'string') return NOT_FOUND;

    let value: any;

    const index = path.indexOf(this.seperator);
    if (index === -1) {
      value = this.store[path];
      value = JSON.parse(value);
      return [value, path];
    }

    const key = path.substring(0, index);
    const innerPath = path.substring(index + 1);

    value = this.store[key];
    if (isArrayOrPlainObject(value)) {
      const fullValue = JSON.parse(value);
      value = get(fullValue, innerPath);

      return [value, key, fullValue, innerPath];
    }

    if (!autoInit) return [value, key];

    const regexArr = /^[0-9]/;
    const base = regexArr.test(innerPath) ? [] : {};
    value = set(base, innerPath, undefined);

    if (isArrayOrPlainObject(value)) {
      value = JSON.parse(value);
    }

    return [undefined, key, value, innerPath];
  }

  getFullPath(path: any) {
    return [this.namespace, path].join(this.seperator);
  }
}

export function localStorage(namespace: any) {
  return new LocalStorage(namespace);
}
