export const SCOPENAME = 'module:campaigns';

export const GET_CAMPAIGNS = `${SCOPENAME}/GET_CAMPAIGNS`;
export const SET_CAMPAIGNS = `${SCOPENAME}/SET_CAMPAIGNS`;
export const GET_FOLDERS = `${SCOPENAME}/GET_FOLDERS`;
export const SET_FOLDERS = `${SCOPENAME}/SET_FOLDERS`;
export const MODIFY_FOLDERS = `${SCOPENAME}/MODIFY_FOLDERS`;
export const MOVE_FOLDERS = `${SCOPENAME}/MOVE_FOLDERS`;
export const REFRESH_FOLDERS = `${SCOPENAME}/REFRESH_FOLDERS`;
export const SET_FOLDERID = `${SCOPENAME}/SET_FOLDERID`;
export const CREATE_CAMPAIGN = `${SCOPENAME}/CREATE_CAMPAIGN`;
export const UPDATE_CAMPAIGN = `${SCOPENAME}/UPDATE_CAMPAIGN`;

export const GET_DEATILCAMPAIGN = `${SCOPENAME}/GET_DEATILCAMPAIGN`;
export const SET_DEATILCAMPAIGN = `${SCOPENAME}/SET_DEATILCAMPAIGN`;

export const LINK_POSTS = `${SCOPENAME}/LINK_POSTS`;

export const RESTORE_CAMPAIGNS = `${SCOPENAME}/RESTORE_CAMPAIGNS`;

export const GET_CAMPAIGN_INFO = `${SCOPENAME}/GET_CAMPAIGN_INFO`;
export const SET_CAMPAIGN_INFO = `${SCOPENAME}/SET_CAMPAIGN_INFO`;

export const GET_REPORT = `${SCOPENAME}/GET_REPORT`;
export const SET_REPORT = `${SCOPENAME}/SET_REPORT`;

export const GET_CERATORS_POSTS = `${SCOPENAME}/GET_CERATORS_POSTS`;

export const DELETE_CERATORS_POSTS = `${SCOPENAME}/DELETE_CERATORS_POSTS`;

export const GET_CREATOR_BY_CHANNEL = `${SCOPENAME}/GET_CREATOR_BY_CHANNEL`;

export const GET_CAMPAIGN_USERS = `${SCOPENAME}/GET_CAMPAIGN_USERS`;
export const SET_CAMPAIGN_USERS = `${SCOPENAME}/SET_CAMPAIGN_USERS`;

export const UPDATE_CAMPAIGN_USER = `${SCOPENAME}/UPDATE_CAMPAIGN_USER`;

export const DELETE_CAMPAIGN = `${SCOPENAME}/DELETE_CAMPAIGN`;

export const CREATE_HASH_TAG = `${SCOPENAME}/CREATE_HASH_TAG`;

export const BATCH_CREATORS_POSTS = `${SCOPENAME}/BATCH_CREATORS_POSTS`;

export const GET_AGENCY_ID = `${SCOPENAME}/GET_AGENCY_ID`;
export const SET_AGENCY_ID = `${SCOPENAME}/SET_AGENCY_ID`;

export const GET_AGENCIES = `${SCOPENAME}/GET_AGENCIES`;
export const SET_AGENCIES = `${SCOPENAME}/SET_AGENCIES`;

export const GET_CREATORS = `${SCOPENAME}/GET_CREATORS`;
export const SET_CREATORS = `${SCOPENAME}/SET_CREATORS`;

export const SET_KEYWORD = `${SCOPENAME}/SET_KEYWORD`;
export const SET_ACTIVE_TYPE = `${SCOPENAME}/SET_ACTIVE_TYPE`;

export const GET_SEARCHED_TAGS = `${SCOPENAME}/GET_SEARCHED_TAGS`;
export const SET_SEARCHED_TAGS = `${SCOPENAME}/SET_SEARCHED_TAGS`;

export const GET_OLAP_BENCHMARKS = `${SCOPENAME}/GET_OLAP_BENCHMARKS`;
export const SET_OLAP_BENCHMARKS = `${SCOPENAME}/SET_OLAP_BENCHMARKS`;

export const BULK_UPLOAD_URLS = `${SCOPENAME}/BULK_UPLOAD_URLS`;

export const MULTI_PREVIEW_URLS = `${SCOPENAME}/MULTI_PREVIEW_URLS`;

export const ADD_POSTS_BY_CSV = `${SCOPENAME}/ADD_POSTS_BY_CSV`;

export const ACTIVE_TYPES = {
  all: 0,
  active: 1,
  inactive: 2,
};
