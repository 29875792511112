import React from 'react';
import CopyRight from '@/common/CopyRight';
import styles from './index.module.less';

const Footer = () => (
  <footer className={styles.footer}>
    <CopyRight />
  </footer>
);

export default React.memo(Footer);
