import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import metrics from '../components/List/CreatorTable/columnsMeta';
import { totalAndChannelsMetrics } from './companyMetircs';
import { tabsConfig } from './config';

interface Metric {
  last: string;
  current: string;
  growth: string;
}

interface Catgory {
  countAvg: number;
  [property: string]: number;
}

interface FullCategory {
  _id?: string;
  $categories?: boolean;
  channels?: Catgory[];
}

function generateAverageValue(item: Catgory, metrics2: Metric[]) {
  metrics2.forEach((metric) => {
    const last = item.countAvg ? get(item, metric.last, 0) / item.countAvg : 0;
    const current = item.countAvg ? get(item, metric.current, 0) / item.countAvg : 0;
    const growth = last ? (current - last) / last : 0;
    set(item, metric.last, last);
    set(item, metric.current, current);
    set(item, metric.growth, growth);
  });
}

function setDisableTotalDefaultValue(item: Catgory, metrics2: Metric[]) {
  metrics2.forEach((metric) => {
    set(item, metric.last, '-');
    set(item, metric.current, '-');
    set(item, metric.growth, '-');
  });
}

/**
 * {
 *   totalPosts: {
 *     ALL: { last: xx, current: xx, growth: xx },
 *     FB: { last: xx, current: xx, growth: xx },
 *     YTB: { last: xx, current: xx, growth: xx }
 *   },
 *   ...
 * }
 */
export function averageAccount(accounts: any = [], tabKey: any = '') {
  // leave as note
  // const disableTotalMetircs = ['viewsVideos', 'engagementRate', 'engagementsVideo', 'engagementsPost'];
  // const averageMetrics = ['audience', 'totalVideos', 'totalPosts', 'totalViews', 'totalEngagements'];

  // if tab has no category will not compute category row
  const tab: any = tabsConfig.tabs.find((i: any) => i.key === tabKey);
  const categories = tab && tab.category;
  if (!categories || !categories.length) {
    return {};
  }

  const hasTotal = tab.category.includes('total');
  const hasAverage = tab.category.includes('average');

  if (!hasTotal && !hasAverage) {
    return {};
  }

  const total: FullCategory = totalAndChannelsMetrics(accounts);
  const average: FullCategory = hasAverage ? cloneDeep(total) : {};

  if (hasAverage) {
    const averageMetrics = metrics.filter((i) => !i.disableTotal);
    generateAverageValue(average as Catgory, averageMetrics);
    if (average.channels) {
      average.channels.forEach((item) => {
        generateAverageValue(item, averageMetrics);
      });
    }
    average.$categories = true;
    average._id = '$categories';
  }

  if (hasTotal) {
    const disableTotalMetircs = metrics.filter((i) => i.disableTotal);
    if (accounts.length > 1) {
      setDisableTotalDefaultValue(total as Catgory, disableTotalMetircs);
      if (total.channels) {
        total.channels.forEach((item) => {
          setDisableTotalDefaultValue(item, disableTotalMetircs);
        });
      }
    }
    total.$categories = true;
    total._id = '$categories';
  }

  return { total: hasTotal ? total : {}, average };
}

/**
 * @param row {$ObjMap}: data row
 * @param dataType {string}: key path eg. views.current
 * @param channel {string}: ALL, Facebook, Youtube, twitter and Instgram
 * @param raw {boolean}: if raw is true return -1, or data from api
 * @returns {*}
 */
export const getRowData = (row: any, dataType: any, channel = 'ALL', raw = false) => {
  let dataChannel = '';
  let index;
  if (channel !== 'ALL') {
    index = findIndex(row.channels, { code: channel });
    dataChannel = `channels[${index}].`;
  }
  const data = get(row, dataChannel + dataType);
  return index === -1 && raw ? -1 : data || 0;
};

export const nameFilter = (
  creators: any,
  name: any,
  metricsFilter?: Array<{ key: string; values: [number, number] }>,
  channel?: string,
) =>
  name || metricsFilter?.length
    ? creators.filter((item: any) => {
        // name
        const filterValue = name.toLowerCase();
        const keyName = get(item, 'companyName', '') || get(item, 'creatorName', '');
        const passName = keyName.toLowerCase().includes(filterValue);
        // metrics
        const notPassMetricsFilter = metricsFilter?.some((filterItem) => {
          const metric = metrics.find((i) => i.key === filterItem.key);
          if (metric) {
            const currentNum = getRowData(item, metric.current, channel);
            return currentNum < filterItem.values[0] || currentNum > filterItem.values[1];
          }
          return false;
        });
        return passName && !notPassMetricsFilter;
      })
    : creators;
