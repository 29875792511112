import { put, call, all, delay } from 'redux-saga/effects';

function* withLoading(func: any, type: any, params: any) {
  try {
    yield put({ type: `${type}_REQUEST` });
    let response;
    if (typeof func === 'function') {
      response = yield call(func, params);
    } else {
      response = yield all(func.map((item: any) => (call as any)(...item)));
    }
    yield put({ type: `${type}_SUCCESS` });
    return response;
  } catch (error) {
    yield put({ type: `${type}_FAILURE` });
    throw error;
  }
}

export default withLoading as any;

export function* sagaSequence(tasks: any, sleep: any) {
  const resSequence: any[] = [];
  const { length } = tasks;
  for (let i = 0; i < length; i++) {
    const [func, params] = tasks[i];
    resSequence[i] = yield call(func, params);
    if (sleep && i !== length - 1) {
      yield delay(sleep);
    }
  }
  return resSequence;
}
