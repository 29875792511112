import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isProductionEnv } from '@/utils/helper';

export function stopSentryCapture() {
  if (process.env.NODE_ENV === 'production' && isProductionEnv()) {
    Sentry.init({ dsn: '' });
  }
}

export function startSentryCapture() {
  if (process.env.NODE_ENV === 'production' && isProductionEnv()) {
    // A hack for sentry
    // Sandbox of Qiankun overrides the original fetch
    // Need to create a private fetch and override its toString function
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const nativeFetch = new Function('return this.fetch')();
    const privateFetch: typeof fetch = function (...p) {
      return nativeFetch(...p);
    };
    privateFetch.toString = () => 'function fetch() { [native code] }';
    window.fetch = privateFetch;

    const { REACT_APP_RUN_ENV, REACT_APP_PACKAGE_NAME, REACT_APP_PACKAGE_VERSION } = process.env;

    // Sentry initialize
    Sentry.init({
      dsn: 'https://8b23c1aa5746495dbd758ce35ff468fa@sentry.io/1483688',
      environment: REACT_APP_RUN_ENV!,
      release: `${REACT_APP_PACKAGE_NAME!}@${REACT_APP_PACKAGE_VERSION!}`,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      ignoreErrors: [/ResizeObserver loop/],
    });
  }

  // Show OPS debug infomation for stage environment
  if (!isProductionEnv()) {
    const { REACT_APP_DEBUG_INFO_FROM_OPS } = process.env;

    if (REACT_APP_DEBUG_INFO_FROM_OPS) {
      sessionStorage.setItem('DEBUG_INFO_FROM_OPS', REACT_APP_DEBUG_INFO_FROM_OPS);
    }
  }
}
