import React from 'react';
import { useDispatch } from 'react-redux';
import { getChannels, getAppConfig } from '~/Auth/store/actions';
import { addFontAwesome } from './helper';
import Footer from './Footer';

const Layout = ({
  children,
  container,
}: {
  children?: React.ReactNode;
  container: HTMLElement;
}) => {
  const dispatch = useDispatch();

  // fetch the necessary public data, they are used by multiple pages
  React.useEffect(() => {
    dispatch(getChannels());
    dispatch(getAppConfig());
  }, [dispatch]);

  React.useEffect(() => {
    addFontAwesome(container);
  }, [container]);

  return (
    <>
      {children}
      <Footer />
    </>
  );
};

export default React.memo(Layout);
