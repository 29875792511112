import React from 'react';
import omit from 'lodash/omit';
import classnames from 'classnames';

interface IconProps {
  src: any;
  [propName: string]: any;
}

class Icon extends React.PureComponent<IconProps> {
  static defaultProps = {
    src: '',
  };

  render() {
    const { src, far, title } = this.props;
    const isBrand = ['facebook', 'instagram', 'snapchat', 'twitter', 'youtube', 'tiktok'].some(
      (brand) => (src || '').includes(brand),
    );

    let prefix = 'fa';
    if (isBrand) {
      prefix = 'fab';
    } else if (far) {
      prefix = 'far';
    }

    const titleText = title || '';

    return (
      <span {...omit(this.props, 'src')}>
        <i title={titleText} className={classnames(prefix, src)} />
      </span>
    );
  }
}

export default Icon;
