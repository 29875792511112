import * as types from './constants';

export function getCreators(params: any, callback?: any) {
  return {
    type: types.GET_CREATORS,
    params,
    callback,
  };
}

export function setCreators(payload: any) {
  return {
    type: types.SET_CREATORS,
    payload,
  };
}

export function getAllCreators(params: any, callback?: any) {
  return {
    type: types.GET_ALL_CREATORS,
    params,
    callback,
  };
}

export function setAllCreators(payload: any) {
  return {
    type: types.SET_ALL_CREATORS,
    payload,
  };
}

export function getTalentGroups() {
  return {
    type: types.GET_TALENTGROUPS,
  };
}

export function postList(payload: any, callback: any) {
  return {
    type: types.POST_LIST,
    payload,
    callback,
  };
}

export function getLeaderboardCounts(params: any, callback?: any) {
  return {
    type: types.GET_LEADERBOARD_COUNTS,
    params,
    callback,
  };
}

export function setPostList(payload: any, callback: any) {
  return {
    type: types.POST_LIST_SAVED,
    payload,
    callback,
  };
}

export function setTalentGroups(payload: any) {
  return {
    type: types.SET_TALENTGROUPS,
    payload,
  };
}

export function setLeaderboardCounts(payload: any) {
  return {
    type: types.SET_LEADERBOARD_COUNTS,
    payload,
  };
}

export function updateFilter(payload: any) {
  return {
    type: types.UPDATE_FILTER,
    payload,
  };
}

export function resetFilter() {
  return {
    type: types.RESET_FILTER,
  };
}

export function updateSorter(params: any) {
  return {
    type: types.UPDATE_SORTER,
    params,
  };
}

export function updateGeneralSorter(payload: any) {
  return {
    type: types.UPDATE_GENERAL_SORTER,
    payload,
  };
}

export function updatePostsSorter(payload: any) {
  return {
    type: types.UPDATE_POSTS_SORTER,
    payload,
  };
}

export function resetLeaderBoard() {
  return {
    type: types.RESET_LEADERBOARD,
  };
}

export function switchTab(params: any) {
  return {
    type: types.SWITCH_TAB,
    params,
  };
}

export function setTab(payload: any) {
  return {
    type: types.SET_TAB,
    payload,
  };
}

export function updateFilterName(payload: any) {
  return {
    type: types.UPDATE_FILTER_NAME,
    payload,
  };
}
