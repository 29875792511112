import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import createRootReducer from './reducers';
import rootSaga from './sagas';

function shouldShowReduxLogger() {
  const loggerFlag = localStorage.getItem('DEBUG_REDUX_LOGGER');

  return loggerFlag && !['false', '0'].includes(loggerFlag);
}

export function createGlobalStore() {
  const isProduction = process.env.NODE_ENV === 'production';
  const composeEnhancers = isProduction
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();

  const history = createBrowserHistory({
    basename: window.__POWERED_BY_QIANKUN__ ? process.env.REACT_APP_SINGLE_APP_URL_PREFIX : '/',
  });

  const middlewares = [
    // for dispatching history actions
    routerMiddleware(history),
    sagaMiddleware,
  ];

  if (shouldShowReduxLogger()) {
    const actionTransformer = (action: any) => action;
    middlewares.unshift(createLogger({ level: 'debug', actionTransformer }));
  }

  const store = createStore(
    // root reducer with router state
    createRootReducer(history),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  if (process.env.NODE_ENV === 'development') {
    window.store = store;
  }

  sagaMiddleware.run(rootSaga);

  return { store, history };
}
