import * as types from './constants';

export function getAuthLocal(payload: any) {
  return {
    type: types.GET_AUTH_LOCAL,
    payload,
  };
}

export function getChannels() {
  return {
    type: types.GET_CHANNELS,
  };
}

export function setChannels(payload: any) {
  return {
    type: types.SET_CHANNELS,
    payload,
  };
}

export function setCurrentUser(payload: any) {
  return {
    type: types.SET_CURRENTUSER,
    payload,
  };
}

export function getEnvInfo() {
  return {
    type: types.GET_ENVINFO,
  };
}

export function setEnvInfo(payload: any) {
  return {
    type: types.SET_ENVINFO,
    payload,
  };
}

export function getFullProfile() {
  return {
    type: types.GET_FULLPROFILE,
  };
}

export function setFullProfile(payload: any) {
  return {
    type: types.SET_FULLPROFILE,
    payload,
  };
}

export function getUsersMeMenus(payload?: any) {
  return {
    type: types.GET_USERS_ME_MENUS,
    payload,
  };
}

export function setUsersMeMenus(payload: any) {
  return {
    type: types.SET_USERS_ME_MENUS,
    payload,
  };
}

export function getMenus(payload: any) {
  return {
    type: types.GET_MENUS,
    payload,
  };
}

export function getAppConfig() {
  return {
    type: types.GET_APP_CONFIG,
  };
}

export function setAppConfig(payload: any) {
  return {
    type: types.SET_APP_CONFIG,
    payload,
  };
}

export function forgotPassword(payload: any, callback: any) {
  return {
    type: types.FORGOT_PASSWORD,
    payload,
    callback,
  };
}

export function resetPassword(payload: any, callback: any) {
  return {
    type: types.RESET_PASSWORD,
    payload,
    callback,
  };
}

export function createUser(payload: any, callback: any) {
  return {
    type: types.CREATE_USER,
    payload,
    callback,
  };
}

export function confirmEmail(payload: any, callback: any) {
  return {
    type: types.CONFIRM_EMAIL,
    payload,
    callback,
  };
}

export function logout() {
  return {
    type: types.USER_LOGOUT,
  };
}

export function getTrialInfo(params: any, callback: any) {
  return {
    type: types.GET_TRIAL_INFO,
    params,
    callback,
  };
}

export function setTrialInfo(payload: any) {
  return {
    type: types.SET_TRIAL_INFO,
    payload,
  };
}

export function confirmTrial(params: any, callback: any) {
  return {
    type: types.CONFIRM_TRIAL,
    params,
    callback,
  };
}
