import { optionNewAPI } from '@/utils/helper';
import client from '../Client/index';

const option = {
  baseURL: `${process.env.REACT_APP_API_HOST_NODE}`,
};

export const getAuthLocal = (params: any) => client.post('/auth/local', params, option);

export const getUsersMe = () => client.get('/users/me');

export const getFullProfile = () => client.get('/users/me/fullProfile');

export const getUsersMeMenus = (params: any) => client.get('/users/me/menu', params);

export const getAppConfig = () => client.get('/agencies/config');

export const forgotPassword = (params: any) => client.post('/users/forgotPassword', params);
export const resetPassword = (params: any) => client.post('/users/resetPassword', params);
export const createUser = (params: any) => client.post('/users', params);
export const confirmEmail = (params: any) => client.post('/users/confirm', params);
export const getTrialInfo = (params: any) => client.get('/users/trial/request', params);
export const confirmTrialRequest = (params: any) => {
  const { createdAt, updatedAt, ...payload } = params;
  return client.post('/users/trial/confirm', payload);
};

// TODO debugInfo update for delmondo-node
export const getEnvInfo = (apiSource = 'python') => {
  if (apiSource === 'python') {
    return client.get('log/debug_info', {}, optionNewAPI(true) as any);
  }
  return client.get('log/debug_info');
};
