import moment from 'moment-timezone';

type TimeFrame =
  | 'custom'
  | 'Last7'
  | 'Last30'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

type DateRange = {
  dateBegin: string;
  dateEnd: string;
  compareDateBegin?: string;
  compareDateEnd?: string;
};

export function getDateRangeFromTimeFrame(
  timeFrame: TimeFrame,
  dateBegin: string,
  dateEnd: string,
  perDateBegin: string,
  perDateEnd: string,
): DateRange {
  let startDate;
  let endDate;
  let perStartDate;
  let perEndDate;
  if (timeFrame === 'custom' || !timeFrame) {
    startDate = moment(dateBegin);
    endDate = moment(dateEnd);
    if (perDateBegin && perDateEnd) {
      perStartDate = moment(perDateBegin);
      perEndDate = moment(perDateEnd);
    }
  } else if (['Last7', 'Last30'].includes(timeFrame)) {
    // including today, ignore latency
    const day = +timeFrame.replace('Last', '');
    startDate = moment().subtract(day, 'days');
    endDate = moment().subtract(1, 'days');
  } else {
    startDate = moment()
      .subtract(+timeFrame, 'months')
      .startOf('month');
    endDate = moment()
      .subtract(+timeFrame, 'months')
      .endOf('month');
    perStartDate = moment()
      .subtract(+timeFrame + 1, 'months')
      .startOf('month');
    perEndDate = moment()
      .subtract(+timeFrame + 1, 'months')
      .endOf('month');
  }
  const dateRange: DateRange = {
    dateBegin: startDate.format('YYYY-MM-DD'),
    dateEnd: endDate.format('YYYY-MM-DD'),
  };
  if (perStartDate && perEndDate) {
    dateRange.compareDateBegin = perStartDate.format('YYYY-MM-DD');
    dateRange.compareDateEnd = perEndDate.format('YYYY-MM-DD');
  }
  return dateRange;
}
