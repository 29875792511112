import { call, put, takeLatest, select } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment-timezone';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import withLoading from '@/sagaMiddleware';
import * as api from '@/service/LeaderBoard';
import * as actions from './actions';
import * as types from './constants';
import * as selectors from './selectors';
import { initDateRangeForPeiod } from '../../../utils/time';

const fetchCreatorsfunc = {
  account: 'getCreators',
  company: 'getCompanies',
  post: 'getPosts',
};
type FetchCreatorsfuncType = keyof typeof fetchCreatorsfunc;
type ApiType = keyof typeof api;

export function* fetchCreators({ type, params, callback }: any) {
  try {
    const { tab, ...rawQuery } = params;
    const query = {
      ...rawQuery,
    };
    delete query.metricsFilter;

    if (tab === 'post') {
      if (!query.dateBegin || !query.dateEnd) {
        const { dateBegin, dateEnd } = initDateRangeForPeiod(7, undefined, 'leaderboard');
        query.dateBegin = dateBegin;
        query.dateEnd = dateEnd;
      }
      if (!query.postSorter || !query.postSorter.columnKey || !query.postSorter.order) {
        const sort = yield select(selectors.postSortSelector);
        query.postSorter = { columnKey: sort.columnKey, order: sort.order };
      }
    }

    const res = yield call(
      withLoading,
      api[fetchCreatorsfunc[tab as FetchCreatorsfuncType] as ApiType],
      type,
      query,
    );
    yield put(
      actions.setCreators({
        ...query,
        creators: res.data || [],
        total: res.total,
        lastUpdated: res.lastUpdated,
        talentGroupId: +res.talentGroup,
        tab,
      }),
    );
    if (callback) {
      yield call(callback);
    }
  } catch (err: any) {
    yield put(
      actions.setCreators({
        creators: [],
        channel: params.channel,
      }),
    );
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchCreators() {
  yield takeLatest(types.GET_CREATORS, fetchCreators);
}

export function* fetchTalentGroups() {
  try {
    const res = yield call(api.getTalentGroups);
    // sort alphabetically
    const sortedRes = orderBy(res, [(item) => item.name.toLowerCase()]);
    yield put(actions.setTalentGroups(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchTalentGroups() {
  yield takeLatest(types.GET_TALENTGROUPS, fetchTalentGroups);
}

export function* fetchLeaderboardCounts() {
  try {
    const res = yield call(api.getTalentGroupCounts);
    yield put(actions.setLeaderboardCounts(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchfetchLeaderboardCounts() {
  yield takeLatest(types.GET_LEADERBOARD_COUNTS, fetchLeaderboardCounts);
}

export function* resetFilters() {
  const lastUpdated = yield select(selectors.lastUpdatedSelector);
  const tab = yield select(selectors.tabSelector);
  const filters = yield select(selectors.filtersSelector);
  const query = {
    ...filters,
    lastUpdated: lastUpdated ? moment(lastUpdated).format('MM/DD/YY') : '',
    tab,
  };
  yield put(actions.getCreators(query));
}

function* watchResetFilters() {
  yield takeLatest(types.RESET_FILTER, resetFilters);
}

export function* switchTab({ params }: any) {
  yield put(actions.setTab(params));
  const filters = yield select(selectors.filtersSelector);
  const tab = yield select(selectors.tabSelector);
  const lastUpdated = yield select(selectors.lastUpdatedSelector);
  const query = {
    ...filters,
    lastUpdated: lastUpdated ? moment(lastUpdated).format('MM/DD/YY') : '',
    tab,
  };
  yield put(actions.getCreators(query));
}

function* watchSwitchTab() {
  yield takeLatest(types.SWITCH_TAB, switchTab);
}

export function* postList(action: any) {
  try {
    const res = yield call(withLoading, api.postList, action.type, action.payload);
    message.success(
      `${
        typeof res._id === 'undefined' ? 'Edit' : 'Create'
      } successful. Data in Leaderboards can take up to 2 hours before it will begin to appear.`,
      3,
    );
    yield put(actions.setPostList(res, action.callback));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    yield put(actions.setPostList(null, action.callback));
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchPostList() {
  yield takeLatest(types.POST_LIST, postList);
}

export function* updateSorter({ params }: any) {
  const { payload } = params;
  const preSorter = yield select(selectors.generalSortSelector);
  const current = { ...preSorter };
  if (isEmpty(payload)) {
    current.order = preSorter.order === 'descend' ? 'ascend' : 'descend';
  } else {
    current.order = payload.order;
    current.sortFunc = get(payload, 'sorter');
    current.columnKey = payload.columnKey;
  }
  yield put(actions.updateGeneralSorter({ current, preColumnKey: preSorter.columnKey }));
}

function* watchUpdateSorter() {
  yield takeLatest(types.UPDATE_SORTER, updateSorter);
}

export function* fetchAllCreators({ type, callback }: any) {
  try {
    const res = yield call(withLoading, api.getAllCreators, type);
    yield put(
      actions.setAllCreators({
        allCreators: res || [],
      }),
    );
    if (callback) {
      yield call(callback);
    }
  } catch (err: any) {
    yield put(
      actions.setAllCreators({
        allCreators: [],
      }),
    );
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchAllCreators() {
  yield takeLatest(types.GET_ALL_CREATORS, fetchAllCreators);
}

export default [
  watchFetchCreators,
  watchFetchTalentGroups,
  watchResetFilters,
  watchSwitchTab,
  watchUpdateSorter,
  watchPostList,
  watchFetchAllCreators,
  watchfetchLeaderboardCounts,
];
