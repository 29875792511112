import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const SuspenseFallback: React.FC = () => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 100 }}>
    <LoadingOutlined style={{ fontSize: 32 }} />
  </div>
);

export default React.memo(SuspenseFallback);
