import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { sortListByAlpha } from '@/utils/helper';
import * as types from './constants';

const initState = {
  AgencyId: 0,
  // serched keyword, scope in campaign name
  keyword: undefined,
  activeType: types.ACTIVE_TYPES.all,
  // active folderId
  folderId: 0,
  campaigns: [],
  agencies: [],
  creators: [],
  folders: [],
  searchedTags: [],
  campaignDetail: {},
  campaignInfo: {},
  report: {},
  users: [],
  // show olapBenchMarks switch
  olapBenchMarks: false,
};

export default function reducers(state, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.SET_CAMPAIGNS: {
      return { ...state, campaigns: action.payload };
    }
    case types.SET_FOLDERS: {
      return { ...state, folders: action.payload };
    }
    case types.REFRESH_FOLDERS: {
      let target;
      switch (action.crud) {
        case 'add': {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { name, _id, AgencyId } = action.payload;
          target = [...state.folders, { name, _id, AgencyId }];
          break;
        }
        case 'delete': {
          target = cloneDeep(state.folders).filter((item: any) => item._id !== action.payload._id);
          break;
        }
        case 'edit': {
          target = cloneDeep(state.folders).map((item: any) =>
            item._id === action.payload._id ? action.payload : item,
          );
          break;
        }
        default:
          break;
      }

      // sort tags on changing name
      if (['add', 'edit'].includes(action.crud)) {
        // move 'My Campaigns' to head
        const sortForAllFolders = (tar: any[] = []) => {
          if (get(tar, '0.name') === 'My Campaigns') return tar;

          const headIndex = findIndex(tar, { name: 'My Campaigns' } as any);
          const head = tar[headIndex];
          const others = [...tar];
          others.splice(headIndex, 1);
          return [head, ...others];
        };
        target = sortForAllFolders(sortListByAlpha(target, 'name'));
      }

      return { ...state, folders: target };
    }
    case types.SET_FOLDERID: {
      const folderId = +action.payload;
      const isValidId = find(state.folders, { _id: folderId });

      return {
        ...state,
        folderId: isValidId ? action.payload : 0 /* My Campaigns */,
      };
    }
    case types.SET_DEATILCAMPAIGN: {
      return { ...state, campaignDetail: action.payload };
    }
    case types.SET_CAMPAIGN_INFO: {
      return { ...state, campaignInfo: action.payload };
    }
    case types.SET_REPORT: {
      return { ...state, report: action.payload };
    }
    case types.SET_CAMPAIGN_USERS: {
      return { ...state, users: action.payload };
    }
    case types.SET_AGENCY_ID: {
      return { ...state, AgencyId: action.payload };
    }
    case types.SET_AGENCIES: {
      return { ...state, agencies: action.payload };
    }
    case types.SET_CREATORS: {
      return { ...state, creators: action.payload };
    }
    case types.SET_SEARCHED_TAGS: {
      return { ...state, searchedTags: action.payload };
    }
    case types.SET_KEYWORD: {
      return { ...state, keyword: action.payload };
    }
    case types.SET_ACTIVE_TYPE: {
      return Object.values(types.ACTIVE_TYPES).includes(action.payload)
        ? { ...state, activeType: action.payload }
        : state;
    }
    case types.SET_OLAP_BENCHMARKS: {
      return { ...state, olapBenchMarks: action.payload };
    }

    default:
      return state;
  }
}
