export const SCOPENAME = 'module:analytics';

export const GET_OVERVIEW = `${SCOPENAME}/GET_OVERVIEW`;
export const SET_OVERVIEW = `${SCOPENAME}/SET_OVERVIEW`;

export const GET_NAMELIST = `${SCOPENAME}/GET_NAMELIST`;
export const SET_NAMELIST = `${SCOPENAME}/SET_NAMELIST`;

export const GET_OLAP_BENCHMARKS = `${SCOPENAME}/GET_OLAP_BENCHMARKS`;
export const SET_OLAP_BENCHMARKS = `${SCOPENAME}/SET_OLAP_BENCHMARKS`;

export const GET_CREATORINFO = `${SCOPENAME}/GET_CREATORINFO`;
export const SET_CREATORINFO = `${SCOPENAME}/SET_CREATORINFO`;

export const GET_MEDIAREPORT = `${SCOPENAME}/GET_MEDIAREPORT`;
export const SET_MEDIAREPORT = `${SCOPENAME}/SET_MEDIAREPORT`;

export const GET_CAMPAIGNSBYCREATORID = `${SCOPENAME}/GET_CAMPAIGNSBYCREATORID`;
export const SET_CAMPAIGNSBYCREATORID = `${SCOPENAME}/SET_CAMPAIGNSBYCREATORID`;

export const POST_CAMPAIGNS = `${SCOPENAME}/POST_CAMPAIGNS`;
export const DELETE_CAMPAIGNS = `${SCOPENAME}/DELETE_CAMPAIGNS`;

export const SEARCH_TAGS = `${SCOPENAME}/SEARCH_TAGS`;
export const SET_SEARCHED_TAGS = `${SCOPENAME}/SET_SEARCHED_TAGS`;

export const SEARCH_ADMIN_TAGS_BY_POST = `${SCOPENAME}/SEARCH_TAGS_BY_POST`;
export const SET_ADMIN_TAGS = `${SCOPENAME}/SET_ADMIN_TAGS`;

export const POST_TAGS = `${SCOPENAME}/POST_TAGS`;
export const DELETE_TAGS = `${SCOPENAME}/DELETE_TAGS`;

export const ADD_CAMPAIGNS_TAGS = `${SCOPENAME}/ADD_CAMPAIGNS_TAGS`;
export const REMOVE_CAMPAIGNS_TAGS = `${SCOPENAME}/REMOVE_CAMPAIGNS_TAGS`;

export const ADD_ADMIN_TAGS = `${SCOPENAME}/ADD_ADMIN_TAGS`;
export const REMOVE_ADMIN_TAGS = `${SCOPENAME}/REMOVE_ADMIN_TAGS`;
export const UPDATE_ADMIN_TAG = `${SCOPENAME}/UPDATE_ADMIN_TAG`;

export const UPDATE_STORY_TITLE = `${SCOPENAME}/UPDATE_SNAPCHAT_TITLE`;
export const SET_STORY_TITLE = `${SCOPENAME}/SET_SNAPCHAT_TITLE`;

export const GET_MEDIA_DETAIL = `${SCOPENAME}/GET_MEDIA_DETAIL`;
export const SET_MEDIA_DETAIL = `${SCOPENAME}/SET_MEDIA_DETAIL`;

export const GET_FACEBOOK_VIDEO_INSIGHTS = `${SCOPENAME}/GET_FACEBOOK_VIDEO_INSIGHTS`;
export const SET_FACEBOOK_VIDEO_INSIGHTS = `${SCOPENAME}/SET_FACEBOOK_VIDEO_INSIGHTS`;

export const GET_STORY_LIST = `${SCOPENAME}/GET_STORY_LIST`;
export const SET_STORY_LIST = `${SCOPENAME}/SET_STORY_LIST`;

export const UPDATE_FRAME = `${SCOPENAME}/UPDATE_FRAME`;
export const UPDATE_FRAME_BATCH = `${SCOPENAME}/UPDATE_FRAME_BATCH`;

export const ADD_STORY = `${SCOPENAME}/ADD_STORY`;

export const GET_SNAP_STORY = `${SCOPENAME}/GET_SNAP_STORY`;
export const SET_SNAP_STORY = `${SCOPENAME}/SET_SNAP_STORY`;

export const GET_CREATOR_CHANNEL_ID = `${SCOPENAME}/GET_CREATOR_CHANNEL_ID`;
export const SET_CREATOR_CHANNEL_ID = `${SCOPENAME}/SET_CREATOR_CHANNEL_ID`;

export const UPDATE_DELETING_CAMPAIGN_IDS = `${SCOPENAME}/UPDATE_DELETING_CAMPAIGN_IDS`;
export const UPDATE_DELETING_TAG_IDS = `${SCOPENAME}/UPDATE_DELETING_TAG_IDS`;
export const CREATE_VIDEO_SEGMENT = `${SCOPENAME}/CREATE_VIDEO_SEGMENT`;
export const UPDATE_VIDEO_SEGMENT = `${SCOPENAME}/UPDATE_VIDEO_SEGMENT`;
export const SET_VIDEO_SEGMENT = `${SCOPENAME}/SET_VIDEO_SEGMENT`;
export const DELETE_VIDEO_SEGMENT = `${SCOPENAME}/DELETE_VIDEO_SEGMENT`;

export const GET_CHANNEL_MAPPINGS = `${SCOPENAME}/GET_CHANNEL_MAPPINGS`;
export const SET_CHANNEL_MAPPINGS = `${SCOPENAME}/SET_CHANNEL_MAPPINGS`;

export const GET_EXPORT_DATA = `${SCOPENAME}/GET_EXPORT_DATA`;

export const DELETE_STORY = `${SCOPENAME}/DELETE_STORY`;

export const CREATE_CONTENT_TAG = `${SCOPENAME}/CREATE_CONTENT_TAG`;

export const UPDATE_SNAP_FRAME = `${SCOPENAME}/UPDATE_SNAP_FRAME`;

export const UPDATE_AGENCY_ID = `${SCOPENAME}/UPDATE_AGENCY_ID`;

export const GET_AGENCIES = `${SCOPENAME}/GET_AGENCIES`;
export const SET_AGENCIES = `${SCOPENAME}/SET_AGENCIES`;
