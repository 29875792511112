import client from '../Client/index';

export const getUserList = () => client.get(`users/list`);

export const getRoleList = () => client.get(`users/roleList`);

export const disableUserById = (id: number) => client.post(`users/disable/${id}`);

export const updateUserRoleById = (params: { id: number; role: string }) =>
  client.post(`users/role`, params);

export const getScheduledExport = ({ userId, type }: any) =>
  client.get(`scheduledExport/byUser/${userId}?type=${type}`);

export const getDownloadByUser = ({ userId }: any) => client.get(`download/byUser/${userId}`);

export const getDownload = (params: any) => client.get(`download/${params}`);

export const updateProfile = ({ id, ...p }: any) => client.put(`users/${id}/profile`, p);

export const changePassword = ({ id, ...p }: any) => client.put(`users/${id}/password`, p);

export const regenerateFile = (params: any) => client.get(`download/regenerate/${params.duid}`);

export const deleteDownload = (params: any) =>
  client.delete(`download/delete/download/${params.fileUuid}`);

// export const connectSocialNetwork = (params: any) => client.get(`connect/${params.social}?source=${params.source}`, {}, {
//   baseURL: `${process.env.REACT_APP_API_HOST_NODE}`
// });

export const unLinkSocialNetwork = (params: any) => client.get(`${params.social}/unlink`);

export const updateCredentials = (params: any) => {
  const path = params._id ? `/${params._id}` : '';
  return client.post(`credentials${path}`, params);
};
