import './public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ConfigProvider, Modal, message } from 'antd';
import { startSentryCapture, stopSentryCapture } from './utils/log';
import { GlobalQueryClient } from './query';
import { createGlobalStore } from './rootStore';
import App from './App';
import '@/stylesheets/global.less';
import style from './app.module.less';

const { REACT_APP_ROOT_ELEMENT_ID, REACT_APP_ANTD_PREFIX, REACT_APP_SINGLE_APP_URL_PREFIX } =
  process.env;
const ROOT_ELEMENT_SELECTOR = `#${REACT_APP_ROOT_ELEMENT_ID!}`;

async function render({ container = document.body, onGlobalStateChange }: QianKunProps) {
  let rootElement = container.querySelector(ROOT_ELEMENT_SELECTOR);

  if (rootElement === null) {
    rootElement = document.createElement('div');
    rootElement.id = REACT_APP_ROOT_ELEMENT_ID!;
    (rootElement as HTMLDivElement).className = style.root;
    container.appendChild(rootElement);
  }

  message.config({ getContainer: () => container, duration: 4 });

  // message/notification/Modal style prefix
  ConfigProvider.config({ prefixCls: REACT_APP_ANTD_PREFIX });

  const globalState = await new Promise<QiankunGlobalState | undefined>((resolve) => {
    if (!window.__POWERED_BY_QIANKUN__ || !onGlobalStateChange) {
      resolve(undefined);

      return;
    }

    onGlobalStateChange((state: QiankunGlobalState) => {
      resolve(state);
    }, true);
  });

  const landingUrl = globalState?.landingPages?.si?.replace(REACT_APP_SINGLE_APP_URL_PREFIX!, '');
  const { store, history } = createGlobalStore();

  history.listen(() => {
    Modal.destroyAll();
    message.destroy();
  });

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ConfigProvider prefixCls={REACT_APP_ANTD_PREFIX} getPopupContainer={() => container}>
          <QueryClientProvider client={GlobalQueryClient}>
            <App landingUrl={landingUrl} container={container} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ConfigProvider>
      </ConnectedRouter>
    </Provider>,
    rootElement,
  );
}

export async function bootstrap() {
  // do nothing
}

export async function mount(props: QianKunProps) {
  startSentryCapture();
  render(props);
}

export async function unmount(props: QianKunProps) {
  const { container = document.body } = props;
  ReactDOM.unmountComponentAtNode(container.querySelector(ROOT_ELEMENT_SELECTOR)!);
  message.destroy();
  stopSentryCapture();
}

if (!window.__POWERED_BY_QIANKUN__) {
  startSentryCapture();
  render({});
}
