const SPLITOR = '__';

const batchInfo: any = {};

export default function loadingReducer(state, action: any) {
  state = state || {};
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;
  if (!requestName.includes(SPLITOR)) {
    return {
      ...state,
      // Store whether a request is happening at the moment or not
      // e.g. will be true when receiving GET_TODOS_REQUEST
      // and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
      [requestName]: requestState === 'REQUEST',
    };
  }

  const info = requestName.split(SPLITOR);
  const batchName = info[0];
  const count = parseInt(info[1], 10) - 1;
  const index = parseInt(info[2], 10);
  if (batchName && !Number.isNaN(count) && !Number.isNaN(index)) {
    if (requestState === 'REQUEST' && index === 0) {
      batchInfo[batchName] = Array(count).fill(false);
    }
    if (requestState !== 'REQUEST') {
      batchInfo[batchName][index] = true;
    }

    const isAllCompleted = batchInfo[batchName].every(
      (completed: boolean | undefined) => completed === true,
    );

    return {
      ...state,
      [batchName]: !isAllCompleted,
    };
  }

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    // and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST',
  };
}

export function getBatchActionType(batchName: string, count: number, index: number) {
  return `${batchName}${SPLITOR}${count}${SPLITOR}${index}`;
}
