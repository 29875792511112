const initState = {};

export default function reducers(state, action: any) {
  state = state || initState;

  switch (action.type) {
    default:
      return state;
  }
}
