import React, { PureComponent } from 'react';
import classnames from 'classnames';
import memoize from 'memoize-one';
import { Menu } from 'antd';
import BrandIcon from '@/common/Icon';
import BaseRouteLink from '@/common/BaseRouteLink';
import { can } from '@/utils/helper';
import { initDateRangeForPeiod } from '@/utils/time';
import styles from './index.module.less';

interface SideMenuProps {
  collapse: boolean;
  pathname: string;
  menus: any[];
  currentUser: any;
  pushLocation: (data: any, opts?: any) => any;
  getMenus: () => any;
}
interface SideMenuState {
  selectedKeys: any;
}

class SideMenu extends PureComponent<SideMenuProps, SideMenuState> {
  static defaultProps = {
    collapse: false,
    pathname: '',
    menus: [],
    currentUser: {},
  };

  constructor(props: SideMenuProps) {
    super(props);
    this.state = {
      selectedKeys: [],
    };
  }

  componentDidMount() {
    if (this.props.menus.length === 0) {
      this.props.getMenus();
    }
  }

  static getDerivedStateFromProps(props: SideMenuProps, state: SideMenuState) {
    // .replace(/^\/([^\/]*).*$/, '$1')
    const key = props.pathname.split('/')[1];
    if (key !== state.selectedKeys[0]) {
      return {
        selectedKeys: [key],
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  normalizeKey = (key = '') => key.replace(/ /g, '').toLowerCase();

  renderMenuItem = memoize((item: any) => {
    const key = this.normalizeKey(item.key);
    let routePath = `/${key}${item.route ? `/${item.route}` : ''}`;
    if (key === 'campaigns') {
      const dataRange = initDateRangeForPeiod(180, undefined, 'campaignList');
      routePath += `?dateBegin=${dataRange.dateBegin}&dateEnd=${dataRange.dateEnd}`;
    }
    return can(item.aclString) ? (
      <Menu.Item key={key} title={item.name}>
        <BaseRouteLink to={routePath}>
          <BrandIcon src={item.icon} className="anticon" style={{ verticalAlign: 'middle' }} />
          <span>{item.name}</span>
        </BaseRouteLink>
      </Menu.Item>
    ) : null;
  });

  render() {
    const { collapse } = this.props;
    return (
      <div className={classnames(styles.sideMenu, { [styles.collapse]: collapse })}>
        <Menu selectedKeys={this.state.selectedKeys} mode="inline" inlineCollapsed={collapse}>
          {this.props.menus.map((item) => this.renderMenuItem(item))}
        </Menu>
      </div>
    );
  }
}

export default SideMenu;
