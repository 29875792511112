import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Empty } from 'antd';

interface VisibleProps {
  validate: any;
  message: any;
  children: any;
}

class Visible extends React.PureComponent<VisibleProps> {
  static defaultProps = {
    validate: {},
    message: '',
  };

  renderEmpty = () => {
    if (this.props.message) {
      return typeof this.props.message === 'string' ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        this.props.message
      );
    }
    return null;
  };

  renderComponents = (validate: any) => (validate ? this.props.children : this.renderEmpty());

  render() {
    const { validate } = this.props;
    if (typeof validate === 'function') {
      return this.renderComponents(validate());
    }
    if (['boolean', 'string', 'number'].includes(typeof validate)) {
      return this.renderComponents(validate);
    }
    return this.renderComponents(!isEmpty(validate));
  }
}

export default Visible;
