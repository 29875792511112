import * as types from './constants';

export function getTopContent(params: any) {
  return {
    type: types.GET_TOP_CONTENT,
    params,
  };
}

export function setTopContent(payload: any) {
  return {
    type: types.SET_TOP_CONTENT,
    payload,
  };
}
