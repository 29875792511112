import { call, takeLatest } from 'redux-saga/effects';
import * as api from '@/service/Charts';
import withLoading from '@/sagaMiddleware';
import * as types from './constants';

export function* ExportExcel(action: any) {
  try {
    const res = yield call(withLoading, api.exportExcel, action.type, action.payload);
    if (action.callback) {
      action.callback(res);
    }
  } catch (err) {
    // do nothing
  }
}

function* watchExportExcel() {
  yield takeLatest(types.EXPORT_EXCEL, ExportExcel);
}

export default [watchExportExcel];
