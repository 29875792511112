const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '{clientId}';
const ISSUER = process.env.REACT_APP_ISSUER || 'https://{yourOktaDomain}.com/oauth2/default';
const BASE_URL = process.env.REACT_APP_OKTA_BASE_URL || '';
const PULSE_URL = process.env.REACT_APP_PULSE || '';
const SOTRAGE_KEY = process.env.REACT_APP_STORAGE_KEY || 'accessToken';

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: `${window.location.origin}/implicit/callback`,
    scopes: ['openid', 'profile'],
  },
  baseUrl: BASE_URL,
  storageKey: SOTRAGE_KEY,
  pulseUrl: PULSE_URL,
};
