import * as types from './constants';

const initState = {
  creators: [],
  genres: [],
  countries: [],
  profile: {},
  accountInfo: {},
  genders: [],
  talentGroups: [],
};

export default function reducers(state, action: any) {
  state = state || initState;

  switch (action.type) {
    case types.SET_TALENTGROUPS: {
      return { ...state, talentGroups: action.payload };
    }
    case types.SET_GENDERS: {
      return { ...state, genders: action.payload };
    }
    case types.SET_ACCOUNT_INFO: {
      return { ...state, accountInfo: action.payload };
    }
    case types.SET_CREATORS: {
      return { ...state, creators: action.payload };
    }
    case types.SET_GENRES: {
      return { ...state, genres: action.payload };
    }
    case types.SET_COUNTRIES: {
      return { ...state, countries: action.payload };
    }
    case types.SET_PROFILE: {
      return { ...state, profile: action.payload };
    }
    default:
      return state;
  }
}
