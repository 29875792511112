import React from 'react';
import get from 'lodash/get';
import { getToday } from '@/common/PeriodGroup/utils';
import styles from './index.module.less';

const todayInfo = getToday().split('-');

export interface CopyRightProps {
  sm?: boolean;
}

export interface CopyRightType extends React.FC<CopyRightProps> {
  LEGAL_URL: string;
  POLICY: string;
  LEGAL_ENTRANCE: React.ReactNode;
}

const CopyRight: CopyRightType = ({ sm = false }: CopyRightProps) => {
  if (sm) {
    return (
      <span className={styles.copyRight}>
        <div style={{ width: '310px', margin: '0 auto' }}>
          By accessing and using Social Insights, you agree to the Conviva{' '}
          <a href={CopyRight.LEGAL_URL} rel="noopener noreferrer" target="_blank">
            Terms of Use
          </a>{' '}
          and{' '}
          <a href={CopyRight.POLICY} rel="noopener noreferrer" target="_blank">
            Privacy Policy
          </a>
        </div>
        <div>{CopyRight.LEGAL_ENTRANCE}</div>
      </span>
    );
  }

  return (
    <span className={styles.copyRight}>
      Conviva{' '}
      <a href={CopyRight.LEGAL_URL} target="_blank" rel="noreferrer">
        Terms of Use
      </a>{' '}
      {CopyRight.LEGAL_ENTRANCE}
    </span>
  );
};

CopyRight.LEGAL_URL = 'https://pulse.conviva.com/legal/#terms';
CopyRight.POLICY = 'https://conviva.com/legal/';
CopyRight.LEGAL_ENTRANCE = `© ${get(todayInfo, 0, 2020)} Conviva. All rights reserved.`;

export default CopyRight;
