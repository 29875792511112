import * as types from './constants';

export function getCampaigns(params: any) {
  return {
    type: types.GET_CAMPAIGNS,
    params,
  };
}

export function setCampaigns(payload: any) {
  return {
    type: types.SET_CAMPAIGNS,
    payload,
  };
}

export function getFolders(payload: any) {
  return {
    type: types.GET_FOLDERS,
    payload,
  };
}

export function setFolders(payload: any) {
  return {
    type: types.SET_FOLDERS,
    payload,
  };
}

export function modifyFolders(crud: any, payload: any, callback: any) {
  return {
    type: types.MODIFY_FOLDERS,
    crud,
    payload,
    callback,
  };
}

export function moveFolders(payload: any, callback: any) {
  return {
    type: types.MOVE_FOLDERS,
    payload,
    callback,
  };
}

export function refreshFolders(crud: any, payload: any) {
  return {
    type: types.REFRESH_FOLDERS,
    crud,
    payload,
  };
}

export function setFolderId(payload: any) {
  return {
    type: types.SET_FOLDERID,
    payload,
  };
}

export function getDetailCampaign(params: any) {
  return {
    type: types.GET_DEATILCAMPAIGN,
    params,
  };
}

export function setDetailCampaign(payload: any) {
  return {
    type: types.SET_DEATILCAMPAIGN,
    payload,
  };
}

export function linkPosts(params: any, callback: any) {
  return {
    type: types.LINK_POSTS,
    params,
    callback,
  };
}

export function restoreCampaign(params: any, callback: any) {
  return {
    type: types.RESTORE_CAMPAIGNS,
    params,
    callback,
  };
}

export function getCampaignInfo(params: any, force: any) {
  return {
    type: types.GET_CAMPAIGN_INFO,
    params,
    force,
  };
}

export function setCampaignInfo(payload: any) {
  return {
    type: types.SET_CAMPAIGN_INFO,
    payload,
  };
}

export function getCampaignReport(params: any, force: any = false) {
  return {
    type: types.GET_REPORT,
    params,
    force,
  };
}

export function setCampaignReport(payload: any) {
  return {
    type: types.SET_REPORT,
    payload,
  };
}

export function getCreatorsPosts(params: any) {
  return {
    type: types.GET_CERATORS_POSTS,
    params,
  };
}

export function deleteCampaignPosts(params: any, callback: any) {
  return {
    type: types.DELETE_CERATORS_POSTS,
    params,
    callback,
  };
}

export function getCreatorByChannel(params: any, callback: any) {
  return {
    type: types.GET_CREATOR_BY_CHANNEL,
    params,
    callback,
  };
}

export function getCampaignUsers() {
  return {
    type: types.GET_CAMPAIGN_USERS,
  };
}

export function setCampaignUsers(payload: any) {
  return {
    type: types.SET_CAMPAIGN_USERS,
    payload,
  };
}

export function updateCampaignUser(params: any, callback: any) {
  return {
    type: types.UPDATE_CAMPAIGN_USER,
    params,
    callback,
  };
}

export function deleteCampaign(params: any, callback: any) {
  return {
    type: types.DELETE_CAMPAIGN,
    params,
    callback,
  };
}

export function createHashTag(params: any, callback: any) {
  return {
    type: types.CREATE_HASH_TAG,
    params,
    callback,
  };
}

export function createCampaign(params: any, callback: any) {
  return {
    type: types.CREATE_CAMPAIGN,
    params,
    callback,
  };
}

export function updateCampaign(params: any, callback: any) {
  return {
    type: types.UPDATE_CAMPAIGN,
    params,
    callback,
  };
}

export function getBatchPosts(params: any, callback: any) {
  return {
    type: types.BATCH_CREATORS_POSTS,
    params,
    callback,
  };
}

export function getAgencyIdByUser() {
  return {
    type: types.GET_AGENCY_ID,
  };
}

export function setAgencyId(payload: any) {
  return {
    type: types.SET_AGENCY_ID,
    payload,
  };
}

export function getAgencies(payload: any) {
  return {
    type: types.GET_AGENCIES,
    payload,
  };
}

export function setAgencies(payload: any) {
  return {
    type: types.SET_AGENCIES,
    payload,
  };
}

export function getCreators(params: any) {
  return {
    type: types.GET_CREATORS,
    params,
  };
}

export function setCreators(payload: any) {
  return {
    type: types.SET_CREATORS,
    payload,
  };
}

export function setKeyword(payload: any) {
  return {
    type: types.SET_KEYWORD,
    payload,
  };
}

export function getSearchedTags(payload: any) {
  return {
    type: types.GET_SEARCHED_TAGS,
    payload,
  };
}

export function setSearchedTags(payload: any) {
  return {
    type: types.SET_SEARCHED_TAGS,
    payload,
  };
}

export function setActiveType(payload: any) {
  return {
    type: types.SET_ACTIVE_TYPE,
    payload,
  };
}

export function getOlapBenchmarks(params: any) {
  return {
    type: types.GET_OLAP_BENCHMARKS,
    params,
  };
}

export function setOlapBenchmarks(payload: any) {
  return {
    type: types.SET_OLAP_BENCHMARKS,
    payload,
  };
}

export function bulkUploadUrls(params: any, callback: any) {
  return {
    type: types.BULK_UPLOAD_URLS,
    params,
    callback,
  };
}

export function multiPreviewPosts(params: any, callback: any) {
  return {
    type: types.MULTI_PREVIEW_URLS,
    params,
    callback,
  };
}

export function addPostsByCSV(params: any, callback: any) {
  return {
    type: types.ADD_POSTS_BY_CSV,
    params,
    callback,
  };
}
