import { all, fork } from 'redux-saga/effects';
import charts from '@/charts/store';
import leaderboard from '~/Leaderboard/store';
import accounts from '~/Accounts/store';
import analytics from '~/Analytics/store';
import campaigns from '~/Campaigns/store';
import auth from '~/Auth/store';
import settings from '~/Settings/store';
import admin from '~/Admin/store';
import download from '~/Download/store';
import exportSaga from '~/Export/store/sagas';
import statics from '~/Statics/store';
import topContent from '~/TopContent/store';
import routerSaga from './router/sagas';
import postPreviewSaga from './postPreview/sagas';

const sagas = [
  ...leaderboard.sagas,
  ...accounts.sagas,
  ...analytics.sagas,
  ...campaigns.sagas,
  ...auth.sagas,
  ...settings.sagas,
  ...admin.sagas,
  ...charts.sagas,
  ...statics.sagas,
  ...download.sagas,
  ...topContent.sagas,
  ...routerSaga,
  ...exportSaga,
  ...postPreviewSaga,
];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}
