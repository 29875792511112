import React from 'react';
import { call, put, takeLatest, select, fork, all, takeEvery } from 'redux-saga/effects';
import moment from 'moment-timezone';
import { message, notification } from 'antd';
import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import get from 'lodash/get';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import toArray from 'lodash/toArray';
import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';
import withLoading, { sagaSequence } from '@/sagaMiddleware';
import * as api from '@/service/Campaigns';
import { addPostByUrl, bulkAddPostsByCSV } from '@/service/Channels';
import { getUsers } from '@/service/Admin';
import { deleteCampaignsByFlaskApi } from '@/service/Analytics';
import { previewPostByIframely } from '@/service/Iframely';
import { pushLocation } from '@/rootStore/router/actions';
import { getCurrentTimeZone } from '@/utils/time';
import { apiEnums } from '@/utils/constant';
import { setStoryQueue, sortListByAlpha } from '@/utils/helper';
import { mediaReportsAvailable, adPlatforms } from '~/Analytics/containers/modalConstants';
import { getMediaChannelSelector } from '~/Analytics/store/selectors';
import { setMediaReport, getNameList as getCreatorsNameList } from '~/Analytics/store/actions';
import * as actions from './actions';
import * as types from './constants';
import * as selectors from './selectors';

type MediaReportsAvailableType = keyof typeof mediaReportsAvailable;

let lastOlapUrl: string;

function getMediaReportsAvailableVal(item: any, key: any) {
  return (mediaReportsAvailable[item as MediaReportsAvailableType] as any)[key];
}

// api response adapter for fetchOlapBenchmarks
function iterateRows(row: any, olapCompareBenchmarks: any) {
  const pctDiff = (newVal: any, ogVal: any) => {
    if (Number.isInteger(ogVal)) {
      ogVal = parseInt(ogVal, 10);
      newVal = parseInt(newVal, 10);
    } else {
      ogVal = parseFloat(ogVal);
      newVal = parseFloat(newVal);
    }
    const changePct = (newVal - ogVal) / ogVal;
    return changePct;
  };

  toArray(row).forEach((benchmark) => {
    const compareVal = get(olapCompareBenchmarks, benchmark.compareValue, null);
    benchmark.comparePctDiff =
      benchmark.value && benchmark.value > 0 && compareVal && compareVal > 0
        ? pctDiff(benchmark.value, compareVal)
        : null;
    benchmark.previous = compareVal;
  });
}

// api response adapter for fetchOlapBenchmarks
function buildBenchmarksCompare(benchmarks: any, olapCompareBenchmarks: any) {
  if (benchmarks.rows) {
    toArray(benchmarks.rows).forEach((row) => iterateRows(row, olapCompareBenchmarks));
  } else {
    iterateRows(benchmarks.data || benchmarks, olapCompareBenchmarks);
  }
  return benchmarks;
}

export function* fetchCampaigns({ type, params }: any): any {
  try {
    const reqParams = {
      ...params,
      timeZone: getCurrentTimeZone(),
    };
    const res = yield call(withLoading, api.getCampaigns, type, reqParams);
    yield put(actions.setCampaigns(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCampaigns() {
  yield takeLatest(types.GET_CAMPAIGNS, fetchCampaigns);
}

export function* fetchFolders({ type, payload }: any) {
  const formatFolders = (rawFolders = []) => {
    const folders = [{ _id: 0, name: 'My Campaigns' }].concat(
      sortListByAlpha(
        rawFolders.map((item) => pick(item, ['_id', 'name'])),
        'name',
      ),
    );

    return folders;
  };
  try {
    const res = yield call(withLoading, api.getFolders, type, payload);
    const folders = formatFolders(res);
    yield put(actions.setFolders(folders));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchFolders() {
  yield takeLatest(types.GET_FOLDERS, fetchFolders);
}

export function* fetchAgencies() {
  try {
    const rez = yield call(api.getAgencies);
    const sortedRes = sortListByAlpha(rez, 'name');
    yield put(actions.setAgencies(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAgencies() {
  yield takeLatest(types.GET_AGENCIES, fetchAgencies);
}

export function* fetchCreators({ params }: any) {
  try {
    const res = yield call(api.getCreators, params);
    yield put(actions.setCreators(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCreators() {
  yield takeLatest(types.GET_CREATORS, fetchCreators);
}

export function* fetchSearchedTags({ type, payload }: any) {
  try {
    const res = yield call(withLoading, api.getSearchedTags, type, payload);
    yield put(actions.setSearchedTags(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchSearchedTags() {
  yield takeLatest(types.GET_SEARCHED_TAGS, fetchSearchedTags);
}

export function* modifyFolders(action: any) {
  const isCampaignNameAvailable = (campaignFolders: any[], folder: any) => {
    campaignFolders = campaignFolders || [];
    const isReaptedName = campaignFolders.some(
      (campaignFolder: any) =>
        +get(campaignFolder, '_id') !== +get(folder, '_id') &&
        get(campaignFolder, 'name') === get(folder, 'name'),
    );
    return !isReaptedName;
  };
  const getOldFolderName = (campaignFolders: any[], _id: any) => {
    campaignFolders = campaignFolders || [];
    const folder = find(campaignFolders, { _id });
    return get(folder, 'name');
  };

  try {
    const campaignFolders = yield select(selectors.foldersSelector);

    if (!isCampaignNameAvailable(campaignFolders, action.payload)) {
      message.error('A folder with the same name already exists, please use a different name.');
      yield call(action.callback, true /* isFailed */);
      return;
    }

    const res = yield call(withLoading, api.modifyFolders, action.type, action);
    if (action.crud === 'add' || action.crud === 'edit') {
      yield call(
        message.success,
        <span>
          The <span style={{ fontWeight: 'bold' }}>{get(action, 'payload.name')}</span> campaign
          folder was {action.crud === 'add' ? 'created' : 'saved'} successfully
        </span>,
      );
    }
    yield call(action.callback);

    const payload = action.crud === 'add' ? res : action.payload;
    if (action.crud === 'edit') {
      payload.oldFolderName = getOldFolderName(campaignFolders, get(action, 'payload._id'));
    }
    yield put(actions.refreshFolders(action.crud, payload));

    if (action.crud === 'add') {
      yield put(actions.setFolderId(res._id));
    }
  } catch (err: any) {
    yield call(action.callback, true /* isFailed */);
    if (err.message || err.code === 404) {
      if (err.code === 500 && err.message === 'UniqueFolderNameAgencyId must be unique') {
        yield call(
          message.error,
          <span>
            Please use another folder name,{' '}
            <span style={{ fontWeight: 'bold' }}>{action.payload.name}</span> is conflicted with a
            history one.
          </span>,
        );
      } else {
        yield call(message.error, err.message || 'The Folder is invalid');
      }
    }
  }
}

function* watchModifysFolder() {
  yield takeLatest(types.MODIFY_FOLDERS, modifyFolders);
}

function getTipForMoveFolder(
  success?: any,
  folders?: any,
  campaigns?: any,
  folderId?: any,
  campaignIds?: any,
) {
  if (!success && !(folderId && 'failed' in folderId)) {
    return 'Failed to move the campaign(s) to folders';
  }

  const ids = success ? campaignIds : folderId.failed;
  const campaignNames = ids
    .map(
      (campaignId: any) => (find(campaigns, { _id: +campaignId }) || {}).campaignName || campaignId,
    )
    .join('&nbsp;&amp;&nbsp;');
  const folderName = (find(folders, { _id: +folderId }) || {}).name;

  const tip = success ? (
    <span style={{ maxWidth: '80vw' }}>
      Moved the&nbsp;
      <span
        style={{ fontWeight: 'bold' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: campaignNames }}
      />{' '}
      campaign(s) to the <span style={{ fontWeight: 'bold' }}>{folderName}</span> folder
      successfully
    </span>
  ) : (
    <span style={{ maxWidth: '80vw' }}>
      Failed to move the&nbsp;
      <span
        style={{ fontWeight: 'bold' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: campaignNames }}
      />{' '}
      campaign(s) to the <span style={{ fontWeight: 'bold' }}>{folderName}</span> folder
    </span>
  );
  return tip;
}

export function* moveFolders(action: any) {
  try {
    const campaigns = yield select(selectors.campaignsSelector);
    const folders = yield select(selectors.foldersSelector);
    const { payload } = action;

    const rez = yield call(withLoading, api.moveFolders, action.type, payload);
    if (rez === true) {
      yield call(
        message.success,
        getTipForMoveFolder(true, folders, campaigns, payload.folderId, payload.campaignIds),
      );
      yield call(action.callback);
    } else {
      yield call(message.error, getTipForMoveFolder(false, folders, campaigns, rez));
    }
  } catch (err: any) {
    yield call(action.callback, true /* isFailed */);
    if (err.message || err.code === 404) {
      yield call(message.error, getTipForMoveFolder(false));
    }
  }
}

function* watchMoveFolder() {
  yield takeLatest(types.MOVE_FOLDERS, moveFolders);
}

export function* fetchCampaignDetail({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.getCampaignDetailById, type, params);
    if (res.AgencyId !== undefined) {
      yield put(getCreatorsNameList({ agencyId: res.agencyId }));
    }
    if (params.copiedCreate /* copy create */) {
      res.campaignName = `${res.campaignName} Copy`;
      delete res._id;
    }
    yield put(actions.setDetailCampaign(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCampaignDetail() {
  yield takeLatest(types.GET_DEATILCAMPAIGN, fetchCampaignDetail);
}

let lastOlapBenchmarksRes: any;
export function* fetchOlapBenchmarks({ type, params }: any) {
  try {
    const isFblive = get(params, 'media') === 'fblive';
    const campaignReport = yield select(selectors.reportSelector);
    const mediaChannel = yield select(getMediaChannelSelector);

    const isParamsOk =
      !isEmpty(params.compareDateBegin) &&
      !isEmpty(params.compareDateEnd) &&
      ((!isFblive && !isEmpty(campaignReport)) || (isFblive && !isEmpty(mediaChannel)));

    const currentUrl = window.location.href.trim();
    const isDiffUrlParms = currentUrl !== lastOlapUrl;
    if (!lastOlapUrl || isDiffUrlParms) {
      lastOlapUrl = currentUrl;
    }
    if (isDiffUrlParms) {
      yield put(actions.setOlapBenchmarks(false));
    }
    if (isParamsOk) {
      const { dateBegin, dateEnd, compareDateBegin, compareDateEnd, paidFilter, ...others } =
        params;
      let res = yield call(withLoading, api.getOlapBenchmarks, type, {
        dateBegin,
        dateEnd,
        compareDateBegin,
        compareDateEnd,
        paidFilter,
        ...others,
      });

      if (res) {
        lastOlapBenchmarksRes = res;
      } else if (!isEmpty(lastOlapBenchmarksRes)) {
        res = lastOlapBenchmarksRes;
      } else {
        return;
      }

      const currentCampaignReport = yield select(selectors.reportSelector);
      const newBenchmarks = get(isFblive ? mediaChannel : currentCampaignReport, 'benchmarks');
      if (isEmpty(newBenchmarks)) {
        lastOlapBenchmarksRes = res;
        return;
      }
      const benchmarks = buildBenchmarksCompare(cloneDeep(newBenchmarks), res);

      if (isFblive) {
        yield put(setMediaReport({ ...mediaChannel, benchmarks }));
      } else {
        yield put(actions.setCampaignReport({ ...currentCampaignReport, benchmarks }));
      }
      yield put(actions.setOlapBenchmarks(true));
    } else {
      lastOlapBenchmarksRes = null;
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetcOlapBenchmarks() {
  yield takeEvery(types.GET_OLAP_BENCHMARKS, fetchOlapBenchmarks);
}

export function* linkPosts({ type, params, callback }: any) {
  try {
    const { isCreate, ...payload } = params;
    const res = yield call(withLoading, api.linkPosts, type, payload);
    if (res.error) {
      yield call(notification.error, {
        message: res.message,
      });
    } else {
      if (!isCreate) {
        yield call(notification.success, {
          message: 'Post Linker Triggered',
        });
      }
      if (callback) {
        yield call(callback);
      }
    }
  } catch (err: any) {
    yield call(notification.error, {
      message: 'Failed to submit link request',
    });
  }
}
function* watchLinkPosts() {
  yield takeLatest(types.LINK_POSTS, linkPosts);
}

export function* restoreCampaigns({ params, callback }: any) {
  try {
    yield call(api.restoreCampaigns, params);
    if (callback) {
      yield call(callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchRestoreCampaigns() {
  yield takeLatest(types.RESTORE_CAMPAIGNS, restoreCampaigns);
}

export function* fetchCampaignInfo({ type, params, force }: any) {
  try {
    const campaignInfo = yield select(selectors.campaignInfoSelector);
    if (+campaignInfo._id !== params.id || force) {
      const res = yield call(withLoading, api.getCampaignInfo, type, params);
      yield put(actions.setCampaignInfo(res));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCampaignInfo() {
  yield takeLatest(types.GET_CAMPAIGN_INFO, fetchCampaignInfo);
}

export function transformCampaignParams(params: any) {
  const { dateBegin } = params;
  let dateEnd;
  if (['snapchat'].includes(params.media)) {
    dateEnd = moment(params.dateEnd).add(1, 'day').format('YYYY-MM-DD');
  } else {
    dateEnd = params.dateEnd;
  }
  const timeZone = params.timeZone || getCurrentTimeZone();
  if (params.media === 'overview') {
    return {
      ...params,
      timeZone,
      dateBegin,
      dateEnd,
      paidFilter: params.paidFilter || 'all',
    };
  }
  return { ...params, timeZone, dateBegin, dateEnd };
}

export function* fetchCampaignReport({ type, params: allParams, force }: any) {
  const { callback, ...params } = allParams;
  try {
    const report = yield select(selectors.reportSelector);
    if (!params.dateBegin || !params.dateEnd) {
      const campaignInfo = yield call(withLoading, api.getCampaignInfo, types.GET_CAMPAIGN_INFO, {
        id: allParams.id,
      });
      const campaignDateBegin = moment(campaignInfo.dateStart).format('YYYY-MM-DD');
      const campaignDateEnd = moment(campaignInfo.dateEnd).format('YYYY-MM-DD');
      yield put(
        pushLocation(
          `?dateBegin=${encodeURIComponent(campaignDateBegin)}&dateEnd=${encodeURIComponent(
            campaignDateEnd,
          )}&paidFilter=${encodeURIComponent(params.paidFilter)}`,
        ),
      );
    }
    if (
      force ||
      +params.id !== +report.id ||
      params.media !== report.media ||
      params.dateBegin !== report.dateBegin ||
      params.dateEnd !== report.dateEnd ||
      params.compareDateBegin !== report.compareDateBegin ||
      params.compareDateEnd !== report.compareDateEnd ||
      params.paidFilter !== report.paidFilter
    ) {
      const currentUrl = window.location.href.trim();
      if (lastOlapUrl !== currentUrl) {
        if (`${lastOlapUrl}`.includes('compareDateBegin')) {
          yield put(actions.setOlapBenchmarks(false));
        }
        lastOlapUrl = currentUrl;
      }
      const transedParams = yield call(transformCampaignParams, params);
      const res: any = yield call(withLoading, api.getCampaignReport, type, transedParams);

      let newData = { ...res, ...params };
      if (lastOlapBenchmarksRes && get(res, 'benchmarks')) {
        const benchmarks = buildBenchmarksCompare(get(res, 'benchmarks'), lastOlapBenchmarksRes);
        newData = { ...newData, benchmarks };
      }

      if (params.media === 'fblive') {
        yield put(setMediaReport(newData));
      } else {
        yield put(actions.setCampaignReport(newData));
      }

      if (lastOlapBenchmarksRes) {
        lastOlapBenchmarksRes = null;
      }

      // for OlapBenchmarks
      if (!isEmpty(get(res, 'benchmarks')) && isFunction(callback)) {
        (callback as any).call();
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCampaignReport() {
  yield takeLatest(types.GET_REPORT, fetchCampaignReport);
}

export function* fetchIgStoresCreatorsPosts({ type, params }: any) {
  const timeZone = params.timeZone || getCurrentTimeZone();
  const { dateEnd, dateBegin } = params;
  const funcs = params.ids
    .split(',')
    .map((id: any) => [
      api.getIgStoriesCampaignPostByCreators,
      { id, timeZone, campaignId: params.campaignId, dateBegin, dateEnd },
    ]);
  const res = yield call(withLoading, funcs, type);

  const creators: any = [];
  const stories = flatten(
    res.map((result: any) => {
      creators.push(result.creators[0]);
      return result.igStories.map((story: any) => {
        const creator = result.creators.find(
          (creator2: any) => creator2._id === story.channel.CreatorId,
        );
        return {
          ...story,
          creator,
        };
      });
    }),
  );

  if (getMediaReportsAvailableVal(params.media, 'sessionQueue')) {
    setStoryQueue(params.media, {
      data: getMediaReportsAvailableVal(params.media, 'sessionQueue')(stories),
      params: { ...params, isCampaigns: true },
    });
  }

  return { creators, stories };
}

export function* fetchGeneralMediaCreatorsPosts({ type, params }: any) {
  const timeZone = params.timeZone || getCurrentTimeZone();
  const res = yield call(withLoading, api.getCampaignPostByCreators, type, {
    ...params,
    timeZone,
    media: getMediaReportsAvailableVal(params.media, 'api') || params.media,
  });

  let transedRes;
  if (['instagram', 'youtube'].includes(params.media)) {
    const mapCreatorPosts = res[getMediaReportsAvailableVal(params.media, 'detailField')].map(
      (post: any) => {
        const creator = res.creators.find(
          (item: any) => get(item, 'channels[0]._id') === post.CreatorChannelId,
        );
        return { ...post, creator };
      },
    );
    transedRes = {
      ...res,
      [getMediaReportsAvailableVal(params.media, 'detailField')]: mapCreatorPosts,
    };
  } else {
    transedRes = res;
  }
  return transedRes;
}

export function* fetchSnapchatCreatorsPosts({ type, params }: any) {
  const res = yield call(withLoading, api.getSnapchatCampaignPostByCreators, type, params);

  if (getMediaReportsAvailableVal(params.media, 'sessionQueue')) {
    setStoryQueue(params.media, {
      data: getMediaReportsAvailableVal(params.media, 'sessionQueue')(res.stories),
      params: { ...params, isCampaigns: true },
    });
  }

  return res;
}

export function* fetchPostsFromFlaskApi({ type, params }: any) {
  const timeZone = params.timeZone || getCurrentTimeZone();
  const transParams = { ...params, timeZone };

  const res = yield call(withLoading, api.getCampaignPostsFromFlaskApi, type, transParams);

  if (getMediaReportsAvailableVal(params.media, 'sessionQueue')) {
    setStoryQueue(params.media, {
      data: getMediaReportsAvailableVal(params.media, 'sessionQueue')(res.stories || res.igStories),
      params: { ...params, isCampaigns: true },
    });
  }

  return res;
}

export function* fetchAdPosts({ type, params }: { type: string; params: any }) {
  const timeZone = params.timeZone || getCurrentTimeZone();
  let media = '';
  switch (params.media) {
    case 'facebookad':
      media = 'facebook';
      break;
    case 'instagramad':
      media = 'instagrams';
      break;
    default:
  }

  return yield call(withLoading, api.getAdPosts, type, {
    ...params,
    timeZone,
    media,
  });
}

export function* fetchCreatorsPosts(actions2: any, pipe = false) {
  try {
    let res;
    const enableFlaskApi = apiEnums.campaigns;
    if (enableFlaskApi) {
      res = yield call(fetchPostsFromFlaskApi, actions2);
    } else if (actions2.params.media === 'instagramstories') {
      res = yield call(fetchIgStoresCreatorsPosts, actions2);
    } else if (actions2.params.media === 'snapchat') {
      res = yield call(fetchSnapchatCreatorsPosts, actions2);
    } else if (adPlatforms.includes(actions2.params.media)) {
      res = yield call(fetchAdPosts, actions2);
    } else {
      res = yield call(fetchGeneralMediaCreatorsPosts, actions2);
    }
    if (pipe) {
      return { ...res, media: actions2.params.media, creatorIds: actions2.params.ids };
    }
    yield put(setMediaReport(res));

    return '';
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
    return '';
  }
}
function* watchFetchCreatorsPosts() {
  yield takeLatest(types.GET_CERATORS_POSTS, fetchCreatorsPosts);
}

export function* fetchBatchCreatorsPosts({ params, callback }: any) {
  let res = [];
  if (params.length > 0) {
    res = yield all(params.map((task: any) => call(fetchCreatorsPosts, { params: task }, true)));
  }
  yield call(callback, res);
}

function* watchFetchBatchCreatorsPosts() {
  yield takeLatest(types.BATCH_CREATORS_POSTS, fetchBatchCreatorsPosts);
}

export function* deleteCreatorsPosts({ type, params, callback }: any) {
  try {
    if (params.enableFlaskApi) {
      const funcs = params.postIds.map((postId: any) => [
        deleteCampaignsByFlaskApi,
        { channel: params.channel, _id: params._id, postId },
      ]);
      yield call(withLoading, funcs, type);
    } else {
      yield call(withLoading, api.deleteCampaignPosts, type, params);
    }
    if (callback) {
      callback();
    }
    yield fork(message.success, 'Posts removed successfully');
  } catch (err: any) {
    yield call(message.error, 'Failed to remove posts from the campaign');
  }
}
function* watchDeleteCreatorsPosts() {
  yield takeLatest(types.DELETE_CERATORS_POSTS, deleteCreatorsPosts);
}

export function* multiPreviewPosts({ params, callback }: any) {
  try {
    let res = {};
    if (isArray(params)) {
      res = yield call(
        sagaSequence as any,
        params.map((url) => [previewPostByIframely, { iframe: 'card-small', media: 0, url }]),
      );
    } else {
      res = yield call(previewPostByIframely, { iframe: 'card-small', media: 0, url: params });
    }
    if (callback) {
      yield call(callback, res);
    }
  } catch (err: any) {
    const res = { ...err, status: err.code };
    yield call(callback, res, res.status !== 404 ? 'Failed to preview post' : true);
  }
}
function* watchMultiPreviewPosts() {
  yield takeLatest(types.MULTI_PREVIEW_URLS, multiPreviewPosts);
}

export function* getCreatorByChannel({ params, index }: any) {
  try {
    const res = yield call(api.getCreatorByChannel, params);
    if (!res) {
      return { type: 'error', message: 'Account was not found, add them first', index };
    }
    return res;
  } catch (err: any) {
    if ([[404, 409]].includes(err.status)) {
      return {
        type: 'error',
        message: err.message || 'Problem getting Account information for post',
        index,
      };
    }
    return {
      type: 'error',
      message: err.message || 'Problem getting Account information for post',
      index,
    };
  }
}

export function* addPostByUrlResolved({ params, index }: any) {
  try {
    const res = yield call(addPostByUrl, params);
    return { ...res, type: 'success', index };
  } catch (err: any) {
    return { type: 'error', message: err.message || 'Failed to add post to Campaign', index };
  }
}

export function* bulkUploadUrls({ params: { lookupParams, campaignId }, callback }: any) {
  const res = yield call(
    sagaSequence as any,
    lookupParams.map((task: any, index: any) => [
      getCreatorByChannel,
      { params: omit(task, ['embedProblem', 'originalInputUrl', 'embedResultUrl']), index },
    ]),
  );
  const errorRes = res.filter((r: any) => r.type === 'error');
  if (errorRes.length) {
    yield call(callback, errorRes, true);
  } else {
    const transFormedRes = res.map((creator: any, index: any) => {
      const post: any = {
        CampaignId: campaignId,
        CreatorChannelId: creator.channels[0]._id,
        code: lookupParams[index].code,
        CreatorId: creator._id,
        socialId: get(creator, 'channels[0].socialId', undefined),
      };
      if (lookupParams[index].embedProblem) {
        post.permalinkUrl = lookupParams[index].originalInputUrl;
        // message.warning("Unable to embed preview, but found matching account for the post. Continue by clicking 'Add Post'");
      } else {
        post.permalinkUrl = lookupParams[index].embedResultUrl;
      }

      if (
        lookupParams[index].code === 'FB' &&
        lookupParams[index].originalInputUrl.split('/')[3].includes('_')
      ) {
        post.permalinkUrl = lookupParams[index].originalInputUrl;
      }
      return post;
    });

    // const uploads = yield all(transFormedRes.map((param, index) => call(addPostByUrlResolved, param, index)));
    const uploads = yield call(
      sagaSequence as any,
      transFormedRes.map((params: any, index: any) => [
        addPostByUrlResolved,
        { params, index },
        200,
      ]),
    );
    const errorUploads = uploads.filter((r: any) => r.type === 'error');
    if (errorUploads.length) {
      yield call(callback, uploads, true);
    } else {
      yield call(callback);
      yield call(message.success, uploads[0].message);
    }
  }
}
function* watchBulkUploadUrls() {
  yield takeLatest(types.BULK_UPLOAD_URLS, bulkUploadUrls);
}

export function* addPostsByCSV({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, bulkAddPostsByCSV, type, params);
    if (res.error) {
      yield call(
        message.error,
        res.message ||
          "Couldn't add the posts. Please try again or contact customer support if the problem persists.",
      );
    } else {
      if (callback) {
        yield call(callback);
      }
      yield call(
        message.success,
        'It will take a few minutes to process the file. A summary email will be sent to you shortly.',
      );
    }
  } catch (err: any) {
    yield call(
      message.error,
      err.message ||
        "Couldn't add the posts. Please try again or contact customer support if the problem persists.",
    );
  }
}
function* watchAddPostsByCSV() {
  yield takeLatest(types.ADD_POSTS_BY_CSV, addPostsByCSV);
}

export function* fetchCampaignUsers() {
  try {
    const users: any = select(selectors.usersSelector);
    if (!users.length) {
      const res = yield call(getUsers as any);
      yield put(actions.setCampaignUsers(res));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCampaignUsers() {
  yield takeLatest(types.GET_CAMPAIGN_USERS, fetchCampaignUsers);
}

export function* updateCampaignUsers({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.updateCampaignUser, type, params);
    const campaigns = yield select(selectors.campaignsSelector);
    const newCampaigns = campaigns.map((item: any) => (item._id === params._id ? res : item));
    yield put(actions.setCampaigns(newCampaigns));
    yield call(notification.success, {
      message: 'Assigned User',
      description: `${params.campaignName}'s information has been updated.`,
    });
    if (callback) {
      yield call(callback, res);
    }
  } catch (err: any) {
    yield call(notification.error, {
      message: 'Assigned User',
      description: `${params.campaignName}'s information was not updated. ${err.message || ''}`,
    });
  }
}
function* watchUpdateCampaignUsers() {
  yield takeLatest(types.UPDATE_CAMPAIGN_USER, updateCampaignUsers);
}

export function* deleteCampaign({ type, params, callback }: any) {
  try {
    yield call(withLoading, api.deleteCampaigin, type, params.campaignId);
    yield call(message.success, `The ${params.campaignName} campaign was deleted successfully`);
    if (callback) {
      yield call(callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchDeleteCampaign() {
  yield takeLatest(types.DELETE_CAMPAIGN, deleteCampaign);
}

export function* createHashTag({ type, params, callback }: any) {
  try {
    const name = params.name.toLowerCase().replace(/#/g, '');
    const res = yield call(withLoading, api.createHashTag, type, { name });
    if (callback) {
      yield call(callback, res);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchCreateHashTag() {
  yield takeLatest(types.CREATE_HASH_TAG, createHashTag);
}

export function* createCampaign({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.editCampaigns, type, {
      ...params,
      timeZone: getCurrentTimeZone(),
    });
    if (res.err) {
      yield call(message.error, 'Problem creating campaign.');
    } else {
      yield put(actions.setDetailCampaign(res));
      if (callback) {
        yield call(callback, res);
      }
      yield call(
        message.success,
        <span>
          The <span style={{ fontWeight: 'bold' }}>{res.campaignName}</span> campaign was created
          successfully
        </span>,
      );
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchCreateCampaign() {
  yield takeLatest(types.CREATE_CAMPAIGN, createCampaign);
}

export function* updateCampaign({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.editCampaigns, type, {
      ...params,
      timeZone: getCurrentTimeZone(),
    });
    if (res.err) {
      yield call(message.error, 'Problem saving changes.');
    } else {
      yield put(actions.setDetailCampaign({ ...params, ...res }));
      yield call(
        message.success,
        <span>
          The <span style={{ fontWeight: 'bold' }}>{res.campaignName}</span> campaign was saved
          successfully
        </span>,
        1 /* update the default duration from 3 to 1 as there's a followed popup in the callback and won't make the it faster */,
      );
      if (callback) {
        yield call(callback, res);
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchUpdateCampaign() {
  yield takeLatest(types.UPDATE_CAMPAIGN, updateCampaign);
}

export function* fetchAgencyIdByUser() {
  try {
    const res = yield call(api.getAgencyIdByUser);
    yield put(actions.setAgencyId(res.AgencyId));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAgencyId() {
  yield takeLatest(types.GET_AGENCY_ID, fetchAgencyIdByUser);
}

export default [
  watchFetchCampaigns,
  watchFetchFolders,
  watchModifysFolder,
  watchMoveFolder,
  watchFetchCampaignDetail,
  watchLinkPosts,
  watchRestoreCampaigns,
  watchFetchCampaignInfo,
  watchFetchCampaignReport,
  watchFetchCreatorsPosts,
  watchDeleteCreatorsPosts,
  watchMultiPreviewPosts,
  watchFetchCampaignUsers,
  watchUpdateCampaignUsers,
  watchDeleteCampaign,
  watchCreateHashTag,
  watchCreateCampaign,
  watchUpdateCampaign,
  watchFetchBatchCreatorsPosts,
  watchFetchAgencyId,
  watchFetchAgencies,
  watchFetchCreators,
  watchFetchSearchedTags,
  watchFetcOlapBenchmarks,
  watchBulkUploadUrls,
  watchAddPostsByCSV,
];
