import * as types from './constants';

export function getDownload(params: any) {
  return {
    type: types.GET_DOWNLOAD,
    params,
  };
}

export function setDownload(payload: any) {
  return {
    type: types.SET_DOWNLOAD,
    payload,
  };
}
