import moment from 'moment-timezone';
import { optionCredentialAPI } from '@/utils/helper';
import client from '../Client/index';

// Country
export const getCountries = () => client.get('/countries/getFullList');
export const setCountryActive = (params: any) =>
  client.put(`/countries/${params.isoCountry2Code}`, params);

// TimeZone
export const getTimeZoneList = () => client.get('/timeZone');

// Studio Logins
export const getStudioLogins = (params: any) =>
  client.get(`/studioLogins?dateBegin=${params.dateBegin}&dateEnd=${params.dateEnd}`);

// Lists
export const getList = ({ id, agencies, creators }: any) =>
  client.get(`/talentGroups/${id}?agencies=${agencies}&creators=${creators}`);
export const getLists = (params: any) => {
  const baseUrl = '/talentGroups?agencies';
  const url = params
    ? `${baseUrl}=${params.agencies}&creators=${params.creators}&count=${params.count}`
    : baseUrl;
  return client.get(url);
};
export const getListAgencies = () => client.get('/agencies/list');
export const getListCreators = () => client.get('/creators/list');
export const postList = (params: any) => {
  // edit or add
  // _id start from 0, so must judge it's type is undefined or not
  const url = typeof params._id !== 'undefined' ? `/talentGroups/${params._id}` : '/talentGroups';
  return client.post(url, params);
};

// Agency
export const getAgencies = (params: any) => {
  const base = '/agencies?order=name';
  const url = params && params.simple ? `${base}&simple=true` : base;
  return client.get(url);
};

export const getAgencyDetailById = (params: { id: number }) => client.get(`/agencies/${params.id}`);

export const postAgency = (params: any) => {
  // edit or add
  // _id start from 0, so must judge it's type is undefined or not
  const url = typeof params._id !== 'undefined' ? `/agencies/${params._id}` : '/agencies';
  return client.post(url, params);
};
export const getImageList = (agencyId: any) =>
  client.get(`/agencies/imageList?agencyId=${agencyId}`);
// export const domainLookup = (params: any) => client.get(`/agencies/domainLookup/${params.domain}?agencyId=${params.agencyId}`);

// Genres
export const getGenres = () => client.get('/genres');
export const getInterests = () => client.get('/interests');
export const getMusicGenres = () => client.get('/musicgenres');
export const modifyGenres = (params: any) => {
  let res;
  switch (params.crud) {
    case 'edit':
      res = client.put(`/${params.typename}/${params.payload._id}`, params.payload);
      break;
    case 'add':
      res = client.post(`/${params.typename}`, params.payload);
      break;
    case 'delete':
      res = client.delete(`/${params.typename}/${params.payload._id}`);
      break;
    default:
      break;
  }
  return res;
};

// credentials
export const getCredentials = () => client.get('/credentials');
export const updateCredential = (params: any) => client.post(`/credentials/${params._id}`, params);

// channelTokens
export const getChannelTokens = (params: any) =>
  client.get(`/channelTokens?vals=${encodeURIComponent(JSON.stringify(params))}`);
export const getInvalidChannelTokens = (params: any) =>
  client.get(
    `/channelTokens/invalidChannelTokens?startDate=${moment(params.startDate)
      .startOf('day')
      .utc()
      .format()}&endDate=${moment(params.endDate).endOf('day').utc().format()}`,
  );
export const getCreatorChannels = () => client.get('/creatorChannels');
export const notifyInvalidChannelToken = (params: any) =>
  client.post('/channelTokens/notifyInvalid', params);

// user
export const getUsers = (params: any) => client.get('/users', params);
export const getRoles = (params: any) => client.get('/users/roles', params);
export const postUser = (params: any) => {
  // edit or add
  // _id start from 0, so must judge it's type is undefined or not
  const url = typeof params._id !== 'undefined' ? `/users/${params._id}` : '/users';
  return client.post(url, params);
};
export const nullifyUserPassword = (params: any) =>
  client.put(`/users/${params.id}/nullifyPassword`);
export const expireUserPassword = (params: any) => client.put(`/users/${params.id}/expirePassword`);
export const getChannelTokensByUserId = (params: any) =>
  client.get(`/channelTokens/byUserId/${params}`);

// scheduledExports
export const getScheduledExports = (params: any) => {
  const type = params.toLowerCase();
  const query = type === 'pending' ? { type: 'all' } : {};
  return client.get(`/scheduledExport/list/${type}`, query);
};
export const cancelScheduledExports = (params: any) =>
  client.get(`/scheduledExport/cancel/${params}`);
export const excuteScheduledExports = (params: any) =>
  client.get(`/scheduledExport/execute/${params.id}`, { force: params.force });
export const updateScheduledExport = (params: any) =>
  client.post(`/scheduledExport/update/${params._id}`, params);
export const deleteDownload = (params: any) => client.delete(`/download/delete/download/${params}`);

// permissions
export const getPermissions = (params: any) => client.get(`/${params}`);
export const modifyPermission = (params: any) => {
  let res;
  switch (params.crud) {
    case 'Edit':
      res = client.put(`/user${params.typename}/${params.payload._id}`, params.payload);
      break;
    case 'Add':
      switch (params.typename) {
        case 'Groups':
        case 'Permissions':
        case 'Roles':
          res = client.post(`/user${params.typename}`, params.payload);
          break;
        case 'GroupMembers':
          res = client.post(
            `/user${params.typename}/${params.payload.UserId}/${params.payload.UserGroupId}`,
            params.payload,
          );
          break;
        case 'GroupPermissions':
          res = client.post(
            `/user${params.typename}/${params.payload.UserPermissionId}/${params.payload.UserGroupId}`,
            params.payload,
          );
          break;
        case 'RolePermissions':
          res = client.post(
            `/user${params.typename}/${params.payload.UserPermissionId}/${params.payload.UserRoleId}`,
            params.payload,
          );
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return res;
};
export const getUserGroup = (params: any) => client.get(`/userGroups/${params}`);
export const getUserRole = (params: any) => client.get(`/userRoles/${params}`);
export const deleteUserGroupMember = (params: any) =>
  client.post(`/userGroupMembers/delete/${params.memberId}/${params.groupId}`);
export const deleteUserGroupPermission = (params: any) =>
  client.post(`/userGroupPermissions/delete/${params.permissionId}/${params.groupId}`);
export const deleteUserRolePermission = (params: any) =>
  client.post(`/userRolePermissions/delete/${params.permissionId}/${params.roleId}`);
export const getUserGroupMembersAddable = (params: any) =>
  client.get(`users?UserGroupId=${params}`);

// menu
export const getMenus = (params: any) => client.get('/menu', params);
export const getUserPermissions = () => client.get('/userPermissions');
export const getMenuItem = (params: any) => client.get(`/menu/${params}`);
export const modifyMenuItem = (params: any) =>
  client.post(params.payload._id ? `/menu/${params.payload._id}` : '/menu', params.payload);

export const importOkta = (params: any) => client.get(`/users/oktaImport/${params.email}`);

// admin/facebooklive
export const checkFacebookLive = (params: any) => client.post('/facebook/live/check', params);
export const launchFacebookLive = (params: any) =>
  client.post('/facebook/live/launch', params.payload);

// SI API Owner
export const getPlanList = () => client.get('/plans', null, optionCredentialAPI());
export const getOwnerLit = (params: any) => client.get('/owners', params, optionCredentialAPI());
export const modifyOwner = (params: any) =>
  client.post('/owners', { crud: params.crud, ...params.payload }, optionCredentialAPI());
export const deleteOwner = (params: any) =>
  client.delete(
    `/owners/${params.payload.id}?apiKeyId=${params.payload.apiKeyId}&usagePlanId=${params.payload.usagePlanId}`,
    optionCredentialAPI(),
  );

// SI API Credential
export const getCredentialLit = (params: any) =>
  client.get('/credentials', params, optionCredentialAPI());

export const modifyAPICredential = (params: any) =>
  client.post('/credentials', params.payload, optionCredentialAPI());

// SI API Usage
export const getUsageList = (params: any) => client.get('/usage', params, optionCredentialAPI());

export const getUsageDaily = (params: any) =>
  client.get('/usageDaily', params, optionCredentialAPI());

// Twitter Pro Media
export const getCreatorChannelNameList = (params: any) =>
  client.get('/creatorChannels/nameList', params);
export const getTwitterProMediaAccounts = () =>
  client.get('/creatorChannels/twitterProMediaAccounts');

// Trial User
export const addTrialUser = (params: any) => client.post('/users/trial', params);

export const getTrialList = () => client.get('/users/trial/list');

export const sendTrialConfirm = (params: { id: string | number }) =>
  client.get(`/users/trial/resend/${params.id}`);

export const getEnableProd = (params: any) => client.post('/users/trial/enable', params);

export const getRemainingInvites = () => client.get('/users/invite/remaining');

export const bulkInviteUsers = (params: any) => client.post('/users/invite', params);

export const getInviteList = () => client.get('/users/invite/list');

export const getAddOns = () => client.get('/addOns');

export const createOrUpdateAddOn = (params: any) => {
  const url = params._id ? `/addOns/${params._id}` : '/addOns';
  return client.post(url, params);
};

// Data Collection
export const queueCirculateJob = (params: any) => client.post('/circulate/queue', params);

export const getCirculates = (params: any) => client.get('/circulate', params);

export const getCirculateJobs = (params: any) =>
  client.get(
    `/circulate/getJobs?startDate=${moment(params.startDate)
      .startOf('day')
      .utc()
      .format()}&endDate=${moment(params.endDate).endOf('day').utc().format()}`,
  );

export const getCirculateJobsStatus = (params: any) =>
  client.get(
    `/circulate/getJobs?startDate=${moment(params.startDate)
      .startOf('day')
      .utc()
      .format()}&endDate=${moment(params.endDate)
      .endOf('day')
      .utc()
      .format()}&ids=${params.ids.join(',')}`,
  );

export const createCirculateJob = (params: any) => client.post('/circulate/createJobs', params);

export const bulkAccountUpload = async (talentGroups: number[], file: File) => {
  const formData = new FormData();
  formData.append('data', file);
  formData.append('talentGroupIds', talentGroups.join(','));

  try {
    const res = await client.post('/creators/csv_import', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return res;
  } catch (err) {
    throw new Error((err as Error).message);
  }
};

export const getAccountList = (params: any) =>
  client.get(
    // https://conviva.atlassian.net/browse/SI-1033
    // there may be '#' in the path
    `/creators/search/${encodeURIComponent(params.searchTerm)}/ALL${
      params.agencyId !== undefined ? `?AgencyId=${params.agencyId}` : ''
    }`,
  );
