import { call, takeEvery, delay } from 'redux-saga/effects';
import withLoading from '@/sagaMiddleware';
import * as api from '@/service/Iframely';
import * as types from './constants';

export function* previewPost({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.previewPostByIframely, type, params);
    if (callback) {
      yield delay(3000);
      yield call(callback, res, 'error' in res);
    }
  } catch (err) {
    yield call(callback, err, true);
  }
}
function* watchPreviewPost() {
  yield takeEvery(types.PREVIEW_POST, previewPost);
}

export default [watchPreviewPost];
