import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ErrorBoundary, withProfiler } from '@sentry/react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { inSingleApp, can } from '@/utils/helper';
import { GlobalGuard, AuthorizedRoute } from '@/routes';
import SuspenseFallback from '@/common/SuspenseFallback';
import Layout from './common/Layout';

const Welcome = lazy(() => import('./common/Welcome/adapter'));
const Developing = lazy(() => import('./common/Developing'));
const MyProfile = lazy(() => import('./common/MyProfile/adapter'));
const Leaderboard = lazy(() => import('./modules/Leaderboard'));
const Accounts = lazy(() => import('./modules/Accounts'));
const AccountsSelf = lazy(() => import('./modules/AccountsSelf'));
const Analytics = lazy(() => import('./modules/Analytics/containers'));
const Campaigns = lazy(() => import('./modules/Campaigns/containers'));
const ContentTags = lazy(() => import('./modules/ContentTags'));
const Settings = lazy(() => import('./modules/Settings/containers/SettingsRouter'));
const Admin = lazy(() => import('./modules/Admin/containers/AdminRouter'));
const Download = lazy(() => import('./modules/Download/containers/adapter'));
const Support = lazy(() => import('./common/Support'));
const Export = lazy(() => import('./modules/Export/containers/adapter'));
const Auth = lazy(() => import('./modules/Auth/containers'));

Spin.setDefaultIndicator(<LoadingOutlined spin />);

const legacyRoutes = [
  {
    path: '/login',
    exact: true,
    component: Auth,
  },
  {
    path: '/login/legacy',
    component: Auth,
  },
  {
    path: '/logout',
    component: Auth,
  },
  {
    path: '/implicit/callback',
    exact: true,
    component: Auth,
  },
  {
    path: '/trialConfirm',
    component: Auth,
  },
];

interface AppProps {
  landingUrl?: string;
  container: HTMLElement;
}

const App = ({ landingUrl, container }: AppProps) => (
  <ErrorBoundary>
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        {inSingleApp()
          ? landingUrl && <Redirect exact from="/" to={landingUrl} />
          : legacyRoutes.map((it) => <Route key={it.path} {...it} />)}
        <Route path="/export">
          <Export />
        </Route>
        <Route path="/">
          <GlobalGuard>
            {() => (
              <Layout container={container}>
                <Suspense fallback={<SuspenseFallback />}>
                  <Switch>
                    <Route exact path="/" component={Welcome} />
                    <Route path="/myprofile" component={MyProfile} />
                    <Route path="/leaderboard" component={Leaderboard} />
                    <Route path="/accounts">
                      {can('self-service.creator') ? <AccountsSelf /> : <Accounts />}
                    </Route>
                    <AuthorizedRoute
                      auth="analytics.view"
                      path="/analytics"
                      component={Analytics}
                    />
                    <Route path="/campaigns" component={Campaigns} />
                    <Route path="/contenttags">
                      <ContentTags />
                    </Route>
                    <Route path="/settings" component={Settings} />
                    <AuthorizedRoute auth="admin.panel" path="/admin" component={Admin} />
                    <Route path="/download/:duid?" component={Download} />
                    <Route path="/support" component={Support} />
                    <Route>
                      <Developing />
                    </Route>
                  </Switch>
                </Suspense>
              </Layout>
            )}
          </GlobalGuard>
        </Route>
      </Switch>
    </Suspense>
  </ErrorBoundary>
);

export default withProfiler(React.memo(App));
