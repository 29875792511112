import moment from 'moment-timezone';
import { get, set } from 'lodash';
import { getPeriodGroupConfig } from '@/common/PersistentDataHoc/LocalStorageHoc';
import { getTQueryData } from '@/query';

/**
 * @param time0
 * @param time1
 * @param ascend boolean
 * @returns {number}
 */
export function sortDate(time0: any, time1: any, ascend: any = true) {
  if (moment(time0).isBefore(time1)) {
    return ascend ? -1 : 1;
  }
  if (moment(time0).isSame(time1)) {
    return 0;
  }
  return ascend ? 1 : -1;
}

export function serializeDate(
  date: moment.MomentInput,
  { type, absolute, dateFormat }: { type: any; absolute?: any; dateFormat?: any },
) {
  let finalDate = '';
  if (absolute) {
    finalDate = moment(date).utc().format(dateFormat);
  } else {
    const dataM: { [index: string]: any } = moment(date);
    // 'startOf' or 'endOf' for type here
    finalDate = dataM[type]('day').utc().format(dateFormat);
  }
  // invalid date
  if (finalDate === moment.invalid().format()) {
    throw new TypeError('Invalid date, please check');
  }
  return finalDate;
}

export function serializeDateForTimeZone(
  date: moment.MomentInput,
  { dateFormat }: { dateFormat?: string },
) {
  if (dateFormat) {
    const finalDate = moment(date).format(dateFormat);
    // invalid date
    if (finalDate === moment.invalid().format()) {
      throw new TypeError('Invalid date, please check');
    }
    return finalDate;
  }
  return date;
}

export function getDurationAsDaysByTimeRange(dateBegin: any, dateEnd: any) {
  const begin = moment(dateBegin);
  const end = moment(dateEnd);
  let duration = Math.abs(end.diff(begin, 'days'));
  if (duration > 1) {
    duration += 1;
  }
  return duration;
  // if (duration > Math.floor(duration)) {
  //   return Math.floor(duration) + 1;
  // }
  // return Math.floor(duration);
}

export function getDateRangeFromNow(days: any) {
  const dateRange = {} as any;
  if (+days === 1) {
    // past 24-hours
    dateRange.dateEnd = moment().format('YYYY-MM-DD HH:mm:ss');
    dateRange.dateBegin = moment().subtract(1, 'd').format('YYYY-MM-DD HH:mm:ss');
  } else {
    // Last X Days
    dateRange.dateEnd = moment().format('YYYY-MM-DD');
    dateRange.dateBegin = moment()
      .subtract(days - 1, 'd')
      .format('YYYY-MM-DD');
  }
  return dateRange;
}

/**
 * format to 'YYYY-MM-DD' or 'YYYY-MM-DD hh:mm:ss'
 */
export const formatDateStr = (mm: any, time?: any) => {
  const timeShape = time === true;
  if (typeof mm === 'string') {
    mm = moment(mm);
  }

  const formater = (mm2: any) =>
    (mm2 || moment()).format(timeShape ? 'YYYY-MM-DD hh:mm:ss' : 'YYYY-MM-DD');

  return Array.isArray(mm) ? mm.map(formater) : formater(mm);
};

/**
 * Note: if applied in case to the page with PeriodGroup component,
 *   such as analysis / campaign list / campaign detail...
 * Use initDateRangeForPeiod !!!(the component has its own logics)
 */
export function initDateRange(days = 14) {
  const mmBase = moment();

  return {
    dateEnd: mmBase.format('YYYY-MM-DD'),
    dateBegin: mmBase.subtract(days - 1, 'd').format('YYYY-MM-DD'),
  };
}

export const isEastern = () => new Date().getTimezoneOffset() < 0;

/**
 * @param prevOneDay: period relative duration, i.e. 'Last 14 Days', calculated by prev-one-day
 * @param moduleNamespace: key in localstorage.periodGroup.currentToggle[namespace] oneOf [analytics | campaign |campaignList]
 */
export function initDateRangeForPeiod(
  days?: number,
  prevOneDay = true,
  moduleNamespace = 'analytics',
) {
  const { dateBegin, dateEnd } = initDateRange(days);
  const inEastern = isEastern();
  const currentStr = moment().format('MMMM Do YYYY, hh:mm:ss a');
  const isUsAm = currentStr.endsWith('am');

  const { periodGroupConfig } = getPeriodGroupConfig();
  const currentToggle = get(periodGroupConfig, `currentToggle.${moduleNamespace}`);

  const ranges = {
    masterEndWithYesterday: {
      dateBegin: formatDateStr(moment(dateBegin).subtract(1, 'day')),
      dateEnd: formatDateStr(moment(dateEnd).subtract(1, 'day')),
    },
    masterEndWithToday: { dateBegin, dateEnd },
    masterEndWithTomorrow: {
      dateBegin: formatDateStr(moment(dateBegin).add(1, 'day')),
      dateEnd: formatDateStr(moment(dateEnd).add(1, 'day')),
    },
  };
  let rangeIndex = 'masterEndWithToday';

  if (currentToggle) {
    prevOneDay = false;
  }
  if (inEastern && !isUsAm) {
    prevOneDay = false;

    /* include today */
    if (currentToggle) {
      rangeIndex = 'masterEndWithTomorrow';
    }
  }

  if (prevOneDay) {
    rangeIndex = 'masterEndWithYesterday';
  }

  return ranges[rangeIndex];
}

/**
 * formatDate - Formats a date
 * @param {String} dateString - Date string to format
 * @param {Object} options - Options for formatting
 * @param {String} options.format - Format to be used
 * @param {String} options.type - Shortcut for setting format type
 * @param {String} options.type - Separator to be used in date only format
 * @param {Boolean} options.utc - If source date should be considered UTC
 */
export function formatDate(dateString: any, options?: any) {
  const opts = {
    format: 'MM/DD/YY hh:mm A',
    separator: '/',
    type: null,
    timezone: null,
    utc: false,
    ...options,
  };
  if (opts.type === 'date') {
    opts.format = `MM${opts.separator}DD${opts.separator}YY`;
  } else if (opts.type === 'time') {
    opts.format = 'hh:mm A';
  } else if (opts.type === 'alt') {
    opts.format = 'MM-DD-YYYY HH:mm:ss';
  }
  if (opts.utc) {
    return opts.unix
      ? moment.unix(dateString).utc().format(opts.format)
      : moment.utc(dateString).format(opts.format);
  }
  return opts.unix
    ? moment.unix(dateString).format(opts.format)
    : moment(dateString).format(opts.format);
}

export function dayOfWeek(day: any) {
  const dayOfWeek2 = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return dayOfWeek2[day];
}

export function formatDateRange(dateBegin: any, dateEnd: any) {
  if (dateBegin && dateEnd) {
    const begin = moment(dateBegin);
    const end = moment(dateEnd);
    if (begin.isSame(end, 'year')) {
      return {
        dateBegin: begin.format('MMM D'),
        dateEnd: end.format('MMM D, YYYY'),
      };
    }
    return {
      dateBegin: begin.format('MMM D, YYYY'),
      dateEnd: end.format('MMM D, YYYY'),
    };
  }
  return {
    dateBegin: '',
    dateEnd: '',
  };
}

export function formatDateRangeReturnObj({ dateBegin, dateEnd }: { dateBegin: any; dateEnd: any }) {
  const begin = moment(dateBegin);
  const end = moment(dateEnd);
  if (begin.isSame(end, 'year')) {
    return {
      dateBegin: begin.format('MMM D'),
      dateEnd: end.format('MMM D, YYYY'),
      isSame: begin.isSame(end),
    };
  }
  return {
    dateBegin: begin.format('MMM D, YYYY'),
    dateEnd: end.format('MMM D, YYYY'),
    isSame: begin.isSame(end),
  };
}

/**
 * data adapter for src/common/PeriodGroup component: in
 */
export const formatDatePeriodFromDateRange = (dateRange = {}, format = '') => {
  const periodType = ['dateBegin', 'dateEnd', 'compareDateBegin', 'compareDateEnd'];
  const valuePos = ['0.0', '0.1', '1.0', '1.1'];

  const period = periodType.reduce(
    (prev, type, index) => {
      const pos = valuePos[index];
      let val = get(dateRange, periodType[index]);
      if (format) {
        val = moment(val).format(format);
      }
      if (val) {
        set(prev, pos, val);
      }
      return prev;
    },
    [[], []],
  );

  return period;
};

/**
 * data adapter for src/common/PeriodGroup component: out
 */
export const formatDatePeriodForSeraching = (value: any) => {
  const periodType = ['dateBegin', 'dateEnd', 'compareDateBegin', 'compareDateEnd'];
  const valuePos = ['0.0', '0.1', '1.0', '1.1'];
  const periodStr = periodType
    .reduce((prev: any, type, index) => {
      const val = get(value, valuePos[index]);
      if (val) {
        prev.push([type, encodeURIComponent(val)].join('='));
      }
      return prev;
    }, [])
    .join('&');

  return periodStr;
};

/**
 * @return {
 *   compareDateBegin: 2019-08-11,
 *   compareDateEnd: 2019-08-12,
 *   dateBegin: 2019-07-24,
 *   dateEnd: 2019-07-31
 * }
 */
interface IFormatForApiWithPeriodParams {
  [key: string]: any;
}
interface IformatForApiWithPeriodOptioms {
  dateFormat?: string;
}
export const formatForApiWithPeriod = <T = IFormatForApiWithPeriodParams>(
  params: Partial<T> = {},
  options: IformatForApiWithPeriodOptioms = {},
): T => {
  const rez = ['dateBegin', 'dateEnd', 'compareDateBegin', 'compareDateEnd'].reduce(
    (prev: any, key: string) => {
      if (key in params) {
        prev[key] = serializeDateForTimeZone(params[key], { ...options });
      }
      return prev;
    },
    {},
  );

  return rez;
};

export const prevDays = (startStr: any, duration: any) =>
  formatDateStr(moment(startStr).subtract(duration, 'days'));

export const defaultAnalyticsDateRange = () => ({
  dateBegin: moment().subtract(14, 'days').startOf('day').format('YYYY-MM-DD'),
  dateEnd: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
});

// TODO: add time zone types
export type TimeZoneList = string;
export const defaultTimeZone: TimeZoneList = 'America/New_York';

export const resetTimeZone = (timeZone = defaultTimeZone) => {
  moment.tz.setDefault(timeZone);
};

export const getCurrentTimeZone = () =>
  (getTQueryData('/users/me')?.agencies[0].timeZone || defaultTimeZone) as TimeZoneList;
