import * as types from './constants';

export function generatePdf(params: any, callback: any) {
  return {
    type: types.GENERATE_PDF,
    params,
    callback,
  };
}

export function startGetPdfServerStatus(params: any, callback: any) {
  return {
    type: types.START_GET_PDF_STATUS,
    params,
    callback,
  };
}

export function stopGetPdfServerStatus() {
  return {
    type: types.CANCEL_GET_PDF_STATUS,
  };
}

export function stopExportPdf() {
  return {
    type: types.STOP_EXPORT_PDF,
  };
}

export function downloadFile(params: any, callback?: any, timerId?: number) {
  return {
    type: types.DOWNLOAD_FILE,
    params,
    callback,
    timerId,
  };
}

export function downloadPdfFile(params: any) {
  return {
    type: types.DOWNLOAD_PDF_FILE,
    params,
  };
}

export function generateExportFile(params: any, callback: any, customApi: any) {
  return {
    type: types.GENERATE_EXPORT_FILE,
    params,
    callback,
    customApi,
  };
}

export function startGetExportFileStatus(params: any, callback: any) {
  return {
    type: types.START_GET_FILE_STATUS,
    params,
    callback,
  };
}

export function stopGetExportFileStatus() {
  return {
    type: types.CANCEL_GET_FILE_STATUS,
  };
}

export function stopExportFile() {
  return {
    type: types.STOP_EXPORT_FILE,
  };
}

export function getScheduledFutureExports(params: any) {
  return {
    type: types.GET_SCHEDULED_EXPOERS,
    params,
  };
}

export function setScheduledFutureExports(payload: any) {
  return {
    type: types.SET_SCHEDULED_EXPOERS,
    payload,
  };
}
