export const SCOPENAME = 'module:auth';

export const GET_AUTH_LOCAL = `${SCOPENAME}/GET_AUTH_LOCAL`;

export const GET_CHANNELS = `${SCOPENAME}/GET_CHANNELS`;
export const SET_CHANNELS = `${SCOPENAME}/SET_CHANNELS`;

export const GET_CURRENTUSER = `${SCOPENAME}/GET_CURRENTUSER`;
export const SET_CURRENTUSER = `${SCOPENAME}/SET_CURRENTUSER`;

export const GET_ENVINFO = `${SCOPENAME}/GET_ENVINFO`;
export const SET_ENVINFO = `${SCOPENAME}/SET_ENVINFO`;

export const GET_FULLPROFILE = `${SCOPENAME}/GET_FULLPROFILE`;
export const SET_FULLPROFILE = `${SCOPENAME}/SET_FULLPROFILE`;

export const GET_USERS_ME_MENUS = `${SCOPENAME}/GET_USERS_ME_MENUS`;
export const SET_USERS_ME_MENUS = `${SCOPENAME}/SET_USERS_ME_MENUS`;
export const GET_MENUS = `${SCOPENAME}/GET_MENUS`;

export const GET_APP_CONFIG = `${SCOPENAME}/GET_APP_CONFIG`;
export const SET_APP_CONFIG = `${SCOPENAME}/SET_APP_CONFIG`;

export const FORGOT_PASSWORD = `${SCOPENAME}/FORGOT_PASSWORD`;
export const RESET_PASSWORD = `${SCOPENAME}/RESET_PASSWORD`;
export const CREATE_USER = `${SCOPENAME}/CREATE_USER`;
export const CONFIRM_EMAIL = `${SCOPENAME}/CONFIRM_EMAIL`;

export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_TRIAL_INFO = `${SCOPENAME}/GET_TRIAL_INFO`;
export const SET_TRIAL_INFO = `${SCOPENAME}/SET_TRIAL_INFO`;
export const CONFIRM_TRIAL = `${SCOPENAME}/CONFIRM_TRIAL`;
