import * as types from './constants';

export function getOverview(params: any) {
  return {
    type: types.GET_OVERVIEW,
    params,
  };
}

export function setOverview(payload: any) {
  return {
    type: types.SET_OVERVIEW,
    payload,
  };
}

export function getCreatorInfo(params: any) {
  return {
    type: types.GET_CREATORINFO,
    params,
  };
}

export function setCreatorInfo(payload: any) {
  return {
    type: types.SET_CREATORINFO,
    payload,
  };
}

export function getNameList(params: any) {
  return {
    type: types.GET_NAMELIST,
    params,
  };
}

export function setNameList(payload: any) {
  return {
    type: types.SET_NAMELIST,
    payload,
  };
}

export function getOlapBenchmarks(params: any) {
  return {
    type: types.GET_OLAP_BENCHMARKS,
    params,
  };
}

export function setOlapBenchmarks(payload: any) {
  return {
    type: types.SET_OLAP_BENCHMARKS,
    payload,
  };
}

export function getMediaReport(params: any) {
  return {
    type: types.GET_MEDIAREPORT,
    params,
  };
}

export function setMediaReport(payload: any) {
  return {
    type: types.SET_MEDIAREPORT,
    payload,
  };
}

export function getCampaignsByCreatorId(params: any) {
  return {
    type: types.GET_CAMPAIGNSBYCREATORID,
    params,
  };
}

export function setCampaignsByCreatorId(payload: any) {
  return {
    type: types.SET_CAMPAIGNSBYCREATORID,
    payload,
  };
}

export function postCampaigns(params: any, storyLevel: boolean) {
  return {
    type: types.POST_CAMPAIGNS,
    params,
    storyLevel,
  };
}

export function addCampaignsTags(payload: any) {
  return {
    type: types.ADD_CAMPAIGNS_TAGS,
    payload,
  };
}

export function deleteCampaigns(params: any) {
  return {
    type: types.DELETE_CAMPAIGNS,
    params,
  };
}

export function searchTagsByPost(params: any, storyLevel) {
  return {
    type: types.SEARCH_ADMIN_TAGS_BY_POST,
    params,
    storyLevel,
  };
}

export function searchTags(params: any, callback: any) {
  return {
    type: types.SEARCH_TAGS,
    params,
    callback,
  };
}

export function setAdminSearchTags(payload: any) {
  return {
    type: types.SET_ADMIN_TAGS,
    payload,
  };
}

export function updateAgencyId(payload: any) {
  return {
    type: types.UPDATE_AGENCY_ID,
    payload,
  };
}

export function setSearchedTags(payload: any) {
  return {
    type: types.SET_SEARCHED_TAGS,
    payload,
  };
}

export function postTags(params: any) {
  return {
    type: types.POST_TAGS,
    params,
  };
}

export function deleteTags(params: any) {
  return {
    type: types.DELETE_TAGS,
    params,
  };
}

export function removeCampaignsAndTags(payload: any) {
  return {
    type: types.REMOVE_CAMPAIGNS_TAGS,
    payload,
  };
}

export function updateStoryTitle(params: any, callback: any) {
  return {
    type: types.UPDATE_STORY_TITLE,
    params,
    callback,
  };
}

export function setStoryTitle(payload: any) {
  return {
    type: types.SET_STORY_TITLE,
    payload,
  };
}

export function getMediaDetail(params: any) {
  return {
    type: types.GET_MEDIA_DETAIL,
    params,
  };
}

export function setMediaDetail(payload: any) {
  return {
    type: types.SET_MEDIA_DETAIL,
    payload,
  };
}

export function getFacebookVideoInsights(params: any) {
  return {
    type: types.GET_FACEBOOK_VIDEO_INSIGHTS,
    params,
  };
}

export function setFacebookVideoInsights(payload: any) {
  return {
    type: types.SET_FACEBOOK_VIDEO_INSIGHTS,
    payload,
  };
}

export function createVideoSegment(params: any, callback: any) {
  return {
    type: types.CREATE_VIDEO_SEGMENT,
    params,
    callback,
  };
}

export function deleteVideoSegment(params: any, callback: any) {
  return {
    type: types.DELETE_VIDEO_SEGMENT,
    params,
    callback,
  };
}

export function updateVideoSegment(params: any, callback: any) {
  return {
    type: types.UPDATE_VIDEO_SEGMENT,
    params,
    callback,
  };
}

export function setVideoSegment(payload: any) {
  return {
    type: types.SET_VIDEO_SEGMENT,
    payload,
  };
}

export function getStoryList(params: any) {
  return {
    type: types.GET_STORY_LIST,
    params,
  };
}

export function setStoryList(payload: any) {
  return {
    type: types.SET_STORY_LIST,
    payload,
  };
}

export function updateFrame(params: any, callback: any) {
  return {
    type: types.UPDATE_FRAME,
    params,
    callback,
  };
}

export function updateFrameBatch(params: any, callback: any) {
  return {
    type: types.UPDATE_FRAME_BATCH,
    params,
    callback,
  };
}

export function AddStory(params: any, successCallback: any, failedCallback: any) {
  return {
    type: types.ADD_STORY,
    params,
    successCallback,
    failedCallback,
  };
}

export function getCreatorChannelId(params: any) {
  return {
    type: types.GET_CREATOR_CHANNEL_ID,
    params,
  };
}

export function setCreatorChannelId(payload: any) {
  return {
    type: types.SET_CREATOR_CHANNEL_ID,
    payload,
  };
}

export function getSnapStory(params: any) {
  return {
    type: types.GET_SNAP_STORY,
    params,
  };
}

export function setSnapStory(payload: any) {
  return {
    type: types.SET_SNAP_STORY,
    payload,
  };
}

export function updateDeleingCampaignIds(payload: any) {
  return {
    type: types.UPDATE_DELETING_CAMPAIGN_IDS,
    payload,
  };
}

export function updateDeleingTagIds(payload: any) {
  return {
    type: types.UPDATE_DELETING_TAG_IDS,
    payload,
  };
}

export function getChannelMappings() {
  return {
    type: types.GET_CHANNEL_MAPPINGS,
  };
}

export function setChannelMappings(payload: any) {
  return {
    type: types.SET_CHANNEL_MAPPINGS,
    payload,
  };
}

export function getExportData(callback: any) {
  return {
    type: types.GET_EXPORT_DATA,
    callback,
  };
}

export function deleteStory(params: any, callback: any) {
  return {
    type: types.DELETE_STORY,
    params,
    callback,
  };
}

export function createContentTag(params: any, callback: any) {
  return {
    type: types.CREATE_CONTENT_TAG,
    params,
    callback,
  };
}

export function updateSnapFrames(payload: any) {
  return {
    type: types.UPDATE_SNAP_FRAME,
    payload,
  };
}

export function addAdminTags(payload: any) {
  return {
    type: types.ADD_ADMIN_TAGS,
    payload,
  };
}

export function updateAdminTag(payload: any) {
  return {
    type: types.UPDATE_ADMIN_TAG,
    payload,
  };
}

export function removeAdminTags(payload: any) {
  return {
    type: types.REMOVE_ADMIN_TAGS,
    payload,
  };
}

export function setAgencies(payload: any) {
  return {
    type: types.SET_AGENCIES,
    payload,
  };
}

export function getAgencies() {
  return {
    type: types.GET_AGENCIES,
  };
}
