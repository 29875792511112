import Cookies, { CookieSetOptions } from 'universal-cookie';

function getDomain() {
  const domain = window.location.hostname.split('.').slice(-2).join('.');
  return ['conviva.com', 'localhost'].includes(domain) ? domain : 'conviva.com';
}

class CustomCookies {
  domain = getDomain();

  cookies = new Cookies();

  get(key: string) {
    return this.cookies.get(key);
  }

  set(key: string, value: string, option?: CookieSetOptions) {
    this.cookies.set(key, value, { domain: this.domain, ...option });
  }

  remove(key: string, option?: CookieSetOptions) {
    this.cookies.remove(key, { domain: this.domain, path: '/', ...option });
  }
}

export default new CustomCookies();
