import * as types from './constants';

const initState = {
  channels: [],
  currentUser: {},
  menus: [],
  appConfig: {},
  envInfo: {} /* { flaskApi: {}, nodeApi: {} } */,
  trialInfo: {},
};

export default function reducers(state: any, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.SET_CHANNELS: {
      return { ...state, channels: action.payload };
    }
    case types.SET_CURRENTUSER: {
      return { ...state, currentUser: action.payload };
    }
    case types.SET_ENVINFO: {
      return { ...state, envInfo: { ...state.envInfo, ...(action.payload || {}) } };
    }
    case types.SET_FULLPROFILE: {
      const currentUser = { ...state.currentUser, ...action.payload };
      return { ...state, currentUser };
    }
    case types.SET_USERS_ME_MENUS: {
      return { ...state, menus: action.payload };
    }
    case types.SET_APP_CONFIG: {
      return { ...state, appConfig: action.payload };
    }
    case types.SET_TRIAL_INFO: {
      return { ...state, trialInfo: action.payload };
    }
    default:
      return state;
  }
}
