import { call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { sortBy } from 'lodash';
import { pushLocation } from '@/rootStore/router/actions';
import * as api from '@/service/Accounts';
import withLoading from '@/sagaMiddleware';
import * as actions from './actions';
import * as types from './constants';

export function* fetchCreators(action: any) {
  try {
    const res = yield call(withLoading, api.getCreators, action.type);
    // new api
    if (res) {
      yield put(actions.setCreators(res));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCreators() {
  yield takeLatest(types.GET_ACCOUNTS, fetchCreators);
}

export function* fetchGenres() {
  try {
    const res = yield call(api.getGenres);
    const sortedRes = sortBy(res, ['name']);
    yield put(actions.setGenres(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchGenres() {
  yield takeLatest(types.GET_GENRES, fetchGenres);
}

export function* fetchCountries({ params }: any) {
  try {
    const res = yield call(api.getCountries, params);
    const sortedRes = sortBy(res, ['isoCountryDescr']);
    yield put(actions.setCountries(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCountries() {
  yield takeLatest([types.GET_ACCOUNTS, types.GET_COUNTRIES], fetchCountries);
}

export function* fetchProfile(action: any) {
  try {
    const res = yield call(withLoading, api.getProfile, action.type, action.params);
    yield put(actions.setProfile(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchProfile() {
  yield takeLatest(types.GET_PROFILE, fetchProfile);
}

export function* fetchAccountInfo(action: any) {
  try {
    const res = yield call(withLoading, api.getAccountInfo, action.type, action.params);
    yield put(actions.setAccountInfo(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchAccountInfo() {
  yield takeLatest(types.GET_ACCOUNT_INFO, fetchAccountInfo);
}

export function* fetchGenders(action: any) {
  try {
    const res = yield call(withLoading, api.getGenders, action.type);
    yield put(actions.setGenders(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchGenders() {
  yield takeLatest(types.GET_GENDERS, fetchGenders);
}

export function* fetchTalentGroups() {
  try {
    const res = yield call(api.getTalentGroups);
    yield put(actions.setTalentGroups(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchTalentGroups() {
  yield takeLatest(types.GET_TALENTGROUPS, fetchTalentGroups);
}

export function* PostAccount({ type, params, autoAdd, rootPath, callback }: any) {
  try {
    const res = yield call(withLoading, api.postAccount, type, params);
    message.success(typeof params._id === 'undefined' ? 'Added Creator' : 'Saved Changes');
    if (callback) {
      yield call(callback);
    }
    if (params._id) {
      if (autoAdd) {
        yield put(pushLocation(`${rootPath}/add`));
      } else {
        yield put(actions.getAccountInfo(res._id));
      }
    } else if (!autoAdd) {
      yield put(pushLocation(`${rootPath}/edit/${res._id}`));
    }
  } catch (err: any) {
    if (callback) {
      yield call(callback);
    }
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchPostAccount() {
  yield takeLatest(types.POST_ACCOUNT, PostAccount);
}

export function* DeleteCreatorChannel(action: any) {
  try {
    const res = yield call(withLoading, api.deleteCreatorChannel, action.type, action.payload);
    if (res.deleted) {
      message.success('Delete successful');
      if (action.callback) {
        yield call(action.callback);
      }
    }
  } catch (err: any) {
    yield call(action.callback);
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchDeleteCreatorChannel() {
  yield takeLatest(types.DELETE_CREATOR_CHANNEL, DeleteCreatorChannel);
}

export function* fetchAllCreators(action: any) {
  try {
    const res = yield call(withLoading, api.getAllCreators, action.type, action.params);
    yield put(actions.setAllCreators(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAllCreators() {
  yield takeLatest(types.GET_ALL_CREATORS, fetchAllCreators);
}

export function* postTransferChannels({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.transferChannels, type, params);
    message.success('Transfer successful');
    if (callback) {
      yield call(callback, [res]);
    }
  } catch (err: any) {
    if (callback) {
      yield call(callback, [err]);
    }
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchPostTransferChannels() {
  yield takeLatest(types.POST_TRANSFER_CHANNELS, postTransferChannels);
}

export default [
  watchFetchProfile,
  watchFetchCreators,
  watchFetchGenres,
  watchFetchCountries,
  watchFetchAccountInfo,
  watchFetchGenders,
  watchFetchTalentGroups,
  watchPostAccount,
  watchDeleteCreatorChannel,
  watchFetchAllCreators,
  watchPostTransferChannels,
];
