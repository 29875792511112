import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Result } from 'antd';
import { can } from '@/utils/helper';

export interface AuthorizedRouteProps extends RouteProps {
  auth: string;
  type?: 'or' | 'and';
}

const AuthorizedRoute = (props: AuthorizedRouteProps) => {
  const { auth, type = 'or', ...rest } = props;
  const authed = !auth || can(auth, type);

  if (!authed) {
    return (
      <Result
        status="error"
        title="Unauthorized"
        subTitle={<span style={{ fontSize: 16 }}>You are not authorized to view that page</span>}
      />
    );
  }

  return <Route {...rest} />;
};

export default React.memo(AuthorizedRoute);
