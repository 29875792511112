import React from 'react';
import { useDispatch } from 'react-redux';
import { ConfigProvider } from 'antd';
import { getChannels, getAppConfig } from '~/Auth/store/actions';
import Header from './Header';
import Footer from './Footer';
import SideMenu from './SideMenu/adapter';
import { addFontAwesome } from './helper';
import styles from './style.module.less';

const LegacyLayout = ({
  children,
  container,
}: {
  children?: React.ReactNode;
  container: HTMLElement;
}) => {
  const [collapse, setCollapse] = React.useState(false);
  const moduleRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  // fetch the necessary public data, they are used by multiple pages
  React.useEffect(() => {
    dispatch(getChannels());
    dispatch(getAppConfig());
  }, [dispatch]);

  React.useEffect(() => {
    addFontAwesome(container);
  }, [container]);

  const handleToggleCollapse = React.useCallback(() => {
    setCollapse((v) => !v);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }, []);

  return (
    <div className={styles.container}>
      <Header collapse={collapse} onCollapseToggle={handleToggleCollapse} />
      <div className={styles.main}>
        <SideMenu collapse={collapse} />
        <div className={styles.content}>
          <ConfigProvider getPopupContainer={() => moduleRef.current!}>
            <div ref={moduleRef} className={styles.module}>
              {children}
            </div>
          </ConfigProvider>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default React.memo(LegacyLayout);
