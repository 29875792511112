import { call, takeLatest, take, race, put, delay, fork } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { message } from 'antd';
import * as api from '@/service/Export';
import withLoading from '@/sagaMiddleware';
import * as types from './constants';
import * as actions from './actions';

// linbo pdf server
function* stopFetchPdfStatus(success = true) {
  yield put({ type: `PDF_SERVER_${success ? 'SUCCESS' : 'FAILURE'}` });
  yield put(actions.stopGetPdfServerStatus());
}

function* watchStopExportPdf() {
  yield takeLatest(types.STOP_EXPORT_PDF, stopFetchPdfStatus);
}

function* getPdfServerStatus(params: any, callback: any) {
  while (true) {
    try {
      yield delay(2000);
      const res = yield call(api.getPdfStatus, params);
      if (res.export === 'Success') {
        yield call(callback, params.reportGuid);
        yield call(stopFetchPdfStatus);
      } else if (res.export === 'Fail') {
        yield fork(message.error, res.message || 'Generate pdf failed');
        yield call(stopFetchPdfStatus, false);
      }
    } catch (err: any) {
      yield fork(message.error, err.message || 'Generate pdf failed');
      yield call(stopFetchPdfStatus, false);
    }
  }
}

function* watchPdfServerStatus() {
  while (true) {
    const { params, callback } = yield take(types.START_GET_PDF_STATUS);
    yield race({
      task: call(getPdfServerStatus, params, callback),
      cancel: take(types.CANCEL_GET_PDF_STATUS),
    });
  }
}

export function* generatePdf({ params, callback }: any) {
  try {
    yield put({ type: 'PDF_SERVER_REQUEST' });
    const res = yield call(api.generatePdf, params);
    yield put(actions.startGetPdfServerStatus(res, callback));
  } catch (err: any) {
    yield put({ type: 'PDF_SERVER_FAILURE' });
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchGeneratePdf() {
  yield takeLatest(types.GENERATE_PDF, generatePdf);
}

export function* downloadPdfFile({ type, params }: any) {
  try {
    const blob = yield call(withLoading, api.downloadPdf, type, params);
    yield call(saveAs, blob, params.filename);
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchDownloadPdfFile() {
  yield takeLatest(types.DOWNLOAD_PDF_FILE, downloadPdfFile);
}

/**
 * Delmondo excel(pdf) service
 +----------------------+
 |                      |
 |  generateExportFile  |
 |                      |
 +----------+-----------+
            |
            |
 +----------v--------------+
 |                         |
 |  watchExportFileStatus  +--------+ Listen +-+
 |                         |                   |
 +-----------+-------------+                   |
             |                                 |
             |                      +----------v-------------+
 +-----------v------------+         |  stopExportFileStatus  |
 |                        |         +----------+-------------+
 |  getExportFileStatus   <------+             |
 |                        |      |             |
 +-----------+------------+      |             |
             |                   |             |
             |                   |             |
             |                   |             |
             +------While--------+             v
                                            Stop loop

 */
function* stopExportFileStatus(success: boolean, timerId?: number) {
  if (timerId) {
    clearTimeout(timerId);
  }
  yield put({ type: `EXPORT_FILE_${success ? 'SUCCESS' : 'FAILURE'}` });
  yield put(actions.stopGetExportFileStatus());
}

function* watchStopExportFileStatus() {
  yield takeLatest(types.STOP_EXPORT_FILE, stopExportFileStatus);
}

function* getExportFileStatus(params: any, callback: any) {
  const maxCount = 120;
  let count = 0;
  while (true) {
    try {
      yield delay(2000);
      if (count < maxCount) {
        const res = yield call(api.getExportFileStatus, params);
        if (res.err) {
          yield fork(message.error, 'There was a problem polling for the download.');
          yield call(stopExportFileStatus, false, params.timerId);
        } else if (params.actionType !== 'email' && res.created && !res.deleted) {
          yield call(callback, res);
          yield call(stopExportFileStatus, true, params.timerId);
        } else if (params.actionType === 'email' && count === 6) {
          // email pdf walkaroud for SOI-989 and SOI-988
          yield call(callback, res);
          yield call(stopExportFileStatus, true, params.timerId);
        }
      } else {
        yield fork(message.error, 'Maximum polling iterations reached.');
        yield call(stopExportFileStatus, false, params.timerId);
      }
      count += 1;
    } catch (err: any) {
      yield fork(message.error, 'There was a problem polling for the download.');
      yield call(stopExportFileStatus, false, params.timerId);
    }
  }
}

function* watchExportFileStatus() {
  while (true) {
    const { params, callback } = yield take(types.START_GET_FILE_STATUS);
    yield race({
      task: call(getExportFileStatus, params, callback),
      cancel: take(types.CANCEL_GET_FILE_STATUS),
    });
  }
}

export function* generateExportFile({ params, callback, customApi }: any) {
  try {
    const { data, timerId } = params;
    yield call(stopExportFileStatus, false);
    yield put({ type: 'EXPORT_FILE_REQUEST' });
    const res = yield call(customApi || api.generateFile, data);
    if (res.error) {
      yield call(message.error, 'There was an error processing your request.  Please try again.');
      yield put({ type: 'EXPORT_FILE_FAILURE' });
    } else {
      const transRes = { ...res };
      if (!res.duid) {
        transRes.duid = data.duid;
      }
      if (data && data.action && data.action.type === 'download') {
        transRes.actionType = (data && data.action && data.action.type) || 'download';
        transRes.fileType = (data && data.file && data.file.fileType) || 'excel';
        transRes.timerId = timerId;
        yield put(actions.startGetExportFileStatus(transRes, callback));
      } else {
        yield call(stopExportFileStatus, true, timerId);
        yield call(callback);
      }
    }
  } catch (err: any) {
    yield put({ type: 'EXPORT_FILE_FAILURE' });
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchGenerateExportFile() {
  yield takeLatest(types.GENERATE_EXPORT_FILE, generateExportFile);
}
// --- end delmondo pdf server -----

export function* downloadFile({ type, params, callback, timerId }: any) {
  try {
    const blob = yield call(withLoading, api.downLoadFile, type, params);
    yield call(saveAs, blob, params.fileName);
    if (callback) {
      yield call(callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  } finally {
    if (timerId) {
      clearTimeout(timerId);
    }
  }
}
function* watchDownloadFile() {
  yield takeLatest(types.DOWNLOAD_FILE, downloadFile);
}

export function* fetchScheduledFutureExports({ params }: any) {
  try {
    const res = yield call(api.getScheduledFutureExport, params);
    yield put(actions.setScheduledFutureExports(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchScheduledFutureExports() {
  yield takeLatest(types.GET_SCHEDULED_EXPOERS, fetchScheduledFutureExports);
}

export default [
  watchGeneratePdf,
  watchPdfServerStatus,
  watchStopExportPdf,
  watchGenerateExportFile,
  watchExportFileStatus,
  watchStopExportFileStatus,
  watchDownloadFile,
  watchFetchScheduledFutureExports,
  watchDownloadPdfFile,
];
