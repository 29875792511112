import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '@/modules/Auth/store/actions';
import SuspenseFallback from '@/common/SuspenseFallback';
import { inSingleApp } from '@/utils/helper';
import { isOkta } from '@/utils/authToken';
import { UserRole } from '@/query/types/user';
import { useAuthVerify, clearAuthToken } from './auth';
import { init } from './init';

interface PrivateRouterProps {
  children: (userInfo: QueryKeyResponse['/users/me']) => React.ReactElement;
}

const GlobalGuard = ({ children }: PrivateRouterProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const verifiedInfo = useAuthVerify();

  if (verifiedInfo === 'fetching') {
    return <SuspenseFallback />;
  }

  if (!verifiedInfo) {
    clearAuthToken();

    if (inSingleApp()) {
      const fullPath = window.location.href.replace(window.location.origin, '');
      window.location.href = `/login?next=${fullPath}`;

      return null;
    }

    return <Redirect to="/logout" />;
  }

  // User verification passed
  init(verifiedInfo);

  dispatch(setCurrentUser({ ...verifiedInfo, okta: isOkta() }));

  // trial user first login will redirect to settings page
  if (
    verifiedInfo.role === UserRole.trial &&
    localStorage.getItem('conviva_si_trial_first') !== '1'
  ) {
    localStorage.setItem('conviva_si_trial_first', '1');

    history.replace('/settings');
  }

  return children(verifiedInfo);
};

export default React.memo(GlobalGuard);
