import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import logoNavbar from '@/assets/images/logo-navbar.png';
import logoSingle from '@/assets/images/logo-single.png';
import DebugInfo from '@/common/Log/DebugInfo';
import Profile from './Profile';
import styles from './index.module.less';

export interface LayoutHeaderProps extends RouteComponentProps {
  /** collapse the header */
  collapse?: boolean;
  /** function to trigger collapse event */
  onCollapseToggle?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = (props) => {
  const { collapse = false, onCollapseToggle } = props;

  return (
    <div className={styles.topHeader}>
      <div className={classnames(styles.logoContainer, { [styles.collapse]: collapse })}>
        <img className={styles.logo} src={collapse ? logoSingle : logoNavbar} alt="conviva" />
        <span className={styles.brand}>Social Insights</span>
      </div>
      {collapse ? (
        <MenuUnfoldOutlined className={styles.collapseBtn} onClick={onCollapseToggle} />
      ) : (
        <MenuFoldOutlined className={styles.collapseBtn} onClick={onCollapseToggle} />
      )}
      <Profile className={styles.pf} />
      <DebugInfo />
    </div>
  );
};

export default withRouter(React.memo(LayoutHeader));
