import { createSelector } from 'reselect';
import * as types from './constants';

const selector = (state: any) => state[types.SCOPENAME];

export const talentGroupsSelector = createSelector(selector, (state) => state.talentGroups);

export const genresSelector = createSelector(selector, (state) => state.genres);

export const averageCreatorSelector = createSelector(selector, (state) => state.averageCreator);

export const filtersSelector = createSelector(selector, (state) => state.filters);

export const filterCreatorsSelector = createSelector(selector, (state) => state.filterCreators);

export const lastUpdatedSelector = createSelector(selector, (state) => state.lastUpdated);

export const tabSelector = createSelector(selector, (state) => state.tab);

export const generalSortSelector = createSelector(selector, (state) => state.sorter);

export const postSortSelector = createSelector(selector, (state) => state.postSorter);
