import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import charts from '@/charts/store';
import leaderboard from '~/Leaderboard/store';
import accounts from '~/Accounts/store';
import analytics from '~/Analytics/store';
import campaigns from '~/Campaigns/store';
import auth from '~/Auth/store';
import settings from '~/Settings/store';
import admin from '~/Admin/store';
import statics from '~/Statics/store';
import download from '~/Download/store';
import dataExport from '~/Export/store';
import topContent from '~/TopContent/store';
import { USER_LOGOUT } from '~/Auth/store/constants';
import loadingReducer from './loading/reducer';

const reducers = {
  [leaderboard.SCOPENAME]: leaderboard.reducer,
  [accounts.SCOPENAME]: accounts.reducer,
  [analytics.SCOPENAME]: analytics.reducer,
  [campaigns.SCOPENAME]: campaigns.reducer,
  [auth.SCOPENAME]: auth.reducer,
  [settings.SCOPENAME]: settings.reducer,
  [admin.SCOPENAME]: admin.reducer,
  [charts.SCOPENAME]: charts.reducer,
  [statics.SCOPENAME]: statics.reducer,
  [dataExport.SCOPENAME]: dataExport.reducer,
  [download.SCOPENAME]: download.reducer,
  [topContent.SCOPENAME]: topContent.reducer,
  loading: loadingReducer,
};

export default (history: any) => (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }
  return combineReducers({
    router: connectRouter(history),
    ...reducers,
  })(state, action);
};
