import { call, put, takeLatest, select } from 'redux-saga/effects';
import { message, notification } from 'antd';
import pick from 'lodash/pick';
import get from 'lodash/get';
import withLoading from '@/sagaMiddleware';
import * as api from '@/service/Settings';
import { fetchFullProfile } from '~/Auth/store/sagas';
import { currentUserSelector } from '~/Auth/store/selectors';
import { generateExportFile } from '~/Export/store/actions';
import { getFullProfile, setFullProfile } from '~/Auth/store/actions';
import * as actions from './actions';
import * as types from './constants';

export function* fetchScheduledExport({ type, params }: any) {
  try {
    let userId = {};
    if (!params.userId && params.userId !== 0) {
      const profile = yield call(fetchFullProfile);
      userId = { userId: profile._id };
    }
    const res = yield call(withLoading, api.getScheduledExport, type, { ...params, ...userId });
    const payload = { key: `${params.type}Exports`, value: res };
    yield put(actions.setScheduledExport(payload));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchScheduledExport() {
  yield takeLatest(types.GET_SCHEDULEDEXPORT, fetchScheduledExport);
}

export function* fetchDownloads({ type, params }: any) {
  try {
    let userId = {};
    if (!params.userId && params.userId !== 0) {
      const profile = yield call(fetchFullProfile);
      userId = { userId: profile._id };
    }
    const res = yield call(withLoading, api.getDownloadByUser, type, { ...params, ...userId });
    yield put(actions.setDownloadByUser(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchDownloads() {
  yield takeLatest(types.GET_DOWNLOADS, fetchDownloads);
}

export function* updateProfile({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.updateProfile, type, params);
    yield put(setFullProfile(pick(res, ['name', 'firstName', 'lastName'])));
    yield call(message.success, 'Profile updated successfully');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchUpdateProfile() {
  yield takeLatest(types.UPDATE_PROFILE, updateProfile);
}

export function* changePassword({ type, params, callback }: any) {
  let success = true;
  try {
    yield call(withLoading, api.changePassword, type, params);
    yield call(message.success, 'Password successfully changed');
  } catch (err: any) {
    let errMessage;
    success = false;
    switch (err.code) {
      case 403: {
        errMessage = 'Incorrect password';
        break;
      }
      case 406: {
        errMessage = 'You may not use one of your last 4 passwords';
        break;
      }
      default: {
        errMessage = err.message || 'unknown error';
      }
    }
    yield call(message.error, errMessage);
  }
  if (callback) {
    yield call(callback, { success });
  }
}
function* watchChangePassword() {
  yield takeLatest(types.CHANGE_PASSWORD, changePassword);
}

export function* regenerateFile({ params, callback }: any) {
  const trans = { duid: params.fileUuid };
  yield put(generateExportFile({ data: trans }, callback, api.regenerateFile));
}
function* watchRegenerateFile() {
  yield takeLatest(types.REGENERATE, regenerateFile);
}

export function* deleteDownload({ type, params, callback }: any) {
  try {
    yield call(withLoading, api.deleteDownload, type, params);
    yield put(actions.removeDownload(params));
    if (callback) {
      yield call(callback);
    }
    yield call(message.success, 'The download has been deleted.');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchDeleteDownload() {
  yield takeLatest(types.DELETE_DONWLOAD, deleteDownload);
}

export function* unlinkSocial({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.unLinkSocialNetwork, type, params);
    yield call(notification.success, {
      message: 'Unlink success',
      description: res.message,
    });
    yield put(getFullProfile());
  } catch (err: any) {
    yield call(message.error, err.message || 'Problem unlinking account');
  }
}
function* watchUnlinkSocial() {
  yield takeLatest(types.UNLINK_SOCIAL, unlinkSocial);
}

export function* updateCredentials({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.updateCredentials, type, params);
    if (res.errors) {
      yield call(message.error, 'Failed to save Snapchat information');
    } else {
      const currentUser = yield select(currentUserSelector);
      const credentials = get(currentUser, 'credentials', []);
      yield put(
        setFullProfile({
          credentials: [
            { ...credentials[0], ...params, ...res, password: '' },
            ...credentials.slice(1),
          ],
        }),
      );
      yield call(message.success, 'Successfully saved Snapchat information');
    }
  } catch (err: any) {
    yield call(message.error, err.message || 'Failed to save Snapchat information');
  }
}
function* watchUpdateCredentials() {
  yield takeLatest(types.UPDATE_CREDENTIALS, updateCredentials);
}

export default [
  watchFetchScheduledExport,
  watchFetchDownloads,
  watchUpdateProfile,
  watchChangePassword,
  watchRegenerateFile,
  watchDeleteDownload,
  watchUnlinkSocial,
  watchUpdateCredentials,
];
