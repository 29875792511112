import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import React, { PureComponent } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { envInfoSelector, currentUserSelector } from '~/Auth/store/selectors';
import * as authActions from '~/Auth/store/actions';
import styles from './index.module.less';

interface DebugInfoProps {
  envInfo: any;
  currentUser: any;
  getEnvInfo: (data?: any) => any;
}

interface DebugInfoState {
  debugInfo: any;
}

const getDebugInfo = () => {
  const showDebug = localStorage.getItem('DEBUG_INFO_SWITCH');
  const data = sessionStorage.getItem('DEBUG_INFO_FROM_OPS');

  if (showDebug === '0' || !showDebug) return false;
  if (isEmpty(data)) return false;

  try {
    return JSON.parse(data!);
  } catch (error) {
    return false;
  }
};

function EmptyEnv({ envName }: any) {
  return (
    <tl>
      <tt>{envName}</tt>
      <td>^_^</td>
    </tl>
  );
}

class DebugInfo extends PureComponent<DebugInfoProps, DebugInfoState> {
  constructor(props: DebugInfoProps) {
    super(props);

    this.state = {
      debugInfo: null,
    };
  }

  componentDidMount() {
    const debugInfo = getDebugInfo();
    if (debugInfo) {
      this.setState({ debugInfo });
    }

    this.getEnvInfo();
  }

  getEnvInfo() {
    const { getEnvInfo /* api debug info */ } = this.props;
    getEnvInfo();
  }

  parseInfo() {
    const { debugInfo } = this.state;
    const { envInfo, currentUser } = this.props;

    let jiraLink;
    const branchName = get(debugInfo, 'branch');
    if (branchName) {
      const jiraInfo = /(SOI-[\d]+)/.exec(branchName);
      if (jiraInfo) {
        jiraLink = `https://jira.corp.conviva.com/browse/${jiraInfo[1]}`;
      }
    }

    const frontendInfo = debugInfo
      ? {
          branch: get(debugInfo, 'branch'),
          hash: get(debugInfo, 'hash'),
          date: get(debugInfo, 'date'),
          tag: get(debugInfo, 'tag'),
          jira: jiraLink,
        }
      : null;

    const flaskInfo = get(envInfo, 'flaskApi')
      ? {
          branch: get(envInfo, 'flaskApi.DEVOPPS_GIT_BRANCH'),
          hash: get(envInfo, 'flaskApi.DEVOPPS_GIT_HASH'),
          date: undefined, // DevOps
          tag: undefined, // DevOps
          jira: undefined, // DevOps
        }
      : null;

    const nodeInfo = get(envInfo, 'nodeApi')
      ? {
          branch: get(envInfo, 'nodeApi.DEVOPPS_GIT_BRANCH'),
          hash: get(envInfo, 'nodeApi.DEVOPPS_GIT_HASH'),
          date: undefined, // DevOps
          tag: undefined, // DevOps
          jira: undefined, // DevOps
        }
      : null;

    const currentAccount = currentUser.email;

    return { frontendInfo, flaskInfo, nodeInfo, currentAccount };
  }

  render() {
    const { debugInfo } = this.state;
    if (!debugInfo) return null;

    const allInfo = this.parseInfo();

    return (
      <div className={styles.debugInfo}>
        {['frontendInfo', 'flaskInfo', 'nodeInfo'].map((typeName) => {
          const type = /(\w+)Info/.exec(typeName)?.[1];

          if (!allInfo[typeName]) {
            return <EmptyEnv envName={type} />;
          }

          return (
            <tl key={typeName}>
              <tt>{type}</tt>
              {['branch', 'hash', 'jira', 'date', 'tag'].map((field) => {
                if (field === 'jira') {
                  return (
                    get(allInfo, `${typeName}.${field}`) && (
                      <td>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                          href={get(allInfo, `${typeName}.${field}`)}
                          target="_blank"
                          style={{ fontWeight: 'bold' }}
                        >
                          Jira ticket
                        </a>
                      </td>
                    )
                  );
                }
                return (
                  get(allInfo, `${typeName}.${field}`) && (
                    <td>{get(allInfo, `${typeName}.${field}`)}</td>
                  )
                );
              })}
            </tl>
          );
        })}
        <div className={styles.currentAccount}>{get(allInfo, 'currentAccount')}</div>
      </div>
    );
  }
}

const selector = createSelector(envInfoSelector, currentUserSelector, (envInfo, currentUser) => ({
  envInfo,
  currentUser,
}));

export default connect(selector, {
  getEnvInfo: authActions.getEnvInfo,
})(DebugInfo);
