import { push, goBack } from 'connected-react-router';
import { put, takeEvery } from 'redux-saga/effects';

export function* pushLocation({ params }: any) {
  yield put(push(params.path));
}

function* watchPushLocation() {
  yield takeEvery('PUSH_LOCATION', pushLocation);
}

export function* goBackLocation() {
  yield put(goBack());
}

function* watchGoBack() {
  yield takeEvery('GO_BACK', goBackLocation);
}

export default [watchPushLocation, watchGoBack];
