import { createSelector } from 'reselect';
import * as types from './constants';

const selector = (state: any) => state[types.SCOPENAME];

const routerSelector = (state: any) => state.router;

export const channelsSelector = createSelector(selector, (state) => state.channels);

export const currentUserSelector = createSelector(selector, (state) => state.currentUser);

export const menusSelector = createSelector(selector, (state) => state.menus);

export const locationSelector = createSelector(routerSelector, (state) => state.location);

export const appConfigSelector = createSelector(selector, (state) => state.appConfig);

export const envInfoSelector = createSelector(selector, (state) => state.envInfo);

export const trialInfoSelector = createSelector(selector, (state) => state.trialInfo);
