import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Tooltip, Popover } from 'antd';
import { CommentOutlined, QuestionCircleFilled, UserOutlined } from '@ant-design/icons';
import BaseRouteLink from '@/common/BaseRouteLink';
import config from '@/config/SSO';
import { isOkta } from '@/utils/authToken';
import Visible from '@/common/Visible';
import * as helpMapping from './lerning-center-map';
import styles from './index.module.less';

export interface ProfileProps {
  feedbackId?: string;
  className?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

const Profile: React.FC<ProfileProps> = (props) => {
  const { feedbackId, className, prefix, suffix } = props;
  const okta = isOkta();

  const matched = Object.keys(helpMapping.PATH_2_PAGE_NAME).find((it) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRouteMatch({ path: it }),
  ) as keyof typeof helpMapping.PATH_2_PAGE_NAME | undefined;

  const quesMenu = (
    <div className={styles.li}>
      {matched && (
        <div>
          <a href={helpMapping.PATH_2_LINK[matched]} target="_blank" rel="noopener noreferrer">
            {helpMapping.PATH_2_PAGE_NAME[matched]} Help
          </a>
        </div>
      )}
      <div>
        <a href={helpMapping.HELP} target="_blank" rel="noopener noreferrer">
          Social Insights Help
        </a>
      </div>
      <div>
        <a href="https://pulse.conviva.com/legal/#terms" rel="noopener noreferrer" target="_blank">
          Legal
        </a>
      </div>
      <div>
        <BaseRouteLink to="/support">Contact Support</BaseRouteLink>
      </div>
    </div>
  );

  const profileMenu = (
    <div className={styles.li}>
      <Visible validate={okta}>
        <div>
          <a
            href={`${config.pulseUrl}/settings/personal_info`}
            rel="noopener noreferrer"
            target="_blank"
          >
            My Profile
          </a>
        </div>
      </Visible>
      <div>
        <BaseRouteLink to="/logout">Log Out</BaseRouteLink>
      </div>
    </div>
  );

  return (
    <div className={className}>
      {prefix}
      <div className={styles.profileContent}>
        {feedbackId && (
          <Tooltip title="User feedback" color="#fff" overlayClassName={styles.feedbackTooltip}>
            <CommentOutlined id={feedbackId} className={styles.ques} />
          </Tooltip>
        )}
        <Popover content={quesMenu} trigger="click" placement="bottom">
          <QuestionCircleFilled twoToneColor="#ccc" className={styles.ques} />
        </Popover>
        <Popover content={profileMenu} trigger="click" placement="bottom">
          <UserOutlined className={styles.ques} />
        </Popover>
      </div>
      {suffix}
    </div>
  );
};

export default React.memo(Profile);
