export const SCOPENAME = 'module:leaderboard';

export const GET_CREATORS = `${SCOPENAME}/GET_CREATORS`;
export const SET_CREATORS = `${SCOPENAME}/SET_CREATORS`;

export const GET_ALL_CREATORS = `${SCOPENAME}/GET_ALL_CREATORS`;
export const SET_ALL_CREATORS = `${SCOPENAME}/SET_ALL_CREATORS`;

export const UPDATE_FILTER = `${SCOPENAME}/UPDATE_FILTER`;
export const RESET_FILTER = `${SCOPENAME}/RESET_FILTER`;

export const POST_LIST = `${SCOPENAME}/POST_LIST`;
export const GET_LEADERBOARD_COUNTS = `${SCOPENAME}/GET_LEADERBOARD_COUNTS`;
export const SET_LEADERBOARD_COUNTS = `${SCOPENAME}/SET_LEADERBOARD_COUNTS`;

export const POST_LIST_SAVED = `${SCOPENAME}/POST_LIST_SAVED`;

export const SET_TALENTGROUPS = `${SCOPENAME}/SET_TALENTGROUPS`;
export const GET_TALENTGROUPS = `${SCOPENAME}/GET_TALENTGROUPS`;

export const UPDATE_SORTER = `${SCOPENAME}/UPDATE_SORTER`;
export const UPDATE_POSTS_SORTER = `${SCOPENAME}/UPDATE_POSTS_SORTER`;
export const UPDATE_GENERAL_SORTER = `${SCOPENAME}/UPDATE_GENERAL_SORTER`;

export const RESET_LEADERBOARD = `${SCOPENAME}/RESET_LEADERBOARD`;

export const SWITCH_TAB = `${SCOPENAME}/SWITCH_TAB`;
export const SET_TAB = `${SCOPENAME}/SET_TAB`;

export const UPDATE_FILTER_NAME = `${SCOPENAME}/UPDATE_FILTER_NAME`;
