import client from '../Client';

export const generatePdf = (params: any) => client.post('pdf/generate', params);

export const getPdfStatus = (params: any) =>
  client.get(`pdf/status/${params.reportGuid}?enablePdf=true`);

export const downloadPdf = (params: any) =>
  client.get(`pdf/download/${params.reportGuid}?enablePdf=true`, null, { responseType: 'blob' });

export const downLoadFile = (params: any) =>
  client.post('download/fetch', params, { responseType: 'blob' });

export const getScheduledFutureExport = (params: any) =>
  client.get(`scheduledExport/byExport/${params.id}`, { section: params.type });

export const generateFile = (params: any) => client.post('download', params);

export const getExportFileStatus = (params: any) => client.get(`download/${params.duid}`);

export const generateExportMetric = (type: string) => client.get(`static/exportMetrics/${type}`);
