import get from 'lodash/get';
import { can } from '@/utils/helper';
import { apiEnums } from '@/utils/constant';

export const adPlatforms = ['instagramad', 'facebookad'];

export const codeToRouter = {
  O: 'overview',
  FB: 'facebook',
  FBAD: 'facebookad',
  FLIVE: 'fblive',
  INST: 'instagram',
  IGAD: 'instagramad',
  IGS: 'instagramstories',
  SNAP: 'snapchat',
  SNAPD: 'snapchatDiscover',
  TIKT: 'tiktok',
  TWT: 'twitter',
  TWTV: 'twitterVideo',
  YTB: 'youtube',
  TCH: 'twitch',
};

export const pdfCustomize = {
  impressionsOrganic: 'io',
  impressionsPaid: 'ip',
  videoViews10s: 'vv1',
  videoViews30s: 'vv3',
  videoViewsComplete: 'vvc',
  videoViewsOrganic: 'vvo',
  videoViewsPaid: 'vvp',
  costPerEngagement: 'cpe',
  costPerVideoView: 'cpvv',
  viewsPerVideo: 'vpv',
};

type MediaMap = {
  [key: string]: any;
};

export const mediaReportsAvailable: MediaMap = {
  overview: {
    title: 'Overview',
    icon: 'fa-globe',
    showAddPost: true,
    channel: 'ALL',
  },
  facebook: {
    code: 'FB',
    title: 'Facebook',
    available: 'hasFacebook',
    analyticAuth: 'analytics.view.facebook',
    campaignAuth: 'campaign.analytics.view.facebook',
    campaignPostAuth: 'campaign.analytics.view.facebook.posts',

    channel: 'Facebook',

    totalPost: {
      field: 'metrics',
      metrics: [
        'videoViews3s',
        'videoViews30s',
        'engagements',
        'shares',
        'linkClicks',
        'impressions',
        'impressionsOrganic',
        'impressionsPaid',
      ],
      extra: {
        field: 'totalVideoLength',
        mapped: 'video.length',
      },
    },

    topContent: (isCampaigns = false) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'topContent' : 'stats.topContent',

    icon: 'fa-facebook color-facebook',

    tabs: ['All', 'Videos', 'Live', 'Posts'],
    detailField: 'posts',
    searchField: 'text',

    typeFilters: {
      options: [
        { value: 'all', text: 'All posts' },
        { value: 'sponsored', text: 'Branded content only' },
      ],
      filter: (value: any, item: any) =>
        value === 'sponsored' ? item.isSponsoredContent === 1 : true,
    },
    viewTypes: {
      published: {
        title: 'View by Publish Date',
        tooltip: 'Metrics from Published Date',
        export: true,
      },
      all: {
        title: 'Page by All Dates',
        tooltip: 'Page Insights within Time Frame',
        export: true,
      },
    },

    campaignType: 'Posts',

    removes: () => can('campaign.remove_post'),
    campaigns: () => can('facebook.assign_to_campaign'),
    tags: () => can('facebook.assign_content_tags'),
    createStory: false,
  },
  facebookad: {
    code: 'FBAD',
    title: 'Facebook Ads',
    available: 'hasFacebookAd',
    analyticAuth: 'analytics.view.facebookAd',
    campaignAuth: 'campaign.analytics.view.facebookAd',
    campaignPostAuth: 'campaign.analytics.view.facebookAd.posts',

    channel: 'Facebook Ad Posts',
    controller: 'adsByCreator',

    totalPost: {
      field: 'metrics',
      metrics: ['impressionsAds', 'videoViewsTotalAds', 'engagementAds', 'spend'],
    },

    api: 'facebook',

    icon: 'fa-facebook color-facebook',

    tabs: ['All', 'Videos', 'Posts'],
    detailField: 'fbAds',
    searchField: ['post.text', 'name'],

    removes: () => true,
    campaigns: () => true,
    tags: () => true,
    // removes: () => can('campaign.remove_post'),
    // campaigns: () => can('facebook.assign_to_campaign'),
    // tags: () => can('facebook.assign_content_tags'),
    createStory: false,
  },
  fblive: {
    title: 'Facebook Live',
    icon: 'fa-facebook color-facebook',
    available: 'hasFbLive',
    analyticAuth: false,
    campaignAuth: 'campaign.analytics.view.fblive',

    channel: 'Facebook Live',

    code: 'FB',

    totalPost: {
      field: 'metrics',
      metrics: [
        'videoViews3s',
        'engagements',
        'liveViews',
        'linkClicks',
        'ama',
        'impressions',
        'impressionsOrganic',
        'impressionsPaid',
      ],
    },

    topContent: () => (apiEnums.campaigns ? 'topContent' : 'stats.topContent'),

    detailField: 'posts',
    searchField: 'text',
    detailTitle: 'Facebook Posts',

    removes: () => can('campaign.remove_post'),
    campaigns: () => false,
    tags: () => can('facebook.assign_content_tags'),
    createStory: false,
  },
  instagram: {
    code: 'INST',
    title: 'Instagram',
    icon: 'fa-instagram color-instagram',
    available: 'hasGrams',
    analyticAuth: 'analytics.view.instagram',
    campaignAuth: 'campaign.analytics.view.instagram',
    campaignPostAuth: 'campaign.analytics.view.instagram.grams',

    channel: 'Instagram',
    api: 'instagrams',

    tabs: ['All', 'Reels', 'Photos', 'Carousels', 'Videos', 'IGTV'],
    detailField: 'grams',
    searchField: 'text',

    totalPost: {
      field: 'metrics',
      metrics: ['videoViews', 'impressions', 'likes', 'comments', 'saved', 'engagements', 'shares'],
    },

    topContent: (isCampaigns: any) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'topContent' : 'stats.topContent',

    campaignType: 'Instagram Posts',

    removes: () => can('campaign.remove_post'),
    campaigns: () => can('instagram.assign_to_campaign'),
    tags: () => can('instagram.assign_content_tags'),
    createStory: false,
  },
  instagramad: {
    code: 'IGAD',
    title: 'Instagram Ads',
    icon: 'fa-instagram color-instagram',
    available: 'hasInstagramAd',
    analyticAuth: 'analytics.view.instagramAd',
    campaignAuth: 'campaign.analytics.view.instagramAd',
    campaignPostAuth: 'campaign.analytics.view.instagramAd.grams',
    controller: 'adsByCreator',

    channel: 'Instagram Ad Posts',
    api: 'instagrams',

    totalPost: {
      field: 'metrics',
      metrics: ['impressionsAds', 'videoViewsTotalAds', 'engagementAds', 'spend'],
    },

    tabs: ['All', 'Videos', 'Photos', 'Carousels'],
    detailField: 'instAds',
    searchField: ['body', 'post.text'],

    campaignType: 'Instagram Posts',

    removes: () => true,
    campaigns: () => true,
    tags: () => true,

    // removes: () => can('campaign.remove_post'),
    // campaigns: () => can('instagram.assign_to_campaign'),
    // tags: () => can('instagram.assign_content_tags'),
    createStory: false,
  },
  igAdDark: {
    code: 'IGDAD',
    channel: 'Instagram Ad Dark Posts',
    title: 'Instagram Ad Dark Posts',
    available: false,
    analyticAuth: false,
    campaignAuth: false,
    campaignPostAuth: false,
  },
  instagramstories: {
    code: 'IGS',
    title: 'Instagram Stories',
    icon: 'fa-instagram color-instagram',
    url: 'instagramStories',
    available: 'hasIgs',
    analyticAuth: 'analytics.view.instagramStories',
    campaignAuth: 'campaign.analytics.view.instagramStory',
    campaignPostAuth: 'campaign.analytics.view.instagramStory.stories',

    channel: 'Instagram Stories',
    api: 'instagrams',

    detailField: 'stories',
    searchField: 'name',
    detailTitle: 'Instagram Stories',

    controller: 'storyByCreator',

    campaignType: 'Instagram Stories',

    removes: () => can('campaign.remove_post'),
    campaigns: () => can('instagramStory.assign_to_campaign'),
    tags: () => can('instagramStory.assign_content_tags'),

    canDelete: (record: any, isCampaigns: any = false) =>
      can('instagramStory.delete') &&
      get(
        record,
        (isCampaigns ? apiEnums.campaigns : apiEnums.analytics)
          ? 'stats.postsCount'
          : 'posts.length',
        0,
      ) === 0,
    canView: () => can('analytics.view.instagramStories'),

    createStory: 'instagramStory.create',
    sessionQueue: (stories = []) =>
      stories.sort((a: any, b: any) => (a.storyDate < b.storyDate ? 1 : -1)).map((s: any) => s._id),
  },
  snapchat: {
    code: 'SNAP',
    title: 'Snapchat',
    icon: 'fa-snapchat',
    available: 'hasSnaps',
    analyticAuth: 'analytics.view.snapchat',
    campaignAuth: 'campaign.analytics.view.snapchat',
    campaignPostAuth: 'campaign.analytics.view.snapchat.stories',

    channel: 'Snapchat',
    api: 'snapchat',

    detailField: 'stories',
    searchField: 'name',
    detailTitle: 'Stories',
    subTitle: 'Choose a story to see the detailed results',

    campaignType: 'Snapchat Stories',

    removes: () => can('campaign.remove_post'),
    campaigns: () => can('snapchat.assign_to_campaign'),
    tags: () => can('snapchat.assign_content_tags'),
    createStory: 'snapchat.add_story',
    canDelete: (record: any, isCampaigns = false) =>
      can('snapchat.delete_story') &&
      can('is.admin') &&
      get(
        record,
        (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'postCount' : 'snaps.length',
        0,
      ) === 0,
    sessionQueue: (stories = []) =>
      stories
        .sort((a: any, b: any) => (a.occurredAt < b.occurredAt ? 1 : -1))
        .map((s: any) => s._id),
  },
  snapchatDiscover: {
    code: 'SNAPD',
    title: 'Snapchat Discover',
    icon: 'fa-snapchat-ghost',
    url: 'snapchatDiscover',
    available: 'hasSnapEditions',
    analyticAuth: 'analytics.view.snapchatDiscover',
    campaignAuth: false,
    campaignPostAuth: false,

    channel: 'Snapchat Discover',

    benchmarks: (isCampaigns: any) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'benchmarks' : 'benchmarks.GLOBAL',
    topContent: (isCampaigns: any) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'topContent' : 'topContent.GLOBAL',
    metricsMap: (isCampaigns: any) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'metricsMap' : 'charts.metrics.map',
    metricsChartData: (isCampaigns: any) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics)
        ? 'metricsChartData'
        : 'charts.metrics.compare.GLOBAL',

    detailTitle: 'Editions',
    subTitle: 'Choose an edition to see the detailed results',
    detailField: 'editions',
    searchField: 'name',

    campaigns: () => false,
    tags: () => false,
    createStory: 'snapchatDiscover.add_edition',
    canDelete: ({ snaps = [] }) => can('snapchatDiscover.delete_edition') && snaps.length === 0,

    controller: 'getByCreatorId',
  },
  twitter: {
    code: 'TWT',
    title: 'Twitter',
    icon: 'fa-twitter color-twitter',
    available: 'hasTweets',
    analyticAuth: 'analytics.view.twitter',
    campaignAuth: 'campaign.analytics.view.twitter',
    campaignPostAuth: 'campaign.analytics.view.twitter.tweets',

    api: 'tweets',
    channel: 'Twitter',

    searchField: ['fullText', 'text', 'title'], // text for Post, title for Videos-Only
    detailField: 'tweets',
    excludeTypes: {
      All: ['Retweets'],
    },

    totalPost: {
      field: 'metrics',
      metrics: [
        'publicEngagementsInt',
        'likesInt',
        'retweetsInt',
        'quoteTweetsInt',
        'repliesInt',
        'impressionsInt',
        'tweetEngagementsInt',
        'organicVideoViewsInt',
        'mediaViewsInt',
      ],
    },

    topContent: (isCampaigns: any) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'topContent' : 'stats.topContent',

    campaignType: 'Tweets',

    removes: () => can('campaign.remove_post'),
    campaigns: () => can('twitter.assign_to_campaign'),
    tags: () => can('twitter.assign_content_tags'),
    createStory: false,

    warningMessage: {
      published:
        'NOTE: Some in-depth metrics may be delayed 48 hours due to how Twitter reports information.',
    },

    authTypes: {
      auth: {
        userLevel: 'twitter.proMedia',
        accountLevel: 'activeTwitterProMedia',
      },
      items: [
        {
          label: 'Tweets',
          key: 'twitter',
        },
        {
          label: 'Videos',
          key: 'twitterVideo',
        },
      ],
    },
  },
  twitterVideo: {
    code: 'TWTV',
    available: false, // never shows on channel dropdown list
    channel: 'Twitter',
    title: 'Twitter',
    icon: 'fa-twitter color-twitter',
    analyticAuth: ['analytics.view.twitter', 'twitter.proMedia'],
    campaignAuth: ['campaign.analytics.view.twitter', 'twitter.proMedia'],
    campaignPostAuth: ['campaign.analytics.view.twitter.tweets', 'twitter.proMedia'],

    searchField: ['text', 'title'], // text for Post, title for Videos-Only
    detailField: 'tweets',

    topContent: (isCampaigns: any) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'topContent' : 'stats.topContent',

    campaignType: 'Videos',

    removes: () => can('campaign.remove_post'),
    campaigns: (viewType: string, campaignType: number) =>
      can('twitter.assign_to_campaign') && campaignType === 0,
    tags: () => can('twitter.assign_content_tags'),
    createStory: false,

    warningMessage: {
      published:
        'NOTE: Some in-depth metrics may be delayed 48 hours due to how Twitter reports information.',
    },

    authTypes: {
      auth: {
        userLevel: 'twitter.proMedia',
        accountLevel: 'activeTwitterProMedia',
      },
      items: [
        {
          label: 'Tweets',
          key: 'twitter',
        },
        {
          label: 'Videos',
          key: 'twitterVideo',
        },
      ],
    },
  },
  youtube: {
    code: 'YTB',
    title: 'YouTube',
    icon: 'fa-youtube color-youtube',
    available: 'hasYouTube',
    analyticAuth: 'analytics.view.youtube',
    campaignAuth: 'campaign.analytics.view.youtube',
    campaignPostAuth: 'campaign.analytics.view.youtube.ytVideos',

    channel: 'YouTube',

    topContent: () => 'topContent',

    totalPost: (isCampaigns: boolean) => ({
      field: isCampaigns ? 'metrics' : 'sortable',
      metrics: ['views', 'minutesViewed', 'comments', 'likes', 'shares'],
    }),

    detailField: 'ytVideos',
    detailTitle: 'YouTube Videos',
    searchField: ['title', 'description'],

    campaignType: 'Videos',

    removes: () => can('campaign.remove_post'),
    campaigns: () => can('youtube.assign_to_campaign'),
    tags: () => can('youtube.assign_content_tags'),
    createStory: false,
    warningMessage: {
      published:
        'NOTE: Some in-depth metrics may be delayed 48 hours due to how YouTube reports information.',
      all: 'NOTE: Some data may be delayed 48 hours due to how YouTube reports daily view information.',
    },
    viewTypes: {
      published: {
        title: 'View by Publish Date',
        tooltip: 'Metrics from Published Date',
        export: true,
      },
      all: {
        title: 'View by All Dates',
        tooltip: 'Metrics within Time Frame',
        export: true,
        v2: true,
      },
    },
  },
  tiktok: {
    code: 'TIKT',
    title: 'TikTok',
    icon: 'fa-tiktok color-tiktok',
    available: 'hasTiktok',
    analyticAuth: 'analytics.view.tiktok',
    campaignAuth: 'campaign.analytics.view.tiktok',
    campaignPostAuth: 'campaign.analytics.view.tiktok.posts',

    channel: 'TikTok',
    api: 'tiktok',

    detailField: 'posts',
    // search to filter field in analytics post table
    searchField: 'videoTitle',
    detailTitle: 'TikTok',

    campaignType: 'Posts',

    removes: () => can('campaign.remove_post'),
    campaigns: () => can('tiktok.assign_to_campaign'),
    tags: () => can('tiktok.assign_content_tags'),
    topContent: (isCampaigns = false) =>
      (isCampaigns ? apiEnums.campaigns : apiEnums.analytics) ? 'topContent' : 'stats.topContent',

    canDelete: (record: any, isCampaigns = false) =>
      can('tiktok.delete') &&
      get(
        record,
        (isCampaigns ? apiEnums.campaigns : apiEnums.analytics)
          ? 'stats.postsCount'
          : 'posts.length',
        0,
      ) === 0,
    canView: () => can('analytics.view.tiktok'),

    createStory: 'tiktok.create',
  },
  twitch: {
    code: 'TCH',
    title: 'Twitch',
    available: 'hasTwitch',
    analyticAuth: 'analytics.view.twitch',
    campaignAuth: 'campaign.analytics.view.twitch',
    campaignPostAuth: 'campaign.analytics.view.twitch.posts',

    channel: 'Twitch',

    totalPost: {
      field: 'metrics',
      metrics: [
        'netFollowers',
        'peakViewers',
        'minutesViewed',
        'chats',
        'uniqueChatters',
        'videoViews',
        'avgMinuteAudience',
        'minutes',
      ],
    },

    searchField: ['text', 'title'],
    icon: 'fa-twitch color-twitch',

    tabs: ['All', 'Live Stream', 'Video'],
    detailField: 'tchVideos',

    campaignType: 'Posts',
    createStory: false,

    removes: () => true,
    campaigns: () => true,
    tags: () => true,

    topContent: () => 'topContent',
  },
};

export const topContentDefault = {
  overview: 'Engagements',
  facebook: 'Engagements',
  fblive: 'Engagements',
  instagram: 'Impressions',
  snapchatDiscover: 'Top Snap Views',
  youtube: 'Avg Watch Time',
  twitter: 'Engagements',
  tiktok: 'Engagements',
  tagreport: 'Views',
  accountprofile: 'Views',
  twitch: 'Views',
};

// correspond to delmondo-node/server/api/static/static.controller.js
export const topContent = {
  accountprofile: [
    { title: 'Views', path: 'metrics.views', format: '0.0a' },
    { title: 'Engagements', path: 'metrics.engagements', format: '0.0a' },
  ],
  facebook: [
    { title: '3-Sec Views', path: 'metrics.videoViews3s', format: '0.0a', type: 'video' },
    { title: '30-Sec Views', path: 'metrics.videoViews30s', format: '0.0a', type: 'video' },
    {
      title: 'Avg Watch Time',
      path: 'metrics.avgTimeWatchedRaw',
      format: '00:00:00',
      type: 'video',
    },
    { title: 'Engagements', path: 'metrics.engagements', format: '0.0a' },
    {
      title: 'Engagement Rate',
      path: 'metrics.engagementRate',
      format: '0.0%',
      campaignsHide: true,
    },
    { title: 'Impressions', path: 'metrics.impressions', format: '0.0a' },
    { title: 'Shares', path: 'metrics.shares', format: '0.0a' },
  ],
  fblive: [
    { title: '3-Sec Views', path: 'metrics.videoViews3s', format: '0.0a', type: 'video' },
    { title: '30-Sec Views', path: 'metrics.videoViews30s', format: '0.0a', type: 'video' },
    {
      title: 'Avg Watch Time',
      path: 'metrics.avgTimeWatchedRaw',
      format: '00:00:00',
      type: 'video',
    },
    { title: 'Engagements', path: 'metrics.engagements', format: '0.0a' },
    { title: 'Impressions', path: 'metrics.impressions', format: '0.0a' },
    { title: 'Shares', path: 'metrics.shares', format: '0.0a' },
  ],
  instagram: [
    { title: 'Impressions', path: 'metrics.impressions', format: '0.0a' },
    { title: 'Views', path: 'metrics.videoViews', format: '0.0a', type: 'video' },
    { title: 'Reach', path: 'metrics.reach', format: '0.0a' },
    { title: 'Engagements', path: 'metrics.engagements', format: '0.0a' },
    {
      title: 'Engagement Rate',
      path: 'metrics.engagementRate',
      format: '0.0%',
      campaignsHide: true,
    },
  ],
  overview: [
    { title: 'Engagements', path: 'metrics.engagements', format: '0.0a' },
    {
      title: 'Engagement Rate',
      path: 'metrics.engagementRate',
      format: '0.0%',
      campaignsHide: true,
    },
    { title: 'Views', path: 'metrics.views', format: '0.0a', type: 'video' },
  ],
  tagreport: [
    { title: 'Views', path: 'v', format: '0.0a' },
    { title: 'Impressions', path: 'i', format: '0.0a' },
    { title: 'Engagements', path: 'fbE', format: '0.0a' },
    { title: 'Views Paid', path: 'vCp', format: '0.0a' },
    { title: 'Views Organic', path: 'vCo', format: '0.0a' },
    { title: 'Avg Watch Time', path: 'vatw', format: '00:00:00' },
    { title: 'Watch Time', path: 'vT', format: '00:00:00' },
    { title: 'Action Rate', path: 'aR', format: '0.0%' },
  ],
  tagcomparisonreport: [
    { title: 'Views', path: 'v', format: '0.0a' },
    { title: 'Impressions', path: 'i', format: '0.0a' },
    { title: 'Engagements', path: 'fbE', format: '0.0a' },
    { title: 'Views Paid', path: 'vCp', format: '0.0a' },
    { title: 'Views Organic', path: 'vCo', format: '0.0a' },
    { title: 'Avg Watch Time', path: 'vatw', format: '00:00:00' },
    { title: 'Total Watch Time', path: 'vT', format: '00:00:00' },
    { title: 'Action Rate', path: 'aR', format: '0.0%' },
  ],
  snapchatDiscover: [
    { title: 'Top Snap Views', path: '', format: '0.0a' },
    { title: 'Attachment Views', path: '', format: '0.0a' },
    { title: 'Shares', path: '', format: '0.0a' },
    { title: 'Average Time', path: '', format: '0.0a' },
  ],
  tiktok: [
    { title: 'Views', path: 'summary.views', format: '0.0a' },
    { title: 'Engagements', path: 'summary.engagements', format: '0.0a' },
    { title: 'Engagement Rate', path: 'summary.engagementRate', format: '0.0%' },
  ],
  twitter: [
    { title: 'Engagements', path: 'metrics.publicEngagementsInt', format: '0.0a' },
    {
      title: 'Engagement Rate',
      path: 'metrics.engagementRate',
      format: '0.0%',
      campaignsHide: true,
    },
    { title: 'Views', path: 'metrics.videoViewsInt', format: '0.0a', type: 'video' },
  ],
  youtube: [
    { title: 'Avg Watch Time', path: 'metricsRaw.avgWatchTime', format: '00:00:00' },
    { title: 'Views', path: 'metricsRaw.views', format: '0.0a' },
    { title: 'Minutes Consumed', path: 'metricsRaw.minutesViewed', format: '0.0a' },
    { title: 'Engagements', path: 'metricsRaw.engagements', format: '0.0a' },
    {
      title: 'Engagement Rate',
      path: 'metrics.engagementRate',
      format: '0.0%',
      campaignsHide: true,
    },
  ],
  twitch: [
    { title: 'Views', path: 'metrics.videoViews', format: '0.0a' },
    { title: 'New Followers', path: 'metrics.netFollowers', format: '0.0a' },
    { title: 'Chats', path: 'metrics.chats', format: '0.0a' },
    { title: 'Minutes Watched', path: 'metrics.minutesViewed', format: '0.0a' },
    { title: 'Peak Viewers', path: 'metrics.peakViewers', format: '0.0a' },
    { title: 'Avg Minute Audience', path: 'metrics.avgMinuteAudience', format: '0.0a' },
  ],
};
