import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import * as types from './constants';

const selector = (state: any) => state[types.SCOPENAME];

export const talentGroupsSelector = createSelector(selector, (state) => state.talentGroups);

export const listCreatorsSelector = createSelector(selector, (state) => state.listCreators);

export const listAgenciesSelector = createSelector(selector, (state) => state.listAgencies);

export const fblSelector = createSelector(selector, (state) => state.facebookLive);

export const sortedListSelector = createSelector(selector, (state) => {
  const sortedTalentGroups = sortBy(state.lists, ['name']);
  const notCompetitorList = sortedTalentGroups.filter(
    (item: any) => item.isCompetitorList === false,
  );
  const competitorList = sortedTalentGroups.filter((item: any) => item.isCompetitorList === true);

  return { notCompetitorList, competitorList };
});

export const invitesSelector = createSelector(selector, (state) => state.invites);

export const agenciesSelector = createSelector(selector, (state) => state.agencies);

export const invitesListSelector = createSelector(selector, (state) => state.invitesList);

export const addOnSelector = createSelector(selector, (state) => state.addOns);

export const userPermissionSelector = createSelector(selector, (state) => state.userPermissions);

export const agencySelector = createSelector(selector, (state) => state.agency);

export const roleSelector = createSelector(selector, (state) => state.roles);
