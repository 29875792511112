import pick from 'lodash/pick';
import { createSelector } from 'reselect';
import * as types from './constants';

const selector = (state: any) => state[types.SCOPENAME];

export const contentSelector = createSelector(selector, (state) => state.content);

export const queryParamsSelector = createSelector(selector, (state) =>
  pick(state, ['startDate', 'endDate', 'channel', 'id', 'type']),
);
