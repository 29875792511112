import * as types from './constants';

const initState = {
  content: [],
  startDate: '',
  endDate: '',
  channel: '',
  id: '',
  type: '', // analytics or campaigns
};

export default function reducers(state, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.SET_TOP_CONTENT: {
      const { params, content } = action.payload;
      return { ...state, ...params, content };
    }
    default:
      return state;
  }
}
