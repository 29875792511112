import client from '../Client';

export const previewPostByIframely = (params: any) =>
  client.get(
    `${window.location.protocol}//iframe.ly/api/oembed`,
    {
      api_key: `${process.env.REACT_APP_IFRAMELY_API_KEY}` || 'iframely.apiKey',
      omit_script: 1,
      ...params,
    },
    { withCredentials: false },
  );

export const previewAdPost = (params: { adId: number | string; platform: string }) =>
  client.get('facebook/ads/preview', params);
