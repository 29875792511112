import * as types from './constants';

export function getS3Urls() {
  return {
    type: types.GET_S3URLS,
  };
}

export function setS3Urls(payload: any) {
  return {
    type: types.SET_S3URLS,
    payload,
  };
}
