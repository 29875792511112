import get from 'lodash/get';
import * as types from './constants';

const initState = {
  agencyId: 0,
  agencies: [],
  overview: {},
  nameList: [],
  creatorInfo: {},
  mediaChannel: {},
  mediaDetail: {},
  campaigns: [],
  adminTags: [],
  searchedTags: [],
  facebookVideoInsights: [],
  storyList: [],
  creatorChannelId: {},
  snapStory: {},
  deletingCampaignIds: [],
  deletingTagIds: [],
  channelMappings: [],
  // show olapBenchMarks switch
  olapBenchMarks: false,
};

function addCampainsAndTags(posts: any, item: any, type: any) {
  const postIndex = posts.findIndex((post: any) => post._id === item.postId);
  const post = posts[postIndex] || {};
  const newPost =
    type === 'campaign'
      ? {
          ...post,
          campaigns: post.campaigns.concat({
            ...item,
            _id: item.campaignId,
          }),
          hasCampaigns: true,
        }
      : {
          ...post,
          tags: (get(post, 'tags') || []).concat({
            ...item,
            _id: item.ContentTagId,
          }),
          hasTags: true,
        };

  return [...posts.slice(0, postIndex), newPost, ...posts.slice(postIndex + 1)];
}

function removeCampaignsAndTags(posts: any, postId: any, itemId: any, type: any) {
  const postIndex = posts.findIndex((post: any) => post._id === postId);
  const post = posts[postIndex];
  const newItems = post[type].filter((c: any) => c._id !== itemId);
  const newPost = {
    ...post,
    [type]: newItems,
    [type === 'campaigns' ? 'hasCampaigns' : 'hasTags']: newItems.length > 0,
  };
  return [...posts.slice(0, postIndex), newPost, ...posts.slice(postIndex + 1)];
}

export default function reducers(state: any, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.UPDATE_AGENCY_ID: {
      return { ...state, agencyId: action.payload };
    }
    case types.SET_CHANNEL_MAPPINGS: {
      return { ...state, channelMappings: action.payload };
    }
    case types.SET_FACEBOOK_VIDEO_INSIGHTS: {
      return { ...state, facebookVideoInsights: action.payload };
    }
    case types.SET_OVERVIEW: {
      return { ...state, overview: action.payload };
    }
    case types.SET_NAMELIST: {
      return { ...state, nameList: action.payload };
    }
    case types.SET_OLAP_BENCHMARKS: {
      return { ...state, olapBenchMarks: action.payload };
    }
    case types.SET_CREATORINFO: {
      return { ...state, creatorInfo: action.payload };
    }
    case types.SET_MEDIAREPORT: {
      return { ...state, mediaChannel: action.payload };
    }
    case types.SET_CAMPAIGNSBYCREATORID: {
      const campaigns = action.payload.map((campaign: any) => ({
        _id: campaign._id,
        campaignName: campaign.campaignName,
      }));
      return { ...state, campaigns };
    }
    case types.UPDATE_ADMIN_TAG: {
      const tagIndex = state.adminTags.findIndex(
        (tag: any) => tag.ContentTagId === action.payload.ContentTagId,
      );
      const adminTag = {
        ...state.adminTags[tagIndex],
        ...action.payload,
      };
      return {
        ...state,
        adminTags: [
          ...state.adminTags.slice(0, tagIndex),
          adminTag,
          ...state.adminTags.slice(tagIndex + 1),
        ],
      };
    }
    case types.ADD_ADMIN_TAGS: {
      const adminTags = [...state.adminTags, ...action.payload];
      return { ...state, adminTags };
    }
    case types.REMOVE_ADMIN_TAGS: {
      const adminTags = state.adminTags.filter(
        (tag: any) => tag.ContentTagId !== action.payload._id,
      );
      return { ...state, adminTags };
    }
    case types.ADD_CAMPAIGNS_TAGS: {
      const entityField = action.payload.entityField || 'mediaChannel';
      const latestPosts = action.payload.data.reduce(
        (accumulatePosts: any, currentItem: any) =>
          addCampainsAndTags(accumulatePosts, currentItem, action.payload.key),
        state[entityField][action.payload.detailField],
      );
      return {
        ...state,
        [entityField]: { ...state[entityField], [action.payload.detailField]: latestPosts },
      };
    }
    case types.REMOVE_CAMPAIGNS_TAGS: {
      const entityField = action.payload.entityField || 'mediaChannel';
      const latestPosts = action.payload.data.postIds.reduce(
        (accumulatePosts: any, currentPostId: any) =>
          removeCampaignsAndTags(
            accumulatePosts,
            currentPostId,
            action.payload.data._id,
            action.payload.key,
          ),
        state[entityField][action.payload.detailField],
      );
      return {
        ...state,
        [entityField]: { ...state[entityField], [action.payload.detailField]: latestPosts },
      };
    }
    case types.SET_SEARCHED_TAGS: {
      return { ...state, searchedTags: action.payload };
    }
    case types.SET_ADMIN_TAGS: {
      return { ...state, adminTags: action.payload };
    }
    case types.SET_STORY_TITLE: {
      const newState = {} as any;
      if (state.mediaChannel.stories && state.mediaChannel.media === action.payload.media) {
        const storyIndex = state.mediaChannel.stories.findIndex(
          (s: any) => s._id === action.payload._id,
        );
        if (storyIndex !== -1) {
          const stories = [
            ...state.mediaChannel.stories.slice(0, storyIndex),
            { ...state.mediaChannel.stories[storyIndex], name: action.payload.name },
            ...state.mediaChannel.stories.slice(storyIndex + 1),
          ];
          newState.mediaChannel = { ...state.mediaChannel, stories };
        }
      }
      if (
        state.mediaDetail &&
        state.mediaDetail.media === action.payload.media &&
        state.mediaDetail._id === action.payload._id
      ) {
        newState.mediaDetail = { ...state.mediaDetail, name: action.payload.name };
      }
      return { ...state, ...newState };
    }
    case types.SET_MEDIA_DETAIL: {
      return { ...state, mediaDetail: action.payload };
    }
    case types.SET_STORY_LIST: {
      return { ...state, storyList: action.payload };
    }
    case types.SET_CREATOR_CHANNEL_ID: {
      return { ...state, creatorChannelId: action.payload };
    }
    case types.SET_SNAP_STORY: {
      return { ...state, snapStory: action.payload };
    }
    case types.UPDATE_DELETING_CAMPAIGN_IDS: {
      let afterIds;
      if (action.payload.type === 'add') {
        afterIds = [...state.deletingCampaignIds, action.payload.id];
      } else {
        afterIds = state.deletingCampaignIds.filter((d: any) => d !== action.payload.id);
      }
      return { ...state, deletingCampaignIds: afterIds };
    }
    case types.UPDATE_DELETING_TAG_IDS: {
      let afterIds;
      if (action.payload.type === 'add') {
        afterIds = [...state.deletingTagIds, action.payload.id];
      } else {
        afterIds = state.deletingTagIds.filter((d: any) => d !== action.payload.id);
      }
      return { ...state, deletingTagIds: afterIds };
    }
    case types.UPDATE_SNAP_FRAME: {
      const frameIndex = state.mediaDetail.snaps.findIndex(
        (snap: any) => snap._id === action.payload._id,
      );
      if (frameIndex !== -1) {
        const newFrame = { ...state.mediaDetail.snaps[frameIndex], ...action.payload };
        const newSnaps = [
          ...state.mediaDetail.snaps.slice(0, frameIndex),
          newFrame,
          ...state.mediaDetail.snaps.slice(frameIndex + 1),
        ];
        return { ...state, mediaDetail: { ...state.mediaDetail, snaps: newSnaps } };
      }
      return state;
    }
    case types.SET_AGENCIES: {
      return { ...state, agencies: action.payload };
    }
    default:
      return state;
  }
}
