import { optionNewAPI } from '@/utils/helper';
import { apiEnums } from '@/utils/constant';
import { TimeZoneList, getCurrentTimeZone } from '@/utils/time';
import { getDateRangeFromTimeFrame } from '~/Leaderboard/components/List/TabHeader/helper';
import client from '../Client/index';

type Query = {
  talentGroupId?: number;
  startDate?: string;
  endDate?: string;
  perStartDate?: string;
  perEndDate?: string;
  timeZone?: TimeZoneList;
};

export const getCreators = (params: any) => {
  const { month, dateBegin, dateEnd, perDateBegin, perDateEnd, talentGroupId, timeZone } = params;
  const query: { channel: string } & Query = {
    channel: 'ALL',
  };

  if (talentGroupId || talentGroupId === 0) {
    query.talentGroupId = talentGroupId;
  }

  const dateRange = getDateRangeFromTimeFrame(month, dateBegin, dateEnd, perDateBegin, perDateEnd);
  query.startDate = dateRange.dateBegin;
  query.endDate = dateRange.dateEnd;
  // add TimeZone for query data
  query.timeZone = timeZone || getCurrentTimeZone();

  if (dateRange.compareDateBegin && dateRange.compareDateEnd) {
    query.perStartDate = dateRange.compareDateBegin;
    query.perEndDate = dateRange.compareDateEnd;
  }

  return client.get('/creators/leaders', query, optionNewAPI(apiEnums.leaders));
};

export const getAllCreators = () => client.get('/creators/nameListLight', null);

export const getCompanies = ({
  month,
  dateBegin,
  dateEnd,
  perDateBegin,
  perDateEnd,
  talentGroupId,
  timeZone = getCurrentTimeZone(),
}: any) => {
  const dateRange = getDateRangeFromTimeFrame(month, dateBegin, dateEnd, perDateBegin, perDateEnd);
  const query: Query = {};
  query.startDate = dateRange.dateBegin;
  query.endDate = dateRange.dateEnd;

  // add TimeZone for query data
  query.timeZone = timeZone;
  if (dateRange.compareDateBegin && dateRange.compareDateEnd) {
    query.perStartDate = dateRange.compareDateBegin;
    query.perEndDate = dateRange.compareDateEnd;
  }

  if (talentGroupId || talentGroupId === 0) {
    query.talentGroupId = talentGroupId;
  }
  return client.get('/creators/leaders/holding_company', query, optionNewAPI(true));
};

export const getPosts = (params: any) => {
  const platform = {
    ALL: 'ALL',
    FB: 'Facebook',
    INST: 'Instagram',
    TWT: 'Twitter',
    YTB: 'YouTube',
  } as any;

  const query = {
    searchKeyword: params.searchKeyword,
    postType: params.postType,
    channel: platform[params.channel],
    startDate: params.dateBegin,
    endDate: params.dateEnd,
    talentGroupId: params.talentGroupId,
    orderBy: params.postSorter.columnKey,
    seq: params.postSorter.order === 'descend' ? 'desc' : 'asc',
    timeZone: params.timeZone || getCurrentTimeZone(),
    accountName: params.name,
  };
  return client.post('/creators/leaders/post_search', query, optionNewAPI(true));
};

export const getList = ({ id, agencies, creators }: any) =>
  client.get(`/talentGroups/${id}?agencies=${agencies}&creators=${creators}`);

export const postList = (params: any) => {
  // edit or add
  // _id start from 0, so must judge it's type is undefined or not
  const url = typeof params._id !== 'undefined' ? `/talentGroups/${params._id}` : '/talentGroups';
  return client.post(url, params);
};

export const getGenres = () => client.get('/genres');

export const getTalentGroups = () => client.get('/talentGroups/leaderList');

export const getTalentGroupCounts = () => client.get('/agencies/talentGroupCounts');
