import { call, put, takeLatest, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { message } from 'antd';
import moment from 'moment-timezone';
import cookies from '@/utils/cookies';
import Client from '@/service/Client';
import { pushLocation } from '@/rootStore/router/actions';
import withLoading from '@/sagaMiddleware';
import * as api from '@/service/Auth';
import { getChannels } from '@/service/Channels';
import * as actions from './actions';
import * as types from './constants';
import * as selectors from './selectors';

export function* fetchAuthLocal(action: any) {
  try {
    const res = yield call(withLoading, api.getAuthLocal, action.type, action.payload.values);
    Client.reset();
    // const cookies = new Cookies();
    // tokenDuration = 18000 seconds = 300 minutes = 5hours
    const maxAge = parseInt(res.tokenDuration, 10);
    // const domain = window.location.hostname.split('.').slice(1).join('.');
    cookies.set('token', res.token, { path: '/', maxAge });
    cookies.set('tokenDuration', res.tokenDuration, { path: '/', maxAge });
    cookies.set(
      'tokenExpiresAt',
      moment(Date.now()).add(maxAge, 'seconds').format('YYYY-MM-DDTHH:mm:ssZ'),
      { path: '/', maxAge },
    );

    window.location.href = action.payload.from || '/';
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAuthLocal() {
  yield takeLatest(types.GET_AUTH_LOCAL, fetchAuthLocal);
}

function* fetchChannels() {
  try {
    const channels = yield select(selectors.channelsSelector);
    if (!channels.length) {
      const res = yield call(getChannels);
      const filterRes = (res || []).filter((i: any) => get(i, 'name', '').toLowerCase() !== 'vine');
      yield put(actions.setChannels(filterRes));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchChannels() {
  yield takeLatest(types.GET_CHANNELS, fetchChannels);
}

function* fetchEnvInfo() {
  try {
    const res = yield call(api.getEnvInfo);
    yield put(actions.setEnvInfo({ flaskApi: res }));
    const res2 = yield call(api.getEnvInfo, 'node');
    yield put(actions.setEnvInfo({ nodeApi: res2 }));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchEnvInfo() {
  yield takeLatest(types.GET_ENVINFO, fetchEnvInfo);
}

function* fetchAppConfig() {
  try {
    const res = yield call(api.getAppConfig);
    yield put(actions.setAppConfig(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAppConfig() {
  yield takeLatest(types.GET_APP_CONFIG, fetchAppConfig);
}

export function* fetchFullProfile() {
  try {
    const res = yield call(api.getFullProfile);
    yield put(actions.setFullProfile(res));
    return res;
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
    return err;
  }
}
function* watchFetchFullProfile() {
  yield takeLatest(types.GET_FULLPROFILE, fetchFullProfile);
}

export function* fetchUsersMeMenus(action: any) {
  try {
    const res = yield call(withLoading, api.getUsersMeMenus, action.type, action.payload);
    yield put(actions.setUsersMeMenus(res.items));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchUsersMeMenus() {
  yield takeLatest(types.GET_USERS_ME_MENUS, fetchUsersMeMenus);
}

function* fetchMenus() {
  try {
    const menus = yield select(selectors.menusSelector);
    if (!menus.length) {
      yield put(actions.getUsersMeMenus());
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchMenus() {
  yield takeLatest(types.GET_MENUS, fetchMenus);
}

function* ForgotPassword(action: any) {
  try {
    const res = yield call(withLoading, api.forgotPassword, action.type, action.payload);
    if (res.message) {
      yield call(message.success, res.message);
      yield put(pushLocation('/login/legacy'));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  } finally {
    yield call(action.callback);
  }
}
function* watchForgotPassword() {
  yield takeLatest(types.FORGOT_PASSWORD, ForgotPassword);
}

function* ResetPassword(action: any) {
  try {
    const res = yield call(withLoading, api.resetPassword, action.type, action.payload);
    if (res.message) {
      yield call(message.success, 'Password successfully changed');
      yield put(pushLocation('/login/legacy'));
    }
  } catch (err: any) {
    let errMessage;
    switch (err.code) {
      case 403: {
        errMessage = 'Incorrect password';
        break;
      }
      case 406: {
        errMessage = 'You may not use one of your last 4 passwords';
        break;
      }
      default: {
        errMessage = err.message || 'unknown error';
      }
    }
    yield call(message.error, errMessage);
  } finally {
    yield call(action.callback);
  }
}
function* watchResetPassword() {
  yield takeLatest(types.RESET_PASSWORD, ResetPassword);
}

function* CreateUser(action: any) {
  try {
    const res = yield call(withLoading, api.createUser, action.type, action.payload);
    if (res.message) {
      yield call(message.success, res.message);
      yield put(pushLocation('/login/legacy'));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  } finally {
    yield call(action.callback);
  }
}
function* watchCreateUser() {
  yield takeLatest(types.CREATE_USER, CreateUser);
}

function* ConfirmEmail(action: any) {
  try {
    yield call(withLoading, api.confirmEmail, action.type, action.payload);
    yield put(pushLocation('/login/legacy'));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  } finally {
    yield call(action.callback);
  }
}
function* watchConfirmEmail() {
  yield takeLatest(types.CONFIRM_EMAIL, ConfirmEmail);
}

function* getTrialUserInfo(action: any) {
  try {
    const res = yield call(withLoading, api.getTrialInfo, action.type, action.params);
    yield put(actions.setTrialInfo(res));
    yield call(action.callback, { type: 'page1' });
  } catch (err: any) {
    yield call(action.callback, { type: 'error', message: err.message });
  }
}
function* watchGetTrialUserInfo() {
  yield takeLatest(types.GET_TRIAL_INFO, getTrialUserInfo);
}

function* confirmTrial(action: ReturnType<typeof actions.confirmTrial>) {
  try {
    yield call(withLoading, api.confirmTrialRequest, action.type, action.params);
    yield call(action.callback, true);
  } catch (err: any) {
    yield call(action.callback, false);
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchConfirmTrial() {
  yield takeLatest(types.CONFIRM_TRIAL, confirmTrial);
}

export default [
  watchFetchAuthLocal,
  watchFetchChannels,
  watchFetchFullProfile,
  watchFetchUsersMeMenus,
  watchFetchMenus,
  watchFetchAppConfig,
  watchFetchEnvInfo,
  watchForgotPassword,
  watchResetPassword,
  watchCreateUser,
  watchConfirmEmail,
  watchGetTrialUserInfo,
  watchConfirmTrial,
];
