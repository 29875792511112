/*
posts.all.current = sum(post.all.current)
posts.video.current = sum(post.video.current)

views.current = sum(post.views.current)

engagement.all.current = sum(engagement.all.current)
engagement.video.current = sum(engagement.video.current) // For culculate

audience.total = sum(audience.total)

engagement_rate.all.current = sum(engagement_rate.all.current_total) / sum(engagement_rate.all.current_post_count)
engagement_rate.all.last = sum(engagement_rate.all.last_total) / sum(engagement_rate.all.last_post_count)

--overall--
views.perVideo.current = views.current / views.video.current if views.video.current > 0 else 0.0
views.perVideo.last = views.last / views.video.last if views.video.last > 0 else 0.0

-- channels --
views.perVideo.current = views.current / posts.video.current if posts.video.current > 0 else 0.0
views.perVideo.last = views.last / posts.video.last if posts.video.last > 0 else 0.0

engagement.perVideo.current = engagement.video.current / posts.video.current if posts.video.current > 0 else 0.0
engagement.perVideo.last = engagement.video.last / posts.video.last if posts.video.last > 0 else 0.0

engagement.perPost.current = engagement.all.current / posts.all.current
engagement.perPost.last = engagement.all.last / posts.all.last
 */
import set from 'lodash/set';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';

function sumMetricByPath(sum: any, current: any, path: any) {
  const sumV = get(sum, path, 0);
  const currentV = get(current, path, 0);
  return sumV + currentV;
}

function updateMetircs(sum: any, current: any, path: any) {
  set(sum, path, sumMetricByPath(sum, current, path));
}

function updatePerMetircs(calculatedMetrics: any, path: any, numerator: any, denominator: any) {
  const dv = get(calculatedMetrics, denominator, 0);
  const nv = get(calculatedMetrics, numerator, 0);
  const perV = nv > 0 ? nv / dv : 0;
  set(calculatedMetrics, path, perV);
}

function updateGrowthPct(calculatedMetrics: any, current: any, last: any) {
  const cv = get(calculatedMetrics, current, 0);
  const lv = get(calculatedMetrics, last, 0);
  const path = current.replace(/\.(\w)+$/, '.growthPct');
  set(calculatedMetrics, path, lv !== 0 ? cv / lv - 1 : 0);
}

export function calculateMetircs(accounts = [], overAll = false) {
  const calculatedMetrics = accounts.reduce((sum, current) => {
    updateMetircs(sum, current, 'audience.total');
    updateMetircs(sum, current, 'audience.last');

    updateMetircs(sum, current, 'posts.video.current');
    updateMetircs(sum, current, 'posts.video.last');

    updateMetircs(sum, current, 'posts.all.current');
    updateMetircs(sum, current, 'posts.all.last');

    updateMetircs(sum, current, 'views.current');
    updateMetircs(sum, current, 'views.last');

    updateMetircs(sum, current, 'engagement.all.current');
    updateMetircs(sum, current, 'engagement.all.last');

    // Engagement for per video
    updateMetircs(sum, current, 'engagement.video.last');
    updateMetircs(sum, current, 'engagement.video.current');

    // Overall views/video video number
    updateMetircs(sum, current, 'views.video.current');
    updateMetircs(sum, current, 'views.video.last');

    // Engagement Rate total
    updateMetircs(sum, current, 'engagement_rate.all.current_total');
    updateMetircs(sum, current, 'engagement_rate.all.last_total');

    updateMetircs(sum, current, 'engagement_rate.all.current_post_count');
    updateMetircs(sum, current, 'engagement_rate.all.last_post_count');

    return sum;
  }, Object.create(null));

  // Views / Video* can not be calculate total
  updatePerMetircs(
    calculatedMetrics,
    'views.perVideo.current',
    'views.current',
    overAll ? 'views.video.current' : 'posts.video.current',
  );
  updatePerMetircs(
    calculatedMetrics,
    'views.perVideo.last',
    'views.last',
    overAll ? 'views.video.last' : 'posts.video.last',
  );

  // Engagements / Video can not be calculate total
  updatePerMetircs(
    calculatedMetrics,
    'engagement.perVideo.current',
    'engagement.video.current',
    'posts.video.current',
  );
  updatePerMetircs(
    calculatedMetrics,
    'engagement.perVideo.last',
    'engagement.video.last',
    'posts.video.last',
  );

  // Engagements / Post can not be calculate total
  updatePerMetircs(
    calculatedMetrics,
    'engagement.perPost.current',
    'engagement.all.current',
    'posts.all.current',
  );
  updatePerMetircs(
    calculatedMetrics,
    'engagement.perPost.last',
    'engagement.all.last',
    'posts.all.last',
  );

  // Engagement Rate can not be calculate total
  updatePerMetircs(
    calculatedMetrics,
    'engagement_rate.all.current',
    'engagement_rate.all.current_total',
    'engagement_rate.all.current_post_count',
  );
  updatePerMetircs(
    calculatedMetrics,
    'engagement_rate.all.last',
    'engagement_rate.all.last_total',
    'engagement_rate.all.last_post_count',
  );

  updateGrowthPct(calculatedMetrics, 'posts.all.current', 'posts.all.last');
  updateGrowthPct(calculatedMetrics, 'posts.video.current', 'posts.video.last');
  updateGrowthPct(calculatedMetrics, 'views.current', 'views.last');
  updateGrowthPct(calculatedMetrics, 'views.perVideo.current', 'views.perVideo.last');
  updateGrowthPct(calculatedMetrics, 'engagement.all.current', 'engagement.all.last');
  updateGrowthPct(calculatedMetrics, 'engagement.perVideo.current', 'engagement.perVideo.last');
  updateGrowthPct(calculatedMetrics, 'engagement.perPost.current', 'engagement.perPost.last');
  updateGrowthPct(calculatedMetrics, 'audience.total', 'audience.last');
  updateGrowthPct(calculatedMetrics, 'engagement_rate.all.current', 'engagement_rate.all.last');

  calculatedMetrics.countAvg = accounts.length;

  return calculatedMetrics;
}

export function totalAndChannelsMetrics(accounts: any) {
  const metrics = calculateMetircs(accounts, true);

  const accountChannels = flatten(accounts.map((i: any) => i.channels || []));
  const groupByAccounts = groupBy(accountChannels, 'code') as any;
  metrics.channels = ['FB', 'INST', 'TWT', 'YTB'].map((code) => {
    const channelMetrics = calculateMetircs(groupByAccounts[code]);
    return { code, ...channelMetrics };
  });

  return metrics;
}

export default function calculateCompanyMetrics(companies = []) {
  const filteredCompanies = [] as any;
  companies.forEach((company: any) => {
    if (company.accounts.length) {
      const metrics = totalAndChannelsMetrics(company.accounts);
      filteredCompanies.push({ ...company, ...metrics });
    }
  });

  return filteredCompanies;
}
