import React from 'react';
import groupBy from 'lodash/groupBy';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import { cloneDeep, get, isEmpty, isFunction, lowerFirst, toArray, omit, flatten } from 'lodash';
import * as api from '@/service/Analytics';
import { pushLocation } from '@/rootStore/router/actions';
import { exportExcel } from '@/charts/store/actions';
import { getCampaignsByCreator } from '@/service/Campaigns';
import {
  byPostAndChannel,
  searchTags,
  tagPost,
  untagPost,
  createContentTags,
} from '@/service/ContentTags';
import withLoading from '@/sagaMiddleware';
import { defaultAnalyticsDateRange, getCurrentTimeZone } from '@/utils/time';
import { setStoryQueue, getStoryQueue, sortListByAlpha } from '@/utils/helper';
import { apiEnums } from '@/utils/constant';
import * as actions from './actions';
import * as types from './constants';
import * as selectors from './selectors';
import { mediaReportsAvailable } from '../containers/modalConstants';

type MediaReportsAvailableType = keyof typeof mediaReportsAvailable;
type ApiType = keyof typeof api;

let lastOlapUrl: string;

// api response adapter for fetchOlapBenchmarks
function iterateRows(row: any, olapCompareBenchmarks: any) {
  const pctDiff = (newVal: any, ogVal: any) => {
    if (Number.isInteger(ogVal)) {
      ogVal = parseInt(ogVal, 10);
      newVal = parseInt(newVal, 10);
    } else {
      ogVal = parseFloat(ogVal);
      newVal = parseFloat(newVal);
    }
    const changePct = (newVal - ogVal) / ogVal;
    return changePct;
  };

  toArray(row).forEach((benchmark) => {
    const compareVal = get(olapCompareBenchmarks, benchmark.compareValue, null);
    benchmark.comparePctDiff =
      benchmark.value && benchmark.value > 0 && compareVal && compareVal > 0
        ? pctDiff(benchmark.value, compareVal)
        : null;
    benchmark.previous = compareVal;
  });
}
// api response adapter for fetchOlapBenchmarks
export function buildBenchmarksCompare(benchmarks: any, olapCompareBenchmarks: any) {
  // snapchat discover has different benchmarks struct
  if (benchmarks.GLOBAL) {
    iterateRows(benchmarks.GLOBAL, olapCompareBenchmarks);
  } else if (benchmarks.rows) {
    toArray(benchmarks.rows).forEach((row) => iterateRows(row, olapCompareBenchmarks));
  } else {
    iterateRows(benchmarks.data || benchmarks, olapCompareBenchmarks);
  }
  return benchmarks;
}

export function transformOverviewParams(params: any) {
  const transedParams = {
    ...params,
    timeZone: getCurrentTimeZone(),
  };
  if (moment().subtract(1, 'days').isSame(params.dateBegin, 'day')) {
    transedParams.skipAudience = true;
  }
  return transedParams;
}

export function* fetchOverview({ type, params: allParams }: any) {
  const { callback, ...params } = allParams;
  try {
    if (!params.dateBegin || !params.dateEnd) {
      const newDateRange = defaultAnalyticsDateRange();
      yield put(
        pushLocation(
          `/analytics/${params.id}/overview?dateBegin=${encodeURIComponent(
            newDateRange.dateBegin,
          )}&dateEnd=${encodeURIComponent(newDateRange.dateEnd)}`,
        ),
      );
    }

    yield put(actions.getCreatorInfo(params));
    const currentUrl = window.location.href.trim();
    if (lastOlapUrl !== currentUrl) {
      if (`${lastOlapUrl}`.includes('compareDateBegin')) {
        yield put(actions.setOlapBenchmarks(false));
      }
      lastOlapUrl = currentUrl;
    }
    const transedParams = yield call(transformOverviewParams, params);
    const res = yield call(withLoading, api.getAnalyticsOverview, type, transedParams);
    yield put(actions.setOverview({ ...res, ...params }));
    if (!isEmpty(get(res, 'benchmarks')) && isFunction(callback)) {
      (callback as any).call();
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchOverview() {
  yield takeLatest(types.GET_OVERVIEW, fetchOverview);
}

export function* fetchCreatorInfo(action: any) {
  try {
    const creatorInfo = yield select(selectors.creatorInfoSelector);
    if (+creatorInfo._id !== +action.params.id) {
      const res = yield call(withLoading, api.getCreatorInfo, action.type, action.params);
      yield put(actions.setCreatorInfo(res));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCreatorInfo() {
  yield takeLatest(types.GET_CREATORINFO, fetchCreatorInfo);
}

export function* fetchNameList({ type, params }: any) {
  try {
    const nameList = yield select(selectors.getNameListSelector);
    if (!nameList.length) {
      const res = yield call(withLoading, api.getCreatorNameList, type, params);
      yield put(actions.setNameList(res));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchNameList() {
  yield takeLatest(types.GET_NAMELIST, fetchNameList);
}

let lastOlapBenchmarksRes: any;
export function* fetchOlapBenchmarks({ type, params }: any) {
  try {
    const isOverview = get(params, 'media') === 'overview';
    const overview = yield select(selectors.getOverviewSelector);
    const isParamsOk =
      !isEmpty(params.compareDateBegin) &&
      !isEmpty(params.compareDateEnd) &&
      (!isOverview || !isEmpty(overview));

    const currentUrl = window.location.href.trim();
    const isDiffUrlParms = currentUrl !== lastOlapUrl;

    if (isDiffUrlParms) {
      lastOlapUrl = currentUrl;
    }
    if (isDiffUrlParms) {
      yield put(actions.setOlapBenchmarks(false));
    }

    if (isParamsOk) {
      const { dateBegin, dateEnd, compareDateBegin, compareDateEnd, ...others } = params;
      let res = yield call(withLoading, api.getOlapBenchmarks, type, {
        dateBegin,
        dateEnd,
        compareDateBegin,
        compareDateEnd,
        ...others,
      });

      if (res) {
        lastOlapBenchmarksRes = res;
      } else if (!isEmpty(lastOlapBenchmarksRes)) {
        res = lastOlapBenchmarksRes;
      } else {
        return;
      }

      if (isOverview) {
        // For the case that olap API takes quick time that the overview data being empty on start and not empty on end
        const overviewLatest = yield select(selectors.getOverviewSelector);
        if (!isEmpty(overviewLatest) || isDiffUrlParms) {
          const newBenchmarks = get(overviewLatest, 'benchmarks');
          if (isEmpty(newBenchmarks)) {
            return;
          }
          const benchmarks = buildBenchmarksCompare(cloneDeep(newBenchmarks), res);
          yield put(actions.setOverview({ ...overviewLatest, benchmarks }));
          yield put(actions.setOlapBenchmarks(!isEmpty(res)));
        }
        return;
      }

      const mediaChannel = yield select(selectors.getMediaChannelSelector);
      if (+mediaChannel.id === +params.id && !isEmpty(res) && isDiffUrlParms) {
        const newBenchmarks = get(mediaChannel, 'benchmarks');
        if (isEmpty(newBenchmarks)) {
          return;
        }
        const benchmarks = buildBenchmarksCompare(cloneDeep(newBenchmarks), res);
        yield put(actions.setMediaReport({ ...mediaChannel, benchmarks }));
        yield put(actions.setOlapBenchmarks(!isEmpty(res)));
      } else if (+mediaChannel.id === +params.id && isDiffUrlParms) {
        lastOlapBenchmarksRes = res;
      } else if (+mediaChannel.id !== +params.id && isDiffUrlParms) {
        yield put(actions.setMediaReport({}));
        lastOlapBenchmarksRes = res;
      } else {
        lastOlapBenchmarksRes = null;
      }
    } else {
      lastOlapBenchmarksRes = null;
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetcOlapBenchmarks() {
  yield takeEvery(types.GET_OLAP_BENCHMARKS, fetchOlapBenchmarks);
}

export function transformParams(params: any) {
  let dateBegin;
  let dateEnd;
  const timeZone = params.timeZone || getCurrentTimeZone();
  switch (params.media) {
    case 'snapchat':
      dateBegin = params.dateBegin;
      dateEnd = moment(params.dateEnd).add(1, 'day').format('YYYY-MM-DD');
      break;
    case 'instagramstories':
      dateBegin = params.dateBegin;
      dateEnd = params.dateEnd;
      break;
    default:
      dateBegin = params.dateBegin;
      dateEnd = params.dateEnd;
  }

  const mediaName = params.media as MediaReportsAvailableType;
  const mediaInfo = mediaReportsAvailable[mediaName] as any;
  const media = mediaInfo.api || params.media;
  const { controller } = mediaInfo;
  const { channel } = mediaInfo;

  return {
    media,
    timeZone,
    dateEnd,
    dateBegin,
    controller,
    channel,
  };
}

export function* fetchMediaReport(action: any) {
  const timeZone = getCurrentTimeZone();
  try {
    const { force, callback, ...params } = action.params;
    if (!params.dateBegin || !params.dateEnd) {
      const newDateRange = defaultAnalyticsDateRange();
      yield put(
        pushLocation(
          `/analytics/${params.id}/${params.media}?dateBegin=${encodeURIComponent(
            newDateRange.dateBegin,
          )}&dateEnd=${encodeURIComponent(newDateRange.dateEnd)}`,
        ),
      );
    }

    const mediaChannel = yield select(selectors.getMediaChannelSelector);
    if (
      params.dateBegin &&
      params.dateEnd &&
      (force === true ||
        +mediaChannel.id !== +params.id ||
        mediaChannel.media !== params.media ||
        params.dateBegin !== mediaChannel.dateBegin ||
        params.dateEnd !== mediaChannel.dateEnd ||
        params.compareDateBegin !== mediaChannel.compareDateBegin ||
        params.compareDateEnd !== mediaChannel.compareDateEnd ||
        params.viewType !== mediaChannel.viewType)
    ) {
      const isDiffUrlParms = window.location.href.trim() !== lastOlapUrl;

      yield put(actions.getCreatorInfo(params));
      if (isDiffUrlParms && `${lastOlapUrl}`.includes('compareDateBegin')) {
        lastOlapUrl = window.location.href.trim();
        yield put(actions.setOlapBenchmarks(false));
      }
      const transedParams = yield call(transformParams, params);
      const res = yield call(withLoading, api.getMediaReport, action.type, {
        ...params,
        timeZone,
        ...transedParams,
      });

      const mediaName = params.media as MediaReportsAvailableType;
      const mediaInfo = mediaReportsAvailable[mediaName] as any;

      if (mediaInfo.sessionQueue) {
        setStoryQueue(params.media, { data: mediaInfo.sessionQueue(res.stories), params });
      }

      if (lastOlapBenchmarksRes && params.media !== 'twitterVideo') {
        const benchmarks = buildBenchmarksCompare(
          get(res, 'benchmarks', []),
          lastOlapBenchmarksRes,
        );
        yield put(actions.setMediaReport({ ...res, ...params, benchmarks }));
        yield put(actions.setOlapBenchmarks(true));
        lastOlapBenchmarksRes = null;
      } else {
        yield put(actions.setMediaReport({ ...res, ...params }));
      }

      // for OlapBenchmarks
      if (!isEmpty(get(res, 'benchmarks')) && isFunction(callback)) {
        (callback as any).call();
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchMediaReport() {
  yield takeLatest(types.GET_MEDIAREPORT, fetchMediaReport);
}

export function* fetchCampaignsByCreatorId({ type, params }: any) {
  try {
    const res = yield call(withLoading, getCampaignsByCreator, type, {
      ...params,
      timeZone: getCurrentTimeZone(),
    });
    yield put(actions.setCampaignsByCreatorId(res.campaigns));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchCampaignsByCreatorId() {
  yield takeLatest(types.GET_CAMPAIGNSBYCREATORID, fetchCampaignsByCreatorId);
}

export function* deleteCampaigns({ type, params }: any) {
  try {
    yield put(actions.updateDeleingCampaignIds({ id: params.data._id, type: 'add' }));
    if (params.enableFlaskApi) {
      const funcs = params.data.postIds.map((postId: any) => [
        api.deleteCampaignsByFlaskApi,
        { channel: params.channel, _id: params.data._id, postId },
      ]);
      yield call(withLoading, funcs, type);
    } else {
      yield call(api.deleteCampaigns, omit(params.data, 'postIds'));
    }
    yield put(
      actions.removeCampaignsAndTags({
        data: params.data,
        key: 'campaigns',
        detailField: params.detailField,
        entityField: params.entityField,
      }),
    );
    yield put(actions.updateDeleingCampaignIds({ id: params.data._id, type: 'remove' }));
    yield call(message.success, 'Successfully removed campaign assignment');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchDeteleCampaigns() {
  yield takeEvery(types.DELETE_CAMPAIGNS, deleteCampaigns);
}

function getAdminTagByStory(tags: any[], posts: any[]) {
  const groupByTagId = groupBy(tags, 'ContentTagId');
  return Object.entries(groupByTagId).map(([, tagWithPostIds]) => {
    const PostIds = (tagWithPostIds as any[]).map((i) => i.PostId);
    const StoryIds = uniq(
      PostIds.map((i) => posts.find((post) => (post.id || post.postId) === i).storyId),
    );
    return {
      ...pick(tagWithPostIds[0], ['name', 'colorSuffix', 'ContentTagId']),
      StoryIds,
      PostIds,
    };
  });
}

export function* searchTagsByPost({ type, params, storyLevel }: any) {
  try {
    const funcs = params.postIds.map((post: any) => [
      byPostAndChannel,
      { agencyId: params.agencyId, postId: post.id, channel: post.channelCode },
    ]);
    const res = yield call(withLoading, funcs, type);
    let tags = flatten(res);
    if (storyLevel) {
      tags = getAdminTagByStory(tags, params.postIds);
    }
    yield put(actions.setAdminSearchTags(tags));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchSearchTagsByPost() {
  yield takeLatest(types.SEARCH_ADMIN_TAGS_BY_POST, searchTagsByPost);
}

export function* searchContentTags({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, searchTags, type, params);
    yield put(actions.setSearchedTags(res));
    if (callback) {
      yield call(callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchSearchContentTags() {
  yield takeLatest(types.SEARCH_TAGS, searchContentTags);
}

export function* postCampaigns({ type, params, storyLevel }: any) {
  try {
    let funcs;
    if (params.enableFlaskApi) {
      funcs = params.data.map((param: any) => [
        api.postCampaignsByFlaskApi,
        { ...param, channel: params.channel },
      ]);
    } else {
      funcs = params.data.map((param: any) => [
        api.postCampaigns,
        { ...omit(param, 'campaignName'), storyLevel },
      ]);
    }
    const res = yield call(withLoading, funcs, type);
    const paramsWithRes = params.data.map((param: any, index: any) =>
      storyLevel
        ? {
            ...param,
            CampaignPostIds: res[index].map((i) => i._id),
          }
        : {
            ...param,
            CampaignPost: res[index],
          },
    );
    yield put(
      actions.addCampaignsTags({
        data: paramsWithRes,
        key: 'campaign',
        detailField: params.detailField,
        entityField: params.entityField,
      }),
    );
    yield call(message.success, 'Successfully created campaign assignment');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchPostCampaigns() {
  yield takeLatest(types.POST_CAMPAIGNS, postCampaigns);
}

export function* createContentTag({ type, params, callback }: any) {
  try {
    const createdTag = yield call(withLoading, createContentTags, type, params.create);
    const addParams = params.add.data.map((param: any) => ({
      ...param,
      contentTagId: createdTag._id,
      ContentTagId: createdTag._id,
      name: createdTag.name,
      colorSuffix: createdTag.colorSuffix,
      tagInfo: {
        name: createdTag.name,
        ContentTagId: createdTag._id,
        colorSuffix: createdTag.colorSuffix,
      },
    }));
    yield put(actions.postTags({ ...params.add, data: addParams }));
    if (callback) {
      yield call(callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchCreateContentTag() {
  yield takeLatest(types.CREATE_CONTENT_TAG, createContentTag);
}

export function* postTags({ type, params }: any) {
  try {
    const funcs = params.data.map((param: any) => [tagPost, param]);
    const res = yield call(withLoading, funcs, type);
    if (params.isAdminTags) {
      if (params.storyLevel) {
        const tag = getAdminTagByStory(res, params.data)[0];
        const adminTags = yield select(selectors.adminTagsSelector);
        const adminTag = adminTags.find((i) => i.ContentTagId === tag.ContentTagId);
        if (adminTag) {
          const PostIds = [...adminTag.PostIds, ...tag.PostIds];
          const StoryIds = [...adminTag.StoryIds, ...tag.StoryIds];
          yield put(
            actions.updateAdminTag({
              PostIds,
              StoryIds,
              ContentTagId: adminTag.ContentTagId,
            }),
          );
        } else {
          yield put(actions.addAdminTags([tag]));
        }
      } else {
        yield put(actions.addAdminTags(res));
      }
    } else {
      let transedRes;
      if (params.storyLevel) {
        const groupByStoryId = groupBy(params.data, 'storyId');
        transedRes = Object.entries(groupByStoryId).map(([storyId, tags]) => ({
          postId: +storyId,
          ...tags[0].tagInfo,
          PostIds: tags.map((i) => i.postId),
        }));
      } else {
        transedRes = res.map((result: any, index: any) => {
          const id = get(params, `data.${index}.postId`);
          const tagInfo = get(params, `data.${index}.tagInfo`);

          return {
            postId: id,
            ...tagInfo,
            ...result,
            ContentTagPost: { ...result, _id: result.TagId },
          };
        });
      }
      yield put(
        actions.addCampaignsTags({
          data: transedRes,
          key: 'tag',
          detailField: params.detailField,
          entityField: params.entityField,
        }),
      );
    }
    yield call(message.success, 'Successfully created tag assignment');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchPostTags() {
  yield takeLatest(types.POST_TAGS, postTags);
}

export function* unPostTags({ type, params }: any) {
  try {
    yield put(actions.updateDeleingTagIds({ id: params.data._id, type: 'add' }));
    const funcs = params.data.postIds.map((postId: any) => [
      untagPost,
      {
        contentTagId: params.data._id,
        postId,
        channelId: params.data.channelId,
      },
    ]);
    yield call(withLoading, funcs, type);
    if (params.isAdminTags) {
      yield put(actions.removeAdminTags(params.data));
    } else {
      yield put(
        actions.removeCampaignsAndTags({
          data: {
            postIds: params.storyLevel ? params.data.storyIds : params.data.postIds,
            _id: params.data._id,
          },
          key: 'tags',
          detailField: params.detailField,
          entityField: params.entityField,
        }),
      );
    }
    yield put(actions.updateDeleingTagIds({ id: params.data._id, type: 'remove' }));
    yield call(message.success, 'Successfully removed tag assignment');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchUnPostTags() {
  yield takeEvery(types.DELETE_TAGS, unPostTags);
}

export function* updateStoryTitle({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.updateStoryTitle, type, params);
    yield put(
      actions.setStoryTitle({ ...res, mediaDetailId: params.detailId, media: params.media }),
    );
    if (callback) {
      yield call(callback);
    }
    yield call(message.success, 'Saved Story Name');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchUpdateStoryTitle() {
  yield takeLatest(types.UPDATE_STORY_TITLE, updateStoryTitle);
}

export function* getMediaReportDetails({ type, params }: any) {
  try {
    const { mediaType } = params;
    let detailApi = api[`get${mediaType}Details` as ApiType];
    const detailParams = omit(params, ['mediaType']);
    if (apiEnums.analytics) {
      detailApi = api.getMediaReportDetails;
      detailParams.channel = get(mediaReportsAvailable, `${lowerFirst(mediaType)}.channel`);
    }
    const res = yield call(withLoading, detailApi, type, detailParams);

    const reportDetailAdapter = (res2: any) => {
      if (mediaType !== 'Youtube') return res2;
      const rawRetention = get(res2, 'retention.0');
      if (isEmpty(rawRetention)) return res2;
      /* data corrector for Youtube retention */
      const retentionPathReg = /b(\d+)/;
      const retention = Object.keys(rawRetention).reduce((prev: any, key) => {
        const value = rawRetention[key];
        const matchedResult = retentionPathReg.exec(key);
        if (matchedResult) {
          if (value > 99.99 /* dirty data */) {
            const leftKey = +matchedResult[1] - 1;
            const rightKey = +matchedResult[1] + 1;
            if (leftKey > 0) {
              const leftKeyStr = `${leftKey}`.padStart(3, '0');
              if (rawRetention[`b${leftKeyStr}`] <= 99.99) {
                prev[key] = rawRetention[`b${leftKeyStr}`];
              }
            } else if (rightKey < 99 && rawRetention[`b${rightKey}`] <= 99.99) {
              const rightKeyStr = `${rightKey}`.padStart(3, '0');
              if (rawRetention[`b${rightKeyStr}`] <= 99.99) {
                prev[key] = rawRetention[`b${rightKeyStr}`];
              }
            } else {
              prev[key] = 0;
            }
          } else {
            prev[key] = value;
          }
        } else {
          prev[key] = value;
        }
        return prev;
      }, {});
      res2.retention[0] = retention;
      return res2;
    };

    const payload = {
      ...reportDetailAdapter(res),
      mediaDetailId: params.detailId,
      media: mediaType.toLowerCase(),
    };

    yield put(actions.setMediaDetail(payload));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchGetMediaReportDetails() {
  yield takeLatest(types.GET_MEDIA_DETAIL, getMediaReportDetails);
}

export function* fetchFacebookVideoInsights({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.getFacebookVideoInsights, type, params);
    yield put(actions.setFacebookVideoInsights(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchFacebookVideoInsights() {
  yield takeLatest(types.GET_FACEBOOK_VIDEO_INSIGHTS, fetchFacebookVideoInsights);
}

export function* fetchStoryListByCreatorChannel({ params }: any) {
  try {
    const res = yield call(api.getStoryListByCreatorChannel, params);
    yield put(actions.setStoryList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchStoryListByCreatorChannel() {
  yield takeLatest(types.GET_STORY_LIST, fetchStoryListByCreatorChannel);
}

export function* updateFrame({ type, params, callback }: any) {
  try {
    yield call(withLoading, api.updateFrame, type, params);
    if (callback) {
      yield call(callback);
    }
    yield call(
      message.success,
      `Successfully saved ${params.media === 'snapchat' ? 'Snapchat' : 'Instagram'} information`,
    );
    const storage = getStoryQueue(`${params.media}.params`);
    if (storage) {
      if (!storage.isCampaigns) {
        yield put(actions.getMediaReport({ ...storage, force: true }));
        // yield put(getCreatorsPosts({ ...storage }));
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(
        message.error,
        <span>
          Failed to save Instagram information
          <br />
          {err.message}
        </span>,
      );
    }
  }
}

function* watchUpdateFrame() {
  yield takeLatest(types.UPDATE_FRAME, updateFrame);
}

export function* updateFrameBatch({ type, params, callback }: any) {
  try {
    yield call(withLoading, api.updateFrameBatch, type, params);
    if (callback) {
      yield call(callback);
    }
    yield call(
      message.success,
      `Successfully saved ${params.media === 'snapchat' ? 'Snapchat' : 'Instagram'} information`,
    );
    const storage = getStoryQueue(`${params.media}.params`);
    if (storage) {
      if (!storage.isCampaigns) {
        yield put(actions.getMediaReport({ ...storage, force: true }));
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(
        message.error,
        <span>
          Failed to save Instagram information
          <br />
          {err.message}
        </span>,
      );
    }
  }
}

function* watchUpdateFrameBatch() {
  yield takeLatest(types.UPDATE_FRAME_BATCH, updateFrameBatch);
}

export function* addStory({ type, params, successCallback, failedCallback }: any) {
  try {
    const detailApi = api[`add${params.mediaType}Story` as ApiType];
    const res = yield call(withLoading, detailApi, type, omit(params, ['mediaType']));

    // For tiktok upload api
    if ('success' in res && res.success === false) {
      if (failedCallback) {
        yield call(failedCallback, res);
      }
      return;
    }

    if (successCallback) {
      yield call(successCallback, res);
    }
    const storage = getStoryQueue(`${params.mediaType.toLowerCase()}.params`);
    if (storage) {
      yield put(actions.getMediaReport({ ...storage, force: true }));
    }
  } catch (err: any) {
    if (failedCallback) {
      yield call(failedCallback, err);
    }
    if (err.message) {
      const msg = `There was a problem processing your request, please try again. ${err.message}`;
      yield call(message.error, msg);
    }
  }
}

function* watchAddStory() {
  yield takeLatest(types.ADD_STORY, addStory);
}

export function* fetchCreatorChannelId({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.getCreatorChannelslId, type, params);
    if (get(res, '_id')) {
      yield put(actions.setCreatorChannelId(res));
    } else {
      yield call(message.error, 'No permissions');
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchCreatorChannelId() {
  yield takeLatest(types.GET_CREATOR_CHANNEL_ID, fetchCreatorChannelId);
}

export function* getSnapStory({ type, params }: any) {
  try {
    const res = yield call(withLoading, api.getSnapchatStory, type, params);
    yield put(actions.setSnapStory(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchGetSnapStory() {
  yield takeLatest(types.GET_SNAP_STORY, getSnapStory);
}

function* createVideoSegment({ type, params, callback }: any) {
  try {
    yield call(withLoading, api.createVideoSegment, type, params);
    if (callback) {
      yield call(callback, params);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchCreateVideoSegment() {
  yield takeLatest(types.CREATE_VIDEO_SEGMENT, createVideoSegment);
}

function* updateVideoSegment({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.updateVideoSegment, type, params);
    if (callback) {
      yield call(callback, res, params);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchUpdateVideoSegment() {
  yield takeLatest(types.UPDATE_VIDEO_SEGMENT, updateVideoSegment);
}

function* deleteVideoSegment({ type, params, callback }: any) {
  try {
    const res = yield call(withLoading, api.deleteVideoSegment, type, params);
    if (callback) {
      yield call(callback, res, params);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchDeleteVideoSegment() {
  yield takeLatest(types.DELETE_VIDEO_SEGMENT, deleteVideoSegment);
}

export function* getChannelMappings({ type }: any) {
  try {
    const res = yield call(withLoading, api.getChannelMappings, type);
    yield put(actions.setChannelMappings(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchChannelMappings() {
  yield takeLatest(types.GET_CHANNEL_MAPPINGS, getChannelMappings);
}

export function* getExportData({ type, callback }: any) {
  try {
    const res = yield call(withLoading, api.getExportData, type);
    if (callback) {
      const exportExcelData = yield call(callback, res);
      yield put(
        exportExcel(exportExcelData, (blob: any) => saveAs(blob, exportExcelData.filename)),
      );
    }
  } catch (err: any) {
    if (err.message) {
      return yield call(message.error, err.message);
    }
  }
  return {};
}

function* watchFetchExportData() {
  yield takeLatest(types.GET_EXPORT_DATA, getExportData);
}

export function* deleteStory({ type, params, callback }: any) {
  try {
    yield call(withLoading, api.deleteStory, type, params.story);
    if (callback) {
      yield call(callback);
    }
    yield put(actions.getMediaReport({ ...params.refresh, force: true }));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchDeleteStory() {
  yield takeLatest(types.DELETE_STORY, deleteStory);
}

export function* fetchAgencies() {
  try {
    const res = yield call(api.getAgencies);
    const sortedRes = sortListByAlpha(res, 'name');
    yield put(actions.setAgencies(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAgencies() {
  yield takeLatest(types.GET_AGENCIES, fetchAgencies);
}

export default [
  watchFetchOverview,
  watchFetchNameList,
  watchFetchCreatorInfo,
  watchMediaReport,
  watchFetchCampaignsByCreatorId,
  watchDeteleCampaigns,
  watchSearchContentTags,
  watchPostCampaigns,
  watchPostTags,
  watchUnPostTags,
  watchUpdateStoryTitle,
  watchGetMediaReportDetails,
  watchFetchFacebookVideoInsights,
  watchFetchStoryListByCreatorChannel,
  watchUpdateFrame,
  watchUpdateFrameBatch,
  watchAddStory,
  watchFetchCreatorChannelId,
  watchGetSnapStory,
  watchUpdateVideoSegment,
  watchCreateVideoSegment,
  watchDeleteVideoSegment,
  watchFetchChannelMappings,
  watchFetchExportData,
  watchDeleteStory,
  watchCreateContentTag,
  watchFetcOlapBenchmarks,
  watchSearchTagsByPost,
  watchFetchAgencies,
];
