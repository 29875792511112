import { QueryClient } from 'react-query';
import queryString from 'query-string';
import { message } from 'antd';
import { fetcher } from './fetcher';
import { QueryError } from './error';

export const GlobalQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60,
      retry: false,
      refetchOnWindowFocus: false,
      queryFn: ({ queryKey, meta }) => {
        const [key, query] = queryKey;
        const fetchOptions = meta?.fetch as Record<string, unknown>;

        if (typeof key !== 'string') {
          throw new Error('Default query need simple query key');
        }

        if (query && typeof query === 'object') {
          const url = queryString.stringifyUrl(
            {
              url: key,
              query: Object.entries(query).reduce<queryString.StringifiableRecord>(
                (prev, [k, v]) => ({
                  ...prev,
                  [k]:
                    Object.prototype.toString.call(v) === '[object Object]' ? JSON.stringify(v) : v,
                }),
                {},
              ),
            },
            {
              arrayFormat: 'bracket',
            },
          );

          return fetcher(url, fetchOptions);
        }

        return fetcher(key, fetchOptions);
      },
      onError: (err) => {
        message.error((err as QueryError).message);
      },
    },
    mutations: {
      onError: (err) => {
        message.error((err as QueryError).message);
      },
    },
  },
});
