import moment from 'moment-timezone';
// eslint-disable-next-line you-dont-need-lodash-underscore/each, you-dont-need-lodash-underscore/get, you-dont-need-lodash-underscore/size
import { isEmpty, sortBy, size, remove, set, get, each } from 'lodash';
import * as numeral from 'numeral';
import { getTQueryData } from '@/query';
import { getCurrentTimeZone } from './time';

export const inSingleApp = () => !!window.__POWERED_BY_QIANKUN__;

export const isProductionEnv = () => ['app', 'app-demo'].includes(process.env.REACT_APP_RUN_ENV!);

export const milliFormat = (num: any, defaultValue: number | string = 0) => {
  if (num > 1) {
    const numStr = num.toString();
    return numStr.replace(/(?=(?!^)(\d{3})+$)/g, ',');
  }
  return num !== undefined && num !== null ? num.toString() : defaultValue;
};

export const normalizeDecimals = (num: any) => {
  if (typeof num === 'number') {
    const exs = num.toExponential().split('e')[1];
    const plusMinus = exs[0];
    const validNum = +exs[1];

    let normalize;

    if (plusMinus === '-') {
      // 0.03264 = 0.033
      normalize = Math.round(num * 10 ** (validNum + 1)) / 10 ** (validNum + 1);
    } else if (validNum === 0) {
      // 1.445 = 1.45
      normalize = Math.round(num * 100) / 100;
    } else if (validNum === 1) {
      // 10.45 = 10.5
      normalize = Math.round(num * 10) / 10;
    } else {
      // 100.45 = 100
      normalize = Math.round(num);
    }

    return normalize;
  }
  return num;
};

export const percentFormt = (num: any) => `${(num * 100).toFixed(1)}%`;

/**
 * @param {*} notExp don't use 'exponent-type'
 */
export const unitNumFormatter = (num: any, digits: any = 1, notExp: any = false) => {
  const si = [
    /* \xa0 is the placeholder to keep the same style with other letter symbols which is friendly for list or table cases */
    { value: 1, symbol: '  ' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];
  // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }

  const prefix = num < 0 ? '-' : '';

  if (!notExp) {
    const divide = Math.abs(num) / si[i].value;
    // return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
    return `${prefix}${(Math.round(`${divide}e${digits}` as any) / 10 ** digits).toFixed(digits)}${
      si[i].symbol
    }`;
  }

  const val = Math.round(`${Math.abs(num)}e${digits}` as any) / 10 ** digits;
  return `${prefix}${val.toFixed(digits)}`;
};

export function formatElementId(str: any) {
  return str.replace(/[^A-Z0-9]/gi, '');
}

export function uppercaseFirst(string: any = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getQueryParameters(str?: string) {
  const query = {} as any;
  const search = (str || window.location.search).slice(1);
  search.split('&').forEach((item: any) => {
    const sItem = item.split('=');
    query[sItem[0]] = decodeURIComponent(sItem[1]);
  });
  return query;
}

export function getAgencyShortname(name: any) {
  return name ? name.replace(/\s|’/g, '').toLowerCase() : '';
}

function pick(str: any, min: any, max?: any) {
  let n;
  let chars = '';

  if (max === undefined) {
    n = min;
  } else {
    n = min + Math.floor(Math.random() * (max - min + 1));
  }

  for (let i = 0; i < n; i++) {
    chars += str.charAt(Math.floor(Math.random() * str.length));
  }

  return chars;
}

function shuffle(str: any) {
  const array = str.split('');
  let tmp;
  let current;
  let top = array.length;

  if (top) {
    top -= 1;
    while (top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
      top -= 1;
    }
  }

  return array.join('');
}

export function generateHash(addSpecials = true) {
  const specials = addSpecials ? '!@#$%^&*()_-+{}:<>?|[]`~' : '';
  const lowercase = 'abcdefghijkmnopqrstuvwxyz'; // without l
  const uppercase = 'ABCDEFGHJKLMNOPQRSTUVWXYZ'; // without I
  const numbers = '023456789'; // without 1

  const all = lowercase + uppercase + numbers + specials;

  let hash = '';
  hash += pick(lowercase, 1);
  hash += pick(uppercase, 1);
  hash += pick(all, 10, 20);
  hash = shuffle(hash);
  return hash;
}

/**
 * Does current user have permission to do something?
 *
 * @param ability {boolean, string or array}
 * @param type
 * @returns {boolean}
 */
export function can(ability: boolean | string | string[], type?: string) {
  if (typeof ability === 'boolean') {
    return ability;
  }

  const userInfo = getTQueryData('/users/me');

  if (!userInfo?.abilities?.length) {
    return false;
  }

  const { abilities } = userInfo;

  if (typeof ability === 'string') {
    return abilities.includes(ability);
  }

  if (Array.isArray(ability)) {
    if (type === 'and') {
      return ability.every((it) => abilities.includes(it));
    }

    return ability.some((it) => abilities.includes(it));
  }

  return false;
}

export function setStoryQueue(keys: any, values: any) {
  try {
    const keyPaths = typeof keys === 'string' ? [keys] : keys;
    const mapValues = Array.isArray(values) ? values : [values];

    const name = keyPaths[0].split('.')[0];

    const sessionStore = JSON.parse(window.sessionStorage.getItem(`sq-${name}`)!);

    const latestStore = keyPaths.reduce((store: any, keyPath: any, index: any) => {
      const paths = keyPath.split('.');
      if (paths.length > 1) {
        return set({ ...store }, paths.slice(1), mapValues[index]);
      }
      return mapValues[index];
    }, sessionStore || {});

    window.sessionStorage.setItem(`sq-${name}`, JSON.stringify(latestStore || {}));
  } catch (e) {
    // do nothing
  }
}

export function getStoryQueue(keys: any, defaultValue?: any) {
  try {
    const path = keys.split('.');
    if (path.length > 1) {
      const sessionStore = JSON.parse(window.sessionStorage.getItem(`sq-${path[0]}`) || '{}');
      return get(sessionStore, path.slice(1), defaultValue);
    }
    const storage = window.sessionStorage.getItem(`sq-${path[0]}`);
    if (storage) {
      return JSON.parse(storage);
    }
    return defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

// checks for whether or not the object has no keys, or an error key, which indicates the data is not available
export function checkForError(obj: any) {
  return obj === undefined ||
    obj === null ||
    obj.error ||
    (['string', 'object'].includes(typeof obj) && size(obj) === 0)
    ? false
    : obj;
}

export function asciiSorter(a: string, b: string) {
  if (a !== undefined && a !== null && b !== undefined && b !== null) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  return (!!a as any) - (!!b as any);
}

export function asciiSorterByLetter(a = '', b = '') {
  const sA = (a || '').trim().toLowerCase();
  const sB = (b || '').trim().toLowerCase();

  return asciiSorter(sA, sB);
}

export function alphaSorter(a = '', b = '') {
  const vA = a !== null ? a.toLowerCase() : '';
  const vB = b !== null ? b.toLowerCase() : '';

  return asciiSorter(vA, vB);
}

/**
 * sort list with field value. upperCase and smallCase will be ignore on ignoreCase being true
 * @param {*} ignoreCase
 */
export function sortListByAlpha(list: any[], field: any, ignoreCase: any = true) {
  if (!Array.isArray(list) || isEmpty(list) || !field) return list;

  if (!ignoreCase) return sortBy(list, field);

  list = list.filter(Boolean);

  return sortBy(list, (item) =>
    typeof item[field] === 'string' ? item[field].toLowerCase() : item[field],
  );
}

export function upRound(num: any, inteval = 50) {
  return Math.ceil(num / inteval) * inteval;
}

export function secondsToHms(d: any) {
  return (numeral as any)(Math.round(d)).format('00:00:00');
}

export function HmsToSeconds(time: any) {
  const parts = time.split(':');
  const timeFormat = /^([0-9]{1,2}):([0-9]{2}):([0-9]{2})$/;
  if (parts.length !== 3 || !time.match(timeFormat)) {
    return false;
  }
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  const seconds = parseInt(parts[2], 10);
  const total = seconds + minutes * 60 + hours * 3600;
  return total;
}

export function ConvertMs(unit: any, data: any, props?: any, deep?: any) {
  const convert = (tagData: any) => {
    each(tagData, (obj) => {
      let newVal;
      each(props, (prop) => {
        const value = obj[prop];
        switch (unit) {
          case 'seconds':
            newVal = value / 1000;
            break;
          case 'minutes':
            newVal = value / 1000 / 60;
            break;
          default:
            newVal = value;
            break;
        }
        obj[prop] = newVal ? Math.round(newVal) : null;
      });
    });
  };
  if (deep) {
    each(data, (tag) => {
      convert(tag);
    });
  } else {
    convert(data);
  }
  return data;
}

/**
 * Request header option for new API
 * @param enableNewAPI {boolean}
 * @returns { baseURL: string } | null
 */
export function optionNewAPI(enableNewAPI: any) {
  const service = '/api';
  return enableNewAPI
    ? {
        baseURL: `${process.env.REACT_APP_API_HOST_PYTHON}/v1${service}`,
      }
    : (null as any);
}

/**
 * Request header option for uaa API
 * @returns { baseURL: string }
 */
export function optionUaaAPI() {
  const service = '/api';
  return {
    baseURL: `${process.env.REACT_APP_UAA_API}/v1${service}`,
  };
}

/**
 * Request header option for Backend API service (Agency Credential API)
 * @returns { baseURL: string }
 */
export function optionCredentialAPI() {
  return {
    baseURL: `${process.env.REACT_APP_CREDENTIAL_API}/v1`,
  };
}

/**
 * convert the lead letter to upper case with keeping others the same
 * @param {string} str
 * @returns {string}
 */
export function capitalize(str: any) {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function subtractString(lg: any, sm: any) {
  const strLg = `${lg}`;
  const strSm = `${sm}`;
  const index = strLg.search(strSm);

  if (index === -1) return lg;

  return strLg.substr(index + strSm.length + 1);
}

/**
 * @param {*} params
 * {
 *   id,
 *   channel,
 *   viewType,
 *   'dateBegin',
 *   'dateEnd',
 *   'compareDateBegin',
 *   'compareDateEnd'
 * }
 */
export function getOlapBenchmarksApi(params: any = {}, useFlaskApi = true) {
  const { id, channel, viewType = 'public', ...others } = params;
  const controller = 'getOlapBenchmarks';
  let media;
  let url;
  let search: any;
  let additionalParam;

  others.timeZone = others.timeZone || getCurrentTimeZone();

  /* delmondo-node api */
  if (!useFlaskApi) {
    switch (channel) {
      case 'facebook':
        media = 'facebook/';
        additionalParam = viewType;
        break;
      case 'twitch':
        media = 'twitch/';
        additionalParam = viewType;
        break;
      case 'fblive':
        media = 'facebook';
        additionalParam = 'live';
        break;
      case 'instagram':
        media = 'instagrams/';
        additionalParam = false;
        break;
      case 'instagramstories':
        media = 'instagrams/';
        additionalParam = true;
        break;
      case 'snapchat':
        media = 'snapchat/';
        additionalParam = '';
        break;
      case 'snapchatDiscover':
        media = 'snapchatDiscover/';
        additionalParam = '';
        break;
      case 'twitter':
        media = 'tweets/';
        additionalParam = '';
        break;
      case 'youtube':
        media = 'youtube/';
        additionalParam = viewType;
        break;
      case 'facebookad':
        media = 'FacebookAd';
        break;
      case 'instagramad':
        media = 'InstagramAd';
        break;
      default:
      //
    }

    search = {
      dateBegin: others.dateBegin,
      dateEnd: others.dateEnd,
      compareDateBegin: others.compareDateBegin,
      compareDateEnd: others.compareDateEnd,
      timeZone: others.timeZone,
    };

    url =
      media === 'overview'
        ? `creators/analytics/${id}/${controller}`
        : `${media}${controller}/${id}/${additionalParam}`;
  } else {
    /* flask api adapter */
    if (params.channel === undefined || channel === 'overview') {
      media = 'ALL';
    } else {
      switch (channel) {
        case 'facebook':
          media = 'Facebook';
          break;
        case 'twitch':
          media = 'Twitch';
          break;
        case 'fblive':
          media = 'Facebook Live';
          break;
        case 'instagram':
          media = 'Instagram';
          break;
        case 'instagramstories':
          media = 'Instagram Stories';
          break;
        case 'snapchat':
          media = 'Snapchat';
          break;
        case 'snapchatDiscover':
          media = 'Snapchat Discover';
          break;
        case 'twitter':
          media = 'Twitter';
          break;
        case 'youtube':
          media = 'YouTube';
          break;
        case 'tiktok':
          media = 'TikTok';
          break;
        case 'facebookad':
          media = 'FacebookAd';
          break;
        case 'instagramad':
          media = 'InstagramAd';
          break;
        default:
        //
      }
    }

    search = {
      startDate: others.compareDateBegin,
      endDate: others.compareDateEnd,
      timeZone: others.timeZone,
    } as any;

    if (search && media) {
      search.channel = media;
    }

    search.byAllDates = params.viewType === 'all' || params.fbViewType === 'all' ? 'True' : 'False';

    url = `creators/analytics/${id}/benchmarks`;
  }

  return { url, search };
}

/**
 * @param {*} params
 * {
 *   id,
 *   channel,
 *   viewType,
 *   'dateBegin',
 *   'dateEnd',
 *   'compareDateBegin',
 *   'compareDateEnd'
 * }
 */
export function getOlapBenchmarksApiForCampaign(params: any = {}, useFlaskApi = true) {
  const { id, channel, ...others } = params as any;
  let media;
  let url;
  let search;

  others.timeZone = others.timeZone || getCurrentTimeZone();

  if (useFlaskApi) {
    /* ['ALL', 'Facebook', 'Instagram', 'Instagram Stories', 'Snapchat', 'Snapchat Discover', 'Twitter', 'YouTube'] */
    if (params.channel === undefined || params.channel === 'overview') {
      media = 'ALL';
    } else {
      switch (channel) {
        case 'facebook':
          media = 'Facebook';
          break;
        case 'twitch':
          media = 'Twitch';
          break;
        case 'fblive':
          media = 'Facebook Live';
          break;
        case 'instagram':
          media = 'Instagram';
          break;
        case 'instagramstories':
          media = 'Instagram Stories';
          break;
        case 'snapchat':
          media = 'Snapchat';
          break;
        case 'snapchatDiscover':
          media = 'Snapchat Discover';
          break;
        case 'twitter':
          media = 'Twitter';
          break;
        case 'youtube':
          media = 'YouTube';
          break;
        case 'tiktok':
          media = 'TikTok';
          break;
        case 'facebookad':
          media = 'FacebookAd';
          break;
        case 'instagramad':
          media = 'InstagramAd';
          break;
        default:
        //
      }
    }

    search = {
      startDate: others.compareDateBegin,
      endDate: others.compareDateEnd,
      timeZone: others.timeZone,
    } as any;

    if (search && media) {
      search.channel = media;
      if (media === 'ALL') {
        search.paidFilter = params.paidFilter;
      }
    }

    url = `campaigns/${id}/benchmarks`;
  } else {
    switch (channel) {
      case 'facebook':
        media = 'Facebook';
        break;
      case 'twitch':
        media = 'Twitch';
        break;
      case 'fblive':
        media = 'FacebookLive';
        break;
      case 'instagram':
        media = 'Instagram';
        break;
      case 'instagramstories':
        media = 'InstagramStory';
        break;
      case 'snapchat':
        media = 'Snapchat';
        break;
      case 'snapchatDiscover':
        media = 'SnapchatDiscover';
        break;
      case 'twitter':
        media = 'Twitter';
        break;
      case 'youtube':
        media = 'YouTube';
        break;
      case 'tiktok':
        media = 'TikTok';
        break;
      case 'facebookad':
        media = 'FacebookAd';
        break;
      case 'instagramad':
        media = 'InstagramAd';
        break;
      default:
      //
    }

    search = {
      dateBegin: others.dateBegin,
      dateEnd: others.dateEnd,
      compareDateBegin: others.compareDateBegin,
      compareDateEnd: others.compareDateEnd,
      timeZone: others.timeZone,
    };

    if (params.channel === undefined || params.channel === 'overview') {
      search.paidFilter = params.paidFilter;
    }

    if (search && media) {
      search.channel = media;
    }

    url = `campaigns/${id}/getOlapBenchmarks`;
  }
  return { url, search };
}

/**
 * @return Array [
 *   [col width, form ele in filter width], normal column
 *   [col width, form ele in filter width], lg column
 *   [col width, form ele in filter width], sm column
 * ]
 */
export function getColWidth(tableNode: any) {
  if (tableNode) {
    const domStyle = tableNode.getBoundingClientRect();
    const tableWidth = `${domStyle.width || 0}px`;

    const rez = ['', '*0.5', '*1.5'].reduce((prev: any, widthRatio: any) => {
      prev.push([
        `calc(${tableWidth}/8${widthRatio})`,
        `calc(${tableWidth}/8${widthRatio} - 16px)`,
      ]);

      return prev as any;
    }, []);
    return rez;
  }

  const rez = ['', '*0.5', '*1.5'].reduce((prev: any, widthRatio: any) => {
    prev.push([
      `calc((100vw - 222px)/8${widthRatio})`,
      `calc((100vw - 222px)/8${widthRatio} - 16px)`,
    ]);

    return prev;
  }, []);

  return rez;
}

export function stopBubble(e: any) {
  e.preventDefault();
  e.stopPropagation();
}

/**
 * @param text
 * @param prefix must be undefined can has default value ''
 * @param suffix must be undefined can has default value ''
 * @returns {string|string}
 */
export function textWarpper(text = '', prefix = '', suffix = ''): any {
  let warpText = text;
  if (text) {
    warpText = `${prefix}${text}${suffix}`;
  }
  return warpText;
}

export function stableSortBy(arr: any, func: any, order: any): any {
  const temp = sortBy(arr, func);
  if (order === 'desc') {
    return temp.reverse();
  }
  return temp;
}

export function toggle(arr: any[], val: any): any {
  if (!Array.isArray(arr)) return arr;

  if (arr.includes(val)) {
    const rez = [...arr];
    remove(rez, (item) => item === val);
    return rez;
  }

  return [...arr, val];
}

export function parseLocationState(location: any, field: any, toNumber: any): any {
  const locationState = get(location, 'state', {});

  if (!field) return locationState;

  if (Array.isArray(field)) {
    return field.reduce((prev, item) => {
      const val = parseLocationState(location, item, toNumber);
      if (val !== undefined) {
        prev[item] = val;
      }
      return prev;
    }, {});
  }

  const fieldVal = get(locationState, field);

  if (fieldVal === undefined) return fieldVal;
  return toNumber ? +fieldVal : fieldVal;
}

const LARGE_LIST_OFFSET = 1000;
// flag to invoke antd/Select or convivad-design/Select
export const isLargeList = (list: any = []): any => size(list) > LARGE_LIST_OFFSET;

export function queryParams(obj: any = {}): any {
  let query = '';
  const keys = Object.keys(obj);
  if (keys.length) {
    keys
      .filter((key) => obj[key] !== null && obj[key] !== undefined && obj[key] !== '')
      .forEach((key, i) => {
        query += `${i === 0 ? '?' : '&'}${key}=${encodeURIComponent(obj[key])}`;
      });
  }
  return query;
}

// https://stackoverflow.com/questions/4149276/javascript-camelcase-to-regular-form
export const deCamelCase = (str: string): string =>
  str
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, (str2) => str2.toUpperCase());

export const formatDuration = (time: number) => {
  const duration = moment.duration(time, 'seconds');
  const hh = Math.floor(duration.asHours());
  const mm = duration.minutes();
  const ss = duration.seconds();
  const normalize = (hms: number) => (hms < 10 ? `0${hms}` : hms);

  return `${normalize(hh)}:${normalize(mm)}:${normalize(ss)}`;
};
