import moment from 'moment-timezone';
import cookies from '@/utils/cookies';
import { inSingleApp } from '@/utils/helper';
import { useUserInfomation } from '@/query/queries/user';

const { REACT_APP_STORAGE_KEY } = process.env;

export function verify() {
  const accessToken = cookies.get(REACT_APP_STORAGE_KEY!);

  // OKTA SSO
  if (accessToken) {
    return moment(Date.now()).unix() <= accessToken?.expiresAt;
  }

  const token = cookies.get('token');
  const tokenExpiresAt = cookies.get('tokenExpiresAt');

  // Stand Alone and NO SSO
  if (!inSingleApp() && token && tokenExpiresAt) {
    return moment(Date.now()).unix() <= moment(tokenExpiresAt).unix();
  }

  return false;
}

export function clearAuthToken() {
  cookies.remove(REACT_APP_STORAGE_KEY!, { domain: '.conviva.com' });
  cookies.remove('token');
  cookies.remove('tokenExpiresAt');
}

export function useAuthVerify() {
  const verified = verify();
  const userInfo = useUserInfomation({ enabled: verified });

  if (!verified) return false;

  if (userInfo.isLoading) return 'fetching';

  if (userInfo.isSuccess) return userInfo.data;

  return false;
}
