import * as types from './constants';

const initState = {
  activeExports: [],
  expiredExports: [],
  downloads: [],
};

export default function reducers(state: any, action: any) {
  state = state || initState;
  switch (action.type) {
    case types.SET_SCHEDULEDEXPORT: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case types.UPDATE_SCHEDULEDEXPORT: {
      const { type, data } = action.payload;
      if (data) {
        const key = `${type}Exports`;
        const index = state[key].findIndex((item: any) => item._id === data._id);
        if (index !== -1) {
          const newExports = [...state[key].slice(0, index), data, ...state[key].slice(index + 1)];
          return { ...state, [key]: newExports };
        }
      }
      return state;
    }
    case types.SET_DOWNLOADS: {
      return { ...state, downloads: action.payload };
    }
    case types.REMOVE_DOWNLOAD: {
      const { fileUuid } = action.payload;
      const downloads = state.downloads.filter((d: any) => d.fileUuid !== fileUuid);
      return { ...state, downloads };
    }
    case types.UPDATE_DONWLOAD: {
      const index = state.downloads.find((d: any) => d._id === action.payload._id);
      if (index !== -1) {
        const downloads = [
          ...state.downloads.slice(0, index),
          state.downloads[index],
          ...state.downloads.slice(index + 1),
        ];
        return { ...state, downloads };
      }
      return state;
    }
    default:
      return state;
  }
}
