export const SCOPENAME = 'module:export';

export const GENERATE_PDF = `${SCOPENAME}/GENERATE_PDF`;
export const START_GET_PDF_STATUS = `${SCOPENAME}/START_GET_PDF_STATUS`;
export const CANCEL_GET_PDF_STATUS = `${SCOPENAME}/CANCEL_GET_PDF_STATUS`;
export const STOP_EXPORT_PDF = `${SCOPENAME}/STOP_EXPORT_PDF`;

export const DOWNLOAD_FILE = `${SCOPENAME}/DOWNLOAD_FILE`;
export const DOWNLOAD_PDF_FILE = `${SCOPENAME}/DOWNLOAD_PDF_FILE`;

export const GENERATE_EXPORT_FILE = `${SCOPENAME}/GENERATE_EXPORT_FILE`;
export const START_GET_FILE_STATUS = `${SCOPENAME}/START_GET_FILE_STATUS`;
export const CANCEL_GET_FILE_STATUS = `${SCOPENAME}/CANCEL_GET_FILE_STATUS`;
export const STOP_EXPORT_FILE = `${SCOPENAME}/STOP_EXPORT_FILE`;

export const GET_SCHEDULED_EXPOERS = `${SCOPENAME}/GET_SCHEDULED_EXPOERS`;
export const SET_SCHEDULED_EXPOERS = `${SCOPENAME}/SET_SCHEDULED_EXPOERS`;
