export const SCOPENAME = 'module:admin';

export const GET_ACCOUNT_LIST = `${SCOPENAME}/GET_ACCOUNT_LIST`;
export const SET_ACCOUNT_LIST = `${SCOPENAME}/SET_ACCOUNT_LIST`;

// country
export const GET_COUNTRIES = `${SCOPENAME}/GET_COUNTRIES`;
export const SET_COUNTRIES = `${SCOPENAME}/SET_COUNTRIES`;
export const SET_COUNTRY_ACTIVE = `${SCOPENAME}/SET_COUNTRY_ACTIVE`;

// studio login
export const GET_STUDIO_LOGINS = `${SCOPENAME}/GET_STUDIO_LOGINS`;
export const SET_STUDIO_LOGINS = `${SCOPENAME}/SET_STUDIO_LOGINS`;

// list management
export const GET_LIST = `${SCOPENAME}/GET_LIST`;
export const SET_LIST = `${SCOPENAME}/SET_LIST`;
export const GET_LISTS = `${SCOPENAME}/GET_LISTS`;
export const SET_LISTS = `${SCOPENAME}/SET_LISTS`;
export const GET_LIST_CREATORS = `${SCOPENAME}/GET_LIST_CREATORS`;
export const SET_LIST_CREATORS = `${SCOPENAME}/SET_LIST_CREATORS`;
export const GET_LIST_AGENCIES = `${SCOPENAME}/GET_LIST_AGENCIES`;
export const SET_LIST_AGENCIES = `${SCOPENAME}/SET_LIST_AGENCIES`;
export const POST_LIST = `${SCOPENAME}/POST_LIST`;

// agencies
export const GET_AGENCIES = `${SCOPENAME}/GET_AGENCIES`;
export const SET_AGENCIES = `${SCOPENAME}/SET_AGENCIES`;
export const POST_AGENCY = `${SCOPENAME}/POST_AGENCY`;
export const GET_IMAGE_LIST = `${SCOPENAME}/GET_IMAGE_LIST`;
export const SET_IMAGE_LIST = `${SCOPENAME}/SET_IMAGE_LIST`;
export const GET_AGENCY = `${SCOPENAME}/GET_AGENCY`;
export const SET_AGENCY = `${SCOPENAME}/SET_AGENCY`;

// genres
export const GET_GENRES = `${SCOPENAME}/GET_GENRES`;
export const SET_GENRES = `${SCOPENAME}/SET_GENRES`;
export const GET_INTERESTS = `${SCOPENAME}/GET_INTERESTS`;
export const SET_INTERESTS = `${SCOPENAME}/SET_INTERESTS`;
export const GET_MUSICGENRES = `${SCOPENAME}/GET_MUSICGENRES`;
export const SET_MUSICGENRES = `${SCOPENAME}/SET_MUSICGENRES`;
export const MODIFY_GENRES = `${SCOPENAME}/MODIFY_GENRES`;
export const REFRESH_GENRES = `${SCOPENAME}/REFRESH_GENRES`;

// credentials
export const GET_CREDENTIALS = `${SCOPENAME}/GET_CREDENTIALS`;
export const SET_CREDENTIALS = `${SCOPENAME}/SET_CREDENTIALS`;
export const UPDATE_CREDENTIAL = `${SCOPENAME}/UPDATE_CREDENTIAL`;

// channleTokens
export const GET_CHANNEL_TOKENS = `${SCOPENAME}/GET_CHANNEL_TOKENS`;
export const SET_CHANNEL_TOKENS = `${SCOPENAME}/SET_CHANNEL_TOKENS`;
export const GET_CREATOR_CHANNELS = `${SCOPENAME}/GET_CREATOR_CHANNELS`;
export const SET_CREATOR_CHANNELS = `${SCOPENAME}/SET_CREATOR_CHANNELS`;
export const GET_INVALID_CHANNEL_TOKENS = `${SCOPENAME}/GET_INVALID_CHANNEL_TOKENS`;
export const SET_INVALID_CHANNEL_TOKENS = `${SCOPENAME}/SET_INVALID_CHANNEL_TOKENS`;
export const NOTIFY_INVALID_TOKEN = `${SCOPENAME}/NOTIFY_INVALID_TOKEN`;

// users
export const GET_USERS = `${SCOPENAME}/GET_USERS`;
export const SET_USERS = `${SCOPENAME}/SET_USERS`;
export const GET_ROLES = `${SCOPENAME}/GET_ROLES`;
export const SET_ROLES = `${SCOPENAME}/SET_ROLES`;
export const POST_USER = `${SCOPENAME}/POST_USER`;
export const NULLIFY_USER_PASSWORD = `${SCOPENAME}/NULLIFY_USER_PASSWORD`;
export const EXPIRE_USER_PASSWORD = `${SCOPENAME}/EXPIRE_USER_PASSWORD`;
export const GET_CHANNEL_TOKENS_BY_USER_ID = `${SCOPENAME}/GET_CHANNEL_TOKENS_BY_USER_ID`;

// scheduledExport
export const GET_SCHEDULED_EXPORTS = `${SCOPENAME}/GET_SCHEDULED_EXPORTS`;
export const SET_SCHEDULED_EXPORTS = `${SCOPENAME}/SET_SCHEDULED_EXPORTS`;
export const CANCEL_SCHEDULED_EXPORTS = `${SCOPENAME}/CANCEL_SCHEDULED_EXPORTS`;
export const EXCUTE_SCHEDULED_EXPORTS = `${SCOPENAME}/EXCUTE_SCHEDULED_EXPORTS`;
export const EDIT_SCHEDULED_EXPORT = `${SCOPENAME}/EDIT_SCHEDULED_EXPORT`;
export const RESET_LAST_SCHEDULED_EXPORT = `${SCOPENAME}/RESET_LAST_SCHEDULED_EXPORT`;

// permission
export const GET_PERMISSIONS = `${SCOPENAME}/GET_PERMISSIONS`;
export const SET_PERMISSIONS = `${SCOPENAME}/SET_PERMISSIONS`;
export const MODIFY_PERMISSION = `${SCOPENAME}/MODIFY_PERMISSION`;
export const GET_USER_GROUP = `${SCOPENAME}/GET_USER_GROUP`;
export const GET_USER_GROUP_MEMBERS = `${SCOPENAME}/GET_USER_GROUP_MEMBERS`;
export const GET_USER_GROUP_PERMISSIONS = `${SCOPENAME}/GET_USER_GROUP_PERMISSIONS`;
export const GET_USER_ROLE_PERMISSIONS = `${SCOPENAME}/GET_USER_ROLE_PERMISSIONS`;
export const DELETE_USER_GROUP_MEMBER = `${SCOPENAME}/DELETE_USER_GROUP_MEMBER`;
export const DELETE_USER_GROUP_PERMISSION = `${SCOPENAME}/DELETE_USER_GROUP_PERMISSION`;
export const DELETE_USER_ROLE_PERMISSION = `${SCOPENAME}/DELETE_USER_ROLE_PERMISSION`;
export const GET_USER_PERMISSIONS = `${SCOPENAME}/GET_USER_PERMISSIONS`;
export const SET_USER_PERMISSIONS = `${SCOPENAME}/SET_USER_PERMISSIONS`;
export const GET_USER_GROUP_MEMBERS_ADDABLE = `${SCOPENAME}/GET_USER_GROUP_MEMBERS_ADDABLE`;
export const SET_USER_GROUP_MEMBERS_ADDABLE = `${SCOPENAME}/SET_USER_GROUP_MEMBERS_ADDABLE`;

// menu
export const GET_MENUS = `${SCOPENAME}/GET_MENUS`;
export const SET_MENUS = `${SCOPENAME}/SET_MENUS`;
export const GET_MENU_ITEM = `${SCOPENAME}/GET_MENU_ITEM`;
export const SET_MENU_ITEM = `${SCOPENAME}/SET_MENU_ITEM`;
export const MODIFY_MENU_ITEM = `${SCOPENAME}/MODIFY_MENU_ITEM`;

export const IMPORT_USER_IN_OKTA = `${SCOPENAME}/IMPORT_USER_IN_OKTA`;
export const SET_USER_OKTA_ID = `${SCOPENAME}/SET_USER_OKTA_ID`;

// facebookLive
export const CHECK_FACEBOOKLIVE = `${SCOPENAME}/CHECK_FACEBOOKLIVE`;
export const SET_FACEBOOKLIVE = `${SCOPENAME}/SET_FACEBOOKLIVE`;
export const LAUNCH_FACEBOOKLIVE = `${SCOPENAME}/LAUNCH_FACEBOOKLIVE`;

// SI API Owner
export const GET_PLAN_LIST = `${SCOPENAME}/GET_PLAN_LIST`;
export const SET_PLAN_LIST = `${SCOPENAME}/SET_PLAN_LIST`;
export const GET_OWNER_LIST = `${SCOPENAME}/GET_OWNER_LIST`;
export const SET_OWNER_LIST = `${SCOPENAME}/SET_OWNER_LIST`;
export const MODIFY_OWNER = `${SCOPENAME}/MODIFY_OWNER`;
export const DELETE_OWNER = `${SCOPENAME}/DELETE_OWNER`;
export const REFRESH_OWNER_LIST = `${SCOPENAME}/REFRESH_OWNER_LIST`;

// SI API Credential
export const GET_CREDENTIAL_LIST = `${SCOPENAME}/GET_CREDENTIAL_LIST`;
export const SET_CREDENTIAL_LIST = `${SCOPENAME}/SET_CREDENTIAL_LIST`;
export const MODIFY_API_CREDENTIAL = `${SCOPENAME}/MODIFY_API_CREDENTIAL`;

// SI API Owner
export const GET_USAGE_LIST = `${SCOPENAME}/GET_USAGE_LIST`;
export const SET_USAGE_LIST = `${SCOPENAME}/SET_USAGE_LIST`;
export const GET_USAGE_DAILY = `${SCOPENAME}/GET_USAGE_DAILY`;
export const SET_USAGE_DAILY = `${SCOPENAME}/SET_USAGE_DAILY`;

// Twitter Pro Media Account
export const GET_TWITTER_PRO_MEDIA_ACCOUNTS = `${SCOPENAME}/GET_TWITTER_PRO_MEDIA_ACCOUNTS`;
export const SET_TWITTER_PRO_MEDIA_ACCOUNTS = `${SCOPENAME}/SET_TWITTER_PRO_MEDIA_ACCOUNTS`;
export const ACTIVE_TWITTER_PRO_MEDIA_ACCOUNT = `${SCOPENAME}/ACTIVE_TWITTER_PRO_MEDIA_ACCOUNT`;
export const DELETE_TWITTER_PRO_MEDIA_ACCOUNT = `${SCOPENAME}/DELETE_TWITTER_PRO_MEDIA_ACCOUNT`;
export const GET_CREATOR_CHANNEL_NAME_LIST = `${SCOPENAME}/GET_CREATOR_CHANNEL_NAME_LIST`;
export const SET_CREATOR_CHANNEL_NAME_LIST = `${SCOPENAME}/SET_CREATOR_CHANNEL_NAME_LIST`;

// Trial User
export const CREATE_TRIAL_USER = `${SCOPENAME}/CREATE_TRIAL_USER`;
export const GET_TRIAL_LIST = `${SCOPENAME}/GET_TRIAL_LIST`;
export const SET_TRIAL_LIST = `${SCOPENAME}/SET_TRIAL_LIST`;
export const RESEND_TRIAL_REQUEST = `${SCOPENAME}/RESEND_TRIAL_REQUEST`;
export const ENABLE_PROD_ACCOUNT = `${SCOPENAME}/ENABLE_PROD_ACCOUNT`;

// Invite
export const GET_REMAINING_INVITES = `${SCOPENAME}/GET_REMAINING_INVITES`;
export const SET_INVITES = `${SCOPENAME}/SET_INVITES`;
export const INVITE_USERS = `${SCOPENAME}/INVITE_USERS`;
export const GET_INVITES_LIST = `${SCOPENAME}/GET_INVITES_LIST`;
export const SET_INVITES_LIST = `${SCOPENAME}/SET_INVITES_LIST`;

// AddOn
export const GET_ADDONS = `${SCOPENAME}/GET_ADDONS`;
export const SET_ADDONS = `${SCOPENAME}/SET_ADDONS`;
export const CREATE_OR_UPDATE_ADDON = `${SCOPENAME}/CREATE_OR_UPDATE_ADDON`;

// Data Collection
export const GET_CIRCULATE_JOBS = `${SCOPENAME}/GET_CIRCULATE_JOBS`;
export const SET_CIRCULATE_JOBS = `${SCOPENAME}/SET_CIRCULATE_JOBS`;
export const GET_CIRCULATE_JOBS_STATUS = `${SCOPENAME}/GET_CIRCULATE_JOBS_STATUS`;
export const SET_CIRCULATE_JOBS_STATUS = `${SCOPENAME}/SET_CIRCULATE_JOBS_STATUS`;

export const GET_CIRCULATES = `${SCOPENAME}/GET_CIRCULATES`;
export const SET_CIRCULATES = `${SCOPENAME}/SET_CIRCULATES`;

export const QUEUE_CIRCULATE_JOB = `${SCOPENAME}/QUEUE_CIRCULATE_JOB`;
export const BATCH_QUEUE_CIRCULATE_JOB = `${SCOPENAME}/BATCH_QUEUE_CIRCULATE_JOB`;
