import {
  useQuery,
  useMutation,
  UseMutationOptions,
  UseQueryOptions,
  MutationFunction,
  UseQueryResult,
} from 'react-query';
import { QueryFilters, Updater } from 'react-query/types/core/utils';
import { SetDataOptions } from 'react-query/types/core';
import { merge } from 'lodash';
import { GlobalQueryClient } from './client';
import { QueryError } from './error';

type ReadonlyQueryKeyResponse = Readonly<QueryKeyResponse>;

type ExtractQueryKey<T extends string | [string, ...unknown[]]> = T extends any[] ? T[0] : T;

interface CreateTQuery {
  <
    Key extends
      | keyof ReadonlyQueryKeyResponse
      | [keyof ReadonlyQueryKeyResponse, Record<string, any>],
  >(
    key: Key,
    options?: UseQueryOptions<ReadonlyQueryKeyResponse[ExtractQueryKey<Key>], QueryError>,
  ): (
    ops?: UseQueryOptions<ReadonlyQueryKeyResponse[ExtractQueryKey<Key>], QueryError>,
  ) => UseQueryResult<ReadonlyQueryKeyResponse[ExtractQueryKey<Key>], QueryError>;
  <
    Key extends
      | keyof ReadonlyQueryKeyResponse
      | [keyof ReadonlyQueryKeyResponse, Record<string, any>],
    KF extends (...p: any[]) => Key = (...p: any[]) => Key,
  >(
    key: KF,
    options?: UseQueryOptions<
      ReadonlyQueryKeyResponse[ExtractQueryKey<ReturnType<KF>>],
      QueryError
    >,
  ): (
    keyParams: Parameters<KF>,
    ops?: UseQueryOptions<ReadonlyQueryKeyResponse[ExtractQueryKey<ReturnType<KF>>], QueryError>,
  ) => UseQueryResult<ReadonlyQueryKeyResponse[ExtractQueryKey<ReturnType<KF>>], QueryError>;
}

export const getTQueryData = <
  Key extends keyof ReadonlyQueryKeyResponse | [keyof ReadonlyQueryKeyResponse, ...any[]],
>(
  key: Key,
  filters?: QueryFilters,
) => GlobalQueryClient.getQueryData<ReadonlyQueryKeyResponse[ExtractQueryKey<Key>]>(key, filters);

export const setTQueryData = <
  Key extends keyof ReadonlyQueryKeyResponse | [keyof ReadonlyQueryKeyResponse, ...any[]],
>(
  key: Key,
  updater: Updater<
    ReadonlyQueryKeyResponse[ExtractQueryKey<Key>] | undefined,
    ReadonlyQueryKeyResponse[ExtractQueryKey<Key>]
  >,
  options?: SetDataOptions,
) =>
  GlobalQueryClient.setQueryData<ReadonlyQueryKeyResponse[ExtractQueryKey<Key>]>(
    key,
    updater,
    options,
  );

export const useTQuery = <
  Key extends
    | keyof ReadonlyQueryKeyResponse
    | [keyof ReadonlyQueryKeyResponse, Record<string, any>],
>(
  key: Key,
  options?: UseQueryOptions<ReadonlyQueryKeyResponse[ExtractQueryKey<Key>], QueryError>,
) => useQuery<ReadonlyQueryKeyResponse[ExtractQueryKey<Key>], QueryError>(key, options);

export const useTMutation = <TData = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, QueryError, TVariables, TContext>,
) => useMutation(mutationFn, options);

export const createTQuery = ((key, options) => {
  if (typeof key === 'function') {
    return (keyParams, ops) => useQuery(key(keyParams), merge(options, ops));
  }

  return (ops) => useQuery(key, merge(options, ops));
}) as CreateTQuery;

export const createTMutation =
  <TData = unknown, TVariables = void, TContext = unknown>(
    mutationFn: MutationFunction<TData, TVariables>,
    defaultOptions?: UseMutationOptions<TData, QueryError, TVariables, TContext>,
  ) =>
  (options?: UseMutationOptions<TData, QueryError, TVariables, TContext>) =>
    useMutation(mutationFn, merge(defaultOptions, options));
