const metricColumns = [
  {
    title: 'Total Posts',
    key: 'totalPosts',
    last: 'posts.all.last',
    current: 'posts.all.current',
    growth: 'posts.all.growthPct',
    numFormat: 0,
  },
  {
    title: 'Total Videos',
    key: 'totalVideos',
    last: 'posts.video.last',
    current: 'posts.video.current',
    growth: 'posts.video.growthPct',
    tooltip: 'Total Video Posts Published in the time period selected',
    numFormat: 0,
  },
  {
    title: 'Video Views*',
    key: 'totalViews',
    last: 'views.last',
    current: 'views.current',
    growth: 'views.growthPct',
    tooltip:
      'Video views in the first 30 days on video posts published in the time period selected on Facebook and YouTube.',
    growthFormat: '0.0',
    hide: ['TWT', 'INST'],
  },
  {
    title: 'Views / Video*',
    key: 'viewsVideos',
    last: 'views.perVideo.last',
    current: 'views.perVideo.current',
    growth: 'views.perVideo.growthPct',
    tooltip:
      'Total Views / Total Videos (In the time period selected for only Facebook and YouTube videos)',
    hide: ['TWT', 'INST'],
    disableTotal: true,
  },
  {
    title: 'Engagement Rate',
    key: 'engagementRate',
    last: 'engagement_rate.all.last',
    current: 'engagement_rate.all.current',
    growth: 'engagement_rate.all.growthPct',
    growthFormat: '0.0',
    numFormat: '0.00%',
    percent: true,
    disableTotal: true,
  },
  {
    title: 'Total Engagements',
    key: 'totalEngagements',
    last: 'engagement.all.last',
    current: 'engagement.all.current',
    growth: 'engagement.all.growthPct',
    numFormat: 0,
  },
  {
    title: 'Engagements / Video',
    key: 'engagementsVideo',
    last: 'engagement.perVideo.last',
    current: 'engagement.perVideo.current',
    growth: 'engagement.perVideo.growthPct',
    disableTotal: true,
  },
  {
    title: 'Engagements / Post',
    key: 'engagementsPost',
    last: 'engagement.perPost.last',
    current: 'engagement.perPost.current',
    growth: 'engagement.perPost.growthPct',
    disableTotal: true,
  },
  {
    title: 'Audience',
    key: 'audience',
    last: 'audience.last',
    current: 'audience.total',
    growth: 'audience.growthPct',
    growthFormat: '0.0',
  },
];

export default metricColumns;
