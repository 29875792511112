import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { pushLocation } from '@/rootStore/router/actions';
import { locationSelector } from '@/rootStore/router/selectors';
import { getMenus } from '~/Auth/store/actions';
import { menusSelector, currentUserSelector } from '~/Auth/store/selectors';
import SideMenu from './index';

const selector = createSelector(
  locationSelector,
  menusSelector,
  currentUserSelector,
  (location, menus, currentUser) => ({
    currentUser,
    pathname: location.pathname,
    menus: menus.map((item: any) => {
      const temp = { ...item };
      if (temp.text === 'Campaigns') {
        temp.route = 'list';
      } else if (temp.text === 'Tag Reports') {
        temp.route = 'list';
      }
      temp.key = temp.text;
      temp.name = temp.text;
      return temp;
    }),
  }),
);

export default connect(selector, {
  getMenus,
  pushLocation,
})(SideMenu as any) as any;
