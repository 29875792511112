import React, { PureComponent } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import { localStorage } from '@/utils/DataPersistence';
import { getTQueryData } from '@/query';

const PERIOD_GROUP_CONFIG = {
  default: false,
  analytics: false,
  campaign: false,
  leaderboard: false,
  dashboard: false,
  campaignList: true,
};

// use {agency}_{userId}_periodGroup as the primary_key for the periodGroup config in localstorage
// to keep the config being segregated by accounts
const getPeriodGroupNamespace = () => {
  const userInfo = getTQueryData('/users/me')!;

  return `${userInfo.agencies[0]._id}_${userInfo._id}_periodGroup`;
};

export const getPeriodGroupConfig = () => {
  const accoutNamespace = getPeriodGroupNamespace();
  const LocalStorage = localStorage(accoutNamespace);

  const data = LocalStorage.get('currentToggle');
  const error = data?.[0];
  let currentToggle = data?.[1];

  const hasRequiredKeys =
    isPlainObject(currentToggle) &&
    ['analytics', 'campaign', 'campaignList', 'leaderboard', 'dashboard'].every(
      (key) => key in currentToggle,
    );

  if (!error && !hasRequiredKeys) {
    currentToggle = { ...PERIOD_GROUP_CONFIG };
    LocalStorage.put('currentToggle', currentToggle);
  }

  return { periodGroupConfig: { currentToggle }, LocalStorage };
};

interface LocalStorageHocInnerProps {}
interface LocalStorageHocInnerState {
  LocalStorage: any;
  periodGroupConfig: any;
}

export default function LocalStorageHoc(WrappedComponent: any) {
  return class WithLocalStorage extends PureComponent<
    LocalStorageHocInnerProps,
    LocalStorageHocInnerState
  > {
    UNSAFE_componentWillMount() {
      const { periodGroupConfig, LocalStorage } = getPeriodGroupConfig();

      this.setState({ periodGroupConfig, LocalStorage });
    }

    updateCurrentToggle = (key: any, value: any) => {
      const { LocalStorage, periodGroupConfig } = this.state;
      const { currentToggle } = periodGroupConfig;

      LocalStorage.put('currentToggle', { ...currentToggle, [key]: value });
    };

    render() {
      const { periodGroupConfig, LocalStorage } = this.state;
      return (
        <WrappedComponent
          periodGroupConfig={periodGroupConfig}
          LocalStorage={LocalStorage}
          updateCurrentToggle={this.updateCurrentToggle}
          {...this.props}
        />
      );
    }
  };
}
