import { call, put, delay, takeLatest, select, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { push } from 'connected-react-router';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import withLoading from '@/sagaMiddleware';
import * as api from '@/service/Admin';
import { pushLocation } from '@/rootStore/router/actions';
import { serializeDate } from '@/utils/time';
import { BatchJobs } from '@/utils/constant';
import * as actions from './actions';
import * as types from './constants';
import * as selectors from './selectors';

export function* fetchCountries(action: any) {
  try {
    const res = yield call(withLoading, api.getCountries, action.type, action.payload);
    yield put(actions.setCountries(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCountries() {
  yield takeLatest(types.GET_COUNTRIES, fetchCountries);
}

export function* setCountryActive(action: any) {
  try {
    const res = yield call(api.setCountryActive, action.payload);
    if (res.length === 1 && res[0] === 1) {
      yield call(message.success, 'Save successful');
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchSetCountryActive() {
  yield takeLatest(types.SET_COUNTRY_ACTIVE, setCountryActive);
}

export function* fetchStudioLogins({ type, payload }: any) {
  try {
    const dateBegin = serializeDate(payload.dateBegin, { type: 'startOf' });
    const dateEnd = serializeDate(payload.dateEnd, { type: 'endOf' });
    const res = yield call(withLoading, api.getStudioLogins, type, { dateBegin, dateEnd });
    yield put(actions.setStudioLogins(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchStudioLogins() {
  yield takeLatest(types.GET_STUDIO_LOGINS, fetchStudioLogins);
}

export function* postList(action: any) {
  try {
    const res = yield call(withLoading, api.postList, action.type, action.payload);
    // Attention: Edit response nothing, Add response new record object
    message.success(`${typeof res._id === 'undefined' ? 'Edit' : 'Add'} successful`);
    yield put(push('/admin/talentgroup'));
  } catch (err: any) {
    yield call(action.callback);
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchPostList() {
  yield takeLatest(types.POST_LIST, postList);
}

export function* fetchList(action: any) {
  try {
    const res = yield call(withLoading, api.getList, action.type, action.payload);
    yield put(actions.setList(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchList() {
  yield takeLatest(types.GET_LIST, fetchList);
}

export function* fetchLists(action: any) {
  try {
    const res = yield call(withLoading, api.getLists, action.type, action.payload);
    yield put(actions.setLists(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchLists() {
  yield takeLatest(types.GET_LISTS, fetchLists);
}

export function* fetchAgencies(action: any) {
  try {
    const res = yield call(withLoading, api.getAgencies, action.type, action.payload);
    yield put(actions.setAgencies(res));
    if (action.callback) {
      yield call(action.callback, res);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAgencies() {
  yield takeLatest(types.GET_AGENCIES, fetchAgencies);
}

export function* fetchAgencyDetail(action: ReturnType<typeof actions.getAgencyDetail>) {
  try {
    const res = yield call(withLoading, api.getAgencyDetailById, action.type, action.params);
    yield put(actions.setAgencyDetail(res));
    if (action.callback) {
      yield call(action.callback, res);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchAgencyDetail() {
  yield takeLatest(types.GET_AGENCY, fetchAgencyDetail);
}

export function* PostAgency(action: any) {
  try {
    yield call(withLoading, api.postAgency, action.type, action.payload);
    // Attention: Edit response nothing, Add response new record object
    message.success(`${typeof action.payload._id === 'undefined' ? 'Add' : 'Edit'} successful`);
    yield put(push('/admin/agency'));
  } catch (err: any) {
    yield call(action.callback);
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchPostAgency() {
  yield takeLatest(types.POST_AGENCY, PostAgency);
}

export function* fetchImageList(action: any) {
  try {
    const res = yield call(withLoading, api.getImageList, action.type, action.payload);
    yield put(actions.setImageList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchImageList() {
  yield takeLatest(types.GET_IMAGE_LIST, fetchImageList);
}

export function* fetchGenres() {
  try {
    const res = yield call(api.getGenres);
    const sortedRes = sortBy(res, ['name']);
    yield put(actions.setGenres(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchGenres() {
  yield takeLatest(types.GET_GENRES, fetchGenres);
}

export function* fetchInterests() {
  try {
    const res = yield call(api.getInterests);
    const sortedRes = sortBy(res, ['name']);
    yield put(actions.setInterests(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchInterests() {
  yield takeLatest(types.GET_INTERESTS, fetchInterests);
}

export function* fetchMusicGenres() {
  try {
    const res = yield call(api.getMusicGenres);
    const sortedRes = sortBy(res, ['name']);
    yield put(actions.setMusicGenres(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchMusicGenres() {
  yield takeLatest(types.GET_MUSICGENRES, fetchMusicGenres);
}

export function* fetchListAgencies(action: any) {
  try {
    const list = yield select(selectors.listAgenciesSelector);
    if (!(list && list.length)) {
      const res = yield call(withLoading, api.getListAgencies, action.type, action.payload);
      yield put(actions.setListAgencies(res));
      if (action.callback) {
        yield call(action.callback);
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchListAgencies() {
  yield takeLatest(types.GET_LIST_AGENCIES, fetchListAgencies);
}

export function* fetchListCreators(action: any) {
  try {
    const list = yield select(selectors.listCreatorsSelector);
    if (!(list && list.length)) {
      const res = yield call(withLoading, api.getListCreators, action.type, action.payload);
      yield put(actions.setListCreators(res));
      if (action.callback) {
        yield call(action.callback);
      }
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchListCreators() {
  yield takeLatest(types.GET_LIST_CREATORS, fetchListCreators);
}

export function* ModifyGenres(action: any) {
  try {
    const res = yield call(withLoading, api.modifyGenres, action.type, action);
    message.success(`${action.crud} ${action.typename} successfully`);
    yield call(action.callback);
    // add returns new object, delete returns 1, edit returns nothing
    yield put(
      actions.refreshGenres(
        action.typename,
        action.crud,
        action.crud === 'add' ? res : action.payload,
      ),
    );
  } catch (err: any) {
    yield call(action.callback);
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchModifyGenres() {
  yield takeLatest(types.MODIFY_GENRES, ModifyGenres);
}

export function* fetchCredentials(action: any) {
  try {
    const res = yield call(withLoading, api.getCredentials, action.type, action.payload);
    yield put(actions.setCredentials(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCredentials() {
  yield takeLatest(types.GET_CREDENTIALS, fetchCredentials);
}

export function* UpdateCredential(action: any) {
  try {
    const res = yield call(withLoading, api.updateCredential, action.type, action.payload);
    if (action.callback) {
      yield call(action.callback, res);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchUpdateCredential() {
  yield takeLatest(types.UPDATE_CREDENTIAL, UpdateCredential);
}

export function* fetchChannelTokens(action: any) {
  try {
    const res = yield call(withLoading, api.getChannelTokens, action.type, action.payload);
    yield put(actions.setChannelTokens(res.count, res.rows));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchChannelTokens() {
  yield takeLatest(types.GET_CHANNEL_TOKENS, fetchChannelTokens);
}

export function* fetchInvalidChannelTokens(action: any) {
  try {
    const res = yield call(withLoading, api.getInvalidChannelTokens, action.type, action.payload);
    yield put(actions.setInvalidChannelTokens(res));
    if (action.callback) {
      yield call(action.callback, action.payload.startDate, action.payload.endDate);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchInvalidChannelTokens() {
  yield takeLatest(types.GET_INVALID_CHANNEL_TOKENS, fetchInvalidChannelTokens);
}

export function* notifyInvalidChannelToken(action: any) {
  try {
    yield call(withLoading, api.notifyInvalidChannelToken, action.type, action.payload);
    message.success(`notify ${action.payload.email} successfully`);
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchNotifyInvalidChannelToken() {
  yield takeLatest(types.NOTIFY_INVALID_TOKEN, notifyInvalidChannelToken);
}

export function* fetchCreatorChannels(action: any) {
  try {
    const res = yield call(withLoading, api.getCreatorChannels, action.type, action.payload);
    yield put(actions.setCreatorChannels(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCreatorChannels() {
  yield takeLatest(types.GET_CREATOR_CHANNELS, fetchCreatorChannels);
}

export function* fetchUsers(action: any) {
  try {
    const res = yield call(withLoading, api.getUsers, action.type, action.payload);
    yield put(actions.setUsers(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchUsers() {
  yield takeLatest(types.GET_USERS, fetchUsers);
}

export function* fetchRoles(action: any) {
  try {
    const res = yield call(withLoading, api.getRoles, action.type, action.payload);
    yield put(actions.setRoles(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchRoles() {
  yield takeLatest(types.GET_ROLES, fetchRoles);
}

export function* PostUser(action: any) {
  try {
    const res = yield call(withLoading, api.postUser, action.type, action.payload);
    if (action.callback) {
      yield call(action.callback);
    }
    // Attention: Add response an object: {message: "A user has been created for <email> ."}, Edit response  record object
    message.success(`${typeof res._id === 'undefined' ? 'Add' : 'Edit'} successful`);
    yield put(push('/admin/users'));
  } catch (err: any) {
    if (action.callback) {
      yield call(action.callback);
    }
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchPostUser() {
  yield takeLatest(types.POST_USER, PostUser);
}

export function* NullifyUserPassword(action: any) {
  try {
    yield call(withLoading, api.nullifyUserPassword, action.type, action.payload);
    message.success('User Password Expiration has been nullified');
  } catch (err: any) {
    message.success('There was an error processing your request');
  }
}
function* watchNullifyUserPassword() {
  yield takeLatest(types.NULLIFY_USER_PASSWORD, NullifyUserPassword);
}

export function* ExpireUserPassword(action: any) {
  try {
    yield call(withLoading, api.expireUserPassword, action.type, action.payload);
    message.success('User Password has been expired');
  } catch (err: any) {
    message.success('There was an error processing your request');
  }
}
function* watchExpireUserPassword() {
  yield takeLatest(types.EXPIRE_USER_PASSWORD, ExpireUserPassword);
}

export function* fetchScheduledExports(action: any) {
  try {
    const res = yield call(withLoading, api.getScheduledExports, action.type, action.payload);
    yield put(actions.setScheduledExports(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchScheduledExports() {
  yield takeLatest(types.GET_SCHEDULED_EXPORTS, fetchScheduledExports);
}

export function* cancelScheduledExports(action: any) {
  try {
    const res = yield call(withLoading, api.cancelScheduledExports, action.type, action.payload);
    // response is always [1], which is useless, so we use action.payload. adding res as condition simply to eliminate eslint warning.
    if (action.callback && res) {
      yield call(action.callback, 'cancelId', action.payload);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchCancelScheduledExports() {
  yield takeLatest(types.CANCEL_SCHEDULED_EXPORTS, cancelScheduledExports);
}

export function* excuteScheduledExports(action: any) {
  try {
    const res = yield call(withLoading, api.excuteScheduledExports, action.type, {
      id: action.payload._id,
      force: action.force,
    });
    // response is always [1], which is useless, so we use action.payload. adding res as condition simply to eliminate eslint warning.
    if (action.callback && res) {
      if (res.err) {
        message.error(`There was a problem processing your request: ${res.err}`);
      } else {
        message.success('The export has been sent.');
        const lastItemRun = { export: cloneDeep(action.payload), download: res.duid };
        yield call(action.callback, lastItemRun);
      }
    }
  } catch (err: any) {
    if (err.message) {
      message.error(`There was a problem processing your request: ${err.message}`);
    }
  }
}
function* watchExcuteScheduledExports() {
  yield takeLatest(types.EXCUTE_SCHEDULED_EXPORTS, excuteScheduledExports);
}

export function* resetLastScheduledExport(action: any) {
  try {
    const res = yield call(
      withLoading,
      api.updateScheduledExport,
      action.type,
      action.payload.export,
    );
    if (action.callback && res) {
      if (res.err) {
        message.error(`There was a problem processing your request: ${res.err}`);
      } else {
        const res2 = yield call(
          withLoading,
          api.deleteDownload,
          action.type,
          action.payload.download,
        );
        if (res2.error) {
          message.error(`There was a problem processing your request: ${res2.error}`);
        } else {
          message.success('The export has been reset.');
          yield call(action.callback);
        }
      }
    }
  } catch (err: any) {
    if (err.message) {
      message.error(`There was a problem processing your request: ${err.message}`);
    }
  }
}
function* watchResetLastScheduledExport() {
  yield takeLatest(types.RESET_LAST_SCHEDULED_EXPORT, resetLastScheduledExport);
}

export function* editScheduledExport(action: any) {
  try {
    const res = yield call(withLoading, api.updateScheduledExport, action.type, action.payload);
    if (res) {
      if (res.err || !res[0]) {
        message.error(`There was a problem processing your request: ${res.err}`);
      } else {
        message.success('The changes have been saved.');
        if (action.callback) {
          yield call(action.callback, res);
        }
      }
    }
  } catch (err: any) {
    if (err.message) {
      message.error(`There was a problem processing your request: ${err.message}`);
    }
  }
}
function* watchEditScheduledExport() {
  yield takeLatest(types.EDIT_SCHEDULED_EXPORT, editScheduledExport);
}

export function* fetchPermissions(action: any) {
  try {
    const res = yield call(withLoading, api.getPermissions, action.type, action.payload);
    yield put(actions.setPermissions(res, action.payload));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchPermissions() {
  yield takeLatest(types.GET_PERMISSIONS, fetchPermissions);
}

export function* ModifyPermission(action: any) {
  try {
    yield call(withLoading, api.modifyPermission, action.type, action);
    message.success(`${action.crud} ${action.typename} successfully`);
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchModifyPermission() {
  yield takeLatest(types.MODIFY_PERMISSION, ModifyPermission);
}

export function* fetchUserGroupMembers(action: any) {
  try {
    const res = yield call(withLoading, api.getUserGroup, action.type, action.payload);
    yield put(actions.setPermissions(res.users, 'userGroupMembers'));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchUserGroupMembers() {
  yield takeLatest(types.GET_USER_GROUP_MEMBERS, fetchUserGroupMembers);
}

export function* fetchUserGroupPermissions(action: any) {
  try {
    const res = yield call(withLoading, api.getUserGroup, action.type, action.payload);
    yield put(actions.setPermissions(res.UserPermissions, 'userGroupPermissions'));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchUserGroupPermissions() {
  yield takeLatest(types.GET_USER_GROUP_PERMISSIONS, fetchUserGroupPermissions);
}

export function* fetchUserRolePermissions(action: any) {
  try {
    const res = yield call(withLoading, api.getUserRole, action.type, action.payload);
    yield put(actions.setPermissions(res.UserPermissions, 'userRolePermissions'));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchUserRolePermissions() {
  yield takeLatest(types.GET_USER_ROLE_PERMISSIONS, fetchUserRolePermissions);
}

export function* deleteUserGroupMember(action: any) {
  try {
    const res = yield call(withLoading, api.deleteUserGroupMember, action.type, action);
    if (action.callback && res) {
      // delete succeed, response: 1
      if (res.err) {
        message.error(`There was a problem processing your request: ${res.err}`);
      } else {
        message.success('Delete Succeed.');
      }
    }
  } catch (err: any) {
    if (err.message) {
      message.error(`Opps. There was a problem processing your request: ${err.message}`);
    }
  } finally {
    if (action.callback) {
      yield call(action.callback);
    }
  }
}
function* watchDeleteUserGroupMember() {
  yield takeLatest(types.DELETE_USER_GROUP_MEMBER, deleteUserGroupMember);
}

export function* deleteUserGroupPermission(action: any) {
  try {
    const res = yield call(withLoading, api.deleteUserGroupPermission, action.type, action);
    if (action.callback && res) {
      // delete succeed, response: 1
      if (res.err) {
        message.error(`There was a problem processing your request: ${res.err}`);
      } else {
        message.success('Delete Succeed.');
      }
    }
  } catch (err: any) {
    if (err.message) {
      message.error(`Opps. There was a problem processing your request: ${err.message}`);
    }
  } finally {
    if (action.callback) {
      yield call(action.callback);
    }
  }
}
function* watchDeleteUserGroupPermission() {
  yield takeLatest(types.DELETE_USER_GROUP_PERMISSION, deleteUserGroupPermission);
}

export function* deleteUserRolePermission(action: any) {
  try {
    const res = yield call(withLoading, api.deleteUserRolePermission, action.type, action);
    if (action.callback && res) {
      // delete succeed, response: 1
      if (res.err) {
        message.error(`There was a problem processing your request: ${res.err}`);
      } else {
        message.success('Delete Succeed.');
      }
    }
  } catch (err: any) {
    if (err.message) {
      message.error(`Opps. There was a problem processing your request: ${err.message}`);
    }
  } finally {
    if (action.callback) {
      yield call(action.callback);
    }
  }
}
function* watchDeleteUserRolePermission() {
  yield takeLatest(types.DELETE_USER_ROLE_PERMISSION, deleteUserRolePermission);
}

export function* fetchUserPermissions(action: any) {
  try {
    const res = yield call(withLoading, api.getPermissions, action.type, 'userPermissions');
    yield put(actions.setUserPermissions(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchUserPermissions() {
  yield takeLatest(types.GET_USER_PERMISSIONS, fetchUserPermissions);
}

export function* fetchUserGroupMembersAddable(action: any) {
  try {
    const res = yield call(
      withLoading,
      api.getUserGroupMembersAddable,
      action.type,
      action.payload,
    );
    yield put(actions.setUserGroupMembersAddable(res));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchUserGroupMembersAddable() {
  yield takeLatest(types.GET_USER_GROUP_MEMBERS_ADDABLE, fetchUserGroupMembersAddable);
}

export function* fetchMenus(action: any) {
  try {
    const res = yield call(withLoading, api.getMenus, action.type, action.payload);
    const sortedRes = sortBy(res, ['sortOrder']);
    yield put(actions.setMenus(sortedRes));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchMenus() {
  yield takeLatest(types.GET_MENUS, fetchMenus);
}

export function* fetchMenuItem(action: any) {
  try {
    const funcs = [[api.getUserPermissions], [api.getRoles], [api.getMenus]];
    if (action.payload) {
      funcs.push([api.getMenuItem, action.payload]);
    }
    const [userPermissions, roles, menus, menuItem]: [any, any, any, any] = yield call(
      withLoading,
      funcs,
    );
    yield put(actions.setMenuItem({ userPermissions, roles, menus, menuItem }));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchMenuItem() {
  yield takeLatest(types.GET_MENU_ITEM, fetchMenuItem);
}

export function* ModifyMenuItem(action: any) {
  try {
    yield call(withLoading, api.modifyMenuItem, action.type, action);
    yield put(actions.getMenuItem(action.payload._id));
    message.success(`${action.crud} successful`);
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchModifyMenuItem() {
  yield takeLatest(types.MODIFY_MENU_ITEM, ModifyMenuItem);
}

export function* GetChannelTokensByUserId(action: any) {
  try {
    const res = yield call(withLoading, api.getChannelTokensByUserId, action.type, action.payload);
    if (action.callback) {
      yield call(action.callback, res[0]);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchGetChannelTokensByUserId() {
  yield takeLatest(types.GET_CHANNEL_TOKENS_BY_USER_ID, GetChannelTokensByUserId);
}

export function* importUserIntoOkta({ params, callback }: any) {
  try {
    const res = yield call(api.importOkta, params);
    yield put(actions.setUserOktaId(res));
    yield call(callback, res);
    yield call(message.success, `${params.email}, ${res.message}`);
  } catch (err: any) {
    yield call(callback);
    if (err.message) {
      yield call(message.error, `Fail to import ${params.email}, ${err.message}`);
    }
  }
}
function* watchImportUserIntoOkta() {
  yield takeEvery(types.IMPORT_USER_IN_OKTA, importUserIntoOkta);
}

export function* fetchOwnerList(action: any) {
  try {
    const res = yield call(withLoading, api.getOwnerLit, action.type, action.payload);
    yield put(actions.setOwnerList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchOwnerList() {
  yield takeLatest(types.GET_OWNER_LIST, fetchOwnerList);
}

export function* fetchPlanList(action: ReturnType<typeof actions.getPlanList>) {
  try {
    const res = yield call(withLoading, api.getPlanList, action.type);
    yield put(actions.setPlanList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchPlanList() {
  yield takeLatest(types.GET_PLAN_LIST, fetchPlanList);
}

export function* ModifyOwner(action: any) {
  try {
    const res = yield call(withLoading, api.modifyOwner, action.type, action);
    message.success(`${action.crud} successful`);
    action.payload = { ...action.payload, apiKeyId: res.apiKeyId, usagePlanId: res.usagePlanId };
    yield put(actions.refreshOwnerList(action.crud, 'ownerList', action.payload));
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchModifyOwner() {
  yield takeLatest(types.MODIFY_OWNER, ModifyOwner);
}

export function* deleteOwner(action: any) {
  try {
    const res = yield call(withLoading, api.deleteOwner, action.type, action);
    if (res) {
      if (res.ResponseMetadata.HTTPStatusCode === 200) {
        message.success('Delete Succeed.');
        yield put(actions.refreshOwnerList('delete', 'ownerList', action.payload));
      } else {
        message.error(`There was a problem processing your request: ${res}`);
      }
    }
  } catch (err: any) {
    message.error(`Opps. There was a problem processing your request: ${err}`);
  }
}
function* watchDeleteOwner() {
  yield takeLatest(types.DELETE_OWNER, deleteOwner);
}

export function* fetchUsageList(action: any) {
  try {
    const res = yield call(withLoading, api.getUsageList, action.type, action.payload);
    yield put(actions.setUsageList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchUsageList() {
  yield takeLatest(types.GET_USAGE_LIST, fetchUsageList);
}

export function* fetchUsageDaily(action: any) {
  try {
    const res = yield call(withLoading, api.getUsageDaily, action.type, action.payload);
    yield put(actions.setUsageDaily(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchUsageDaily() {
  yield takeLatest(types.GET_USAGE_DAILY, fetchUsageDaily);
}

export function* fetchCreatorChannelNameList(action: any) {
  try {
    const res = yield call(withLoading, api.getCreatorChannelNameList, action.type, action.payload);
    yield put(actions.setCreatorChannelNameList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchCreatorChannelNameList() {
  yield takeLatest(types.GET_CREATOR_CHANNEL_NAME_LIST, fetchCreatorChannelNameList);
}

export function* fetchTwitterProMediaAccounts(action: any) {
  try {
    const res = yield call(withLoading, api.getTwitterProMediaAccounts, action.type);
    yield put(actions.setTwitterProMediaAccounts(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchTwitterProMediaAccounts() {
  yield takeLatest(types.GET_TWITTER_PRO_MEDIA_ACCOUNTS, fetchTwitterProMediaAccounts);
}

export function* ActiveTwitterProMediaAccount(action: any) {
  try {
    const res = yield call(withLoading, api.queueCirculateJob, action.type, action.payload);
    if (res.error === false) {
      message.success(
        `${
          action.payload.message.activate ? 'Activate' : 'Deactivate'
        } message is handling. Please refresh this page after one minute to confirm.`,
      );
      if (action.payload.message.activate) {
        yield delay(2000);
        // force to reload twitterProMediaAccounts, then will not activate the same handle repeatedly
        window.location.reload();
      } else {
        yield put(actions.deleteTwitterProMediaAccount(action.payload.message));
      }
    } else {
      message.error(
        `${action.payload.message.activate ? 'Activate' : 'Deactivate'} message failed. ${
          res.message
        }`,
      );
    }
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchActiveTwitterProMediaAccount() {
  yield takeLatest(types.ACTIVE_TWITTER_PRO_MEDIA_ACCOUNT, ActiveTwitterProMediaAccount);
}

export function* fetchCredentialList(action: any) {
  try {
    const res = yield call(withLoading, api.getCredentialLit, action.type, action.payload);
    yield put(actions.setCredentialList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCredentialList() {
  yield takeLatest(types.GET_CREDENTIAL_LIST, fetchCredentialList);
}

export function* ModifyAPICredential(action: any) {
  try {
    yield call(withLoading, api.modifyAPICredential, action.type, action);
    message.success(`${action.crud} successful`);
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchModifyAPICredential() {
  yield takeLatest(types.MODIFY_API_CREDENTIAL, ModifyAPICredential);
}

export function* checkFacebookLive(action: any) {
  try {
    const res = yield call(withLoading, api.checkFacebookLive, action.type, action.payload);
    yield put(actions.setFacebookLive(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchCheckFacebookLive() {
  yield takeLatest(types.CHECK_FACEBOOKLIVE, checkFacebookLive);
}

export function* launchFacebookLive(action: any) {
  try {
    const fblData = yield select(selectors.fblSelector);
    const socialId = get(fblData, 'socialId');
    const streamIdStr = get(fblData, 'liveVideo.streamIdStr');
    if (socialId && streamIdStr) {
      const payload = { socialId, streamIdStr };
      const res = yield call(withLoading, api.launchFacebookLive, action.type, payload);
      if (!res.error) {
        message.success('Launched successfully');
      }
    } else {
      message.error('Launched failed');
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchLaunchFacebookLive() {
  yield takeLatest(types.LAUNCH_FACEBOOKLIVE, launchFacebookLive);
}

export function* createTrialUser(action: ReturnType<typeof actions.createTrialUser>) {
  try {
    const res = yield call(withLoading, api.addTrialUser, action.type, action.payload);
    if (action.callback) {
      yield call(action.callback, res);
    }
    message.success('Create trial user successful');
    yield put(pushLocation('/admin/trials'));
  } catch (err: any) {
    if (action.callback) {
      yield call(action.callback);
    }
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchCreateTrialUser() {
  yield takeLatest(types.CREATE_TRIAL_USER, createTrialUser);
}

export function* getTrialUserList(action: ReturnType<typeof actions.getTrialUserList>) {
  try {
    const res = yield call(withLoading, api.getTrialList, action.type);
    yield put(actions.setTrialUserList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchGetTrialUserList() {
  yield takeLatest(types.GET_TRIAL_LIST, getTrialUserList);
}

export function* resendTrialRequest(action: ReturnType<typeof actions.resendTrialConfirmRequest>) {
  try {
    yield call(withLoading, api.sendTrialConfirm, action.type, action.payload);
    yield put(actions.getTrialUserList());
    yield call(action.callback);
    yield call(message.success, 'Resend successfully');
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchResendTrialRequest() {
  yield takeLatest(types.RESEND_TRIAL_REQUEST, resendTrialRequest);
}

export function* enableProdAccount(action: ReturnType<typeof actions.enableProdAccounts>) {
  try {
    yield call(withLoading, api.getEnableProd, action.type, action.payload);
    yield call(action.callback);
    yield call(message.success, 'Enable production account successfully');
    yield put(actions.getTrialUserList());
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchEnableProdAccount() {
  yield takeLatest(types.ENABLE_PROD_ACCOUNT, enableProdAccount);
}

export function* getRemainingInvites() {
  try {
    const invites = yield select(selectors.invitesSelector);
    if (isEmpty(invites)) {
      const res = yield call(withLoading, api.getRemainingInvites);
      yield put(actions.setInvites(res));
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchGetRemainingInvites() {
  yield takeLatest(types.GET_REMAINING_INVITES, getRemainingInvites);
}

export function* inviteUsers(action: ReturnType<typeof actions.inviteUsers>) {
  try {
    const res = yield call(withLoading, api.bulkInviteUsers, action.type, action.params);
    yield call(action.callback, res);
    if (action.isTrial) {
      const invites = yield select(selectors.invitesSelector);
      const left = invites.left - res.filter((i: any) => i.status === 'success').length;
      yield put(actions.setInvites({ left }));
    }
  } catch (err: any) {
    yield call(action.callback, 'error');
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchInviteUsers() {
  yield takeLatest(types.INVITE_USERS, inviteUsers);
}

export function* getInvitesList(action: ReturnType<typeof actions.getInvitesList>) {
  try {
    const res = yield call(withLoading, api.getInviteList, action.type);
    yield put(actions.setInvitesList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchGetInvitesList() {
  yield takeLatest(types.GET_INVITES_LIST, getInvitesList);
}

export function* getAddOns(action: ReturnType<typeof actions.getAddOns>) {
  try {
    const res = yield call(withLoading, api.getAddOns, action.type);
    yield put(actions.setAddOns(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchGetAddOns() {
  yield takeLatest(types.GET_ADDONS, getAddOns);
}

export function* createOrUpdateAddOn(action: ReturnType<typeof actions.createOrUpdateAddOn>) {
  try {
    yield call(api.createOrUpdateAddOn, action.params);
    yield call(action.callback, true);
    yield put(actions.getAddOns());
    yield call(message.success, 'Create AddOn Successfully');
  } catch (err: any) {
    yield call(action.callback, false);
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchCreateOrUpdateAddOn() {
  yield takeLatest(types.CREATE_OR_UPDATE_ADDON, createOrUpdateAddOn);
}

export function* getCirculates(action: ReturnType<typeof actions.getCirculates>) {
  try {
    const res = yield call(withLoading, api.getCirculates, action.type);
    yield put(actions.setCirculates(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}
function* watchFetchCirculates() {
  yield takeLatest(types.GET_CIRCULATES, getCirculates);
}

export function* getCirculateJobs(action: ReturnType<typeof actions.getCirculateJobs>) {
  try {
    const res = yield call(withLoading, api.getCirculateJobs, action.type, action.payload);
    yield put(actions.setCirculateJobs(res));
    if (action.callback) {
      yield call(action.callback, action.payload.startDate, action.payload.endDate);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchGetCirculateJobs() {
  yield takeLatest(types.GET_CIRCULATE_JOBS, getCirculateJobs);
}

export function* getCirculateJobsStatus(action: ReturnType<typeof actions.getCirculateJobsStatus>) {
  try {
    const res = yield call(withLoading, api.getCirculateJobsStatus, action.type, action.payload);
    yield put(actions.setCirculateJobsStatus(res));
    if (action.callback) {
      yield call(action.callback, action.payload.startDate, action.payload.endDate);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchGetCirculateJobsStatus() {
  yield takeLatest(types.GET_CIRCULATE_JOBS_STATUS, getCirculateJobsStatus);
}

export function* queueCirculateJob(action: ReturnType<typeof actions.queueCirculateJob>) {
  try {
    // insert circulateJob before queue, otherwise updateJobStatus will fail because not found jobId.
    const res2 = yield call(withLoading, api.createCirculateJob, action.type, {
      messages: [{ message: action.payload.message }],
      creatorId: action.creatorId,
      creatorName: action.creatorName,
    });
    if (res2.rows) {
      const res = yield call(withLoading, api.queueCirculateJob, action.type, action.payload);
      if (res.error === false) {
        message.success('Create and queue job successful.');
        yield put(push('/admin/datacollection'));
      } else {
        message.error(`queue job failed. ${res.message}`);
      }
    } else {
      message.error(`Create job failed. ${res2}`);
    }

    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchQueueCirculateJob() {
  yield takeLatest(types.QUEUE_CIRCULATE_JOB, queueCirculateJob);
}

export function* batchQueueCirculateJob(action: ReturnType<typeof actions.batchQueueCirculateJob>) {
  try {
    let results = [];
    if (action.payload.length > 0) {
      // only queue the no prev condition limit messages at first
      const noPrevLimitMessages = action.payload.filter((item: any) =>
        BatchJobs.find((job: any) => job.name === item.message.type && !job.params.prev),
      );
      results = yield all(
        noPrevLimitMessages.map((job: any) =>
          call(withLoading, api.queueCirculateJob, action.type, job),
        ),
      );
    }
    const errors = results.filter((item: any) => item.error === true);
    if (errors.length > 0) {
      message.error(
        `Create jobs for backfill new account  failed. ${errors
          .map((item: any) => item.message)
          .join('|')}`,
      );
    } else {
      const res2 = yield call(withLoading, api.createCirculateJob, action.type, {
        messages: action.payload,
        BatchJobs,
        creatorId: action.creatorId,
        creatorName: action.creatorName,
      });
      if (res2.rows) {
        message.success(`${res2.rows} jobs are successful created.`);
        yield put(push('/admin/datacollection'));
      }
    }
    if (action.callback) {
      yield call(action.callback);
    }
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchBatchqueueCirculateJob() {
  yield takeLatest(types.BATCH_QUEUE_CIRCULATE_JOB, batchQueueCirculateJob);
}

export function* fetcAccountList(action: any) {
  try {
    const res = yield call(withLoading, api.getAccountList, action.type, action);
    yield put(actions.setAccountList(res));
  } catch (err: any) {
    if (err.message) {
      yield call(message.error, err.message);
    }
  }
}

function* watchFetchAccountList() {
  yield takeLatest(types.GET_ACCOUNT_LIST, fetcAccountList);
}

export default [
  watchPostUser,
  watchNullifyUserPassword,
  watchExpireUserPassword,
  watchFetchRoles,
  watchModifyGenres,
  watchFetchGenres,
  watchFetchCredentials,
  watchUpdateCredential,
  watchFetchChannelTokens,
  watchFetchInvalidChannelTokens,
  watchNotifyInvalidChannelToken,
  watchFetchCreatorChannels,
  watchFetchUsers,
  watchFetchInterests,
  watchFetchMusicGenres,
  watchFetchCountries,
  watchFetchAgencies,
  watchPostAgency,
  watchFetchImageList,
  watchFetchList,
  watchFetchListAgencies,
  watchFetchListCreators,
  watchFetchLists,
  watchPostList,
  watchSetCountryActive,
  watchFetchStudioLogins,
  watchFetchScheduledExports,
  watchCancelScheduledExports,
  watchExcuteScheduledExports,
  watchResetLastScheduledExport,
  watchEditScheduledExport,
  watchFetchPermissions,
  watchModifyPermission,
  watchFetchUserGroupMembers,
  watchFetchUserGroupPermissions,
  watchFetchUserRolePermissions,
  watchDeleteUserGroupMember,
  watchDeleteUserGroupPermission,
  watchDeleteUserRolePermission,
  watchFetchUserPermissions,
  watchFetchUserGroupMembersAddable,
  watchFetchMenus,
  watchFetchMenuItem,
  watchModifyMenuItem,
  watchGetChannelTokensByUserId,
  watchImportUserIntoOkta,
  watchFetchOwnerList,
  watchModifyOwner,
  watchDeleteOwner,
  watchFetchUsageList,
  watchFetchUsageDaily,
  watchFetchCreatorChannelNameList,
  watchFetchTwitterProMediaAccounts,
  watchActiveTwitterProMediaAccount,
  watchFetchCredentialList,
  watchModifyAPICredential,
  watchLaunchFacebookLive,
  watchLaunchFacebookLive,
  watchCheckFacebookLive,
  watchCreateTrialUser,
  watchGetTrialUserList,
  watchResendTrialRequest,
  watchEnableProdAccount,
  watchGetRemainingInvites,
  watchInviteUsers,
  watchGetInvitesList,
  watchGetAddOns,
  watchCreateOrUpdateAddOn,
  watchFetchAgencyDetail,
  watchFetchCirculates,
  watchGetCirculateJobs,
  watchGetCirculateJobsStatus,
  watchQueueCirculateJob,
  watchBatchqueueCirculateJob,
  watchFetchPlanList,
  watchFetchAccountList,
];
