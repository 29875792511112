import pick from 'lodash/pick';
import { message } from 'antd';
import { optionNewAPI, getOlapBenchmarksApi } from '@/utils/helper';
import { batchAsync } from '@/utils/batchAsync';
import { apiEnums } from '@/utils/constant';
import client from '../Client/index';

export const getAnalyticsOverview = ({ id, ...p }: any) =>
  client.get(`creators/analytics/${id}/detail`, p);

export const getCreatorNameList = (params: any) =>
  client.get('/creators/nameList', {
    simpleSpeed: true,
    ...params,
  });

export const getCreatorInfo = (params: any) => client.get(`creators/analytics/${params.id}`);

export const getMediaReport = (params: any) => {
  /* if (optionNewAPI(apiEnums.olap)) {
    const queryParams = pick(params, ['channel', 'dateBegin', 'dateEnd']);
    if (['facebook', 'youtube'].includes(params.media)) {
      queryParams.byAllDates = params.viewType === 'published' ? 'False' : 'True';
    }
    return client.get(`creators/analytics/${params.id}`, queryParams, optionNewAPI(apiEnums.olap));
  } */
  /* if (enableNewAPI(apiEnums.analytics)) {
    const queryParams = {
      startDate: params.dateBegin,
      endDate: params.dateEnd,
      channel: params.channel,
    };
    if (['facebook', 'youtube'].includes(params.media)) {
      queryParams.byAllDates = params.viewType === 'published' ? 'False' : 'True';
    }
    return client.get(`creators/analytics/${params.id}`, queryParams, optionNewAPI(apiEnums.analytics));
  } */
  if (['Facebook', 'YouTube'].includes(params.channel)) {
    return client.get(`${params.media}/byCreator/${params.id}/${params.viewType}`, {
      dateBegin: params.dateBegin,
      dateEnd: params.dateEnd,
      timeZone: params.timeZone,
    });
  }
  if (params.media === 'twitterVideo') {
    // viewType=published: Post(default)
    // viewType=all: Video-Only
    return client.get(`tweets/byCreator/${params.id}/videos`, {
      dateBegin: params.dateBegin,
      dateEnd: params.dateEnd,
      timeZone: params.timeZone,
    });
  }
  return client.get(`${params.media}/${params.controller || 'byCreator'}/${params.id}`, {
    dateBegin: params.dateBegin,
    dateEnd: params.dateEnd,
    timeZone: params.timeZone,
  });
};

export const postCampaigns = (params: any) => {
  if (params.storyLevel) {
    return client.post(`campaignPosts/story/${params.postId}/${params.campaignId}`, {
      StoryId: params.postId,
      CampaignId: params.campaignId,
    });
  }
  const transParams = {
    PostId: params.postId,
    CampaignId: params.campaignId,
    ChannelId: params.channelId,
  };
  return client.post(`campaignPosts/${params.postId}/${params.campaignId}/${params.channelId}`, {
    ...transParams,
  });
};

export const postCampaignsByFlaskApi = (params: any) =>
  client.post(
    `campaigns/add/${params.campaignId}`,
    {
      postId: params.postId,
      channel: params.channel,
    },
    optionNewAPI(true) as any,
  );

export const deleteCampaigns = (params: any) => {
  const transParams = {
    postIds: params.campaignPostIds,
    _id: params._id,
  };
  return client.post(`campaignPosts/delete/${params._id}`, {
    ...transParams,
  });
};

export const deleteCampaignsByFlaskApi = (params: any) =>
  client.post(
    `campaigns/remove/${params._id}`,
    {
      ...params,
    },
    optionNewAPI(true) as any,
  );

export const updateStoryTitle = ({ media, ...p }: any) => {
  const m = media === 'instagramstories' ? 'instagrams' : media;
  return client.put(`${m}/storyTitle/${p._id}`, p);
};

export const getMediaReportDetails = (params: any) =>
  client.get(
    `creators/analytics/detail/${params.creatorId}/${params.detailId}`,
    {
      channel: params.channel,
    },
    optionNewAPI(apiEnums.analytics) as any,
  );

let lastOlapBenchmarksReq: any;
export const getOlapBenchmarks = (params: any, force?: boolean) => {
  /* eslint-disable */
  if (!params) return null;

  if (params.media === 'twitterVideo') {
    return Promise.resolve({});
  }

  const { media, id, viewType, dateBegin, dateEnd, compareDateBegin, compareDateEnd, timeZone } =
    params;
  const isAvailable = ['dateBegin', 'dateEnd', 'compareDateBegin', 'compareDateEnd'].every(
    (key) => !!params[key],
  );
  if (!isAvailable) return undefined;

  const { url, search } = getOlapBenchmarksApi({
    id,
    channel: media,
    viewType,
    dateBegin,
    dateEnd,
    compareDateBegin,
    compareDateEnd,
    timeZone,
  });
  const apVer = optionNewAPI(apiEnums.olap);

  const currentUrl = window.location.href.trim();
  const shouldReq = !lastOlapBenchmarksReq || currentUrl !== lastOlapBenchmarksReq;
  console.log(
    'service olap req 1: lastOlapBenchmarksReq & shouldReq',
    lastOlapBenchmarksReq,
    shouldReq,
  );

  if (shouldReq) {
    lastOlapBenchmarksReq = currentUrl;
    return client.get(url, search, apVer as any);
  } else {
    lastOlapBenchmarksReq = null;
  }

  if (force /* request from export page */) {
    return client.get(url, search, apVer as any);
  }

  return null;
};

/*
    -- USED --
  DO NOT DELETE
 */
export const getSnapchatDiscoverDetails = (params: any) =>
  client.get(`snapchatDiscover/getEdition/${params.detailId}`);

export const getFacebookDetails = (params: any) =>
  client.get(`facebook/videoInsights/${params.detailId}`);

export const getInstagramstoriesDetails = (params: any) => {
  if (params.campaignId) {
    return client.get(`instagrams/campaign/${params.campaignId}/story/${params.detailId}`);
  }
  return client.get(`instagrams/story/${params.detailId}`);
};

export const getSnapchatDetails = (params: any) => client.get(`snapchat/${params.detailId}`);

export const getFacebookVideoInsights = (params: any) =>
  client.get(`facebook/videoInsights/${params}`);
export const getInstagramDetails = ({ detailId, ...p }: any) =>
  client.get(`instagrams/${detailId}`, p);

export const getYoutubeDetails = (params: any) => client.get(`youtube/${params.detailId}`);

export const getTwitchDetails = (params: any) => client.get(`twitch/${params.detailId}`);

export const getFacebookLiveStatus = (params: any) =>
  client.get('facebook-live', params, optionNewAPI(apiEnums.facebookLive) as any);

export const createVideoSegment = (params: any) => client.post('facebook/videoSegments', params);

export const updateVideoSegment = (params: any) =>
  client.put(`facebook/videoSegments/${params._id}`, params);

export const deleteVideoSegment = (params: any) =>
  client.delete(`facebook/videoSegments/${params.id}`);

export const getStoryListByCreatorChannel = (params: any) => {
  const media = params.media === 'instagramstories' ? 'instagrams' : params.media;
  const controller =
    params.media === 'instagramstories' ? 'storyListByCreatorChannel' : 'storiesList';
  return client.get(
    `${media}/${controller}/${params.channelId}`,
    pick(params, ['dateBegin', 'dateEnd', 'timeZone']),
  );
};

export const updateFrame = (params: any) => {
  const media = params.media === 'instagramstories' ? 'instagrams' : params.media;
  const { media: _, ...payload } = params;

  if (payload._id && (payload.SnapchatStoryId || payload.InstagramStoryId)) {
    return client.put(`${media}/frame/${params._id}`, payload);
  }

  message.error('Invalid request parameters');
};

export const updateFrameBatch = (params: any) => {
  const media = params.media === 'instagramstories' ? 'instagrams' : params.media;
  const { media: _, ...payload } = params;

  if (payload._ids?.length && (payload.SnapchatStoryId || payload.InstagramStoryId)) {
    const { _ids: _, ...omitedPayload } = payload;

    return batchAsync(
      payload._ids.map(
        (it: number) => () => client.put(`${media}/frame/${it}`, { ...omitedPayload, _id: it }),
      ),
      5,
    );
  }

  message.error('Invalid request parameters');
};

export const addInstagramstoriesStory = (params: any) =>
  client.post('instagrams/createStory', params);

export const addSnapchatStory = (params: any) => client.post('snapchat', params);
export const addSnapchatUpdateStory = (params: any) => client.put(`snapchat/${params._id}`, params);
export const addSnapchatDiscoverStory = (params: any) =>
  client.post('snapchatDiscover/add', params.data);
export const addTikTokStory = (params: any) =>
  client.post(`/tiktok/upload/${params.creatorChannelId}`, params.data);

export const getCreatorChannelslId = ({ id, ...p }: any) =>
  client.get(`creatorChannels/creatorChannelId/${id}`, p);

export const getSnapchatStory = (params: any) =>
  client.get(`snapchat/${params.storyId}`, { isEdit: true });

export const getTiktokStory = (params: any) => client.get(`tiktok/${params.storyId}`);

export const getChannelMappings = () => client.get('/static/channelMappings');

export const getExportData = () => client.get('/static/exportData');

export const deleteStory = (params: any) => {
  let api;
  switch (params.media) {
    case 'instagramstories':
      api = 'instagrams/story';
      break;
    case 'snapchat':
      api = 'snapchat';
      break;
    case 'snapchatDiscover':
      api = 'snapchatDiscover';
      break;
    default:
      api = '';
  }
  return client.delete(`${api}/${params._id}`);
};

export const getAgencies = () => client.get('/agencies/nameList');
