export const { REACT_APP_PULSE: LEARNING_CENTER_HOST } = process.env;

export const HELP = `${LEARNING_CENTER_HOST}/learning-center/content/si_application/social_insights_application.htm`;

export const PATH_2_PAGE_NAME = {
  '/leaderboard': 'Leaderboard',
  '/accounts': 'Accounts',
  '/analytics': 'Analytics',
  '/campaigns': 'Campaigns',
  '/contenttags': 'Content Tags',
  '/settings': 'Settings',
};

export const PATH_2_LINK = Object.entries({
  '/leaderboard': '/learning-center/content/si_application/dashboards/leaderboard_dashboard.htm',
  '/accounts': '/learning-center/content/si_application/dashboards/accounts_dashboard.htm',
  '/analytics': '/learning-center/content/si_application/dashboards/analytics_dashboard.htm',
  '/campaigns': '/learning-center/content/si_application/dashboards/campaigns_dashbaord.htm',
  '/contenttags': '/learning-center/content/si_application/dashboards/content_tag_dashboard.htm',
  '/settings': '/learning-center/content/si_application/dashboards/settings_dashboard.htm',
}).reduce((prev, [key, val]) => ({ ...prev, [key]: `${LEARNING_CENTER_HOST}${val}` }), {}) as {
  [key in keyof typeof PATH_2_PAGE_NAME]: string;
};
