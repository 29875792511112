import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import toArray from 'lodash/toArray';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import moment from 'moment-timezone';

export const DATE_OPTION_REGEX = /^[1-9][0-9]*$/;
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const DATE_TIME_REGEX = /^\d{4}-\d{2}-\d{2}(\s\d{2}:\d{2}:\d{2})?$/;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';

// class indicator for the dropdown patch (click outside of the period select component)
export const PERIOD_GROUP_CONTEXT = 'periodGroupContext';

// default duration for period only with start date, i.e 'current' indicator
export const DEFAULT_DURATION = 7;

/**
 * available opers of PeriodGroup.config.periodOptions[i].operators
 */
export const AVAILABLE_OPTION_OPERATORS = ['custom', 'clear'];

/**
 * filter an object by leaving not empty value
 * @param {*} {
 *   a: 1,
 *   b: 0,
 *   c: false,
 *   d: undefined,
 *   e: null,
 * }
 * @return {
 *   a: 1
 * }
 */
export const filterObject = (obj: any) => {
  if (!isPlainObject(obj)) return obj;

  return pickBy(obj, (value) => Boolean(value));
};

/**
 * convert the option config to specific shape
 * @param {*} operators
 * @param {*} onlyOn // leave item with true
 * @return {object} {
 *   custom: true, // has custom trigger
 *   clear: true, // has clear tigger
 * }
 */
export const formatPeriodOperatorsConfig = (operators: any, onlyOn = true, onlyLeaveKey = true) => {
  const defaultConfig = {
    custom: true,
    clear: false,
  };

  if (isEmpty(operators)) return defaultConfig;

  let rez;
  if (isPlainObject(operators)) {
    rez = { ...defaultConfig, ...operators };
  } else {
    rez = toArray(operators).reduce((prev, item) => {
      prev[item] = true;

      return prev;
    }, defaultConfig);
  }
  rez = pick(rez, AVAILABLE_OPTION_OPERATORS);

  if (!onlyOn) return onlyOn;

  const availableConfig = filterObject(rez);

  return onlyLeaveKey ? keys(availableConfig) : availableConfig;
};

/**
 * format to 'YYYY-MM-DD' or 'YYYY-MM-DD hh:mm:ss'
 */
export const formatDateStr = (mm?: any, time?: any) => {
  const timeShape = time === true;

  const formater = (mm2: any) =>
    (mm2 || moment()).format(timeShape ? DATE_TIME_FORMAT : DATE_FORMAT);

  return Array.isArray(mm) ? mm.map(formater) : formater(mm);
};

/**
 * get the duration as day
 * @param {*} period: ['dt1', 'dt2']
 * @return
 *   more=false: duration days
 *   more=true: [duration days, startMoment, endMoment]
 */
export const getDuration = (period?: any, more: any = false) => {
  const [start, end] = toArray(period);
  const mmStart = moment(start);
  const mmEnd = moment(end);
  const duration = moment.duration(mmEnd.diff(mmStart));
  const durationDays = Math.abs(Math.floor(duration.asDays()));

  return more ? [durationDays, mmStart, mmEnd] : (durationDays as any);
};

export const isEastern = () => new Date().getTimezoneOffset() < 0;

/**
 * current date/datetime string
 * @param {*} time
 */
export const getYesterday = (time: any) =>
  formatDateStr(moment(new Date()).subtract(1, 'day'), time);

/**
 * calc today value for local env according to location and timezone
 * @param {*} time
 */
export const getToday = (time?: boolean) => {
  const inEastern = isEastern();
  const currentStr = moment().format('MMMM Do YYYY, hh:mm:ss a');
  const isUsAm = currentStr.endsWith('am');

  const todayStr = formatDateStr(moment(new Date()), time);
  const tommorrowStr = formatDateStr(moment(new Date()).add(1, 'day'), time);

  if (!isUsAm) {
    if (inEastern) return tommorrowStr;

    return todayStr;
  }

  return todayStr;
};

/**
 * current date/datetime string
 * @param {*} time
 */
export const getPeriodEnd = (time: any, duration: any, currentToggle: any) => {
  const inEastern = isEastern();
  const currentStr = moment().format('MMMM Do YYYY, hh:mm:ss a');
  const yesterdayStr = getYesterday(time);
  const isUsAm = currentStr.endsWith('am');

  const todayStr = formatDateStr(moment(new Date()), time);
  const tommorrowStr = formatDateStr(moment(new Date()).add(1, 'day'), time);

  if (!currentToggle) {
    // bj morning, ny night
    if (!isUsAm) {
      if (inEastern) return +duration === 1 /* today */ ? tommorrowStr : todayStr;

      return +duration === 1 /* today */ ? todayStr : yesterdayStr;
    }

    return +duration === 1 /* today */ ? todayStr : yesterdayStr;
  }

  // bj morning, ny night
  if (!isUsAm) {
    if (inEastern) return +duration === 2 /* yesterday */ ? todayStr : tommorrowStr;

    return +duration === 2 /* yesterday */ ? yesterdayStr : todayStr;
  }

  return +duration === 2 /* yesterday */ ? yesterdayStr : todayStr;
};

/**
 * convert yyyy-mm-dd to mm-dd-yyyy
 */
export function dateToMMDDYYYY(yyyyMMDD = '') {
  if (typeof yyyyMMDD !== 'string' && !Array.isArray(yyyyMMDD)) return yyyyMMDD;

  const arrDetail = typeof yyyyMMDD === 'string' ? yyyyMMDD.split('-') : yyyyMMDD;

  return arrDetail[0].length === 4
    ? [arrDetail[1], arrDetail[2], arrDetail[0]].join('-')
    : yyyyMMDD;
}
